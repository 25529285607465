import './header.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { Logo } from 'brands';

const Header = ({ title, left, right }) => (
    <div className="header">
        <div className="container">
            <div className="left">
                {left}
            </div>

            <div className="middle">
                {title}
            </div>

            <div className="right">
                {right}
            </div>
        </div>
    </div>
);

Header.propTypes = {
    title: PropTypes.any,
    left: PropTypes.any,
    right: PropTypes.any
};

Header.defaultProps = {
    title: <Logo useLocation />,
    left: null,
    right: null
};

export default Header;
