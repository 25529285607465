import React from 'react';
import PropTypes from 'prop-types';
import './moduleInfo.scss';
import { TabsBase } from 'apps/administration/components';
import { EditableContent } from 'apps/administration/containers';

const EditableContentTab = ({ options }) => {
    const optionsTab = options.map(
        opt => (
            <TabsBase.TabPane
                tab={opt.tab}
                key={opt.key}
            >
                <div className={`page-scroll module-info module-info-${opt.module}`}>
                    <EditableContent
                        name={opt.name}
                        editable={opt.editable}
                        className="editable-tab-content"
                    />
                </div>
            </TabsBase.TabPane>
        )
);
   return (
       <TabsBase>
           {optionsTab}
       </TabsBase>
   );
};

EditableContentTab.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            editable: PropTypes.any.isRequired,
            name: PropTypes.any.isRequired,
            onClick: PropTypes.func.isRequired,
            tab: PropTypes.any.isRequired,
            key: PropTypes.any.isRequired,
            module: PropTypes.string.isRequired
        })
    ).isRequired
};

export default EditableContentTab;
