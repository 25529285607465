export default {
    title: 'Edit Profile',
    registration: 'Registration',
    subtitle: 'Looks like you’re new here.',
    notes1: 'We need to know a little bit about you, then we’ll get you screening as quickly as possible.',
    notes2: 'If you are experiencing severe or life-threatening symptoms, you should immediately SEEK MEDICAL ATTENTION BY CALLING {emergencyNumber}',
    placeholders: {
        employeeNumber: 'Add your ID Number',
        firstName: 'Add your first name',
        lastName: 'Add your last name',
        jobCategory: 'Please select',
        address: 'Add your address',
        address2: 'Add your address',
        gender: 'Please select',
    },
    labels: {
        employeeNumber: 'ID number',
        employeeNumberTooltip: 'This is your Employee ID, Student ID, or other identifying number assigned by the organization asking you to screen.',
        firstName: 'First Name',
        lastName: 'Last Name',
        jobCategory: 'I am...',
        jobCategoryTooltip: `Other workers include:
            
            • Federal, state, and local law enforcement

            • {emergencyNumber} call center employees

            • Fusion center employees

            • Hazardous material responders (government and private sector)

            • Janitorial staff and other custodial staff

            • Workers (including contracted vendors) in food and agriculture
            
            • Critical manufacturing, information technology, transportation, energy, and government facilities
        `,
        button: 'Finish registration',
        buttonSave: 'Save Changes', 
        optin: {
            screen: 'Yes, please send me reminder messages and notifications from admins via SMS or email! I can always opt out later. Message and data rates may apply.',
        },
        terms: 'By continuing, you agree to the {termsLink} and {policyLink} ',
        phone: 'Phone Number',
        dateOfBirth: 'Date of Birth',
        address: 'Address 1',
        address2: 'Address 2',
        city: 'City',
        state: 'State',
        zip: 'Zip Code',
        medicalConsentBefore: 'By continuing, you authorize the {locationTitle}\'s',
        medicalConsentMiddle: 'Use and Disclosure of Confidential Medical Information',
        medicalConsentAfter: 'by Authorized Personnel.',
        gender: 'Gender',
    },
    mergeUsers: {
        buttonSave: 'Save Changes',
        text: `Our system indicates that this phone number {phone} is already in use with two different user profiles.
        Please select from below the user profile
        that you will be using from now onwards.`,
        current: 'Current',
        note: 'All your user data will be saved on the selected user profile.'
    },
};
