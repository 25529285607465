/* eslint-disable class-methods-use-this */
import { local } from 'services';
import { LocationService } from './location.service';
import { BenefitsService } from '../benefits';
import { LocationConstants } from './location.constants';
import { JobTitleService } from '../jobtitle';

class LocationActionsClass {
    benefitsCache = {};

    jobTitlesCache = {};

    questionsCache = {};

    newsFeedCache = {};

    static get(id) {
        return (dispatch) => {
            dispatch(({ type: LocationConstants.LOADING }));
            return LocationService.get(id)
                .then((data) => {
                    dispatch(({ type: LocationConstants.LOADED, data }));
                    return data;
                })
                .catch((error) => {
                    dispatch(({ type: LocationConstants.ERROR, error }));
                });
            };
    }

    getByCid(cid) {
        // eslint-disable-next-line consistent-return
        return async (dispatch) => {
            dispatch(({ type: LocationConstants.LOADING }));
            try {
                const data = await LocationService.getByCid(cid);
                dispatch(({ type: LocationConstants.LOADED, data }));
                return data;
            } catch (error) {
                dispatch(({ type: LocationConstants.ERROR, error }));
            }
        };
    }

    getByDomain(subdomain) {
        return (dispatch) => {
            dispatch(({ type: LocationConstants.LOADING }));
            return LocationService.getByDomain(subdomain)
                .then((data) => {
                    dispatch(({ type: LocationConstants.LOADED, data }));
                    
                    local.subdomain(subdomain);
                    return data;
                })
                .catch((error) => {
                    dispatch(({ type: LocationConstants.ERROR, error }));
                });
            };
    }

    questions(locationId, forEdit) {
        const cached = this.questionsCache[locationId];
        if (cached) {
            return new Promise((resolve) => { resolve(cached); });
        }
        return LocationService.questions(locationId, forEdit)
            .then((data) => {
                this.questionsCache[locationId] = data;
                return data;
            });
    }

    benefits(locationId) {
        const cached = this.benefitsCache[locationId];
        if (cached) {
            return new Promise((resolve) => { resolve(cached); });
        }
        return BenefitsService.list(locationId)
            .then((data) => {
                this.benefitsCache[locationId] = data;
                return data;
            });
    }

    jobtitles(locationId) {
        const cached = this.jobTitlesCache[locationId];
        if (cached) {
            return new Promise((resolve) => { resolve(cached); });
        }
        return JobTitleService.list(locationId)
            .then((data) => {
                this.jobTitlesCache[locationId] = data;
                return data;
            });
    }
}

const LocationActions = new LocationActionsClass();

export { LocationActions }; 
