export const AUTH_TYPE = {
    OTP: 'phone',
    EMAIL: 'email',
    PHONE: 'phonePassword',
    SSO: 'sso',
    GOOGLE: 'google',
    FACEBOOK: 'facebook',
    APPLE: 'apple',
    ADMIN: 'admin', // email+password form,
};

export const USER_ATTRS = {
    phone: 'phone_number',
    email: 'email',
    mfa: 'custom:mfa',
};

export const MFA_TYPE = {
    phone: 'phone_number',
    email: 'email',
};

export const AMPLIFY_SYMBOL = (typeof Symbol !== 'undefined'
    && typeof Symbol.for === 'function'
    ? Symbol.for('amplify_default')
    : '@@amplify_default');

export const AuthState = {
    PreSignIn: 'preSignIn',
    SignIn: 'signIn',
    ConfirmSignIn: 'confirmSignIn',
    ConfirmSignInOTP: 'confirmSignInOTP',
    SignedIn: 'signedIn',
    
    SignUp: 'signUp',
    ConfirmSignUp: 'confirmSignUp',
    SignedUp: 'signedUp',
    
    ForgotPassword: 'forgotPassword',
    ForgotPasswordCode: 'forgotPasswordCode',
    ForgotPasswordEmail: 'forgotPasswordEmail',
    RequireNewPassword: 'requireNewPassword',
    VerifyPhone: 'verifyPhone',
    VerifyEmail: 'VerifyEmail',
    SignedOut: 'signedOut',
    TOTPSetup: 'TOTPSetup',

    CustomConfirmSignIn: 'customConfirmSignIn',
    MFASetup: 'MFASetup',
};

export const HubEvents = {
    customOAuthState: 'customOAuthState',
    customState_failure: 'customState_failure',
    customSignIn: 'customSignIn',
    customSignOut: 'customSignOut',
    cognitoHostedUI: 'cognitoHostedUI',
    signIn: 'signIn',
    cognitoHostedUI_failure: 'cognitoHostedUI_failure',
    parsingUrl_failure: 'parsingUrl_failure',
    signOut: 'signOut'
};

const AWS_AUTH_CALLBACK = process.env.REACT_APP_AWS_CALLBACK || 'token'; // must match cognito setting

export const AuthConstants = {
    REDIRECTED_FROM_HOSTED_UI: 'amplify-redirected-from-hosted-ui',
    SIGNIN_FROM_HOSTED_UI: 'amplify-signin-with-hostedUI',
    AUTHENTICATOR_AUTHSTATE: 'amplify-authenticator-authState',
    AWS_AUTH_CALLBACK,
};

/*
@Note
SMS_MFA: The user needs to input the code received from SMS message. 
    You can submit the code by Auth.confirmSignIn.
SOFTWARE_TOKEN_MFA: The user needs to input the OTP(one time password). 
    You can submit the code by Auth.confirmSignIn.
NEW_PASSWORD_REQUIRED: This happens when the user account is created 
    through the Cognito console. The user needs to input the new 
    password and required attributes. You can submit those data by Auth.completeNewPassword.
MFA_SETUP: This happens when the MFA method is TOTP(the one time password) 
    which requires the user to go through some steps to generate those passwords. 
    You can start the setup process by Auth.setupTOTP.
*/
export const Challenges = {
    SMS_MFA: 'SMS_MFA',
    SOFTWARE_TOKEN_MFA: 'SOFTWARE_TOKEN_MFA',
    NEW_PASSWORD_REQUIRED: 'NEW_PASSWORD_REQUIRED',
    MFA_SETUP: 'MFA_SETUP',
    CUSTOM_CHALLENGE: 'CUSTOM_CHALLENGE'
};
