export default {
    immunization: 'Immunization',
    immunizations: 'Immunizations',
    noImmunizations: 'No immunization data yet',

    buttonReport: 'Add Immunization',
    buttonReportExempt: 'Add Immunization Exemption',
    immunizationTitle: 'Add Immunization',
    exemptTitle: 'Add Immunization Exemption',
    
    viewMore: 'View More',
    viewLess: 'View Less',
    form: {
        type: 'Vaccine Type',
        date: 'Date',
        vaccineLocation: 'Vaccine Location',
    },
    formExempt: {
        type: 'Vaccine Type',
        date: 'Date',
        reason: 'Reason',
        religion: 'Religious',
        disability: 'Medical',
        other: 'Other'
    },
    success: {
        backToHomeBtnText: 'Back to Homepage',
        immunization: {
            title: 'Immunization reported successfully!', 
            text: 'Thank you for reporting your immunization.',
        },
        exempt: {
            title: 'Exemption (Unvaccinated) reported successfully!', 
            text: 'Thank you for reporting.', 
        }
    }
};
