import React from 'react';
import { IconButton } from 'components';
import { AddIcon } from 'components/Icons';
import { useNavigate } from 'routing';

const AddButton = ({ ...rest }) => {
    const navigate = useNavigate();

    const onClickDefault = () => {
        navigate('..');
    };
    return (
        <IconButton 
            onClick={onClickDefault}
            size="large"
            {...rest}
        >
            <AddIcon fontSize="inherit" />
        </IconButton>
    );
};

export default AddButton;
