import { api } from 'services';
import transformer from './question.transformer';

class QuestionService {
    static list(locationId, forEdit, oldAdmin) {
        return api.get(`/locations/${locationId}/questions`)
            .then(({ data }) => data)
            .then(data => transformer.in(data, forEdit, oldAdmin));
    }

    static update(id, data) {
        const postData = transformer.out(data);
        return api.post(`/locations/${id}/questions`, { data: postData });
    }
}

export { QuestionService }; 
