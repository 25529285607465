export default {
    locationNotFound: 'Al parecer está usando un enlace no válido. Por favor contacte a un representante de su organización para obtener uno correcto.',
    patientNotFound: 'Error cargando usuario.',
    codeSent: 'Le enviamos otro código. Por favor revise sus mensajes.',
    lastCodeSent: 'Le enviamos otro código. Ha alcanzado el número máximo de mensajes de texto por este día.',
    lastCodeSentError: 'Ha alcanzado el número máximo de mensajes de texto por este día.',
    missingPhoneNumber: 'El número de teléfono no está registrado.',
    verCodeMissing: 'Falta el código de verificación',
    codeExpired: 'El código ha expirado.',
    codeWrong: 'Hmmm... Ese código no funcionó. Revíselo y trate de ingresarlo de nuevo. Tiene {message} intentos restantes.',
    networkError: 'Falla en la red',
    unknownError: 'Ha ocurrido un error desconocido. Por favor inténtelo de nuevo.',
    missingLocation: 'Por favor añada identificación de ubicación o indicación válida', 
    lockedNotifications: 'Las notificaciones están bloqueadas. Por favor desbloquear',
    missingContact: 'Por favor complete su primer evaluacion antes de editar las configuraciones de perfil.', 
    serverError: 'Ups, Ahora estamos teniendo problemas para conectarnos con el servidor. Por favor inténtelo de nuevo en unos minutos.', 
    invalidCredentials: 'Correo electronico o contraseña no válida',
    userLimitReached: 'Se alcanzó el límite de usuarios para su cuenta. Comuníquese con la persona responsable en su organización.',
    patientInactive: 'Su estado se encuentra inactivo, por favor contacte su administrador de sitio.',
    registrationDisabled: 'Registro no permitido. Por favor contacte a su representante de HR para mas detalles.' 
};
