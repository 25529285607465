import './wrapper.scss';
import React from 'react';
import PropTypes from 'prop-types';
import Header from '../header';
import Footer from '../footer';
import MainMenu from '../menu';
import SideMenu from '../sidemenu';

const Wrapper = ({ children, className, footer, title, right, left }) => (
    <div className={`lintelio-wrapper ${className}`}>
        <Header
            title={title}
            left={left}
            right={right}
        />
        <div className="content">
            <div className="container">
                {children}
            </div>
        </div>
        {footer && (
            <Footer>
                {footer}
            </Footer>
        )}
    </div>
);

Wrapper.propTypes = {
    footer: PropTypes.any,
    children: PropTypes.any,
    className: PropTypes.string,
    right: PropTypes.any,
    left: PropTypes.any,
    title: PropTypes.any,
};

Wrapper.defaultProps = {
    className: '',
    footer: <MainMenu />,
    children: null,
    right: null,
    left: <SideMenu />,
    title: undefined,
};

export default Wrapper;
