/* eslint-disable array-callback-return */
import { PatientConstants } from 'modules/patient/patient.constants';
import { CovidFlow } from 'modules/screening/covid/flow';
import { AttendanceFlow } from 'modules/screening/attendance/flow';
import { IntakeFlow } from 'modules/screening/intake/flow';
import constants from './screening.constants';
import { QUESTION_OUTCOMES } from './screening.model';

const initialState = Object.freeze({
    $on: false,
    $ended: false,
    questions: [],
    answers: [],
    current: {},
    callback: undefined,
    outcomes: {},
});

const getScreening = (config) => {
    if (config.$screening === 'attendance') {
        return AttendanceFlow(config).map((item, index) => ({ ...item, index }));
    }
    if (config.$screening === 'intake') {
        return IntakeFlow(config).map((item, index) => ({ ...item, index }));
    }
    return CovidFlow(config).map((item, index) => ({ ...item, index }));
};

const showIf = (condition, outcomes) => {
    if (!condition) {
        return true;
    }
    return condition(outcomes);
};

const findNext = (questions, currentIdx, outcomes) => {
    const nextIdx = currentIdx + 1;
    if (nextIdx >= questions.length) {
        return false;
    }
    const next = questions[nextIdx];
    const showIt = showIf(next.show, outcomes);
    if (showIt) {
        return next;
    }
    return findNext(questions, nextIdx, outcomes);
};

const getOutcome = (options, value, outcomeFn, answers, isIgnore) => {
    if (outcomeFn) {
        return outcomeFn(value);
    }
    if (options) {
        if (isIgnore) {
            const finalOutcome = {
                ignoreExposure: null, 
                ignoreSymptoms: null
            };
            answers
                .filter(i => i.outcome === QUESTION_OUTCOMES.IGNORE)
                .map(({ options: itemOptions, value: itemValue }) => {
                    const valueArr = Array.isArray(itemValue) ? itemValue : [itemValue];
                    const picked = itemOptions
                        .filter(i => valueArr.find(answeredValue => i.value === answeredValue));
                    picked.map((answered) => {
                        const { ignoreExposure, ignoreSymptoms } = answered.outcome;
                        if (ignoreExposure) finalOutcome.ignoreExposure = true;
                        if (ignoreSymptoms) finalOutcome.ignoreSymptoms = true;
                    });
                });
                    
            return finalOutcome;
        }
        return options.some((item) => {
            if (!item.outcome) {
                return false;
            }
            if (Array.isArray(value)) {
                return value.includes(item.value);
            }
            return item.value === value;
        });
    }
    return false;
};

const screening = (state = initialState, action = {}) => {
    switch (action.type) {
        case PatientConstants.LOGOUT: {
            return {
                ...initialState,
            };
        }
        case constants.START: {
            const questions = getScreening(action.config);
            const current = findNext(questions, -1, {});
            return {
                ...state,
                $on: true,
                current,
                questions,
                outcomes: {},
                config: action.config,
                title: action.config?.title
            };
        }
        case constants.PREV: {
            const { answers, outcomes } = state;
            if (!answers.length) {
                return initialState;
            }
            const current = answers.pop();
            outcomes[current.outcome] = false;
            return {
                ...state,
                answers,
                current,
                outcomes,
            };
        }
        case constants.ANSWER: {
            const { answers: oldAnswers, questions, outcomes } = state;
            const { answer } = action;
            const { value, options, outcome, outcomeFn } = answer;

            const isIgnore = QUESTION_OUTCOMES.IGNORE === outcome;
            const allAnswers = [...oldAnswers, answer];
            const newOutcome = getOutcome(options, value, outcomeFn, allAnswers, isIgnore);
            let isOutcome = false;
            if (newOutcome === true || Object.isString(newOutcome) || Object.isObject(newOutcome)) {
                outcomes[outcome] = newOutcome;
                isOutcome = true;
            } 

            const answers = [...oldAnswers, { ...answer, newOutcome, isOutcome }];//
            const current = findNext(questions, answer.index, outcomes);
            return {
                ...state,
                answers,
                current,
                outcomes,
            };
        }
        case constants.SENT: {
            return {
                ...state,
                ...action.data
            };
        }
        case constants.END: {
            return {
                ...initialState,
                $ended: true,
            };
        }
        default:
            return state;
    }
};

export default screening;
