import './masked.scss';
import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import PropTypes from 'prop-types';
import locales from 'locales';
import { TempConfig } from 'utils';

export const MaskedTemperature = ({ onChange, unit }) => {
    const { min, max, maskDefault, maskMax, startWith } = TempConfig[unit];
    const [mask, setMask] = useState(maskDefault);

    const onBeforeMaskedStateChange = ({ nextState, currentState, previousState }) => {
        let newState = { ...nextState };
        let { value: nextValue } = nextState;
        const { value: currentValue } = currentState;
        const first = currentValue.charAt(0);
    
        if (!startWith.includes(first)) {
            nextValue = '';
            setMask(maskDefault);
        } else if (parseFloat(nextValue) > max && previousState) {
            newState = { ...previousState };
            nextValue = previousState.value;
        }

        return {
            ...newState,
            value: nextValue
        };
    };

    const setTemperature = (event) => {
        const temperature = parseFloat(event.target.value);
        
        let newMask = maskDefault;
        if (/^1/.test(temperature)) {
            const suffixOne = temperature.toString().length >= 3 ? '.9' : '';
            newMask = maskMax + suffixOne;
        } else {
            const suffixNine = temperature.toString().length >= 2 ? '.9' : '';
            newMask = maskDefault + suffixNine;
        }
        setMask(newMask);

        if (temperature >= min && temperature <= max) {
            onChange(temperature);
        } else {
            onChange(null);
        }
    };

    return (
        <>
            <InputMask
                className="c-input block c-input-temperature"
                mask={mask}
                beforeMaskedStateChange={onBeforeMaskedStateChange}
                onChange={setTemperature}
                maskPlaceholder={null}
                inputMode="numeric"
            />
            <small>
                {locales.formatString(locales.fields.validation.temperature, { min, max, unit })}
            </small>
        </>
    );
};

MaskedTemperature.propTypes = {
    onChange: PropTypes.func,
    unit: PropTypes.string,
};

MaskedTemperature.defaultProps = {
    unit: 'F',
    onChange: () => {},
};
