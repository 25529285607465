import React from 'react';
import PropTypes from 'prop-types';
import { List, ListItem } from 'components';
import locales from 'locales';
import { BackButton } from 'apps/lintelio/layout';
import iconLang from 'apps/lintelio/assets/icons/ico-language.svg';
import RemindersMenuItems from './sidemenu.reminders';

const icons = {
    language: <div className="icon small"><img src={iconLang} alt="Language" /></div>,
};

const SideMenuItemsSettings = ({ onBack, onNext, onClose }) => { 
    const { common: { menu: strings } } = locales;
    return (
        <List>
            <ListItem onClick={onBack}>
                <BackButton 
                    size="small" 
                    onClick={() => {}} 
                    text
                />
            </ListItem>
            <ListItem
                prefix={icons.language}
                onClick={onNext}
                arrow
            >
                {strings.language}
            </ListItem>
            <RemindersMenuItems onClose={onClose} />
        </List>
    );
};

SideMenuItemsSettings.propTypes = {
    onNext: PropTypes.func,
    onBack: PropTypes.func,
    onClose: PropTypes.func.isRequired,
};

SideMenuItemsSettings.defaultProps = {
    onBack: () => {},
    onNext: () => {}
};

export default SideMenuItemsSettings;
