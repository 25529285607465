import { api } from 'services';
import Transformer from './transformers';

class AnnouncementService {
    static list(id) {
        return api.list(`/locations/${id}/newsfeed`)
        .then(Transformer.list);
    }

    static getByLocation(id) {
        return api.list(`/locations/${id}/admin/newsfeed`)
        .then(Transformer.list);
    }

    static update(id, postData) {
        const outData = Transformer.out(postData);
        return api.post(`/locations/${id}/newsfeed`, outData)
                  .then(Transformer.list);
    }
}

export { AnnouncementService };
