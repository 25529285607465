import { createStore, compose, applyMiddleware, combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';
import patient from './patient/patient.reducer';
import location from './location/location.reducer';
import screening from './screening/screening.reducer';
import user from './user/user.reducer';
import layout from './layout/layout.reducer';
import alerts from './notification/notification.reducer';
import admin from './admin/admin.reducer';
import provider from './provider/provider.reducer';
import document from './document/document.reducer';

const RootReducer = combineReducers({
    patient,
    location,
    screening,
    user,
    layout,
    alerts,
    admin,
    provider,
    document
});

const store = createStore(
    RootReducer,
    compose(
        applyMiddleware(
            thunkMiddleware,
        ),
    )
);

export default store;
