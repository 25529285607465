import './phone.scss';
import React, { useState } from 'react';
import PhoneInput, { getCountryCallingCode } from 'react-phone-number-input';

import PropTypes from 'prop-types';
import SelectCode from './code';

const defaultCountry = 'US';

const countries = {
    US: getCountryCallingCode('US'),
    BG: getCountryCallingCode('BG'),
    HN: getCountryCallingCode('HN'),
    MX: getCountryCallingCode('MX'),
    DE: getCountryCallingCode('DE'),
    NL: getCountryCallingCode('NL'),
};

export const Phone = ({ onChange, value, disabled, name, className }) => {
    const [code, setCode] = useState(defaultCountry);

    const changeHandler = (phone) => {
        onChange(phone || '');
    };

    let inputProps;
    if (value) {
        inputProps = { value };
    }

    return (
        <PhoneInput
            {...inputProps}
            className={`${className} ${disabled ? 'PhoneInput-disabled' : ''}`}
            name={name}
            placeholder={`+${countries[code]}`}
            disabled={disabled}
            countries={Object.keys(countries)}
            countrySelectComponent={
                // eslint-disable-next-line react/no-unstable-nested-components
                React.forwardRef((props, ref) => (
                    <SelectCode
                        {...props}
                        ref={ref}
                        setCode={setCode}
                    />
                ))
            }
            defaultCountry={defaultCountry}
            limitMaxLength
            onChange={changeHandler}
            inputMode="tel"
        />
    );
};

Phone.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.any,
    disabled: PropTypes.bool,
    name: PropTypes.string,
    className: PropTypes.string
};

Phone.defaultProps = {
    onChange: () => { },
    value: undefined,
    disabled: false,
    name: '',
    className: ''
};
