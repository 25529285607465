import React from 'react';

const TestsGuidlineText = () => (
    <div className="body p-bulk">
        <span className="bold">Overview:</span>
        <p>
            - Please fill in all mandatory fields for
            uploaded tests in the &quot;Bulk upload input sheet&quot;
        </p>
        <p>
            - Please follow the below guidelines for data
            in the specific fields
        </p>
        <p className="mb-s">
            - If there are missing mandatory fields or data formats
            of the fields are incorrect, no tests will be uploaded
            and an error message will be given
        </p>
        <span className="bold">Mandatory fields:</span>
        <p className="mb-s">
            - Phone number <em>(max: 20 characters)</em> <span className="bold">OR </span>
            Email address <em>(max 50 characters)</em>.
            System can not accept both.
        </p>
        <p>
            - Test Date: field indicating when the test was taken
            Date format is &quot;MM/DD/YYYY&quot;
            e.g. 07/04/1987 July 4th 1987
        </p>
        <p>
            - Test Result: options are either &quot;Positive&quot;
            or &quot;Negative&quot;
        </p>
        <p>
            - Test Taken At: options are either &quot;Lab&quot;, &quot;Home&quot;
            or &quot;Home-Proctored&quot;
        </p>
        <p>
            - Type: options are either &quot;Antigen&quot;
            or &quot;PCR&quot;
        </p>
        <span className="bold">Other fields:</span>
        <p>
            - Test Location: free-form text field. Mandatory if
            Test Taken At is &quot;Laboratory&quot;
        </p>
    </div>
);

export { TestsGuidlineText };
