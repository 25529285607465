import locales from 'locales';

export const MODULE_AUTOSCREEN_STATUS_CONST = {
    NOACTION: 0, // @DEFAULT
    REQUIRED: 1,
    FAILED: 3,
    
};

export const MODULE_AUTOSCREEN_STATUS = {
    NOACTION: { 
        key: 'NOACTION',
        value: MODULE_AUTOSCREEN_STATUS_CONST.NOACTION,
        label: 'No Action Required'
    },
    REQUIRED: {
        key: 'REQUIRED',
        value: MODULE_AUTOSCREEN_STATUS_CONST.REQUIRED,
        label: 'Overdue'
    },
    FAILED: {
        key: 'FAILED',
        value: MODULE_AUTOSCREEN_STATUS_CONST.FAILED,
        label: 'Failed. No action required'
    },
};

export const getAutoscreenStatusTitle = (autoscreenStatus) => {
    const { common } = locales;
    
    let subTitle = common.statusSubtitle.notRequired;
    if (autoscreenStatus === MODULE_AUTOSCREEN_STATUS.REQUIRED.key) {
        subTitle = common.statusSubtitle.pending;
    } 
    return subTitle;
};
