export default {
    quickScreening: {
        submit: 'Yes, my condition is unchanged',
        cancel: 'I would like to complete a full screening',
        title: 'Quick Screen',
        header: {
            title: 'You have the option of completing a Quick Screen.',
            body: 'Your last screening was {date} at {time}. Is your condition the same now as it was then? See your last screening details below.'
        },
        message: {
            text: 'Has your condition changed since your last report?',
            button: 'My condition has not changed'
        },
    },
    result: {
        options: ['Workplace', 'Other'],
        message: 'You have successfully completed your Screening',
        title: 'Screening Result',
        buttonBack: 'Back to last question',
        buttonSubmit: 'Back to Homescreen',
        unknown: 'Result: UNKNOWN',
        resultNurseText: 'Let’s get you connected to a nurse over the phone. Just a few more questions.',
        form: {
            fullIntake: 'Where were you most likely exposed to COVID-19?',
            text: 'We`ll have a nurse call you back soon. Is this the best number to reach you on?',
            zip: 'What’s your zip code?',
            notes: 'Please give a brief description of where you might have been exposed and/or when your symptoms started',
            notesIntake: 'Please provide additional details of where you might have been exposed and/or when your symptoms started',
            phoneNumber: 'Phone number',
            callbackDelay: 'Call me back in',
            waitTime: 'Current wait time if you decide to call now is about {waitTime} minutes.',
            button: 'Call Me',
            select: {
                asap: 'As soon as possible',
                fifteen: '15 minutes',
                thirty: '30 minutes',
                fortyfive: '45 minutes',
                hour: '1 hour',
            }
        },
    },
    warning: {
        checkbox: 'Don\'t show this message again for the next 10 days',
        button: 'Got it',
    },
    questions: {
        title: 'Screening',
        none: 'None',
        back: 'Back',
        cancel: 'Cancel',
        continue: 'Next'
    },
    success: {
        title: 'Thank you for completing the Screening',
        back: 'Back to Homescreen',
    },
    successNurse: {
        title: 'Thank you for scheduling a call.',
        subtitle: 'One of our nurses will call you back shortly.',
        back: 'Back to Homescreen'
    }
};
