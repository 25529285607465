import './confirmUpdateModal.scss';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Tree } from 'apps/administration/components';
import { useSelector } from 'react-redux';
import { IconWarning } from 'apps/administration/containers';
import { useAlerts } from 'hooks';

const LocationConfirmUpdateModal = ({ setVisible, onConfirm, message }) => {
    const { children } = useSelector(state => state.location);
    const { start } = useAlerts(true);
    const [checked, setChecked] = useState([]);
    const [treeData, setTreeData] = useState([]);

    useEffect(() => {
        if (children.length) {
            const data = children
                .map(item => ({
                    value: item.id,
                    label: item.title,
                    isLeaf: true
                }));
            setTreeData(data);
        }
    }, [children]);

    const onOk = (locIds) => {
        if (locIds) {
            onConfirm(locIds)
                .then(start);
        } else {
            onConfirm();
        }
        
        setVisible(false);
    };

    const onCancel = () => setVisible(false);

    const ModalButtons = [
        <Button
            key="cancel"
            type="default"
            size="small"
            onClick={onCancel}
        >
            Cancel
        </Button>,
        <Button
            key="save"
            type="primary"
            size="small"
            onClick={() => onOk(checked)}
        >
            Update
        </Button>
    ];

    return (
        <Modal
            className="modal-with-icon modal-confirm-update-locations"
            open
            title={(
                <>
                    <div>
                        <IconWarning />
                        {message}
                    </div>
                    <h5>
                        If you want to copy-down the changes, please select 
                        sub-locations from the list below:
                    </h5>
                </>
            )}
            closable={false}
            footer={ModalButtons}
        >

            <Tree
                checkedKeys={checked}
                multiple
                showSearch
                searchLabel="Search Location"
                itemLabel="Locations"
                height={280}
                options={treeData}
                onCheck={setChecked}
            />
        </Modal>
    );
};

LocationConfirmUpdateModal.propTypes = {
    setVisible: PropTypes.func.isRequired,
    message: PropTypes.any.isRequired,
    onConfirm: PropTypes.func.isRequired
};

export { LocationConfirmUpdateModal };
