// @NEW
export default {
    vaccination: {
        title: 'Are you sure?',
        text: 'I certify that this statement about my vaccination status is true and accurate. I understand that knowingly providing false information regarding my vaccination status may subject me to criminal penalties.',
        okButton: 'Yes, I certify',
    },
    exempt: {
        title: 'Are you sure?',
        text: 'I attest that the report I am submitting is true and accurate.',
        okButton: 'Yes, I confirm',
    },
    testing: {
        title: 'Are you sure?',
        text: 'I attest that the report I am submitting is true and accurate.',
        okButton: 'Yes, I confirm',
    },
    booster: {
        title: 'Are you sure?',
        text: 'I certify that this statement about my vaccination status is true and accurate. I understand that knowingly providing false information regarding my vaccination status may subject me to criminal penalties.',
        okButton: 'Yes, I certify',
    },
    immunization: {
        title: 'Are you sure?',
        text: 'I certify that this statement about my vaccination status is true and accurate. I understand that knowingly providing false information regarding my vaccination status may subject me to criminal penalties.',
        okButton: 'Yes, I certify',
    },
};
