import US from 'assets/images/flags/US.svg';
import ES from 'assets/images/flags/ES.svg';
import VN from 'assets/images/flags/VN.svg';

export const langs = [
    {
        key: 'en',
        title: 'English',
        icon: US
    },
    {
        key: 'es',
        title: 'Español',
        icon: ES
    },
    {
        key: 'vn',
        title: 'Tiếng Việt',
        icon: VN
    },
];

export const LangDefault = 'en';
