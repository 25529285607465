import { ModulesConfig } from 'apps/administration/constants';

const transformModulesIn = (moduleIds) => {
    const modules = {};
    Object.values(ModulesConfig).forEach((item) => {
        modules[item.module] = moduleIds?.includes(item.id);
    });
    return modules;
};

export default {
    in: transformModulesIn
};
