import { APIT } from 'utils';

const transformPatientOut = (patient) => {
    const data = { ...patient };
    const language = data.language ? data.language.toUpperCase() : undefined;

    return {
        ...data,
        language,
        sendScreenReminders: APIT.bool.out(data.sendScreenReminders),
        isActive: APIT.bool.out(data.isActive),
        isMedicalConsentAccepted: APIT.bool.out(data.isMedicalConsentAccepted),
        bannerHiddenDate: APIT.date.out.toUTC(data.bannerHiddenDate),
        isolationStartDate: APIT.date.out.toUTCStart(data.isolationStartDate),
        isolationEndDate: APIT.date.out.toUTCStart(data.isolationEndDate),
        remindersBannerDate: APIT.date.out.toUTC(data.remindersBannerDate),
    };
};

export default transformPatientOut;
