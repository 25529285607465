import { isTestFailed } from 'modules/module';

export const DUE_DATES = {
    FIRST: 'dueDate',
    SECOND: 'secondDueDate'
};

export const VERIFICATION_CODE_NUMBER = [
    { 
        value: process.env.REACT_APP_VERIFICATION_CODE_TOLL_FREE_NUMBER, 
        label: `${process.env.REACT_APP_VERIFICATION_CODE_TOLL_FREE_NUMBER} Toll Free` 
    },
    { 
        value: process.env.REACT_APP_VERIFICATION_CODE_NON_TOLL_FREE_NUMBER, 
        label: `${process.env.REACT_APP_VERIFICATION_CODE_NON_TOLL_FREE_NUMBER} Non-toll Free` 
    }
];

export const GENDER = [
    {
        label: 'Male',
        value: 1,
    },
    {
        label: 'Female',
        value: 2,
    },
    {
        label: 'Other',
        value: 3,
    },
    {
        label: 'Prefer not to specify',
        value: 4,
    },
];

export const PATIENT_STATUS = {
    UNREGISTERED: 0,
    ACTIVE: 1,
    IMPORTED: 2,
    CREATED_BY_PYPESTREAM: 3
};

export const PATIENT_ACTIVE_STATUS = {
    ACTIVE: 1,
    INACTIVE: 0,
};

const reasonsLabels = {
    screenStatus: 'Failed screening',
    testStatus: 'Positive Covid Test',
    manual: 'Manual'
};

export const getHoldReasons = (patient) => {
    const { $moduleStatus: { autoscreen: autoscreenStatus } } = patient;
    const reasons = [];
    if (autoscreenStatus === 'FAILED') {
        reasons.push('screenStatus');
    }
    
    if (isTestFailed(patient)) {
        reasons.push('testStatus');
    }
    if (!reasons.length) {
        reasons.push('manual');
    }
    return reasons.map(key => reasonsLabels[key] || key);
};
