const AdminConstants = {
    ERROR: 'ADMIN_ERROR',
    LOADING: 'ADMIN_LOADING',
    LOADED: 'ADMIN_LOADED',
    LOGOUT: 'ADMIN_LOGOUT',
    CLEAR: 'ADMIN_CLEAR',
};

export {
    AdminConstants
};
