export default {
    subtitleUser: '¡Bienvenido, {userName}!',
    alerts: 'Alertas',
    newAlerts: 'Nuevas alertas',
    noNewAlerts: 'sin nuevas alertas',
    unreadAlerts: 'Alertas sin Leer',
    latestAlert: 'Alertas recientes',
    viewAll: 'Ver todo',
    tapScreen: 'toque para revision',
    tapInfo: 'Toque para informacion',
    reports: 'Reportes',
    notAvailable: 'No disponible',
    incident: 'Incident Reporting', // @NEW
    id: 'Identificación',
    status: 'Estado',
    screenStatus: 'Estado de Revision',
    idNumber: 'Número de Identificación',
    messageWait: '¡Parece que usted completó una evaluación recientemente! Vuelva a intentarlo mas tarde para evaluarlo nuevamente.',
    details: 'Detalles', 
    buttonScreen: 'Evaluación', 
    buttonAction: 'Acciones', 
    title: 'Pandemia', // translated
    buttonReport: 'Reporte', 
    buttonTesting: 'Reporte de prueba', 
    buttonVaccination: 'Reporte de vacunación',
    buttonVaccinationBooster: 'Reportar Vacuna de Refuerzo', // @NEW
    buttonSecondVaccination: 'Segunda Vacuna',
    proctoredTest: 'Prueba Supervisada', // @NEW
    buttonGuidelines: 'Presione aquí para más detalles',
    buttonShowDetails: 'Mostrar detalles',
    buttonHideDetails: 'Ocultar detalles',
    buttonWorkplaceInjury: 'Lesion en el Trabajo',
    buttonMore: 'Mas',
    buttonNewReport: 'Nuevo Reporte',
    total: 'Total',
    ok: 'Ok',
    benefits: 'Beneficios',
};
