import React, { useState } from 'react';
import { Radio, TextArea } from 'components';
import { FieldTypes } from 'utils';
import PropTypes from 'prop-types';

export const AdvancedRadio = (props) => {
    const { options, onChange, ...rest } = props;

    const [textValue, setTextValue] = useState();
    const [radioValue, setRadioValue] = useState();
    const [showTextarea, setShowTextarea] = useState(false);

    const onRadioChange = (val) => {
        onChange(val);
        setRadioValue(val);
        setShowTextarea(false);
        if (val === FieldTypes.TEXTAREA) {
            setShowTextarea(true);
        }
    };

    const onTextChange = (val) => {
        setTextValue(val);
        onChange(val);
    };

    return (
        <>
            <Radio.Group
                onChange={onRadioChange}
                value={radioValue}
                {...rest}
            >
                {options.map(item => (
                    <Radio
                        key={item.key || item.value}
                        value={item.value}
                    >
                        {item.text}
                    </Radio>
                ))}
            </Radio.Group>
            {
                showTextarea && (
                    <TextArea
                        rows={2}
                        value={textValue}
                        onChange={onTextChange}
                    />
                )
            }
        </>
    );
};

AdvancedRadio.propTypes = {
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
};
