import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, Tooltip } from 'apps/administration/components';
import { NOTIFICATION_INLINE } from 'modules/notification';
import { showError } from 'utils';
import { useAlerts } from 'hooks';
import { UploadOutlined } from '@ant-design/icons';
import Context from '../context';

const ExportButton = ({ onBeforeExport }) => {
    const { fetchParams, fetchAction, columns, persistentFilters } = useContext(Context);
    const [loading, setLoading] = useState(false);
    const { start } = useAlerts(true);

    const onClick = async () => {
        if (onBeforeExport) {
            const next = await onBeforeExport();
            if (!next) {
                return;
            }
        }
        const exportColumns = {};
        columns
            .filter(item => !item.hide && item.key && !(item.exportable === false))
            .forEach((item) => {
                const name = item.sortBy || item.key;
                exportColumns[name] = encodeURIComponent(item.display);
            });

        setLoading(true);

        const { filters, sort } = fetchParams;
        fetchAction(1, 500000, { ...filters, ...persistentFilters }, sort, {
            columns: {
                ...exportColumns
            },
            headers: {
                Accept: 'application/vnd.ms-excel',
            },
        })
            .then(() => {
                start(NOTIFICATION_INLINE.exportStart);
            })
            .finally(() => {
                setLoading(false);
            })
            .catch(showError);
    };

    return (
        <Tooltip
            placement="bottom"
            title="Export data in Excel (.xlsx format)"
        >

            <Button
                type="link"
                onClick={onClick}
                icon={<UploadOutlined />}
                loading={loading}
            >
                Export
            </Button>
        </Tooltip>
    );
};

ExportButton.propTypes = {
    onBeforeExport: PropTypes.func
};

ExportButton.defaultProps = {
    onBeforeExport: undefined
};

export { ExportButton };
