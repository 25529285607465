import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Questions from './questions/index';
import Result from './result';
import QuickScreening from './quick';

const Autoscreening = () => {
    const { current } = useSelector(state => state.screening);
    const [isQuick, setIsQuick] = useState(false);
    const { isExit } = current;

    if (isQuick) {
        return <QuickScreening setIsQuick={setIsQuick} />;
    }

    if (isExit) {
        return <Result />;
    }
    return (
        <Questions setIsQuick={setIsQuick} />
    );
};

export { Autoscreening };
