import { APIT } from 'utils';

const transformListIn = (data) => {
    if (data && !data.data) {
        return data;
    }
    return {
        ...data,
        data: data.data.map(item => ({
            ...item, 
            lastActivityDateTime: APIT.date.in.toLocal(item.lastActivityDateTime)
        })),
    };
};

export default transformListIn;
