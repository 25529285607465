export default {
    testingTitle: 'Reporte de prueba',
    vaccinationTitle: 'Reporte de vacunacion',
    boosterTitle: 'Reportar Refuerzo', // @NEW
    vaccinations: 'Vacunas', 
    tests: 'Examenes', 
    exemptTitle: 'Reporte de Exención (No Vacunado)',
    message: '¡El informe de su evaluación fue enviado exitosamente!', 
    messageVaccination: '¡EL reporte de vacunación fue enviado exitosamente',
    testSuccessReportTitle: 'Prueba reportada con éxito!', 
    testSuccessReportText: 'Gracias por reportar su prueba.', 
    vaccineSuccessReportTitle: 'Vacunación reportada con éxito!', 
    vaccineSuccessReportText: 'Gracias por reportar su vacunación.',
    boosterSuccessReportTitle: 'Refuerzo Reportado Exitosamente!', // @NEW
    boosterSuccessReportText: 'Gracias por Reportar su Refuerzo.', // @NEW
    optOutSuccessReportTitle: 'Exención (No Vacunado) reportada exitosamente!', 
    optOutSuccessReportText: 'Gracias por informar.', 
    backToHomeBtnText: 'Ir a pagina de inicio', 
    noTests: 'Aún no se han informado pruebas', 
    noVaccinations: 'Aún no se han reportado vacunas',
    viewMore: 'Ver Mas', 
    viewLess: 'Ver Menos', 
    tooltip: {
        testLocation: 'Por favor ingrese el lugar donde fue examinado o la ID de su supervisor de prueba',
    },
    form: {
        manufacturer: 'Fabricante de vacuna',
        firstShot: '(cambiar si no fue hoy)',
        shot: 'Fecha',
        vaccineLocation: 'Lugar de Vacuna',
        vaccineManufacturer: 'Por favor seleccione fabricante antes',
        testResult: 'Informe de evaluación',
        dateOfTest: 'Fecha de la evaluación',
        testReportDate: 'Test Report Date', // @NEW
        testLocation: 'Lugar/ID supervisor de prueba', 
        attachments: 'Archivos Adjuntos',
        attachmentsTooltip: 'Tipos de archivos soportados: .pdf, .xls, .xlsx, .doc, .docx, .jpg, .jpeg, .png, .heif',
        attachmentsTab: 'Toque para subir',
        lotNumber: 'Numero de fabricante',
        secondShotSection: 'Segunda dosis',
        firstShotSection: 'Primera Dosis',
        boosterDose: 'Dosis de Refuerzo', // @NEW
        doseSection: 'Dosis',
        testNegative: 'Negativo a COVID-19',
        testPositive: 'Postivio a COVID-19 ',
        testInconclusive: 'Inconcluso',
        antigen: 'Antigeno', // @NEW
        pcr: 'PCR', // @NEW
        home: 'Casa', // @NEW
        lab: 'Laboratorio', // @NEW
        homeProctored: 'Supervisado en Casa', // @NEW
        testType: 'Tipo de Prueba', // @NEW
        testTakenAt: 'Examen realizado en', // @NEW
    },
    formExempt: { 
        reason: 'Razón', 
        religion: 'Religiosa',
        disability: 'Médica',
        other: 'Otra',
    }
};
