import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { formatTime, now } from 'utils';

const Timer = ({ start, ticking, format, countdown, onEnd }) => {
    const [delta, setDelta] = useState();

    useEffect(() => {
        let diffs;
        if (countdown) {
            diffs = start.diff(now(), 'second');
        } else {
            diffs = now().diff(start, 'second');
        }
        setDelta(diffs);
    }, [countdown, start]);

    useEffect(() => {
        let interval = null;

        interval = setInterval(() => {
            if (countdown) {
                if (delta <= 0) {
                    clearInterval(interval);
                    if (onEnd) {
                        onEnd();
                    }
                } else setDelta(del => del - ticking);
            } else {
                setDelta(del => del + ticking);
            }
        }, ticking * 1000);

        return () => clearInterval(interval);
    }, [countdown, delta, onEnd, ticking]);
    
    const timeLeft = delta < 0 ? 0 : delta;
    return formatTime(timeLeft, format);
};

Timer.propTypes = {
    start: PropTypes.object,
    ticking: PropTypes.number,
    format: PropTypes.string
};

Timer.defaultProps = {
    start: now(),
    ticking: 1,
    format: undefined
};

export { Timer };
