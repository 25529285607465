export default {
    validation: {
        name: 'Các Ký tự không hợp lệ.',
        manychars: 'Tên nên có ít nhất hai ký tự.',
        string: 'Các Ký tự không hợp lệ',
        zip: 'Mã Zip không hợp lệ',
        email: 'Email không hợp lệ',
        number: 'Các ký tự không hợp lệ',
        required: 'Miền được yêu cầu',
        terms: 'Should accept terms', // @NEW
        phoneNumber: 'Số điện thoại không hợp lệ',
        temperature: 'Nhập giá trị giữa {min} °{unit} và {max} °{unit}',
        password: 'Mật khẩu phải gồm ít nhất 8 ký tự bao gồm một chữ cái viết hoa, một chữ cái viết thường và 1 con số.',
        phoneExists: 'This phone number is already taken', // @NEW
        subdomainExists: 'This name is already taken. Please try another.', // @NEW
    }
};
