import { APIT } from 'utils';
import { QUESTION_OUTCOMES } from 'modules/screening';
import locales, { langs } from 'locales';
import { transformTransItemIn } from '../lang/lang.transformer';

const transformQuestionsIn = (data, forEdit) => data.map((item) => {
    if (forEdit) {
        return {
            ...item,
            key: `Q_CUSTOM_${item.id}`,
            outcome: item.outcome ? item.outcome : '',
            options: item.options.map(option => ({
                ...option,
                outcome: option.outcome === 1,
                key: option.labelSlug,
                ...transformTransItemIn(option.labelSlug, 'label'),
            })),
            ...transformTransItemIn(item.labelSlug, 'label'),
        };
    }
    const stringsEn = locales.getContent().en;
    return {
        type: item.type,
        key: `Q_CUSTOM_${item.id}`,
        outcome: item.outcome ? item.outcome : '',
        label: locales[item.labelSlug],
        labelToPost: stringsEn[item.labelSlug],
        options: item.options.map((option) => {
            if (item.outcome === QUESTION_OUTCOMES.IGNORE) {
                return ({
                    key: option.labelSlug,
                    outcome: {
                        ignoreExposure: option.ignoreExposure,
                        ignoreSymptoms: option.ignoreSymptoms
                    },
                    value: stringsEn[option.labelSlug],
                    text: locales[option.labelSlug],
                });
            }
            return ({
                key: option.labelSlug,
                outcome: APIT.bool.in(option.outcome),
                value: stringsEn[option.labelSlug],
                text: locales[option.labelSlug],
            });
        }),
    };
});

export const transformLangOut = (item, field) => langs.map(({ key: lang }) => ({
    [lang]: item[`${field}$${lang}`]
}));

const transformQuestionsOut = ({ data, ...rest }) => ({
    ...rest,
    data: data.map((item, index) => ({
        ...item,
        order: index,
        id: item.id < 0 ? undefined : item.id,
        labelSlug: transformLangOut(item, 'label'),
        options: item.options && (item.options.map(option => ({
            ...option,
            id: option.id < 0 ? undefined : option.id,
            labelSlug: transformLangOut(option, 'label'),
        })))
    }))
});

export default {
    in: transformQuestionsIn,
    out: transformQuestionsOut
};
