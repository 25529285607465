import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Modal, Spin } from 'components';
import locales from 'locales';
import { isPastDate, now } from 'utils';
import { useNavigate } from 'routing';
import IcoReminder from 'apps/lintelio/assets/images/icon.reminder.svg';
import { PatientActionsUser } from 'modules/patient';

const OptInSmsPopup = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { common: { remindersPopup: strings } } = locales;
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const patient = useSelector(state => state.patient);
    const { 
        remindersBannerDate,
        phone,
        $optInProgress
    } = patient;
    
    const { smsSendingEnabled } = useSelector(state => state.location);

    const hideUntil = (inDays) => {
        setLoading(true);
        const date = now().add(inDays, 'day');
        dispatch(PatientActionsUser.update({ remindersBannerDate: date }))
            .finally(() => setLoading(false));
    };

    const setupPhone = async () => {
        navigate('profile');
        setVisible(false);
    };
    const showDialog = smsSendingEnabled && !phone && !$optInProgress; 
        
    useEffect(() => {
        if (showDialog) {
            if (remindersBannerDate) {
                setVisible(isPastDate(remindersBannerDate));
            } else setVisible(true);
        } else setVisible(false);
    }, [remindersBannerDate, showDialog]);
    
    return (
        <Modal
            visible={visible}
            footer={(
                <>
                    <Button
                        type="primary"
                        block
                        className="m-center mt-s mb-s"
                        onClick={setupPhone}
                    >
                        {strings.button1}
                    </Button>
                    <Button
                        type="link"
                        block
                        onClick={() => hideUntil(7)}
                    >
                        {strings.button2}
                    </Button>
                    <Button
                        type="link"
                        block
                        onClick={() => hideUntil(70000)}
                    >
                        {strings.button3}
                    </Button>
                </>
            )}
        >
            <Spin spinning={loading}>
                <img 
                    className="m-center mt-l block" 
                    src={IcoReminder} 
                    alt="Reminders" 
                />
                <h1 className="ta-center mt mb-s">{strings.title}</h1>
                <p className="ta-center">{strings.subtitle}</p>
                <p className="ta-center">{strings.subtitle1}</p>
                
            </Spin>
        </Modal>
    );
}; 

export { OptInSmsPopup };
