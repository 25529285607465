import './confirm.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'components';
import locales from 'locales';
import IcoWarning from 'assets/images/ico.warning.svg';

const ConfirmDialog = ({ visible, setVisible, onOk, strings, loading }) => {
    const { common: { leavePopup: stringsDefault } } = locales;
    const texts = { ...stringsDefault, ...strings };

    return (
        <Modal
            visible={visible}
            className="confirm-dialog"
            footer={(
                <>
                    <Button
                        block
                        type="primary brand"
                        onClick={onOk}
                        loading={loading}
                    >
                        {texts.okButton}
                    </Button>
                    <Button
                        block
                        type="link"
                        onClick={() => setVisible(false)}
                    >
                        {texts.cancelButton}
                    </Button>
                </>
              )}
        >
            <img 
                className="icon-modal" 
                src={IcoWarning}
                alt="Warning"
            />

            <h1>{texts.title}</h1>

            <p>{texts.text}</p>
            
        </Modal>
    );
 };

ConfirmDialog.propTypes = {
     visible: PropTypes.bool.isRequired,
     setVisible: PropTypes.func.isRequired,
     onOk: PropTypes.func.isRequired,
     strings: PropTypes.object.isRequired,
     loading: PropTypes.bool
 };

 ConfirmDialog.defaultProps = {
    loading: false
};

export { ConfirmDialog };
