export const REMINDER_TYPE = {
    email: 'email',
    sms: 'sms',
    alert: 'alert'
};

const UserNotInArgs = { 
    phone: true, 
    email: true, 
    sendScreenReminders: true, 
    sendTestRemidners: true 
};

export const getReminderTypes = ({ patient, location }) => {
    const { smsSendingEnabled, emailSendingEnabled } = location;
    const user = patient || UserNotInArgs;
    
    const types = [REMINDER_TYPE.alert];
    
    if (smsSendingEnabled && user.phone) {
        types.push(REMINDER_TYPE.sms);
    }

    if (emailSendingEnabled && user.email) {
        types.push(REMINDER_TYPE.email);
    }
    return types;
};
