import React from 'react';
import PropTypes from 'prop-types';

const Address = ({ address1, address2, city, state, zip }) => (
    <address>
        {address1}{address2 && ` ${ address2}`}{address1 && city && ', '} 
        {city}{city && state && ', '}{' '}
        {state}{' '}
        {zip}
    </address>
);

Address.propTypes = {
    address1: PropTypes.string,
    address2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
};

Address.defaultProps = {
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
};

export { Address };
