import FileSaver from 'file-saver';
import { isChrome, isIos } from 'utils';

const CLEAR_FILE_OBJECT_AFTER_MIN = 15;

const FileDownload = (data, filename, contentType = 'application/octet-stream', forceDownload = false, imgInlineView = false) => {
    const blob = new Blob([data], { type: contentType });

    if (imgInlineView) {
        return data;
    }
        
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // IE workaround for "HTML7007: One or more blob URLs were
        // revoked by closing the blob for which they were created.
        // These URLs will no longer resolve as the data backing
        // the URL has been freed."
        window.navigator.msSaveOrOpenBlob(blob, filename);
    } else if (isChrome() && isIos()) {
        /* Chrome + iOS, #47013 */
        FileSaver.saveAs(blob, filename);
    } else {
        const blobURL = (window.URL && window.URL.createObjectURL) 
            ? window.URL.createObjectURL(blob) 
            : window.webkitURL.createObjectURL(blob);
        const tempLink = document.createElement('a');
        tempLink.style.display = 'none';
        tempLink.href = blobURL;
        
        if (forceDownload) {
            tempLink.setAttribute('download', filename);
            // Safari thinks _blank anchor are pop ups. We only want to set _blank
            // target if the browser does not support the HTML5 download attribute.
            // This allows you to download files in desktop safari if pop up blocking
            // is enabled.
            if (typeof tempLink.download === 'undefined') {
                tempLink.setAttribute('target', '_blank');
            }
        } else {
            tempLink.setAttribute('target', '_blank');
        }

        document.body.appendChild(tempLink);
        tempLink.click();

        // Fixes "webkit blob resource error 1"
        setTimeout(() => {
            document.body.removeChild(tempLink);
            window.URL.revokeObjectURL(blobURL);
        }, CLEAR_FILE_OBJECT_AFTER_MIN * 60 * 1000);
    } 
    return true;
};

const apiDownload = (api, url, forceDownload = false, imgInlineView = false) => api({
        method: 'GET',
        url,
        responseType: 'arraybuffer',
        headers: { Accept: 'application/vnd.ms-excel, application/pdf, application/json' }, 
    })
    .then(({ file, filename, contentType }) => {
        FileDownload(file, filename, contentType, forceDownload, imgInlineView);
        return file;
    })
    .then(res => ({ data: imgInlineView ? res : [] }));

export default apiDownload;
