import { APIT } from 'utils';

const transformListIn = data => ({
    ...data,
    data: data.data.map(item => ({
        ...item,
        readAt: APIT.date.in.toLocal(item.readAt),
        createdAt: APIT.date.in.toLocal(item.createdAt)
    }))
});
export default transformListIn;
