import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { showError } from 'utils';
import locales from 'locales';
import { AUTOCREENING_RESULT_CONST, ScreeningActions } from 'modules/screening';
import { LOCATION_CALLBACKS } from 'modules/location';
import { BackButton, CloseButton, Wrapper } from 'apps/lintelio/layout';
import ResultTriage from './result.triage';
import ResultDigital from './result.digital';
import ResultTriageSuccess from './result.triage.success';

const ResultWrapper = (props) => {
    const { routes: { screening: strings } } = locales;

    return (
        <Wrapper
            className="autoscreening-result"
            title={strings.questions.title}
            footer={null}
            left={<BackButton disabled />}
            right={<CloseButton disabled />}
            {...props}
        />
    );
};

const AutoscreeningResult = () => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const {
        answers,
        callback,
        current: { key: resultKey },
        outcomes,
        config: { module }
    } = useSelector(state => state.screening);

    const isTriageResult = resultKey === AUTOCREENING_RESULT_CONST.NURSE;

    const onResultSubmit = useCallback((values) => {
        const data = {
            ...values,
            answers,
            callNow: 1,
            result: resultKey,
            outcomes,
            module
        };
        setLoading(true);
        return dispatch(ScreeningActions.send(data))
            .catch(showError)
            .finally(() => setLoading(false));
    }, [answers, resultKey, outcomes, module, dispatch]);

    if (callback === LOCATION_CALLBACKS.NURSE) {
        return (
            <ResultTriageSuccess
                wrapper={ResultWrapper}
            />
        );
    }
    if (isTriageResult) {
        return (
            <ResultTriage
                loading={loading}
                onSubmit={onResultSubmit}
                wrapper={ResultWrapper}
            />
        );
    }
    return (
        <ResultDigital
            loading={loading}
            onSubmit={onResultSubmit}
            wrapper={ResultWrapper}
        />
    );
};

export default AutoscreeningResult;
