import { now } from 'utils';

/* @TODONEXT import from utils */
const FieldTypes = {
    RADIO: 'RADIO',
    CHECKBOXES: 'CHECKBOX',
    TEMPERATURE: 'temperature',
    DATE: 'date',
    TEXTAREA: 'OPEN_ENDED',
    ADVANCED_RADIO: 'ADVANCED_RADIO',
}; 

const HOURS_BETWEEN_SCREENINGS = process.env.REACT_APP_HOURS_BETWEEN_SCREENINGS || 4;

export const TEMPERATURE_SCALES = { C: 'C', F: 'F' };

export const AUTOCREENING_RESULT_CONST = {
    NURSE: 'RN',
    FAIL: 'FAIL',
    PASS: 'PASS',
    ER: 'ER',
    VACCINATED_WITH_EXPOSURE: 'VACCINATED WITH EXPOSURE'
};

export const AutoscreeningResultOptions = [
    {
        value: AUTOCREENING_RESULT_CONST.NURSE,
        text: 'Speak with a Nurse'
    },
    {
        value: AUTOCREENING_RESULT_CONST.FAIL,
        text: 'Isolate',
    },
    {
        value: AUTOCREENING_RESULT_CONST.ER,
        text: 'ER',
    },
    {
        value: AUTOCREENING_RESULT_CONST.PASS,
        text: 'Pass'
    },
    {
        value: AUTOCREENING_RESULT_CONST.VACCINATED_WITH_EXPOSURE,
        text: 'Vaccinated with Exposure'
    }
];

export const QUESTION_OUTCOMES = {
    ER: 'ER',
    SYMPTOMS: 'SYMPTOMS',
    EXPOSURE: 'EXPOSURE',
    IGNORE: 'IGNORE',
    FAIL: 'FAIL',
    EXPOSURE_HCP: 'EXPOSURE_HCP',
    EXPOSURE_HCP1: 'EXPOSURE_HCP1',
    EXPOSURE_HCP2: 'EXPOSURE_HCP2',
    EXPOSURE_HCP3: 'EXPOSURE_HCP3',
    WORK: 'WORK',
    PROTECTION: 'PROTECTION',
    ON_FAIL: 'ON_FAIL',
};

const CUSTOM_QUESTIONS_GROUP = {
    SYMPTOMS: 'SYMPTOMS',
    EXPOSURE: 'EXPOSURE',
    IGNORE: 'IGNORE',
    FAIL: 'FAIL',
    ON_FAIL: 'ON_FAIL',
};

const QuestionTypes = {
    RADIO: { value: FieldTypes.RADIO, label: 'Single-choice Question' },
    CHECKBOXES: { value: FieldTypes.CHECKBOXES, label: 'Multiple Choice Question' },
    TEXTAREA: { value: FieldTypes.TEXTAREA, label: 'Open Question' },
};

export const CustomQuestionsGroups = [
    {
        value: CUSTOM_QUESTIONS_GROUP.SYMPTOMS,
        categoryTitle: 'symptoms',
        label: 'Symptoms',
        outcomes: [{
            value: 'outcome',
            label: 'Symptoms'
        }],
        types: [QuestionTypes.RADIO, QuestionTypes.CHECKBOXES],
    },
    {
        value: CUSTOM_QUESTIONS_GROUP.EXPOSURE,
        categoryTitle: 'exposure',
        label: 'Exposure',
        outcomes: [{
            value: 'outcome',
            label: 'Exposure'
        }],
        types: [QuestionTypes.RADIO, QuestionTypes.CHECKBOXES]
    },
    {
        value: CUSTOM_QUESTIONS_GROUP.IGNORE,
        categoryTitle: 'ignore symptoms & exposure',
        label: 'Ignore symptoms & exposure ',
        outcomes: [
            {
                value: 'ignoreSymptoms',
                label: 'Symptoms'
            },
            {
                value: 'ignoreExposure',
                label: 'Exposure'
            },
        ],
        types: [QuestionTypes.RADIO, QuestionTypes.CHECKBOXES]
    },
    {
        value: CUSTOM_QUESTIONS_GROUP.FAIL,
        categoryTitle: 'pass or fail',
        label: 'Pass/Fail',
        outcomes: [{
            value: 'outcome',
            label: 'Fail'
        }],
        types: [QuestionTypes.RADIO, QuestionTypes.CHECKBOXES]
    },
    {
        value: '',
        categoryTitle: 'other',
        label: 'No effect on outcome',
        types: [QuestionTypes.RADIO, QuestionTypes.CHECKBOXES, QuestionTypes.TEXTAREA]
    },
    {
        value: CUSTOM_QUESTIONS_GROUP.ON_FAIL,
        categoryTitle: 'fail-only custom',
        label: 'Fail-only custom questions',
        types: [QuestionTypes.RADIO, QuestionTypes.CHECKBOXES, QuestionTypes.TEXTAREA]
    },
];

export const isQuestionTextarea = type => type === FieldTypes.TEXTAREA;

export const getScreeningOutcomeTitle = key =>
    AutoscreeningResultOptions.find(i => i.value === key)?.text || '';

export const canScreenAgain = (lastScreenDate) => {
    if (!lastScreenDate) {
        return true;
    }
    const minutesBetween = parseFloat(HOURS_BETWEEN_SCREENINGS) * 60;
    const minutesSince = now().diff(lastScreenDate, 'minutes');
    return minutesSince >= minutesBetween;
};
