export default {
    labels: {
        back: 'Atrás',
        next: 'Siguiente',
        phone: 'Número de Teléfono',
        password: 'Contraseña',
        email: 'Correo electrónico',
        tryAgain: 'Intente de nuevo',
        forgotPassword: '¿Olvidó su contraseña?',
        resetPassword: 'Reiniciar contraseña',
    },
    // @NEW section
    social: {
        subtitle: 'Ingresar con Redes Sociales',
        or: 'ó'
    },
    preSignIn: {
        title: 'Welcome to the <div>{title}</div> Health and Safety Service', // @NEW
        labels: {
            button: 'Comencemos',
        },
        placeholders: {
            location: 'Seleccione su ubicación' 
        }
    },
    signInPhone: {
        title: 'Agregue un número de teléfono',
        subtitle: 'Ingrese su número de teléfono celular para comenzar.',
        popupHelp: {
            linknote: '¿Por qué lo necesitamos?',
            note: 'Necesitamos enviar un código a este número para verificar que es realmente usted. Cargos por mensajes de texto de su proveedor pueden aplicarse (Casi nunca lo hacen estos días, pero debemos decírselo).',
            noteClose: 'Entiendo',
        }
    },
    signInEmail: {
        title: 'Agregar correo electrónico',
        subtitle: 'Ingrese su correo electrónico para empezar.',
    },
    code: {
        title: 'Verificación',
        subtitle: 'Introduzca el código de verificación',
        subtitleUser: '¡Bienvenido, {firstName}!',
        notes: {
            email: 'Hemos enviado un código de verificación al correo {maskedUsername}. El código expira cinco minutos después de haber sido solicitado.',
            phone_number: 'Acabamos de enviarle un código de verificación al número de teléfono que termina en (xxx) xxx-{maskedUsername}. El código expira cinco minutos después de haber sido solicitado.'
        },
        tryAgainNote: '¿No recibió un código?',
        codeSent: {
            email: 'Le enviamos otro código. Por favor revise su correo electrónico.',
            phone_number: 'Le enviamos otro código. Por favor revise sus mensajes.'
        },
    },
    signInPassword: {
        title: 'Contraseña',
        subtitle: 'Por favor ingrese su contraseña.',
    },
    signUpPassword: {
        title: 'Nueva contraseña',
        subtitle: 'Asegúrese de que su contraseña contenga al menos 8 caracteres, incluida una letra mayúscula, una letra minúscula y un número.',
    },
    forgotPassword: {
        title: '¿Olvidó su contraseña?',
        subtitle: 'Ingrese su {label} abajo y enviaremos un mensaje para reiniciar su contraseña',
    },
    popupSwitch: {
        title: 'Cambiar a autenticación de contraseña',
        text1: '¿Cansado/a de ingresar códigos de verificación? Puedes utilizar una contraseña. Te explicaremos el proceso',
        text2: 'Por favor tome en cuenta que este cambio es permanente. Una vez que decides utilizar una contraseña, no puedes regresar a utilizar códigos de verificación. Dicho esto, pensamos que es la mejor decisión.',
        text3: '¿Te gustaría cambiar a un proceso de autenticación basado en contraseña?',
        text: '¿Cambiar a la autenticación basado en contraseña?',
        textlink: 'Presione aquí'
    },
    verify: {
        title: 'Agregue un número de teléfono',
        subtitle: 'Necesitamos configurar su número de teléfono primero, solo por esta vez.',
        label: 'Número de Teléfono',
        titleSuccess: 'Completado',
        subtitleSuccess: 'El número de teléfono ha sido verificado exitosamente. Ahora puede encender sus recordatorios de texto',
    },
};
