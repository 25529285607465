import './imageCircle.scss';

import React from 'react';
import PropTypes from 'prop-types';

const ImageCircle = ({ type, children }) => (
    <div className={`image-circle ${type}`}>
        <div>
            {children}
        </div>
    </div>       
);

ImageCircle.propTypes = {
    type: PropTypes.string,
    children: PropTypes.string,
};

ImageCircle.defaultProps = {
    children: null,
    type: ''
};

export { ImageCircle };
