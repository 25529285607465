import './accessDenied.scss';
import React from 'react';
import { Result } from 'apps/administration/components';

const AccessDenied = () => (
    <Result
        className="content-403"
        title="You don’t have permission to view this page."
        subTitle="Contact your administrator if you need help."
    />
);

export default AccessDenied;
