import { formats, APIT } from 'utils';
import { Timezones } from 'constants';

const FieldSuffix = {
    DATE: '_DATE',
    TEMPERATURE: 'Q_TEMPERATURE'
};

const transformQuestionsIn = (questions, temperatureUnit) => 
    Object.entries(questions).map(([key, question]) => {
        const { label, value } = question;
        const { DATE, TEMPERATURE } = FieldSuffix;

        let answer;
        if (key.substring(key.length - DATE.length) === DATE) {
            answer = APIT.date.in.toLocal(value).format(formats.date.in);
        } else if (key === TEMPERATURE) {
            answer = `${value}°${temperatureUnit}`;
        } else if (Array.isArray(value)) {
            let values = '';
            value.map((i, idx) => values += `${i}${idx !== value.length - 1 ? ', ' : ''}`);
            answer = values;
        } else {
            answer = value;
        }

        const title = label.replace(/<\/?[^>]+(>|$)/g, '');
        return {
            type: key,
            question: title,
            answer,
        };
    });

const transformScreeningIn = (data) => {
    const q = JSON.parse(data.questionsAndAnswers);
    const $questions = transformQuestionsIn(q, data.temperatureUnit);
    return {
        ...data,
        $questions,
        createdAt: APIT.date.in.toLocal(data.createdAt),
        timezoneName: Timezones[data.timezone]
        
    };
};

export default transformScreeningIn;
