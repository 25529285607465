/* eslint-disable no-multi-assign */
/* eslint-disable no-bitwise */
/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { TelehealthService } from 'modules/telehealth';
import { Spin } from '../Spin';

function encode(input) {
    const keyStr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
    let output = '';
    let chr1; let chr2; let chr3; 
    let enc1; let enc2; let enc3; let enc4;
    let i = 0;

    while (i < input.length) {
        chr1 = input[i++];
        chr2 = i < input.length ? input[i++] : Number.NaN; // Not sure if the index 
        chr3 = i < input.length ? input[i++] : Number.NaN; // checks are needed here

        enc1 = chr1 >> 2;
        enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
        enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
        enc4 = chr3 & 63;

        if (Number.isNaN(chr2)) {
            enc3 = enc4 = 64;
        } else if (Number.isNaN(chr3)) {
            enc4 = 64;
        }
        output += keyStr.charAt(enc1) + keyStr.charAt(enc2)
            + keyStr.charAt(enc3) + keyStr.charAt(enc4);
    }
    return output;
}

const Image = ({ id, alt, width, ...rest }) => {
    const [src, setSrc] = useState('');
    const [maxWidth, setMaxWidth] = useState(false);

    useEffect(() => {
        TelehealthService.downloadFile(id, false, true)
            .then(({ data }) => {
                const bytes = new Uint8Array(data);
                setSrc(`data:image/png;base64,${encode(bytes)}`);
            });
    }, [id]);

    if (!src) {
        return (
            
            <Spin 
                spinning 
                style={{ margin: width / 2 }} 
            />
        );
    }
    return (
        <img
            src={src}
            alt={alt}
            width={maxWidth ? undefined : width}
            onClick={() => setMaxWidth(!maxWidth)}
            {...rest}
        />
    );
};

Image.propTypes = {
    id: PropTypes.number.isRequired, 
    alt: PropTypes.string, 
    width: PropTypes.number
};

Image.defaultProps = {
    alt: '',
    width: 300
};

export { Image };
