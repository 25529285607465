import React from 'react';
import { ModulesConfig } from 'apps/administration/constants';

const renderModules = value => value?.map((id) => {
    const module = Object.values(ModulesConfig)
        .filter(item => item.visibleOn.locationList)
        .find(item => item.id === id);
        
    if (!module) {
        return null;
    }
    return (
        <div
            title={module.title} 
            key={module.id}
            className={`list-item-module module module-${module.module}`}
        >
            <div />
        </div>
    );
});

export { renderModules };
