import transformList from './list';
import transformRegistrationFields from './registrationFields';
import transformLocationOut from './out';
import transformLocationIn from './in';

export default {
    in: transformLocationIn,
    out: transformLocationOut,
    list: transformList,
    registrationFields: transformRegistrationFields
};
