import './Select.scss';
import React from 'react';
import { PropTypes } from 'prop-types';
import locales from 'locales';
import { ArrowDown } from 'components/Icons';
import SelectMUI from '@mui/material/Select';
import { Input } from '../Input';
import { MenuItem } from '../Menu';

const Select = ({
    options, value, onChange,
    title, placeholder, className,
    disabled,
    prefix,
    dropdownClassName,
    disabledOptions,
    renderValue
}) => {
    const { common } = locales;

    const onBeforeChange = (e) => {
        onChange(e.target.value);
    };

    const renderValueDefault = (selected) => {
        if (selected === '') {
            return (
                <span className="placeholder">
                    {placeholder || common.pleaseSelect}
                </span>
            );
        }

        return options.find(i => i.value.toString() === selected)?.label;
    };

    return (
        <SelectMUI
            disabled={disabled}
            value={(value || '').toString()}
            onChange={onBeforeChange}
            aria-label={title}
            className={`c-select ${className}`}
            variant="standard"
            label=""
            labelId={title}
            input={<Input prefix={prefix} />}
            inputProps={{ placeholder: placeholder || common.pleaseSelect }}
            IconComponent={ArrowDown}
            displayEmpty
            renderValue={renderValue || renderValueDefault}
            MenuProps={{ className: `c-select-dropdown ${dropdownClassName}` }}
        >
            {options.map(item => (
                <MenuItem
                    key={item.value}
                    value={item.value.toString()}
                    prefix={item.prefix}
                    disabled={disabledOptions.includes(item.value)}
                >
                    {item.label}
                </MenuItem>
            ))}

        </SelectMUI>
    );
};

Select.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired,
        prefix: PropTypes.object
    })).isRequired,
    value: PropTypes.any,
    onChange: PropTypes.func,
    title: PropTypes.string,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    prefix: PropTypes.any,
    dropdownClassName: PropTypes.string,
    disabledOptions: PropTypes.array,
    renderValue: PropTypes.func
};

Select.defaultProps = {
    value: '',
    onChange: undefined,
    placeholder: '',
    title: '',
    className: '',
    disabled: false,
    prefix: null,
    dropdownClassName: '',
    disabledOptions: [],
    renderValue: undefined
};

export { Select };
