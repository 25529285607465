import './medicalConsent.scss';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Modal, SanitizedHtml } from 'components';
import { signOut } from 'security';
import locales from 'locales';
import { PatientActionsUser } from 'modules/patient';
import { LOCATION_CONTENT_MANAGEMENT_SLUGS } from 'modules/location';

const MEDICAL_CONSTANT = LOCATION_CONTENT_MANAGEMENT_SLUGS.MEDICAL.key;

const MedicalConsentPopup = ({ visible, onClose }) => {
    const dispatch = useDispatch();
    const { common } = locales;
    const { medicalConsentPopup: strings } = common;
    const [loading, setLoading] = useState(false);
    const { contentManagement } = useSelector(state => state.location);

    const [title, setTitle] = useState();
    const [body, setBody] = useState(' ');

    const onAuthorize = () => {
        setLoading(true);
        dispatch(PatientActionsUser.update({ isMedicalConsentAccepted: true }))
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        if (contentManagement) {
            const customContent = contentManagement
                .find(item => item.type === MEDICAL_CONSTANT);
            if (customContent?.title) {
                setTitle(locales[customContent.title]);
            }
            if (customContent?.description) {
                setBody(locales[customContent.description]);
            }
        }
    }, [contentManagement]);
   
    return (
        <Modal
            visible={visible}
            closable={false}
            title={strings.header}
            className="medical-consent-modal"
            footer={onClose ? (
                <Button
                    type="link"
                    block
                    onClick={onClose}
                >
                    {common.close}
                </Button>
            )
                : (
                    <>
                        <Button
                            type="primary brand"
                            block
                            onClick={onAuthorize}
                            loading={loading}
                        >
                            {strings.button1}
                        </Button>
                        <Button
                            type="link"
                            block
                            onClick={signOut}
                        >
                            {strings.button2}
                        </Button>
                    </>
                )}
        >
            <h1>{title || ' '}</h1>
            <SanitizedHtml>
                {body}
            </SanitizedHtml>
        </Modal>
    );
};

MedicalConsentPopup.propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func
};

MedicalConsentPopup.defaultProps = {
    onClose: undefined
};

export default MedicalConsentPopup;
