import { api } from 'services';
import Transformer from './transformers';

class ReminderService {
    static get(id) {
        return api.get(`/locations/${id}/reminders`)
         .then(data => Transformer.in(data));
    }

    static update(id, data) {
        const postData = Transformer.out(data);
        return api.put(`/locations/${id}/reminders`, postData);
    }

    static getByPatient(id) {
        return api.get(`/patients/${id}/reminders`)
            .then(Transformer.in);
    }

    static updateByPatient(id, data) {
        const postData = Transformer.out(data);
        return api.put(`/patients/${id}/reminders`, postData)
            .then(Transformer.in);
    }
}

export default ReminderService; 
