export default {
    // General
    timezone: 'Time Zone',
    temperatureUnit: 'Temperature Unit',
    emergencyNumber: 'Emergency Number',
    whiteLabelPermission: 'Allow White Labeling',
    whiteLabelReminders: 'Display Logo in Email Reminders',
    whiteLabelAlerts: 'Display Logo in Email Alerts',
    showBenefitsTile: 'Show Benefits Tile',

    // White label
    color: 'Color',
    logo: 'Logo',

    // Authentication
    tokenValidityHours: 'Token Validity',

    // Registration settings
    isSelfRegistrationEnabled: 'Disable Self-registration',

    // Pandemic Screening Settings
    // screeningTypes: 'Pandemic Submodules',
    flowType: 'Type of flow',
    tempCheck: 'Is Temperature Included',
    temperatureThreshold: 'Temperature Threshold',
    exposureDays: 'Exposure Period (duration in days)',
    isScreeningDetailsEnabled: 'View Screening Details',
    isScreenFullIntakeEnabled: 'Screen Full Intake',
    isSymptomPopupHidingDisabled: 'Disable Symptom Popup Hiding',

    // Pandemic Questions
    allowCustomQuestions: 'Allow Custom Questions',
    isQuickScreeningEnabled: 'Quick Screen',
    areExposureQuestionsRequired: 'Ask Exposure Questions to Users with Immunity',
    passIfVaccinatedEnabled: 'Pass Vaccinated Users on Exposure',
    isSpecificSymptomsEnabled: 'Ask for Specific Symptoms',
    hideCongestionOption: 'Hide "Congestion or runny nose',
    questions: 'Custom Questions',
    $hideTemperature: 'Hide Temperature Question',
    // hiddenQuestions_SYMPTOMS: 'Hide Default Symptom Questions', // @TODONEXT other EP
    // hiddenQuestions_EXPOSURE: 'Hide Default Exposure Questions', // @TODONEXT other EP
    hiddenQuestions: 'Show/Hide System Questions',

    // Pandemic Reminders
    remindersPaused: 'Pause Reminders',
    reminders: 'Reminders',

    // Pandemic Protocols
    areAttachmentsMandatory: 'Mandatory Test/Vaccination Attachments',
    protocolVerificationMandatory: 'Mandatory Test/Vaccination Verification',
    screenValidityHoursForVaccinated: 'Screening Required for Vaccinated',
    screenValidityHours: 'Testing Required for Unvaccinated',
    testValidityDays: 'Testing Required  for Unvaccinated',
    isVaccinationMandatory: 'Vaccination Required',

    // Incident
    incidentPullIntegrationId: 'Lintelio Enterprise Pull Integration ID',

    // Content Management
    holdPageTitle$en: 'Hold Page Title (English)',
    holdPageTitle$es: 'Hold Page Title (Español)',
    holdPageTitle$vn: 'Hold Page Title (Vietnamese)',
    holdPageDescription$en: 'Hold Page Description (English)',
    holdPageDescription$es: 'Hold Page Description (Español)',
    holdPageDescription$vn: 'Hold Page Description (Vietnamese)',

    screenPageTitle$en: 'OK Page Title (English)',
    screenPageTitle$es: 'OK Page Title (Español)',
    screenPageTitle$vn: 'OK Page Title (Vietnamese)',
    screenPageDescription$en: 'OK Page Description (English)',
    screenPageDescription$es: 'OK Page Description (Español)',
    screenPageDescription$vn: 'OK Page Description (Vietnamese)',

    protocolPageTitle$en: 'Pandemic Protocol Title (English)',
    protocolPageTitle$es: 'Pandemic Protocol Title (Español)',
    protocolPageTitle$vn: 'Pandemic Protocol Title (Vietnamese)',
    protocolPageDescription$en: 'Pandemic Protocol Description (English)',
    protocolPageDescription$es: 'Pandemic Protocol Description (Español)',
    protocolPageDescription$vn: 'Pandemic Protocol Description (Vietnamese)',

    medicalPageTitle$en: 'Medical Concent Title (English)',
    medicalPageTitle$es: 'Medical Concent Title (Español)',
    medicalPageTitle$vn: 'Medical Concent Title (Vietnamese)',
    medicalPageDescription$en: 'Medical Concent Description (English)',
    medicalPageDescription$es: 'Medical Concent Description (Español)',
    medicalPageDescription$vn: 'Medical Concent Description (Vietnamese)',

    testingRemindersPageTitle$en: 'Testing Reminders Title (English)',
    testingRemindersPageTitle$es: 'Testing Reminders Title (Español)',
    testingRemindersPageTitle$vn: 'Testing Reminders Title (Vietnamese)',
    testingRemindersPageDescription$en: 'Testing Reminders Description (English)',
    testingRemindersPageDescription$es: 'Testing Reminders Description (Español)',
    testingRemindersPageDescription$vn: 'Testing Reminders Description (Vietnamese)',
    announcements: 'Homepage Newsfeed',
    benefits: 'Benefits',
    immunizationTypes: 'Immunization Vaccine Types',
    chargebeeSubscriptionId: 'Default Chargebee Subscription Id',
    chargebeeCustomerId: 'Chargebee Customer Id',

    smsSendingEnabled: 'Allow SMS Communication',
    emailSendingEnabled: 'Allow Email Communication',
    enableSmsAlertForAdmins: 'Enable SMS Alerts for Admins',
    
    /* @TODONEXT
    jobTitles: 'OccupationMapping',
    */
};

// List of location fields (not only updatable)
// which should be saved always
export const FieldsToKeep = [
    'registrationFields',
    'announcements',
    'questions',
    'hiddenQuestions',
    'benefits',
    'immunizationTypes'
];   
