import { RESERVED_SUBDOMAINS, ADMIN_SUBDOMAIN } from 'constants';

export const getSubDomain = (includeReserved) => {
    const { host } = window.location;
    const arr = host.split('.');
    if (!arr.length) {
        return undefined;
    }
    const sub = arr[0];
    if (!includeReserved && RESERVED_SUBDOMAINS.includes(sub.toLowerCase())) {
        return undefined;
    }
    return sub;
};

export const isAdminPortal = getSubDomain(true) === ADMIN_SUBDOMAIN;

export const subDomain = getSubDomain();

export const toQueryParams = (params, encode) => Object.keys(params)
    .map((k) => {
        let value = params[k];
        if (Object.isObject(value)) {
            if (Object.isEmpty(value)) {
                value = '';
            } else {
                value = JSON.stringify(value);
            }
        }
        if (Array.isArray(value)) {
            return `${k}=${value}`;
        }
        if (value) {
            if (encode) {
                return `${k}=${encodeURIComponent(value)}`;
            } 
            return `${k}=${value}`;
        }
        return '';
    })
    .join('&');

export function checkURL(url) {
    return (url.match(/\.(jpeg|jpg|gif|png)$/) != null);
}

export async function toDataURL(url, callback) {
    const xhr = new XMLHttpRequest();
    xhr.onload = () => {
        const reader = new FileReader();
        reader.onloadend = () => {
            callback(reader.result);
        };
        reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
}

export const getQueryParams = () => {
    const entries = new URLSearchParams(window.location.search).entries();
    const result = {};
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of entries) {
        result[key] = value;
    }
    return result;
};

export const splitPaths = () => {
    const { pathname } = window.location;
    const arr = pathname.split('/');
    const last = arr.pop();
    const currentPath = Number.isNaN(last) ? `/${ last}` : '';
    const basePath = arr.join('/') + currentPath;
    let param = basePath.split('/').pop();
    param = Number.isNaN(param) ? undefined : parseInt(param, 10);
    
    return { 
        basePath, 
        currentPath,
        param
    };
};
