import React from 'react';
import PropTypes from 'prop-types';

const formatNumber = x => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

const TableTitle = ({ title, total }) => (
    <h1>
        {title}
        {' '}
        {Object.isDefined(total) && (
            <span className="muted">
                (Total {formatNumber(total)})
            </span>
        )}
    </h1>
);

TableTitle.propTypes = {
    title: PropTypes.string.isRequired,
    total: PropTypes.number
};

TableTitle.defaultProps = {
    total: undefined
};
export { TableTitle };
