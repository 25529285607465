import React from 'react';
import PropTypes from 'prop-types';
import locales from 'locales';
import { ConfirmDialog } from '../confirm';

const LeaveDialog = ({ visible, setVisible, onOk }) => {
    const { common: { leavePopup: strings } } = locales;

    return (
        <ConfirmDialog
            visible={visible}
            setVisible={setVisible}
            onOk={onOk}
            strings={strings}
        />

    );
 };

LeaveDialog.propTypes = {
     visible: PropTypes.bool.isRequired,
     setVisible: PropTypes.func.isRequired,
     onOk: PropTypes.func.isRequired,
};

export { LeaveDialog };
