const transformJobTitlesIn = data => data && data.data.map(item => ({
    ...item,
    label: item.title,
    value: item.id
}));

const deleteTransformJobTitleOut = (data) => {
    const jobTitles = data.map(({ id }) => ({ id }));
    return { jobTitles };
};

const transformJobTitleOut = (data, locationId) =>
    ({ jobTitles: data, locationId });

export default {
    in: transformJobTitlesIn,
    out: transformJobTitleOut,
    outDelete: deleteTransformJobTitleOut
};
