import { transformFilters } from 'services';

const transformOut = ({ users, sendTo, ...rest }) => {
    const res = {
        users: JSON.stringify(users),
        ...rest
    };
    /*
    if (sendAs) {
        res.sendAlert = sendAs.includes(REMINDER_TYPE.alert);
        res.sendEmail = sendAs.includes(REMINDER_TYPE.email);
        res.sendSms = sendAs.includes(REMINDER_TYPE.sms);
    } */
     if (sendTo) {
        res.sendTo = transformFilters(sendTo, false);
    } 
    return res;
};
export default transformOut;
