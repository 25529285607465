const DocumentConstants = {
    ERROR: 'DOCUMENT_ERROR',
    LOADING: 'DOCUMENT_LOADING',
    LOADED: 'DOCUMENT_LOADED',
    LOGOUT: 'DOCUMENT_LOGOUT',
    CLEAR: 'DOCUMENT_CLEAR',
};

export {
    DocumentConstants
};
