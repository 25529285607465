import { APIT } from 'utils';
import StatusTransformer from './status';

const transformPatientListIn = (data) => {
    if (data && !data.data) {
        return data;
    }
    return {
        ...data, 
        data: data.data.map(item => 
            ({
                ...item, 
                $moduleStatus: StatusTransformer.in(item),
                isActive: APIT.bool.in(item.isActive),

                lastTestDate: APIT.date.in.toLocal(item.lastTestDate),
                screenerDate: APIT.date.in.toLocal(item.screenerDate),
                dateOfBirth: APIT.date.in.toLocal(item.dateOfBirth),
                isolationStartDate: APIT.date.in.toLocal(item.isolationStartDate),
                isolationEndDate: APIT.date.in.toLocal(item.isolationEndDate),
            }))
    };
};

export default transformPatientListIn;
