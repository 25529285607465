import { api } from 'services';
import { toQueryParams } from 'utils';
import Transformer from './transformers';

class EnterpriseQuestionService {
    static list(filters) {
        const params = toQueryParams(filters);
        return api.get(`/enterprise/questions?${ params}`)
            .then(Transformer.list);
    }
}

export { EnterpriseQuestionService };
