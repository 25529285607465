import './Spin.scss';
import React from 'react';
import { Spin as SpinBase } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const Spin = props => (
    <SpinBase
        indicator={antIcon}
        {...props}
    />
);

export { Spin };
