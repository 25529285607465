import locales, { langs, replaceTranslations } from 'locales';

export const transformTranslationsIn = (data) => {
    const res = {};
    if (data && data.length) {
        data.forEach((i) => {
            const {
                language: LANG,
                translationSlug: key,
                translationText: text
            } = i;
            const lang = LANG.toLowerCase();
            if (!res[lang]) res[lang] = {};
            res[lang][key] = text;
        });
    }
    replaceTranslations(res);
};

export const transformTransItemIn = (slug, field) => {
    const strings = locales.getContent();
    const tr = {};
    langs.forEach(({ key: lang }) => {
        const param = (field ? `${field}$` : '') + lang;
        tr[param] = strings[lang] ? strings[lang][slug] : undefined;
    });
    return tr;
};
