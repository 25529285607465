export default {
    Q_WORK: {
        label: 'Are you reporting to work today?',
        options: {
            Y: 'Yes',
            N: 'No'
        },
    },
    Q_IS_ER: {
        title: 'Is this an emergency?',
        label: 'Stop and call {emergencyNumber} if you are experiencing any of the following:',
        label1: 'Severe difficulty breathing',
        label2: 'Chest Pain',
        label3: 'Pressure or tightness in the chest',
        label4: 'Difficulty remaining conscious',
        label5: 'Confusion',
        label6: 'Bluish lips or face',
        options: {
            Y: 'I am experiencing at least one of these',
            N: 'I do not have any of these'
        },
    },
    Q_NO_WORK: {
        label: 'Why are you not reporting to work?',
        options: {
            SICK: 'I’m feeling sick today',
            INJURED: 'I was injured recently and can’t work',
            NOT_SCHEDULED: 'I was not scheduled to work today',
            PTO: 'I am in PTO',
            OTHER: 'Other (please specify)'
        },
    },
};
