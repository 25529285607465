import { QUESTION_OUTCOMES } from '../../screening.model';
import getFlow from './flow';

export { QUESTION_OUTCOMES };

const CovidFlow = (config) => {
    const { 
        showExposureQuestions, 
        $hiddenQuestions = [],
        $moduleStatus,
        ...rest 
    } = config;
    
    let flow = getFlow(rest);
    flow = flow.filter(item => !(item.static && item.group && $hiddenQuestions[item.group]));
    
    // Remove Exposure Questions (static & custom) to Users with Immunity
    if (!showExposureQuestions) {
        flow = flow.filter(item => item.outcome !== QUESTION_OUTCOMES.EXPOSURE);
    }
    
    /* @TEST debug(flow);  
        const res = flow
        .filter(item => !item.exit)
        .map(({ key, group, outcome, label }) => ({ key, group, outcome, label }));
        console.info(res);
    */
  
    return flow;
};

export { CovidFlow };
