import './sidemenu.scss';
import React from 'react';
import { LayoutConstants } from 'modules/layout';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Drawer, Divider, SlideContainer } from 'components';
import { Version } from 'brands';

import MenuItems from './sidemenu.items';
import MenuItemsSettings from './sidemenu.items.settings';
import MenuItemsSettingsLang from './sidemenu.items.settings.lang';

const SideMenu = () => {
    const dispatch = useDispatch();
    const { menuOpened } = useSelector(state => state.layout);
    
    const onClose = () => {
        dispatch({ type: LayoutConstants.MENU_CLOSE });
    };

    const openMenu = (menu = 'first') => {
        dispatch({ type: LayoutConstants.MENU_OPEN, menu });
    };

    return (
        <>
            <Button
                title="Burger Menu"
                type="link bttn-burger"
                onClick={() => openMenu()}
            ><span />
            </Button>
            <Drawer
                anchor="left"
                onClose={onClose}
                open={!!menuOpened}
                className="lintelio-menu-drawer"
            >
                <div className="mask menu-header">
                    <div className="grad" />
                    <div className="p-first" />
                    <div className="p-second" />
                    <Button 
                        shape="circle" 
                        type="link close" 
                        onClick={onClose}
                    ><div />
                    </Button>
                </div>
                <SlideContainer current={menuOpened}>
                    <MenuItems 
                        onClose={onClose} 
                    />
                    <MenuItemsSettings 
                        onClose={onClose} 
                    />
                    <MenuItemsSettingsLang 
                        onClose={onClose} 
                    />
                </SlideContainer>
                
                <div className="version">
                    <Divider />
                    <Version />
                </div>
            </Drawer>
        </>
    );
};

export default SideMenu;
