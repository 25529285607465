import './footer.scss';
import React from 'react';
import PropTypes from 'prop-types';

const Footer = ({ children }) => (
    <div className="footer">
        <div className="container">
            {children}
        </div>
    </div>
);

Footer.propTypes = {
    children: PropTypes.any.isRequired
};

export default Footer;
