import { api } from 'services';

class BenefitsService {
    static list(id) {
        return api.get(`/locations/${id}/benefits`)
            .then(({ data }) => data);
    }

    static get(id) {
        return api.get(`/benefits/${id}`);
    }
}

export default BenefitsService; 
