import { message } from 'components';
import { showError } from 'utils';
import { Hub, AMPLIFY_SYMBOL, HubEvents } from 'security';
import { PatientConstants as constants } from './patient.constants';
import { PatientServiceUser } from './patient.service.user';
import { UserService } from '../user';

class PatientActionsUser {
    static get() {
        return (dispatch) => {
            dispatch(({ type: constants.LOADING }));
            return PatientServiceUser.get()
                .then((data) => {
                    dispatch(({ type: constants.LOADED, data }));
                    Hub.dispatch('auth', 
                        { event: HubEvents.customSignIn, data }, 
                        'Auth', 
                        AMPLIFY_SYMBOL
                    );
                    return data;
                })
                .catch((error) => {
                    dispatch({
                        type: constants.ERROR,
                        error 
                    });
                });
        };
    }

    static merge(...args) {
        return (dispatch) => {
            dispatch(({ type: constants.LOADING }));
            return UserService.merge(...args)
                .then(PatientServiceUser.get)
                .then((data) => {
                    dispatch(({ type: constants.LOADED, data }));
                    return data;
                })
                .catch((error) => {
                    dispatch({
                        type: constants.ERROR,
                        error 
                    });
                });
        };
    }

    static register(...args) {
        return (dispatch) => {
            dispatch(({ type: constants.LOADING }));
            return PatientServiceUser.register(...args)
                .then((data) => {
                    dispatch(({ type: constants.LOADED, data }));
                    return data;
                })
                .catch((err) => {
                    dispatch({
                        type: constants.ERROR,
                        error: showError(err)
                    });
                });
        };
    }

    static check(...args) {
        return (dispatch) => {
            dispatch(({ type: constants.LOADING }));
            return PatientServiceUser.check(...args)
                .then((data) => {
                    if (data.verificationCode) { 
                        message.info(data.verificationCode); 
                    }
                    dispatch(({ type: constants.LOADED, data }));
                    return data;
                })
                .catch((error) => {
                    dispatch({
                        type: constants.ERROR,
                        error: showError(error)
                    });
                });
        };
    }

    static sync(...attrs) {
        return PatientServiceUser.sync(...attrs);
    }

    static setLang(isGuest, language) {
        return (dispatch) => {
            dispatch({ type: constants.LOADED, data: { language } });
            if (!isGuest) {
                PatientServiceUser.update({ language })
                    .then((data) => {
                        dispatch({ type: constants.LOADED, data });
                    })
                    .catch((error) => {
                        dispatch({
                            type: constants.ERROR,
                            error: showError(error)
                        });
                    });
            }
        };
    }

    static update(...args) {
        return (dispatch) => {
            dispatch(({ type: constants.LOADING }));

            return PatientServiceUser.update(...args)
                .then((data) => {
                    dispatch(({ type: constants.LOADED, data }));
                    return data;
                })
                .catch((error) => {
                    dispatch({
                        type: constants.ERROR,
                        error: showError(error)
                    });
                });
        };
    }

    static resendValidationCode(phone) {
        return (dispatch) => {
            dispatch(({ type: constants.LOADING }));

            return PatientServiceUser.resendValidationCode(phone);
        };
    }

    static validateCode(...args) {
        return (dispatch) => {
            dispatch(({ type: constants.LOADING }));

            return PatientServiceUser.validateCode(...args)
                .then((data) => {
                    dispatch(({ type: constants.LOADED, data }));
                    Hub.dispatch('auth', 
                        { event: HubEvents.customSignIn, data }, 
                        'Auth', 
                        AMPLIFY_SYMBOL
                    );
                    return data;
                })
                .catch((error) => {
                    dispatch({
                        type: constants.ERROR,
                        error: showError(error)
                    });
                });
        };
    }

    static reminders() {
        return (dispatch) => {
            dispatch({ type: constants.LOADING });

            return PatientServiceUser.reminders()
                .then((data) => {
                    dispatch(({ type: constants.REMINDERS_LOADED, data }));
                })
                .catch((error) => {
                    showError(error);
                }); 
        };
    }

    static remindersUpdate(...args) {
        return (dispatch) => {
            dispatch({ type: constants.LOADING });

            return PatientServiceUser.remindersUpdate(...args)
                .then((data) => {
                    dispatch(({ type: constants.REMINDERS_LOADED, data }));
                })
                .catch((error) => {
                    dispatch({
                        type: constants.ERROR,
                        error: showError(error)
                    });
                });
        };
    }

    static signOut() {
        return dispatch => PatientServiceUser.signOut()
            .then(() => {
                dispatch({ type: constants.LOGOUT });
            });
    }
}

export { PatientActionsUser }; 
