export const HIDDEN_QUESTION_GROUPS = {
    ER: 'ER',
    SYMPTOMS: 'SYMPTOMS',
    SYMPTOM_DATE: 'SYMPTOM_DATE',
    EXPOSURE: 'EXPOSURE',
    EXPOSURE_DATE: 'EXPOSURE_DATE',
    TEMPERATURE: 'TEMPERATURE',
    PROTECTION: 'PROTECTION',
    TRIAGE: 'TRIAGE'
};
