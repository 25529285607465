export default {
    title: 'Attendance',
    attendanceStatus: 'Attendance Status',
    questions: {
        title: 'Attendance', 
        none: 'None',
        back: 'Back',
        cancel: 'Cancel',
        continue: 'Next'
    },
};
