/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { GA } from 'utils';
import { register } from './serviceWorkerRegistrar';
import ServiceWorkerContext from './context';

// Initialize deferredPrompt for use later to show browser install prompt.
window.deferredPWAPrompt = undefined;

window.addEventListener('beforeinstallprompt', (e) => {
    // Prevent the mini-infobar from appearing on mobile
    e.preventDefault();
    // Stash the event so it can be triggered later.
    window.deferredPWAPrompt = e;
    // Update UI notify the user they can install the PWA
    // Optionally, send analytics event that PWA install promo was shown.
});

window.addEventListener('appinstalled', () => {
    // Clear the deferredPrompt so it can be garbage collected
    window.deferredPWAPrompt = null;
    // Optionally, send analytics event to indicate successful install
  });

const ServiceWorkerProvider = ({ children }) => {
    const [waitingServiceWorker, setWaitingServiceWorker] = useState(null);
    const [isUpdateAvailable, setUpdateAvailable] = useState(false);

    const registerSW = () => {
        register({
            onUpdate: (registration) => {
                if (registration && registration.waiting) {
                    setWaitingServiceWorker(registration.waiting);
                    setUpdateAvailable(true);
                }
            }, 
            onFailure: () => {
                GA.sendSWEvent('sw_install_error');
            },
            /* onRegister: () => {
                logSwEvent('sw_registered');
            }, */
            onSuccess: () => {
                GA.sendSWEvent('sw_registered_success');
            },
            onInstall: () => {
                GA.sendSWEvent('sw_registered_success');
            } 
        });
    };

    useEffect(() => {
        // Service weoker is disabled
        // unregister();
        registerSW();
        /* @REMOVE interval for prod & stage
        const registerEverySecs = 60;
        const interval = setInterval(registerSW, registerEverySecs * 1000);
        return () => clearInterval(interval);
        */
      }, []);

    useEffect(() => {
        // Setup an event listener to automatically reload the page
        // after the Service Worker has been updated, this will trigger
        // on all the open tabs of our application, so that we don't leave
        // any tab in an incosistent state
        if (waitingServiceWorker) {
            waitingServiceWorker.addEventListener('statechange', (event) => {
                if (event.target.state === 'activated') {
                    window.location.reload();
                }
            });
        }
    }, [waitingServiceWorker]);

    const isPushNotificationSupported = () => 'serviceWorker' in navigator && 'PushManager' in window;

    const install = async () => {
        if (window.deferredPWAPrompt) {
            window.deferredPWAPrompt.prompt();
            const { outcome } = await window.deferredPWAPrompt.userChoice;
            if (outcome === 'accepted') {
                window.deferredPWAPrompt = null;
                return true;
            }
        }
        return false;
    };

    const value = React.useMemo(() => ({
        isUpdateAvailable,
        isPushNotificationSupported,
        install,
        update: () => {
            if (waitingServiceWorker) {
                /* const onUpdateClick = () => {
                    update();
                    message.destroy('$msgUpdate');
                };
                message.info({
                    key: '$msgUpdate',
                    content: (
                        <>
                            A new version is available
                            <Button type="link" onClick={onUpdateClick}>REFRESH</Button>
                        </>
                    ),
                    duration: 0,
                }); */
                // We send the SKIP_WAITING message to tell the Service Worker
                // to update its cache and flush the old one
                waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
            }
        }
    }), [isUpdateAvailable, waitingServiceWorker]);

    return (
        <ServiceWorkerContext.Provider value={value}>
            {children}
        </ServiceWorkerContext.Provider>
    );
};

ServiceWorkerProvider.propTypes = {
    children: PropTypes.any.isRequired
};

export { ServiceWorkerProvider };
