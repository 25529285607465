import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Input, Form, Select } from 'components';
import { REGISTRATION_FIELD_TYPE, LocationService } from 'modules/location';
import { ValidationRules } from 'utils';

import { useApi } from 'hooks';

const ProfileCustomFields = () => {
    const { Item } = Form;
    const rules = ValidationRules.translate();
    const { id: locationId } = useSelector(state => state.location);

    const [loadFields, { data: fields }] = useApi(LocationService.registrationFields, []);

    useEffect(() => {
        if (locationId) {
            loadFields(locationId);
        }
    }, [loadFields, locationId]);

    return fields.map((item, idx) => {
        const rule = item.isRequired ? [rules.required] : [];
        const options = item.options?.map(option => (
            {
                value: option,
                label: option
            }
        ));
        return (
            <Item
                key={item.id}
                name={`customField${idx + 1}`}
                label={item.label}
                rules={rule}
            >
                {item.type === REGISTRATION_FIELD_TYPE.SELECT
                    ? (
                        <Select
                            options={options}
                            placeholder={item.placeholder}
                        />
                    )
                    : (<Input placeholder={item.placeholder} />)}
            </Item>
        );
    });
};

export default ProfileCustomFields;
