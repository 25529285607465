import './image.scss';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Upload, Button } from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { local } from 'services';
import ImageAlt from 'assets/images/image_alt.svg';

const ImageUpload = ({ value, onChange, maxCount, accept, name }) => {
    const [thisValue, setThisValue] = useState();

    useEffect(() => {
        setThisValue(value);
    }, [value]);

    const beforeChange = (file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            setThisValue(e.target.result);
            onChange(e.target.result);
        };
        reader.readAsDataURL(file);
        return false;
    };

    const onDeleteClick = () => onChange(null);
    
    return (
        <div className="image-uploader">
            <Upload 
                listType="picture-card"
                defaultFileList={[thisValue] || []}
                accept={accept}
                name={name}
                beforeUpload={beforeChange}
                headers={{ Authorization: `Bearer ${local.token()}` }}
                showUploadList={false}
                maxCount={maxCount}
                multiple={maxCount > 1}
            >
                <div>
                    <PlusOutlined />
                    <div style={{ marginTop: 8 }}>Upload</div>
                </div>
            </Upload>
            
            <div className="preview">
                <img
                    src={value || ImageAlt} 
                    alt="N/A"
                    className={value ? '' : 'empty'}
                />
                <Button
                    type="link"
                    size="large"
                    disabled={!value}
                    onClick={onDeleteClick}
                    icon={<DeleteOutlined />}
                />
            </div> 
        </div>
    );
};

ImageUpload.propTypes = {
    onChange: PropTypes.func,
    accept: PropTypes.string,
    name: PropTypes.string,
    maxCount: PropTypes.number,
    // eslint-disable-next-line react/require-default-props
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
        PropTypes.array,
    ]),
};

ImageUpload.defaultProps = {
    name: 'file',
    maxCount: 1,
    accept: '.als, .cer, .csv, .dat, .doc, .docx, .epl, .exe, .gif, .ico, .ics, .jpeg, .jpg, .js, .mdb, .msi, .ofx, .pdf, .pfx, .png, .ppt, .pptx, .qbo, .qfx, .rar, .rtf, .sql, .swf, .tif, .txt, .xls, .xlsx, .xml, .zip, .zpl',
    onChange: () => { },
};

export { ImageUpload };
