import './Card.scss';
import React from 'react';
import PropTypes from 'prop-types';

const Card = ({ title, className, children }) => (
    <div className={`card ${className}`}>
        { title && <h2>{title} </h2> }
        <div className="card-content">
            { children }
        </div>
    </div>
);

Card.propTypes = {
    title: PropTypes.any,
    className: PropTypes.string,
    children: PropTypes.any.isRequired
};

Card.defaultProps = {
    title: null,
    className: ''
};
export { Card };
