import React from 'react';
import { Modal } from 'apps/administration/components';
import IcoWarning from 'assets/images/ico.warning.svg';

const { confirm } = Modal;

const ConfirmDialog = ({
    title, text, onOk, okText, cancelText,
    className, okButtonProps, cancelButtonProps, onCancel = () => { }
}) => confirm({
    className: `confirm-dialog ${className}`,
    icon: <img src={IcoWarning} width={40} alt="Warning" />,
    okText: okText || 'Delete',
    cancelText: cancelText || 'Cancel',
    content: (
        <span>
            {text}
        </span>
    ),
    title,
    onOk() {
        return onOk();
    },
    onCancel() {
        return onCancel();
    },
    okButtonProps: okButtonProps || {},
    cancelButtonProps: cancelButtonProps || {},
});

export { ConfirmDialog, };
