import { MODULE_PANDEMIC } from '../../module';
import transformIncidentOut from './incident';

const transformScreeningOut = (screening) => {
    const { result, answers, outcomes, module, ...data } = screening; 
    const questionsAndAnswers = {};
    const questionsAndAnswersCodes = {};
    const incidentDescription = module === MODULE_PANDEMIC 
        ? transformIncidentOut.out(answers) : undefined;

    const addOptionsWithCodes = (options, value, isOutcome) => {
        if (!options) return;
        if (options && !options.length) return;
        const option = options.find(i => i.value === value);
        if (option?.code) {
            questionsAndAnswersCodes[option.code] = isOutcome ? 1 : 0;
        }
    };
    
    answers.forEach((item) => {
        const { 
            key, value, 
            label, labelToPost, 
            isOutcome, 
            options, 
            code 
        } = item; 

        let answer = value;
        if (value === 'Y') answer = 'Yes';
        if (value === 'N') answer = 'No';
        
        questionsAndAnswers[key] = { 
            label: labelToPost || label, 
            value: answer 
        };
        
        if (code) {
            questionsAndAnswersCodes[code] = isOutcome ? 1 : 0;
        }

        if (Array.isArray(value)) {
            value.forEach((val) => {
                addOptionsWithCodes(options, val, true);
            });
        } else {
            addOptionsWithCodes(options, value, isOutcome);
        }
    });

    const failReason = Object.keys(outcomes)
        .filter(key => outcomes[key] === true && !key.includes('_'))
        .join(', ')
        .toLowerCase()
        .replace('fail', 'custom');
    
    return {
        ...data,
        questionsAndAnswers,
        questionsAndAnswersCodes,
        incidentDescription,
        screeningResults: result,
        failReason,
        module
    };
};

export default transformScreeningOut;
