/* eslint-disable react/no-unescaped-entities */
import React from 'react';

const AdminsGuidlineText = () => (
    <div className="body p-bulk">
        <span className="bold">Overview:</span>
        <p>
            - Please fill in all mandatory fields for
            uploaded admins in the &quot;Bulk upload input sheet&quot;
        </p>
        <p>
            - Please follow the below guidelines for data
            in the specific fields
        </p>
        <p className="mb-s">
            - If there are missing mandatory fields or data formats
            of the fields are incorrect, no admins will be uploaded
            and an error message will be given
        </p>

        <span className="bold">Mandatory fields:</span>
        <p>- Email
            <em>(max: 50 characters)</em>
        </p>
        <p>- First name
            <em>(max: 20 characters, excluding special symbols)</em>
        </p>
        <p>- Last name
            <em>(max: 20 characters, excluding special symbols)</em>
        </p>
        <p>- Role
            <em>('ADMIN')</em>
        </p>
        <p>- Location IDs
            <em>Comma separated Location IDs</em>
        </p>
    </div>
);

export { AdminsGuidlineText };
