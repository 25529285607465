import './message.scss';
import { Button, Banner } from 'components';
import React from 'react';
import PropTypes from 'prop-types';
import locales from 'locales';

const QuickScreenMessage = ({ setIsQuick }) => {
    const { routes: { screening: strings } } = locales;
    return (
        <Banner className="blue quick-screen-message">
            <div>{strings.quickScreening.message.text}</div>
            <Button 
                size="small"
                type="default"
                onClick={() => setIsQuick(true)}
            >
                {strings.quickScreening.message.button}
            </Button>
        </Banner>
    );
};

QuickScreenMessage.propTypes = {
    setIsQuick: PropTypes.func.isRequired
};

export default QuickScreenMessage;
