import './InfiniteScroll.scss';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import locales from 'locales';
import { showError } from 'utils';
import Skeleton from '@mui/material/Skeleton';
import ScrollDetect from './scrollDetect';

const Take = 25; 

const InfiniteScroll = React.forwardRef(({ service, setData, params }, ref) => {
    const { common } = locales;
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(1);

    const take = params.take || Take;

    const loadMore = () => service({ 
            ...params, 
            page, 
            take
        })
        .then((data) => {
            setData(val => [...(val || []), ...data.data]);
            setPage(data.current_page + 1);
            setHasMore(data.current_page < data.total_pages);
        })
        .catch((err) => {
            showError(err);
            setHasMore(false);
        });

    const reload = () => {
        service({ 
            ...params, 
            page: 1, 
            take: take * (page - 1) 
        })
        .then((data) => {
            setData(data.data);
        })
        .catch((err) => {
            showError(err);
            setHasMore(false);
        });
    };

    React.useImperativeHandle(ref, () => ({
        reload
    }));

    return (
        <ScrollDetect
            loadMore={loadMore}
            hasMore={hasMore}
        >
            {hasMore && (
                <center>
                    <Skeleton 
                        variant="rectangular" 
                        className="c-skeleton"
                    >{common.loading}...
                    </Skeleton>
                </center>
            )}
            {!hasMore && ' '}
            
        </ScrollDetect>
    );
});

InfiniteScroll.propTypes = {
    service: PropTypes.func.isRequired,
    setData: PropTypes.func.isRequired,
    params: PropTypes.shape({
        take: PropTypes.number,
        filters: PropTypes.object,
        sort: PropTypes.object
    })
};

InfiniteScroll.defaultProps = {
    params: {
        take: Take,
        filters: {},
        sort: {}
    }
};

export { InfiniteScroll };
