/* eslint-disable import/no-mutable-exports */
import {
    message as antdMessage,
    notification as antdNotification,
    App,
    Modal as antdModal
} from 'antd';

let message = antdMessage;
let notification = antdNotification;
let modal = antdModal;

/**
 * This component is used to escape the antd's static functions. 
 */
function EscapeAntd() {
    const staticFunctions = App.useApp();
    message = staticFunctions.message;
    notification = staticFunctions.notification;
    modal = staticFunctions.modal;
    return null;
}

export {
    message,
    notification,
    modal,
    EscapeAntd
};
