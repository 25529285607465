import { APIT } from 'utils';
import { LOCATION_TRANS_TYPES } from '../location.model';
import transformAuth from './auth';
import transformLocales from './locales';
import transformBenefits from './benefits';

const transformLocationOut = (data) => {
    const {
        isScreenFullIntakeEnabled, allowCustomQuestions, 
        whiteLabelPermission, whiteLabelReminders, whiteLabelAlerts,
        emailSendingEnabled, smsSendingEnabled, 
        isSpecificSymptomsEnabled,
        passIfVaccinatedEnabled,
        isScreeningDetailsEnabled, remindersPaused, benefits, subdomain,
    } = data;

    const obj = {
        ...data,
        // Toggles
        whiteLabelPermission: APIT.bool.out(whiteLabelPermission),
        whiteLabelReminders: APIT.bool.out(whiteLabelReminders),
        whiteLabelAlerts: APIT.bool.out(whiteLabelAlerts),
        isSpecificSymptomsEnabled: APIT.bool.out(isSpecificSymptomsEnabled),
        isScreenFullIntakeEnabled: APIT.bool.out(isScreenFullIntakeEnabled),
        emailSendingEnabled: APIT.bool.out(emailSendingEnabled),
        smsSendingEnabled: APIT.bool.out(smsSendingEnabled),
        remindersActive: APIT.bool.out(remindersPaused, true),
        isScreeningDetailsEnabled: APIT.bool.out(isScreeningDetailsEnabled),
        passIfVaccinatedEnabled: APIT.bool.out(passIfVaccinatedEnabled),
        allowCustomQuestions: APIT.bool.out(allowCustomQuestions),
        areAttachmentsMandatory: APIT.bool.out(data.areAttachmentsMandatory),
        isMedicalConsentEnabled: APIT.bool.out(data.isMedicalConsentEnabled),
        isDateOfBirthFieldEnabled: APIT.bool.out(data.isDateOfBirthFieldEnabled),
        isGenderFieldEnabled: APIT.bool.out(data.isGenderFieldEnabled),
        isVaccinationMandatory: APIT.bool.out(data.isVaccinationMandatory),
        isSelfRegistrationEnabled: APIT.bool.out(data.isSelfRegistrationEnabled, true),
        isTriage: APIT.bool.out(data.isTriage),
        isActive: APIT.bool.out(data.isActive),
        tempCheck: APIT.bool.out(data.$hideTemperature, true),
        subdomain: subdomain === '' ? null : subdomain,
        benefits: transformBenefits.out(benefits),
        ...transformAuth.out(data),
        ...transformLocales.out(data, LOCATION_TRANS_TYPES),
    };
    return obj;
};

export default transformLocationOut;
