export default {
    title: 'Reminders', 
    screenReminders: 'Screening Reminders', 
    testReminders: 'Testing Reminders',
    scheduled: 'Reminders scheduled',
    selectTypeSend: 'Please select how to send reminder',
    selectTypeReceive: 'Please select how to receive reminder',
    types: {
        email: 'Email',
        sms: 'SMS',
        alert: 'In-App',
    },
    days: {
        Monday: 'Monday',
        Tuesday: 'Tuesday',
        Wednesday: 'Wednesday',
        Thursday: 'Thursday',
        Friday: 'Friday',
        Saturday: 'Saturday',
        Sunday: 'Sunday',
    },
    done: 'Done', 
    set: 'Set', 
    selectTime: 'Select time', 
    back: 'Back', 
    addSchedule: '+ Add', 
    scheduled1: 'Scheduled',
    remove: 'Remove',
    banner: 'To receive SMS alerts and reminders, please add a phone number from your profile settings or by clicking here.'
};
