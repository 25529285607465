import React from 'react';
// eslint-disable-next-line camelcase
import { UNSAFE_NavigationContext } from 'react-router-dom';

const useBlocker = (blocker, when = true) => {
    const { navigator } = React.useContext(UNSAFE_NavigationContext);

    React.useEffect(() => {
        if (!when) return;
        const unblock = navigator.block((tx) => {
            const autoUnblockingTx = {
                ...tx,
                retry() {
                    // Automatically unblock the transition so it can play all the way
                    // through before retrying it. TODO: Figure out how to re-enable
                    // this block if the transition is cancelled for some reason.
                    unblock();
                    tx.retry();
                },
            };
            blocker(autoUnblockingTx);
        });

        // eslint-disable-next-line consistent-return
        return () => {
            unblock();
        };
    }, [navigator, blocker, when]);
};

export { useBlocker }; 
