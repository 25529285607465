import { Amplify } from '@aws-amplify/core';
import { isAdminPortal } from 'utils';
import { AuthConstants } from './constants';
// Debug Auth
// window.LOG_LEVEL = 'DEBUG';

const awsDomain = process.env.REACT_APP_AWS_DOMAIN;
const awsRegion = process.env.REACT_APP_AWS_REGION;
const awsUserPoolId = process.env.REACT_APP_AWS_USER_POOL_ID;
const awsClientId = process.env.REACT_APP_AWS_USER_POOL_CLIENT_ID;
const awsFlowTypeAdmin = process.env.REACT_APP_AWS_FLOW_TYPE_ADMIN || 'USER_SRP_AUTH';
const awsFlowTypeClient = process.env.REACT_APP_AWS_FLOW_TYPE_CLIENT || 'USER_SRP_AUTH';    
const awsFlowType = isAdminPortal ? awsFlowTypeAdmin : awsFlowTypeClient;

// Cognito Hosted UI configuration
const config = {
    Auth: {
        region: awsRegion,
        userPoolId: awsUserPoolId,
        userPoolWebClientId: awsClientId,
        authenticationFlowType: awsFlowType,
        oauth: {
            domain: `${awsDomain}.auth.${awsRegion}.amazoncognito.com`,
            scope: [
                'phone',
                'email',
                'openid',
                'profile',
                'aws.cognito.signin.user.admin'
            ],
            redirectSignIn: `${window.location.origin}/${AuthConstants.AWS_AUTH_CALLBACK}`, 
            redirectSignOut: `${window.location.origin}/${AuthConstants.AWS_AUTH_CALLBACK}`,
            // responseType in [token, code]
            // note that REFRESH token will only be generated when the responseType is code
            responseType: 'code',
        }
    },
    
};

Amplify.configure(config);

export default config;
