import './Form.scss';
import React from 'react';

const FormBase = require('antd/lib/form').default;

const Form = props => (
    <FormBase
        layout="vertical"
        size="middle"
        colon={false}
        scrollToFirstError
        {...props}
    />
);

Form.useForm = FormBase.useForm;
Form.List = FormBase.List;
Form.Item = FormBase.Item;
Form.Provider = FormBase.Provider;

export { Form };
