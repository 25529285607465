import { api } from 'services';
import { PatientServiceUser } from 'modules/patient';
import Transformer from './transformers';

class ScreeningService {
    static list(...args) {
        return api.list('/screenings', ...args)
            .then(Transformer.list);
    }

    static listByPatient(id, ...args) {
        return api.list(`/patients/${id}/screenings`, ...args)
            .then(Transformer.list);
    }

    static get(id) {
        return api.get(`/screenings/${id}`)
            .then(Transformer.in);
    }

    static quickScreen(data) {
        return api.post('/patients/quickScreening', data);
    }

    static send(data) {
        const patientId = PatientServiceUser.id || data.id; 
        const dataOut = Transformer.out(data);
        // return Promise.resolve(r => r({ callback: 0 }));
        return api.post(`/patients/${patientId}/screenings`, dataOut)
            .then(res => ({ ...res, callback: data.callback }));
    }
}

export default ScreeningService; 
