export default {
    screening: {
        title: 'Screening',
        outcome: 'Outcome', 
        date: 'Date', 
    },
    testing: {
        title: 'Testing',
        verified: 'Verified',
        unverified: 'Unverified',
        POSITIVE: 'Positive',
        NEGATIVE: 'Negative',
        INCONCLUSIVE: 'Inconclusive',
        desease: 'Disease/Agent',
        verification: 'Verification Issued By',
        status: 'Test Status',
        testResult: 'Test Result',
        date: 'Test Date',
        reportDate: 'Report Date',
        location: 'Location',
        facility: 'Facility',
        admin: 'Admin',
        type: 'Type',
        takenAt: 'Taken At',
        antigen: 'Antigen',
        pcr: 'PCR',
        lab: 'Laboratory',
        home: 'Home',
        'Home-Proctored': 'Home-Proctored',
    },
    vaccination: {
        title: 'Vaccination',
        manufacturer: 'Manufacturer',
        shotDate: 'Date of Shot',
        firstShotDate: 'Date of First Shot',
        secondShotDate: 'Date of Second Shot',
        status: 'Vaccination Status',
        verified: 'Verified',
        unverified: 'Unverified',
        desease: 'Disease/Agent',
        vaccine: 'Vaccine',
        lot: 'Lot Number',
        date: 'Date of Shot',
        lot1: 'Lot First Shot',
        lot2: 'Lot Second Shot',
        date1: 'Date of First Shot',
        date2: 'Date of Second Shot',
        facility: 'Facility',
        facility1: 'Facility First Shot',
        facility2: 'Facility Second Shot',
        verification: 'Verification Issued By',
        admin: 'Admin',
    },
    vaccinationOptOut: {
        reason: 'Reason',
        date: 'Date',
        verified: 'Verified',
        unverified: 'Unverified',
        status: 'Exemption (Unvaccinated) Status',
        reasonDetails: 'Reason Details',
    }
};
