import './Badge.scss';
import React from 'react';
import PropTypes from 'prop-types';
import BadgeMUI from '@mui/material/Badge';

const Badge = ({ children, color, className, ...rest }) => (
    <BadgeMUI 
        color={color} 
        className={`c-badge ${className}`} 
        {...rest}
    >
        {children}
    </BadgeMUI>
);

Badge.propTypes = {
    children: PropTypes.any.isRequired,
    color: PropTypes.string,
    className: PropTypes.string
};

Badge.defaultProps = {
    color: 'error',
    className: ''
};

export { Badge };
