import React, { useEffect, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

let openSnackbarFn;

const Notifier = () => {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState({
        text: '',
        type: 'success',
        duration: 3
    });

    const openSnackbar = (data) => {
        setMessage(data);
        setOpen(true);
    };
    
    useEffect(() => {
        openSnackbarFn = openSnackbar;
    }, []);

    const handleSnackbarRequestClose = () => {
        setOpen(false);
        setMessage({});
    };

    return (
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={open}
            autoHideDuration={(message.duration || 3) * 1000}
            onClose={handleSnackbarRequestClose}
            ContentProps={{
                'aria-describedby': 'snackbar-message-id',
            }}
        >
            <Alert 
                severity={message.type}
                elevation={2}
                sx={{ width: '100%' }}
            >
                {message.text}
            </Alert>
        </Snackbar>
    );
};

const message = {
    error: (text, duration) => openSnackbarFn({ text, type: 'error', duration }),
    info: (text, duration) => openSnackbarFn({ text, type: 'info', duration }),
    success: (text, duration) => openSnackbarFn({ text, type: 'success', duration }),
    warning: (text, duration) => openSnackbarFn({ text, type: 'warning', duration }),
};

export { message, Notifier };
