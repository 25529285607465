/* eslint-disable max-len */
export default {
    new: 'Nuevo', 
    close: 'Cerrar',
    cancel: 'Cancelar',
    submit: 'Enviar',
    confirm: 'Confirmar',
    policy: 'Política de Privacidad',
    terms: 'Terminos y condiciones de Uso',
    policyLink: '/privacidad',
    termsLink: '/terminos',
    done: 'Listo',
    selectDate: 'seleccione Fecha',
    pleaseSelect: 'Por favor seleccione',
    pleaseSpecify: 'Por favor especifique',
    additionalComments: 'Comentarios Adicionales', 
    next: 'Siguiente',
    back: 'Atrás',
    signOut: 'Cerrar Sesión',
    success: 'Éxito',
    action: 'Accion', 
    screen: 'Evaluacion', 
    report: 'Reporte', 
    status: 'Estado',
    optOut: 'Excluirme (Vacunación)',
    optOutVaccination: 'Reporte de Exención (No Vacunado)', 
    viewProtocols: 'Ver Protocolos', 
    protocol: 'Protocolo',
    year: 'Año', 
    month: 'Mes',
    day: 'Dia', 
    date: 'Fecha',
    proceed: 'Proceder', 
    noData: 'Nada que mostrar aún',
    loading: 'Cargando', 
    OK: 'OK', 
    menu: { 
        home: 'Página Principal',
        back: 'Atrás',
        settings: 'Configuraciones', 
        support: 'Soporte',
        personalInfo: 'Información Personal',
        editProfile: 'Editar Perfil',
        general: 'General',
        language: 'Idioma',
        reminders: 'Notificaciones',
        screenReminders: 'Recordatorios para evaluacion', 
        about: 'Acerca de',
        scheduled: 'Programar Recordatorios',
        scheduledOne: 'Recordatorio programado',
        history: 'Ver Historial', 
        installText: 'Cuando descargue la aplicacion sera mas facil utilizarlo en el telefono!', 
        installBtn: 'Instalar', 
        skipInstallBtn: 'Ahora no',
        integration: 'Acuerdo de integracion para evaluaciones esternas', 
    },
    bottomMenu: {
        home: 'Pagina Principal', 
        benefits: 'Beneficios', 
        alerts: 'Alertas', 
        reports: 'Reportes', 
        screen: 'Pandemia', 
        more: 'Mas', 
        attendance: 'Asistencia', 
        incident: 'Incidente', // @NEW
        telehealth: 'Supervisor', // @NEW, 
        lifeworks: 'LifeWorks',
        integrityLine: 'Integrity Line',
        immunization: 'Immunization', // @NEW
    },
    jobCategory: {
        healthcare: 'Personal de salud',
        other: 'Otro', 
    },
    remindersPopup: {
        title: '¡Recordatorios y alertas SMS están habilitadas!',
        subtitle: 'Tu administrador ha habilitado recordatorios y alertas por mensaje de texto para tu ubicacion.',
        subtitle1: 'Presiona abajo para ingresar tu teléfono ahora, o hazlo después en tu perfil. Una vez listo, puedes activar tus recordatorios SMS.',
        button1: 'Configurar numero telefonico.',
        button2: 'Recordarme en 7 días',
        button3: 'No mostrar de nuevo',
    },
    medicalConsentPopup: {
        header: 'Consentimiento de Usuario', 
        button1: 'Yo autorizo', 
        button2: 'No autorizo (sacarme de sesión)', 
    },
    leavePopup: {
        title: '¿Esta seguro?', 
        text: 'Los datos que ha ingresados se perderan.', 
        okButton: 'Si, Salir de esta pagina', 
        cancelButton: 'No, Fue un error' 
    },
    fileUpload: {
        errorFileSize: 'Tamaño de archivo mayor a 10MB no es permitido', 
        errorMaxCount: 'Cuenta maxima de archivos ha sido excedida'  
    },
    customContent: {
        holdAttendance: { 
            header: 'En Espera',
            title: 'Usted no se estara reportandose a trabajar hoy.',
            text: 'Para asistencia por favor comuniquese con su gerente de recursos humanos o revise la lista de beneficios disponibles.',
        },
        hold: {
            header: 'En Espera',
            title: 'Parece que no se está sintiendo bien', 
            text: 'No se reporte a trabajar hoy. Contacte a su departamento de Recursos Humanos inmediatamente para discutir próximas instrucciones. Gracias!', 
        },
        screen: {
            header: 'Ok', 
            title: '',
            text: '',
        },
        test: {
            header: 'Prueba', 
            title: '', 
            text: '', 
        },
        vaccination: {
            header: 'Vacunación',
            title: 'Usted debe vacunarse',
            text: 'Parece que necesita vacunarse contra COVID-19. Por favor contacte al representante de su organización para obtener instrucciones sobre cómo proceder.', 
        },
        protocol: { 
            header: 'Protocolo de Pandemia',
            title: '',
            text: '',
        },
        medical: { 
            header: 'Consentimiento Medico',
            title: '',
            text: '',
        },
        testingReminders: { 
            header: 'Recordatorios de Prueba',
            title: 'La validez de la prueba COVID expira en [x] dias',
            text: 'Para ingresar otro resultado de examen por favor siga el siguiente link.',
        }
    },
    modules: {
        screen: 'Pandemia', 
        test: 'Prueba',
        vaccination: 'Vacunación',
        attendance: 'Asistencia',
        injury: 'Lesion', 
        optOut: 'Exención (No Vacunado)', 
        incident: 'Incidente',
    },
    moduleActions: {
        screen: 'Evaluación',
        test: 'Prueba',
        vaccination: 'Vacunación',
        attendance: 'Asistencia', 
        injury: 'Lesion'  
    }, 
    moduleStatus: {
        OK: 'Ok',  
        HOLD: 'En Espera',  
        REQUIRED: 'Acción Requerida',
    },
    statusTitle: {
        noPending: 'No hay acciones pendientes',  
        hold: 'Su cuenta esta suspendida',  
        pending: '1 Acción Pendiente',  
        pendingMany: '{count} Acciones Pendientes',  
        overdue: '1 Acción Atrasada',  
        overdueMany: '{count} Acciones Atrasadas',  
    },
    statusSubtitle: {
        notRequired: 'No se Requiere Accion', 
        pending: 'Pendiente', 
        due: 'Pendiente en  {dueDays} dias',
        overdue: 'Atrasada', 
    },
    vaccinationStatus: {
        VACC: 'Vacunado/a',
        UNVACC: 'No Vacunado/a',
        OPTOUT: 'Exento',
        VACCREQUIRED: 'Vacunación requerida', 
        PARTIALLYVACC: 'Parcialmente vacunado/a', 
    },
    testStatus: {
        OK: 'Sin acciones requeridas',
        NEGATIVE: 'Proximo examen  {date}',
        POSITIVE: 'Examen Positivo',
        REQUIRED: 'Examen Requerido',
    },
    attendanceStatus: {
        OK: 'No Hay Acciones Pendientes',  
        HOLD: 'Su Cuenta Esta En Espera',  
        REQUIRED: '1 Accion Atrasada',  
    }
};
