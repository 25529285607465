export default {
    er: {
        title: 'Llame al {emergencyNumber}.',
        label: '<p class="ta-center">Puede que tenga una emergencia medica.</p>'
    },
    cleared: {
        title: 'Aprobado',
        label: '<p class="ta-center">Gracias por realizar su evaluacion.</p>',
    },
    failed: {
        title: 'Ausente',
        label: '<p class="ta-center">Sentimos que no pueda presentarse al trabajo el dia de hoy. Porfavor haga click abajo para acceder a sus beneficios.</p>',
        labelNoBenefits: '<p class="ta-center">Sentimos que no pueda presentarse al trabajo el dia de hoy.</p>', 
        buttonLabel: 'Ir a Pagina de Beneficios'
    }
};
