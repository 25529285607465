export default {
    locationNotFound: 'Có vẻ bạn đang sử dụng liên kết không hợp lệ. Vui lòng liên lạc đại diện ở tổ chức để nhận liên kết đúng.',
    patientNotFound: 'Lỗi tải người sử dụng.',
    codeSent: 'Chúng tôi đã gửi mã khác. Vui lòng kiểm tra tin nhắn.',
    lastCodeSent: 'Chúng tôi đã gửi mã khác. Bạn đã chạm mức tối đa số lượng tin nhắn trong ngày.',
    lastCodeSentError: 'Bạn đã chạm mức tối đa số lượng tin nhắn trong ngày.',
    missingPhoneNumber: 'Số điện thoại không được đăng ký.',
    verCodeMissing: 'Thiếu mã xác nhận',
    codeExpired: 'Mã hết hạn.',
    codeWrong: 'Hmmm... mã đó không hoạt động. Kiểm tra và thử lại một lần nữa. Bạn có {message} lần thử còn lại.',
    networkError: 'Lỗi Mạng lưới',
    unknownError: 'Lỗi không biết đã được xuất hiện. Vui lòng thử lại.',
    missingLocation: 'Vui lòng cung cấp vị trí hợp lệ của danh số id hoặc dãy chữ', 
    lockedNotifications: 'Thông báo bị khóa. Vui lòng mở khóa.',
    missingContact: 'Vui lòng hoàn thành kiểm tra đầu tiên của bạn trước khi sửa đổi cài đặt dữ liệu.',
    serverError: 'Oops, chúng tôi đang gặp vấn đề kết nối với máy chủ vào lúc này. Vui lòng thử lại sau ít phút.',
    invalidCredentials: 'Email hoặc mật khẩu không hợp lệ',
    userLimitReached: 'Giới hạn người dùng đã chạm đến tài khoản của bạn. Liên lạc người có trách nhiệm trong tổ chức của bạn.',
    patientInactive: 'Trạng thái của bạn không hoạt động, vui lòng liên lạc ban giám hiệu trường học.',
    registrationDisabled: 'Registration is not allowed. Please contact your HR representative for more details.' // @NEW
};
