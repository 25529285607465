import './Help.scss';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { HelpIcon, CloseIcon } from 'components/Icons';
import { Button, Modal } from 'components';

import IconButton from '@mui/material/IconButton';

const Help = ({ children }) => {
    const [visible, setVisible] = useState(false);
    return (
        <>
            <Modal 
                className="c-help-modal"
                title={<HelpIcon />} 
                visible={visible}
                onClose={() => setVisible(false)}
                footer={(
                    <Button 
                        size="small"
                        type="link" 
                        onClick={() => setVisible(false)}
                        icon={<CloseIcon />}
                    >
                        Close
                    </Button>
                )}
            >
                <p>{children}</p>
                
            </Modal>
            <IconButton
                size="small"
                className="c-help"
                onClick={() => setVisible(true)}
            >
                <HelpIcon fontSize="inherit" />
            </IconButton>
        </>
    );
};

Help.propTypes = {
    children: PropTypes.any.isRequired,
};

export { Help };
