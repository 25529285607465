import React from 'react';
import { Button } from 'components';
import { useDispatch } from 'react-redux';
import { ScreeningActions } from 'modules/screening';
import { ImageCircle } from 'apps/lintelio/containers';
import locales from 'locales';
import PropTypes from 'prop-types';

const ResultTriageSuccess = ({ wrapper: Wrapper }) => {
    const { routes: { screening: { successNurse: strings } } } = locales;
    const dispatch = useDispatch();
    
    const onBack = () => {
        dispatch(ScreeningActions.end());
    };
    
    return (
        <Wrapper
            footer={(
                <Button 
                    type="primary brand" 
                    onClick={onBack}
                    block
                >{strings.back}
                </Button>
            )}
        >
            <ImageCircle
                type="screening-nurse-success mv"
            />
            <h1 className="ta-center mb-s">{strings.title}</h1>
            <p className="mt ta-center">{strings.subtitle}</p>
        </Wrapper>
    );
};

ResultTriageSuccess.propTypes = {
    wrapper: PropTypes.any.isRequired
};

export default ResultTriageSuccess;
