import './Highlight.scss';
import React from 'react';
import PropTypes from 'prop-types';

const Highlight = ({ search, text }) => {
    if (!search) {
        return text;
    }
    const len = search.length;
    const index = text.toLowerCase().indexOf(search);
    if (index > -1) {
        const beforeStr = text.substr(0, index);
        const string = text.substr(index, len);
        const afterStr = text.substr(index + len);
        return (
            <>
                {beforeStr}
                <span className="highlight-text">
                    {string}
                </span>
                {afterStr}
            </>
        );
    }
    return text;
};

Highlight.propTypes = {
    search: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired
};

export { Highlight };
