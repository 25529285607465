export default {
    labels: {
        next: 'Next',
        back: 'Back',
        phone: 'Phone number',
        password: 'Password',
        email: 'Email',
        tryAgain: 'Try again',
        forgotPassword: 'Forgot your password?',
        resetPassword: 'Reset password',
    },
    social: {
        subtitle: 'Sign in with your Social Accounts',
        or: 'or'
    },
    preSignIn: {
        title: 'Welcome to the <div>{title}</div> Health and Safety Service',
        labels: {
            button: 'Let’s get started',
        },
        placeholders: {
            location: 'Please select location'
        }
    },
    signInPhone: {
        title: 'Add Phone Number',
        subtitle: 'Enter your mobile phone number below to get started.',
        popupHelp: {
            linknote: 'Why do we need it?',
            note: 'We need to send a code to this phone number to verify that it\'s really you.  Text messaging fees from your carrier may apply (they hardly ever do these days, but we have to say that).',
            noteClose: 'Got it',
        }
    },
    signInEmail: {
        title: 'Add Email',
        subtitle: 'Enter your email below to get started.',
    },
    code: {
        title: 'Verification',
        subtitle: 'Enter your verification code',
        subtitleUser: 'Welcome, {firstName}!',
        notes: {
            email: 'We just sent a verification code by email to {maskedUsername}. The code will expire five minutes after you requested it.',
            phone_number: 'We just sent a verification code to the phone number ending in {maskedUsername}. The code will expire five minutes after you requested it.'
        },
        tryAgainNote: 'Didn’t receive a code?',
        codeSent: {
            email: 'We have sent you another code. Please check your email.',
            phone_number: 'We have sent you another code. Please check your messages.'
        },
    },
    signInPassword: {
        title: 'Password',
        subtitle: 'Please enter your password.',
    },
    signUpPassword: {
        title: 'New Password',
        subtitle: 'Make sure your password contains at least 8 characters including one uppercase letter, one lowercase letter and 1 number.', 
    },
    forgotPassword: {
        title: 'Forgot password?',
        subtitle: 'Enter your {label} below and we will send a message to reset your password', 
    },
    popupSwitch: {
        title: 'Switch to password-based authentication',
        text1: 'Tired of entering verification codes? You can switch to using a password instead. We\'ll walk you through the process.',
        text2: 'Keep in mind this is a ONE-TIME SWITCH. Once you decide to start using a password, you can\'t go back to single-use verification codes. That said, we think this is a good move.',
        text3: 'Do you want to switch to password-based authentication?',
        text: 'Switch to password-based authentication?',
        textlink: 'Tap here'
    },
    verify: {
        title: 'Verify Phone',
        subtitle: 'We need to verify your phone number first. This is a one time action.',
        label: 'Phone',
        titleSuccess: 'All Done',
        subtitleSuccess: 'The phone number was successfully verified. You can now turn on the SMS Reminders',
    },
};
