import { api } from 'services';
import { transformTranslationsIn } from './lang.transformer';

class LangService {
    static translations(cid) {
        return api
            .get(`/translations/${cid}`)
            .then(({ data }) =>
                transformTranslationsIn(data));
    }
}

export default LangService;
