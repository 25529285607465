export default {
    quickScreening: {
        submit: 'Vâng, điều kiện của tôi không thay đổi',
        cancel: 'Tôi muốn hoàn tất kiểm tra đầy đủ',
        title: 'Kiểm tra nhanh',
        header: {
            title: 'Bạn có lựa chọn hoàn thành Kiểm tra Nhanh.',
            body: 'Kiểm tra lần cuối cung vào ngày {date} lúc {time}. Bạn có chứng nhận rằng điều kiện của bạn bây giờ giống như trước đây? Xem các chi tiết kiểm tra lần trước của bạn ở bên dưới.'
        },
        message: {
            text: 'Has your condition changed since your last report?', // @NEW
            button: 'My condition has not changed' // @NEW
        },
    },
    result: {
        options: ['Nơi làm việc', 'Khác'],
        message: 'Bạn đã hoàn thành kiểm tra thành công',
        title: 'Kết quả Kiểm tra',
        buttonBack: 'Quay lại câu hỏi trước',
        buttonSubmit: 'Quay lại màn hình chính',
        unknown: 'Kết quả: KHÔNG BIẾT',
        resultNurseText: 'Chúng ta hãy kết nối với y tá qua điện thoại. Chỉ một ít câu hỏi nữa.',
        form: {
            fullIntake: 'Bạn đã có thể tiếp xúc với COVID-19 ở đâu?',
            text: 'Chúng tôi sẽ có y tá liên lạc với bạn sớm. Số điện thoại để liên lạc bạn thuận tiện nhất là số nào?',
            zip: 'Mã zip của bạn là gì?',
            notes: 'Vui lòng miêu tả ngắn gọc về nơi mà bạn đã có thể tiếp xúc và/hoặc khi các triệu chứng của bạn đã bắt đầu',
            notesIntake: 'Vui lòng cung cấp chi tiết bổ sung về nơi bạn đã có thể tiếp xúc và/hoặc khi các triệu chứng đã bắt đầu',
            phoneNumber: 'Số điện thoại',
            callbackDelay: 'Gọi lại cho tôi',
            waitTime: 'Thời gian đợi hiện tại nếu bạn quyết định gọi bây giờ là khoảng {waitTime} phút.',
            button: 'Gọi tôi',
            select: {
                asap: 'Sớm nhất có thể',
                fifteen: '15 phút',
                thirty: '30 phút',
                fortyfive: '45 phút',
                hour: '1 tiếng',
            }
        },
    },
    warning: {
        checkbox: 'Không xuất hiện tin nhắn này nữa trong 10 ngày tới',
        button: 'Đã hiểu',
    },
    questions: {
        title: 'Kiểm tra',
        none: 'Không có',
        back: 'Trở lại',
        cancel: 'Hủy bỏ',
        continue: 'Kế tiếp'
    },
    success: {
        title: 'Cám ơn quý vị đã hoàn tất kiểm tra',
        back: 'Quay trở lại Trang nhà',
    },
    successNurse: {
        title: 'Xin cám ơn đã lên lịch cho cuộc gọi.',
        subtitle: 'Một trong những y tá của chúng tôi sẽ gọi bạn trong ít phút.',
        back: 'Quay trở lại Trang nhà'
    }
};
