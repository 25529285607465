import './editableContent.scss';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { 
    Button, InputHtml, InputHtmlToolbarExtended, Form, FormItem, Input, Spin 
} from 'apps/administration/components';
import { showError } from 'utils';
import { ConfirmDialog } from 'apps/administration/containers';
import { SettingsService } from 'modules/settings';
import { SanitizedHtml } from 'components';

const EditableField = ({ item, editable, footer, onSaveSuccess, onDeleteSuccess }) => {
    const [editMode, setEditMode] = useState(false);
    const [changed, setChanged] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [form] = Form.useForm();
    const [data, setData] = useState({});

    const onFinish = async (values) => {
        setUpdating(true);
        let service;
        if (!item.id) {
            service = SettingsService.create;
        } else {
            service = SettingsService.update;
        }
        service({ ...item, ...values })
            .then((res) => {
                if (onSaveSuccess) {
                    onSaveSuccess(res);
                } else {
                    setData({ ...item, ...res });
                    setEditMode(false);
                }
            })
            .catch(showError)
            .finally(() => setUpdating(false));
    };

    const onCancel = () => {
        setEditMode(false);
        form.reset();
    };

    const onDeleteClick = () => {
        ConfirmDialog({
            title: 'Delete Tab',
            text: `Are you sure you want to delete "${item.title}" tab?`,
            onOk: () => {
                if (item.id) {
                    setDeleting(true);
                    SettingsService.delete(item.id)
                        .then(() => onDeleteSuccess(item.index))
                        .finally(() => setDeleting(false));
                } else {
                    onSaveSuccess();
                }
            }
        });
    };

    useEffect(() => {
        setData(item);
    }, [item]);

    const EditButtons = (
        <>
            <Button
                type="default"
                onClick={onCancel}
            >
                Cancel
            </Button>
            <Button
                type="primary btn-action"
                onClick={form.submit}
                disabled={editMode && !changed}
                loading={updating}
            >
                Save
            </Button>
        </>
    );

    const ToggleButton = (
        <Button
            type="primary btn-action"
            onClick={() => setEditMode(true)}
        >
            Edit Content
        </Button>
    );

    return (
        <div className="page-content double">
            {!item && <Spin className="center" />}

            {item && editMode && (
                <Form
                    form={form}
                    onFinish={onFinish}
                    initialValues={data}
                    onValuesChange={() => setChanged(true)}
                >
                    {onSaveSuccess && (
                        <FormItem
                            label="Tab Title"
                            name="title"
                        >
                            <Input />
                        </FormItem>
                    )}
                    <FormItem name="value">
                        <InputHtml
                            toolbar={InputHtmlToolbarExtended}
                            theme="snow"
                            className="editable-content"
                        />
                    </FormItem>
                </Form>
            )}
            {item && !editMode && (
                <SanitizedHtml className="content-html">
                    {data?.value || ''}
                </SanitizedHtml>
            )}

            <div className="page-footer">
                {editable && onDeleteSuccess && (
                    <div className="left">
                        <Button
                            type="danger"
                            loading={deleting}
                            onClick={onDeleteClick}
                        >Delete Tab
                        </Button>
                    </div>
                )}
                {editable && editMode && EditButtons}
                {editable && !editMode && ToggleButton}
                {footer}
            </div>
        </div>
    );
};

EditableField.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
        value: PropTypes.string,
        $new: PropTypes.bool,
        index: PropTypes.number
    }),
    onSaveSuccess: PropTypes.func,
    onDeleteSuccess: PropTypes.func,
    editable: PropTypes.bool,
    footer: PropTypes.element
};

EditableField.defaultProps = {
    onSaveSuccess: undefined,
    onDeleteSuccess: undefined,
    editable: false,
    item: undefined,
    footer: null
};

export default EditableField;
