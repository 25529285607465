export default {
    titles: {
        er: 'Gọi {emergencyNumber}.',
        isolate: 'Please click the \'Back to Homescreen\' button below for your next steps.', // @NEW
        caution: 'Cẩn thận',
        pass: 'Đạt'
    },
    er: {
        HOUSEHOLD: 'Đây có thể là trường hợp khẩn cấp y tế',
        EVENT: 'Có thể bạn đang gặp trường hợp khẩn cấp về y tế.',
        EMPLOYER: 'Có thể bạn đang gặp trường hợp khẩn cấp về y tế.',
    },
    cleared: {
        title: 'Đạt',
        HOUSEHOLD: 'Theo CDC, câu trả lời của bạn cho thấy không ai trong gia đình bạn gặp phải bất kỳ triệu chứng nào giống COVID-19. Nếu ai đó bắt đầu cảm thấy bệnh, vui lòng báo cho nhà trường ngay lập tức.',
        EMPLOYER: 'Theo CDC, câu trả lời của bạn cho thấy không ai trong gia đình bạn gặp phải bất kỳ triệu chứng nào giống COVID-19. Bạn có thể đi làm hôm nay. Nếu bạn bắt đầu cảm thấy bệnh trong suốt giờ làm việc, vui lòng báo cho quản lý của bạn ngay lập tức và rời khỏi chỗ làm.',
        EVENT: 'Theo CDC, câu trả lời của bạn cho thấy bạn không có bất kỳ triệu chứng nào giống COVID-19. Nếu bạn bắt đầu cảm thấy bệnh, hãy rời đi ngay lập tức và báo cáo với bộ phận liên lạc tổ chức.'
    },
    nurse: 'Chúng tôi nghĩ bạn nên nói chuyện với một chuyên gia chăm sóc sức khỏe. ',

isolateSymptomsHCP: `Theo CDC, câu trả lời của bạn cho thấy bạn nên tự cách ly : 
            * Ít nhất 10 ngày kể từ ngày các triệu chứng của bạn bắt đầu 
            * VÀ các triệu chứng của bạn đã được cải thiện 
            * VÀ bạn không bị sốt ít nhất trong vòng 24 giờ (và không sử dụng bất kỳ loại thuốc hạ sốt nào)    

            1. Duy trì khoảng cách (lớn hơn 6 feet) với những người khác. Đeo khẩu trang che mặt ở những nơi công cộng. Không tụ tập các nhóm, tránh đám đông, tránh tụ tập.

            2. Kiểm tra nhiệt độ hai lần mỗi ngày.

            3. Tiếp tục theo dõi các thay đổi của các triệu chứng: sốt ({temperature} hoặc cao hơn) hoặc ớn lạnh, mới ho hoặc ho nặng hơn, thở gấp hoặc khó thở, đau nhức cơ hoặc cơ thể, mệt mỏi liên quan đến bệnh tật, đau đầu, mới bị mất vị giác hoặc khứu giác, đau họng, nghẹt mũi hoặc chảy nước mũi, buồn ói hoặc ói mửa, tiêu chảy. Nếu các triệu chứng của bạn xấu đi hoặc chưa được chữa trị, hãy liên lạc với nhà cung cấp dịch vụ y tế hoặc sở y tế địa phương để được hướng dẫn thêm. 

            4. GỌI {emergencyNumber} nếu bất kỳ người nào bị hoặc phát triển bất kỳ triệu chứng nào sau đây:
            * Khó thở nghiêm trọng 
            * Đau ngực
            * Nặng hoặc tức ngực 
            * Khó khăn duy trì sự tỉnh táo
            * Môi hoặc mặt tím tái 

            5. Xét nghiệm có thể được đề nghị. Truy cập vào thử nghiệm khác nhau. Bạn có thể liên lạc với nhà cung cấp dịch vụ y tế hoặc sở y tế địa phương để xem liệu dịch vụ này có sẵn ở khu vực của bạn hay không.

            6. Bạn không nên tự ý ngừng cách ly cho đến khi nhận được giấy phép từ nhà cung cấp dịch vụ y tế                                   

            7. Liên lạc với phòng y tế lao hoặc chương trình kiểm soát lây nhiễm để được hướng dẫn thêm về việc trở lại làm việc 
`,
isolateExposureHCP: `Theo CDC, câu trả lời của bạn cho thấy bạn nên tự cách ly trong 14 ngày từ lần tiếp xúc cuối cùng.

    1. Duy trì khoảng cách (lớn hơn 6 feet) với những người khác. Đeo khẩu trang che mặt ở những nơi công cộng. Không tụ tập các nhóm, tránh đám đông, tránh tụ tập

    2. Tiếp tục theo dõi các thay đổi của các triệu chứng: sốt ({temperature} hoặc cao hơn) hoặc ớn lạnh, mới ho hoặc ho nặng hơn, thở gấp hoặc khó thở, đau nhức cơ hoặc cơ thể, mệt mỏi liên quan đến bệnh tật, đau đầu, mới bị mất vị giác hoặc khứu giác, đau họng, nghẹt mũi hoặc chảy nước mũi, buồn ói hoặc ói mửa, tiêu chảy. Nếu các triệu chứng của bạn xấu đi hoặc chưa được chữa trị, hãy liên lạc với nhà cung cấp dịch vụ y tế hoặc sở y tế địa phương để được hướng dẫn thêm. 

    3. GỌI {emergencyNumber} nếu bất kỳ người nào bị hoặc phát triển bất kỳ triệu chứng nào sau đây:
    * Khó thở nghiêm trọng 
    * Đau ngực 
    * Nặng hoặc tức ngực
    * Khó khăn duy trì sự tỉnh táo
    * Môi hoặc mặt tím tái,
`,
isolateSymptomsDEFAULT: `Theo CDC, câu trả lời của bạn cho thấy bạn nên tự cách ly: 
    * Ít nhất 10 ngày kể từ ngày các triệu chứng của bạn bắt đầu 
    * VÀ các triệu chứng của ban đã được cải thiện
    * VÀ bạn không bị sốt ít nhất trong vòng 24 giờ (và không sử dụng bất kỳ loại thuốc hạ sốt nào)    

    1. Duy trì khoảng cách (lớn hơn 6 feet) với những người khác. Đeo khẩu trang che mặt ở những nơi công cộng. Không tụ tập các nhóm, tránh đám đông, tránh tụ tập 
    2. Kiểm tra nhiệt độ hai lần mỗi ngày

    3. Tiếp tục theo dõi các thay đổi của các triệu chứng: sốt ({temperature} hoặc cao hơn) hoặc ớn lạnh, mới ho hoặc ho nặng hơn, thở gấp hoặc khó thở, đau nhức cơ hoặc cơ thể, mệt mỏi liên quan đến bệnh tật, đau đầu, mới bị mất vị giác hoặc khứu giác, đau họng, nghẹt mũi hoặc chảy nước mũi, buồn ói hoặc ói mửa, tiêu chảy. Nếu các triệu chứng của bạn xấu đi hoặc chưa được chữa trị, hãy liên lạc với nhà cung cấp dịch vụ y tế hoặc sở y tế địa phương để được hướng dẫn thêm. 

    4. GỌI {emergencyNumber} nếu bất kỳ người nào bị hoặc phát triển bất kỳ triệu chứng nào sau đây:
    * Khó thở nghiêm trọng 
    * Đau ngực
    * Nặng hoặc tức ngực
    * Khó khăn duy trì sự tỉnh táo
    * Môi hoặc mặt tím tái

    5. Xét nghiệm có thể được đề nghị. Truy cập vào thử nghiệm khác nhau. Bạn có thể liên lạc với nhà cung cấp dịch vụ y tế hoặc sở y tế địa phương để xem liệu dịch vụ này có sẵn ở khu vực của bạn hay không. 

    6. Bạn không nên tự ý ngừng cách ly cho đến khi nhận được giấy phép từ nhà cung cấp dịch vụ y tế                             
`,
isolateExposureDEFAULT: `Theo CDC, câu trả lời của bạn cho thấy bạn nên tự cách ly 14 ngày kể từ lần tiếp xúc cuối cùng của bạn. 

            1. Duy trì khoảng cách (lớn hơn 6 feet) với những người khác. Đeo khẩu trang che mặt ở những nơi công cộng. Không tụ tập các nhóm, tránh đám đông, tránh tụ tập. 

            2. Kiểm tra nhiệt độ hai lần mỗi ngày.

            3. Tiếp tục theo dõi các thay đổi của các triệu chứng: sốt ({temperature} hoặc cao hơn) hoặc ớn lạnh, mới ho hoặc ho nặng hơn, thở gấp hoặc khó thở, đau nhức cơ hoặc cơ thể, mệt mỏi liên quan đến bệnh tật, đau đầu, mới bị mất vị giác hoặc khứu giác, đau họng, nghẹt mũi hoặc chảy nước mũi, buồn ói hoặc ói mửa, tiêu chảy. Nếu các triệu chứng của bạn xấu đi hoặc chưa được chữa trị, hãy liên lạc với nhà cung cấp dịch vụ y tế hoặc sở y tế địa phương để được hướng dẫn thêm.

            4. GỌI {emergencyNumber} nếu bất kỳ người nào bị hoặc phát triển bất kỳ triệu chứng nào sau đây:
            * Khó thở nghiêm trọng 
            * Đau ngực 
            * Nặng hoặc tức ngực
            * Khó khăn duy trì sự tỉnh táo
            * Môi hoặc mặt tím tái 

            5. Xét nghiệm có thể được đề nghị. Truy cập vào thử nghiệm khác nhau. Bạn có thể liên lạc với nhà cung cấp dịch vụ y tế hoặc sở y tế địa phương để xem liệu dịch vụ này có sẵn ở khu vực của bạn hay không. 

            6. Bạn không nên tự ý ngừng cách ly cho đến khi nhận được giấy phép từ nhà cung cấp dịch vụ y tế.
`,
// @NEW Section

isolateExposureCOMMUNITY_CURRENT: `
1. Per CDC guidelines, wear a mask around others for 10 days. 
2. Get a COVID test on day 5 if possible. 
3. If you develop OR currently have symptoms, get a COVID test and stay home. 
4. These recommendations do not take the place of state, local, or tribal laws, rules, and regulations.
5. Follow company-specific directions for testing, quarantine and for reporting to work. 
6. Contact your Primary Care provider for further questions.
7. Go to CDC.gov/coronavirus or your local health department website for additional answers to frequently asked questions and further information.
`,
isolateSymptomsCOMMUNITY_CURRENT: `
1. Per CDC guidelines, you should self-isolate and get a COVID test. Stay home for 5 days if your symptoms are COVID-related. 
2. If your symptoms are resolving after 5 days, you can leave your home. 
3. Continue to wear a mask around others for an additional 5 days. 
4. These recommendations do not take the place of state, local, or tribal laws, rules, and regulations.
5. Follow company-specific directions for testing, quarantine and for reporting to work.
6. Contact your Primary Care provider for further questions.
7. Go to CDC.gov/coronavirus or your local health department website for additional answers to frequently asked questions and further information.
`,
isolateExposureHOUSEHOLD_CURRENT: `
1. Per CDC guidelines, wear a mask around others for 10 days. 
2. Get a COVID test on day 5 if possible. 
3. If you develop OR currently have symptoms, get a COVID test and stay home. 
4. These recommendations do not take the place of state, local, or tribal laws, rules, and regulations.
5. Follow company and school-specific directions for testing, quarantine and for reporting to work or school.
6. Contact а Primary Care provider for further questions.
7. Go to CDC.gov/coronavirus or your local health department website for additional answers to frequently asked questions and further information.
`,
isolateSymptomsHOUSEHOLD_CURRENT: `
1. Per CDC guidelines, you should self-isolate and get a COVID test. Stay home for 5 days if your symptoms are COVID-related. 
2. If symptoms are resolving after 5 days, self-isolation can be ended.
3. Continue to wear a mask around others for an additional 5 days. 
4. These recommendations do not take the place of state, local, or tribal laws, rules, and regulations.
5. Follow company and school-specific directions for testing, quarantine and for reporting to work or school.
6. Contact a Primary Care provider for further questions.
7. Go to CDC.gov/coronavirus or local health department website for additional answers to frequently asked questions and further information.
`,
isolateExposureCOMMUNITY_POSITIVE: `
1. Per CDC guidelines, you do not need to get a test unless you develop new symptoms. 
2. If you develop or currently have any new symptoms, report to your manager, self-isolate immediately and take a COVID test. 
3. These recommendations do not take the place of state, local, or tribal laws, rules, and regulations.
4. Follow company-specific directions for testing, quarantine and for reporting to work. 
5. Contact your Primary Care provider for further questions. 
6. Go to CDC.gov/coronavirus or your local health department website for additional answers to frequently asked questions and further information.
`,
isolateSymptomsCOMMUNITY_POSITIVE: `
1. Per CDC guidelines, you should self-isolate and get a COVID test. Stay home for 5 days if your symptoms are COVID-related. 
2. If your symptoms are resolving after 5 days, you can leave your home. 
3. Continue to wear a mask around others for an additional 5 days. 
4. These recommendations do not take the place of state, local, or tribal laws, rules, and regulations.
5. Follow company-specific directions for testing, quarantine and for reporting to work.
6. Contact your Primary Care provider for further questions.
7. Go to CDC.gov/coronavirus or your local health department website for additional answers to frequently asked questions and further information.
`,
isolateExposureHOUSEHOLD_POSITIVE: `
1. Per CDC guidelines, a COVID test is not needed unless new symptoms develop.
2. If currently symptomatic or new symptoms develop, report to administration or manager, self-isolate immediately and take a COVID test. 
3. These recommendations do not take the place of state, local, or tribal laws, rules, and regulations.
4. Follow company and school-specific directions for testing, quarantine and for reporting to work or school.
5. Contact a Primary Care provider for further questions. 
6. Go to CDC.gov/coronavirus or your local health department website for additional answers to frequently asked questions and further information.
`,
isolateSymptomsHOUSEHOLD_POSITIVE: `
1. Per CDC guidelines, you should self-isolate and get a COVID test. Stay home for 5 days if your symptoms are COVID-related. 
2. If symptoms are resolving after 5 days, self-isolation can be ended.
3. Continue to wear a mask around others for an additional 5 days. 
4. These recommendations do not take the place of state, local, or tribal laws, rules, and regulations.
5. Follow company and school-specific directions for testing, quarantine and for reporting to work or school.
6. Contact a Primary Care provider for further questions.
7. Go to CDC.gov/coronavirus or local health department website for additional answers to frequently asked questions and further information.
`,
// @NEW
isolateExposureCOMMUNITY_NOT_CURRENT: `
1. Follow company-specific directions for testing, quarantine and for reporting to work. 
2. If in CA, per California Department of Health, as long as you are not having symptoms, you do not need to isolate.
3. If your organization is following CDC guidelines, stay home for 5 days. Then continue to wear a mask around others for an additional 5 days. If unable to quarantine, wear a mask for 10 days. 
4. Get a COVID test on day 5 if possible. 
5. If you develop OR currently have symptoms, get a COVID test and stay home. 
6. These recommendations do not take the place of state, local or triable laws, rules, and regulations.
7. Contact your Primary Care provider for further questions.
8. Go to CDC.gov/coronavirus or your local health department website for additional answers to frequently asked questions and further information.
`,
isolateSymptomsCOMMUNITY_NOT_CURRENT: `
1. Per CDC guidelines, you should self-isolate and get a COVID test. Stay home for 5 days if your symptoms are COVID-related.
2. If your symptoms are resolving after 5 days, you can leave your home. 
3. Continue to wear a mask around others for an additional 5 days. 
4. These recommendations do not take the place of state, local, or tribal laws, rules, and regulations.
5. Follow company-specific directions for testing, quarantine and for reporting to work.
6. Contact your Primary Care provider for further questions.
7. Go to CDC.gov/coronavirus or your local health department website for additional answers to frequently asked questions and further information.
`,
// @NEW
isolateExposureHOUSEHOLD_NOT_CURRENT: `
1. Follow company and school-specific directions for testing, quarantine and for reporting to work or school. 
2. If in CA, per California Department of Health, as long as you are not having symptoms, you do not need to isolate.
3. If your organization is following CDC guidelines, stay home for 5 days. Then continue to wear a mask around others for an additional 5 days. If unable to quarantine, wear a mask for 10 days. 
4. Get a COVID test on day 5 if possible. 
5. If you develop OR currently have symptoms, get a COVID test and stay home. 
6. These recommendations do not take the place of state, local or triable laws, rules, and regulations.
7. Contact your Primary Care provider for further questions.
8. Go to CDC.gov/coronavirus or your local health department website for additional answers to frequently asked questions and further information.
`,
isolateSymptomsHOUSEHOLD_NOT_CURRENT: `
1. Per CDC guidelines, you should self-isolate and get a COVID test. Stay home for 5 days if your symptoms are COVID-related. 
2. If symptoms are resolving after 5 days, self-isolation can be ended.
3. Continue to wear a mask around others for an additional 5 days. 
4. These recommendations do not take the place of state, local, or tribal laws, rules, and regulations.
5. Follow company and school-specific directions for testing, quarantine and for reporting to work or school.
6. Contact a Primary Care provider for further questions.
7. Go to CDC.gov/coronavirus or local health department website for additional answers to frequently asked questions and further information.
`,

isolateBanner: ''
};
