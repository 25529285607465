import { MODULE_VACCINATION_STATUS } from 'modules/module';
import { APIT } from 'utils';
import StatusTransformer from './status';

const transformPatientIn = (data) => {
    const {
        firstName, lastName,
        email, supervisorEmail,
        quickScreening
    } = data;
    
    const avatar = (firstName && firstName[0].toUpperCase())
        + (lastName && lastName[0].toUpperCase());

    const language = (data.language || 'en').toLowerCase();
    const $moduleStatus = StatusTransformer.in(data);

    return {
        ...data,
        avatar,
        language,
       ...(quickScreening && ({
            quickScreening: {
                ...quickScreening,
                questionsAndAnswers: JSON.parse(quickScreening?.questionsAndAnswers),
                questionsAndAnswersCodes: JSON.parse(quickScreening?.questionsAndAnswersCodes)
            }
        })),
        fullName: `${data.firstName } ${ data.lastName}`,
        supervisorEmail: supervisorEmail || email,
        isActive: APIT.bool.in(data.isActive),
        sendScreenReminders: APIT.bool.in(data.sendScreenReminders), 
        isSupervisor: APIT.bool.in(data.isSupervisor), 
        isSymptomPopupHidingDisabled: APIT.bool.in(data.isSymptomPopupHidingDisabled),
        isMedicalConsentAccepted: APIT.bool.in(data.isMedicalConsentAccepted),
        isMedicalConsentEnabled: APIT.bool.in(data.isMedicalConsentEnabled), 
        
        isolationStartDate: APIT.date.in.toLocal(data.isolationStartDate),
        isolationEndDate: APIT.date.in.toLocal(data.isolationEndDate),
        lastTestDate: APIT.date.in.toLocal(data.lastTestDate),
        lastScreeningDate: APIT.date.in.toLocal(data.lastScreeningDate),
        dateOfBirth: APIT.date.in.toLocal(data.dateOfBirth),
        bannerHiddenDate: APIT.date.in.toUTC(data.bannerHiddenDate),
        remindersBannerDate: APIT.date.in.toLocal(data.remindersBannerDate),
        createdAt: APIT.date.in.toLocal(data.createdAt),
        updatedAt: APIT.date.in.toLocal(data.updatedAt),
        // Readonly
        $moduleStatus,
        $isVaccinated: $moduleStatus?.vaccination === MODULE_VACCINATION_STATUS.VACC.key,
    };
};

export default transformPatientIn;
