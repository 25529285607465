export default {
    title: 'Điều chỉnh Dữ liệu',
    registration: 'Ghi danh',
    subtitle: 'Có vẻ bạn là người mới sử dụng.',
    notes1: 'Chúng tôi cần biết một ít thông tin về bạn, sau đó chúng tôi sẽ cho bạn kiểm tra nhanh nhất có thể.',
    notes2: 'Nếu bạn đang có những triệu chứng nghiêm trọng hoặc đe dọa mạng sống, bạn nên ngay lập tức TÌM SỰ HỖ TRỢ Y TẾ BẰNG CÁCH GỌI {emergencyNumber}',
    placeholders: {
        employeeNumber: 'Thêm danh số của bạn',
        firstName: 'Thêm tên của bạn',
        lastName: 'Thêm họ của bạn',
        jobCategory: 'Vui lòng chọn',
        address: 'Add your address', // @NEW
        address2: 'Add your address', // @NEW
        gender: 'Please select', // @NEW
    },
    labels: {
        employeeNumber: 'ID number', // @NEW
        employeeNumberTooltip: 'Đây là Danh số Nhân viên, Học sinh, hoặc nhận diện số khác được chỉ định bởi tổ chức yêu cầu bạn kiểm tra.',
        firstName: 'Tên',
        lastName: 'Họ',
        jobCategory: 'Tôi là ...',
        jobCategoryTooltip: `Công nhân thiết yếu bao gồm:
            
            • Nhân viên thực thi pháp luận Liên bang, Tiểu bang và địa phương

            • Nhân viên đường dây tổng đài {emergencyNumber} 

            • Nhân viên trung tâm Fusion 

            • Người xử lý đồ vật nguy hiểm (cơ quan chính phủ hoặc tư nhân)

            • Nhân viên lao công hoặc bảo vệ

            • Các công nhân (bao gồm nhà thầu cung ứng) trong ngành thực phẩm và nông nghiệp
            
            • Các cơ sở sản xuất, công nghệ thông tin, vận chuyển, năng lượng, và chính phủ thiết yếu`,
        button: 'Hoàn tất Ghi danh',
        buttonSave: 'Lưu các thay đổi',
        optin: {
            screen: 'Yes, please send me reminder messages and notifications from admins via SMS or email! I can always opt out later. Message and data rates may apply.', // @NEW
        },
        terms: 'Bởi việc tiếp tục, tôi đồng ý với {termsLink} và {policyLink} ',
        phone: 'Số điện thoại',
        dateOfBirth: 'Date of Birth', // @NEW
        address: 'Address 1', // @NEW
        address2: 'Address 2', // @NEW
        city: 'City', // @NEW
        state: 'State', // @NEW
        zip: 'Zip Code', // @NEW
        medicalConsentBefore: 'By continuing, you authorize the {locationTitle}\'s', // @NEW
        medicalConsentMiddle: 'Use and Disclosure of Confidential Medical Information', // @NEW
        medicalConsentAfter: 'by Authorized Personnel.', // @NEW
        gender: 'Gender', // @NEW
    },
    // @NEW section
    mergeUsers: {
        buttonSave: 'Save Changes',
        text: `Our system indicates that this phone number {phone} is already in use with two different user profiles.
        Please select from below the user profile
        that you will be using from now onwards.`,
        current: 'Current',
        note: 'All your user data will be saved on the selected user profile.'
    },
};
