import React, { useEffect, useRef, useCallback } from 'react';
import { Button, notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { NotificationActions, LOCAL_KEY, NOTIFICATION_TYPE } from 'modules/notification';

const GET_EVERY_MS = 5 * 1000;

const useAlerts = () => {
    const interval = useRef({ threads: 0 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const dispatch = useCallback(useDispatch(), []);
    const alerts = useSelector(state => state.alerts);

    const ExcludedTypes = [
        NOTIFICATION_TYPE.exportReady,
        NOTIFICATION_TYPE.importReady,
    ];

    const filteredAlerts = alerts
        .filter(item => ExcludedTypes.includes(item.type));

    const getAlerts = useCallback(() => {
        dispatch(NotificationActions.getAlertsUnread());
    }, [dispatch]);

    const start = (type) => {
        if (Object.isString(type?.message)) {
            notification.info({
                message: type.message,
                duration: 3,
                key: 'wait'
            });
        }

        interval.current.threads += 1;

        if (interval.current.id) {
            return;
        }

        interval.current.id = setInterval(getAlerts, GET_EVERY_MS);
    };

    const clear = useCallback((cnt) => {
        if (interval.current.id) {
            interval.current.threads -= cnt;
            if (interval.current.threads < 1) {
                clearInterval(interval.current.id);
                interval.current.id = undefined;
            }
        }
    }, []);

    const onDelete = useCallback((id) => {
        dispatch(NotificationActions.delete(id));
    }, [dispatch]);

    const onDownload = useCallback((item) => {
        dispatch(NotificationActions.download(item.id, item.downloadLink));
    }, [dispatch]);

    const show = useCallback((item) => {
        notification.info({
            className: 'notification',
            message: (
                <>
                    <h2>{item.title}</h2>
                    <p
                        dangerouslySetInnerHTML={{ __html: item.text }}
                    />
                    { item.downloadLink && (
                        <Button
                            type="primary"
                            block
                            size="small"
                            onClick={() => onDownload(item)}
                        >
                            Download
                        </Button>
                    )}
                </>
            ),
            duration: 15,
            onClose: () => onDelete(item.id)
        });
        if (item.downloadLink) {
            onDownload(item);
        }
        dispatch(NotificationActions.markShow(item.id));
    }, [dispatch, onDelete, onDownload]);

    useEffect(() => {
        const shownIds = JSON.parse(localStorage.getItem(LOCAL_KEY)) || [];
        const unread = filteredAlerts
            .filter(item => !item.show && !item.readAt && !shownIds.find(i => item.id === i));

        unread.forEach(show);

        if (unread.length) {
            clear(unread.length);
        }
    }, [clear, dispatch, filteredAlerts, show]);

    return {
        start,
    };
};

export { useAlerts };
