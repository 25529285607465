import './Lang.scss';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PatientActionsUser } from 'modules/patient';
import { langs } from 'locales';

const withLang = WrappedComponent => (props) => {
    const { id, language } = useSelector(state => state.patient);
    const dispatch = useDispatch();
    
    const changeLang = (value) => {
        dispatch(PatientActionsUser.setLang(!id, value));
    };

    const current = langs.find(item => item.key === (language || 'en'));

    const LangOptions = langs.map(item => ({ 
        value: item.key, 
        label: item.title, 
        icon: item.icon 
    }));

    return (
        <WrappedComponent 
            currentLang={current}
            langOptions={LangOptions}
            setLang={changeLang}
            {...props}
        />
    );
};

export { withLang };
