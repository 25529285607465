export default 
{
    title: 'Hỗ trợ',
    successMessage: 'Đã hiểu. Chúng tôi sẽ liên lạc với bạn sớm nhất có thể.',
    title1: 'Cần giúp đỡ? Nhiều vấn đề có thể được giải quyết bằng việc làm một trong những việc sau:',
    titleRequest: 'Yêu cầu hỗ trợ',
    button: 'Send Request', // @NEW
    email: 'Email',
    textArea: 'Describe the issue you have', // @NEW
    reportSuccess: 'Support Requested Successfully!', // @NEW
    success: 'Success', // @NEW
    thankYouNote: 'Thank you, your request has been received, we will contact you shortly. Please don\'t forget to check your Spam folder, just in case!', // @NEW
    panel1: {
        header: 'Sẽ ra sao nếu tôi đang có những triệu chứng bởi vì dị ứng, COPD, v.v...?',
        text: `Như được nói trước khi kiểm tra, những người sử dụng có đang có những triệu chứng bởi vì dị ứng,
        COPD, hoặc các bệnh kinh niên không nên đánh dấu (những) triệu chứng này trong câu hỏi.`
    },
    panel2: {
        header: 'Sẽ ra sao nếu nhiều người sử dụng đang kiểm tra trên cùng một thiết bị?',
        text: `Nếu bạn và những người khác đang hoàn tất kiểm tra trên cùng một thiết bị, vui lòng đăng xuất
        bằng cách nhấn vào ký hiệu ba đường kẻ ở trên góc phải của màn hình để vào phần cài đặt và sau đó nhấn “Sign Out.”`
    },
    panel3: {
        header: 'Tại sao tôi không thể kiểm tra nhiều hơn một lần mỗi bốn tiếng?',
        text: 'Trong nỗ lực tránh trùng lặp các báo cáo, những người sử dụng chỉ có thể kiểm tra mỗi bốn tiếng.'
    },
    panel4: {
        header: '“Tài khoản của bạn bị tạm ngưng" nghĩa là gì?',
        text: `Nếu  bạn thấy màn hình hiện ra “Your Account is on hold,” điều  này có nghĩa là bạn không
        đạt yêu cầu kiểm tra. Điều phối viên chương trình sẽ cần thực hiện gỡ bỏ tình trạng này của bạn.`
    },
    panel5: {
        header: 'Sẽ ra sao nếu tôi có vấn đề kỹ thuật?',
        text: `Nếu bạn có vấn đề kỹ thuật, vui lòng thử xóa bộ nhớ tạm của trình duyệt hoặc email {email}.
        Bạn có thể xóa bộ nhớ tạm bằng cách vào lịch sử trình duyệt, chọn clear browsing data,
        and clearing the cache. Đây là cách xóa bộ nhớ tạm 
        <strong>
            <a 
                href="https://9to5mac.com/2018/05/24/iphone-ipad-how-to-clear-cache/" 
                target="_blank"
                rel="noopener noreferrer"
            >your iPhone
            </a>
        </strong>
        and
        <strong>
            <a 
                href="https://support.google.com/accounts/answer/32050?co=GENIE.Platform%3DAndroid&amp;hl=en" 
                target="_blank"
                rel="noopener noreferrer"
            >
                your android
            </a>
        </strong>
        .`
    },
    panel6: {
        header: 'Didn\'t find the answer?', // @NEW
        text: 'Click on the link below to describe the issue you\'re seeing.' // @NEW
    }
};
