import { FieldTypes } from 'utils';
import locales from 'locales';

const stringsEn = locales.getContent().en.models.attendance.attendanceQuestions;

export default (args) => {
    const { models: { attendance: { attendanceQuestions: strings } } } = locales;

    const {
        params
    } = args;

    return {
        Q_WORK: {
            key: 'Q_WORK',
            static: true,
            type: FieldTypes.RADIO,
            label: strings.Q_WORK.label,
            labelToPost: strings.Q_WORK.label,
            options: [
                {
                    value: 'Yes',
                    text: strings.Q_WORK.options.Y,
                    outcome: true,
                },
                {
                    value: 'No',
                    text: strings.Q_WORK.options.N,
                    outcome: false,
                }
            ],
        },
        Q_IS_ER: {
            key: 'Q_IS_ER',
            static: true,
            type: FieldTypes.RADIO,
            label: `
                <div>
                    <div class="image-circle mb-s screening-WARN"><div></div></div>
                    <h1 class="ta-center">${strings.Q_IS_ER.title}</h1>
                    <p class="error">${locales.formatString(strings.Q_IS_ER.label, params)}</p>
                    
                    <ul>
                        <li>${strings.Q_IS_ER.label1}</li>
                        <li>${strings.Q_IS_ER.label2}</li>
                        <li>${strings.Q_IS_ER.label3}</li>
                        <li>${strings.Q_IS_ER.label4}</li>
                        <li>${strings.Q_IS_ER.label5}</li>
                        <li>${strings.Q_IS_ER.label6}</li>
                    </ul>
                </div>
            `,
            labelToPost: `
                ${locales.formatString(stringsEn.Q_IS_ER.label, params)}
                ${stringsEn.Q_IS_ER.label1},
                ${stringsEn.Q_IS_ER.label2},
                ${stringsEn.Q_IS_ER.label3},
                ${stringsEn.Q_IS_ER.label4},
                ${stringsEn.Q_IS_ER.label5},
                ${stringsEn.Q_IS_ER.label6},
            `,
            options: [
                {
                    value: 'Yes',
                    text: strings.Q_IS_ER.options.Y,
                    outcome: true,
                },
                {
                    value: 'No',
                    text: strings.Q_IS_ER.options.N
                },
            ],
        },
        Q_NO_WORK: {
            key: 'Q_NO_WORK',
            static: true,
            type: FieldTypes.ADVANCED_RADIO,
            label: strings.Q_NO_WORK.label,
            labelToPost: stringsEn.Q_NO_WORK.label,
            options: [
                {
                    value: stringsEn.Q_NO_WORK.options.SICK,
                    text: strings.Q_NO_WORK.options.SICK,
                    outcome: true,
                },
                {
                    value: stringsEn.Q_NO_WORK.options.INJURED,
                    text: strings.Q_NO_WORK.options.INJURED,
                    outcome: true,
                },
                {
                    value: stringsEn.Q_NO_WORK.options.NOT_SCHEDULED,
                    text: strings.Q_NO_WORK.options.NOT_SCHEDULED,
                    outcome: true,
                },
                {
                    value: stringsEn.Q_NO_WORK.options.PTO,
                    text: strings.Q_NO_WORK.options.PTO,
                    outcome: true,
                },
                {
                    value: FieldTypes.TEXTAREA,
                    text: strings.Q_NO_WORK.options.OTHER,
                    outcome: true,
                    type: FieldTypes.TEXTAREA,
                },
            ],
            props: {
                className: 'radio-text-area'
            }
        },
    };
};
