export default {
    quickScreening: {
        submit: 'si, mi condicion no ha cambiado',
        cancel: 'Me gustaria completar una evaluacion completa',
        title: 'Evaluacion Rapida',
        header: {
            title: 'Usted tiene la opcion de completar una evaluacion rapida.',
            body: 'Su ultima evaluacion fue el {date} a las {time}. ¿Es su condicion la misma? Vea los detalles de su ultima revision abajo.'
        },
        message: {
            text: '¿Ha cambiado su condicion desde su ultima revision?',
            button: 'Mi condicion no ha cambiado.'
        },
    },
    result: {
        options: ['Lugar de trabajo', 'Otros'],
        message: 'Ha completado con éxito su evaluación',
        title: 'Resultados de la Evaluación',
        buttonBack: 'Regresar a la ultima pregunta',
        buttonSubmit: 'Regresar a la pantalla de Inicio',
        unknown: 'Resultado: DESCONOCIDO',
        resultNurseText: 'Vamos a comunicarlo por teléfono con una enfermera. Solo unas cuantas preguntas más.',
        form: {
            fullIntake: '¿Dónde estuvo más probablemente con exposición al COVID-19?',
            text: 'Haremos que una enfermera se comunique con usted pronto. ¿Es este el mejor número para contactarlo?',
            zip: '¿Cual es su código postal?',
            notes: 'Por favor brinde una breve descripción de dónde pudo haberse expuesto y/o cuándo sus síntomas empezaron',
            notesIntake: 'Por favor brinde detalles adicionales de dónde puedo haberse expuesto y/o cuándo sus síntomas empezaron',
            phoneNumber: 'Número de  teléfono',
            callbackDelay: 'Llámeme dentro de',
            waitTime: 'El tiempo de espera actualmente si decide llamar ahora es cerca de {waitTime} minutos.',
            button: 'Llámeme',
            select: {
                asap: 'Lo antes posible',
                fifteen: '15 minutos',
                thirty: '30 minutos',
                fortyfive: '45 minutos',
                hour: '1 hora',
            }
        },
    },
    warning: {
        checkbox: 'No mostrar este mensaje de nuevo por los próximos 10 dias',
        button: 'Entendido',
    },
    questions: {
        title: 'Evaluación',
        none: 'Ninguno',
        back: 'Regresar',
        cancel: 'Cancelar',
        continue: 'Siguiente'
    },
    success: {
        title: 'Gracias por completar la Evaluación',
        back: 'Regresar a la pantalla de Inicio',
    },
    successNurse: {
        title: 'Gracias por programar una llamada.',
        subtitle: 'Una de nuestras enfermeras le llamará dentro de poco.',
        back: 'Regresar a la pantalla de Inicio'
    }
};
