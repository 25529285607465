export default {
    subtitleUser: 'Welcome, {userName}!', 
    alerts: 'Alerts', 
    newAlerts: 'New Alerts',
    noNewAlerts: 'No New Alerts',
    unreadAlerts: 'unread Alerts', 
    latestAlert: 'Latest Alert', 
    viewAll: 'View All', 
    tapScreen: 'Tap for screen',
    tapInfo: 'Tap for info',
    reports: 'Reports',
    notAvailable: 'Not Available',
    incident: 'Incident',
    id: 'ID', 
    status: 'Status',
    screenStatus: 'Screen Status', 
    idNumber: 'ID Number',
    messageWait: 'Looks like you recently completed a screen! Check back later to screen again.',
    details: 'Details', 
    title: 'Pandemic',
    buttonScreen: 'Screen', 
    buttonAction: 'Actions', 
    buttonReport: 'Report', 
    buttonTesting: 'Testing Report', 
    buttonVaccination: 'Report Vaccination',
    buttonVaccinationBooster: 'Report Vaccination Booster',
    buttonSecondVaccination: 'Vaccination Second Shot',
    proctoredTest: 'Proctored Test',
    buttonGuidelines: 'Tap here for more details',
    buttonShowDetails: 'Show Details', 
    buttonHideDetails: 'Hide Details', 
    buttonWorkplaceInjury: 'Workplace Injury', 
    buttonMore: 'More', 
    buttonNewReport: 'New Report', 
    total: 'Total', 
    ok: 'Ok', 
    benefits: 'Benefits',
};
