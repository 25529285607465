/* eslint-disable  */
const __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
    function adopt(value) {
        return value instanceof P ? value : new P(((resolve) => {
            resolve(value);
        }));
    }

    return new (P || (P = Promise))(((resolve, reject) => {
        function fulfilled(value) {
            try {
                step(generator.next(value));
            } catch (e) {
                reject(e);
            }
        }

        function rejected(value) {
            try {
                step(generator.throw(value));
            } catch (e) {
                reject(e);
            }
        }

        function step(result) {
            result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
        }

        step((generator = generator.apply(thisArg, _arguments || [])).next());
    }));
};

const __generator = this && this.__generator || function (thisArg, body) {
    let _ = {
        label: 0,
        sent: function sent() {
            if (t[0] & 1) throw t[1];
            return t[1];
        },
        trys: [],
        ops: []
    };
    let f;
    let y;
    let t;
    let g;
    return g = {
        next: verb(0),
        throw: verb(1),
        return: verb(2)
    }, typeof Symbol === 'function' && (g[Symbol.iterator] = function () {
        return this;
    }), g;

    function verb(n) {
        return function (v) {
            return step([n, v]);
        };
    }

    function step(op) {
        if (f) throw new TypeError('Generator is already executing.');

        while (_) {
            try {
                if (f = 1, y && (t = op[0] & 2 ? y.return : op[0] ? y.throw || ((t = y.return) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
                if (y = 0, t) op = [op[0] & 2, t.value];

                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;

                    case 4:
                        _.label++;
                        return {
                            value: op[1],
                            done: false
                        };

                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;

                    case 7:
                        op = _.ops.pop();

                        _.trys.pop();

                        continue;

                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }

                        if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
                            _.label = op[1];
                            break;
                        }

                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }

                        if (t && _.label < t[2]) {
                            _.label = t[2];

                            _.ops.push(op);

                            break;
                        }

                        if (t[2]) _.ops.pop();

                        _.trys.pop();

                        continue;
                }

                op = body.call(thisArg, _);
            } catch (e) {
                op = [6, e];
                y = 0;
            } finally {
                f = t = 0;
            }
        }

        if (op[0] & 5) throw op[1];
        return {
            value: op[0] ? op[1] : void 0,
            done: true
        };
    }
};

const __read = this && this.__read || function (o, n) {
    let m = typeof Symbol === 'function' && o[Symbol.iterator];
    if (!m) return o;
    const i = m.call(o);
    let r;
    const ar = [];
    let e;

    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) {
            ar.push(r.value);
        }
    } catch (error) {
        e = {
            error
        };
    } finally {
        try {
            if (r && !r.done && (m = i.return)) m.call(i);
        } finally {
            if (e) throw e.error;
        }
    }

    return ar;
};

const __spread = this && this.__spread || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) {
        ar = ar.concat(__read(arguments[i]));
    }

    return ar;
};

import React from 'react';

function useLockFn(fn) {
    const _this = this;

    const lockRef = React.useRef(false);
    return React.useCallback(function () {
        const args = [];

        for (let _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }

        return __awaiter(_this, void 0, void 0, function () {
            let ret; let
                e_1;
            return __generator(this, (_a) => {
                switch (_a.label) {
                    case 0:
                        if (lockRef.current) {
                            return [2
                                /* return */
                            ];
                        }
                        lockRef.current = true;
                        _a.label = 1;

                    case 1:
                        _a.trys.push([1, 3, , 4]);

                        return [4,
                            /* yield */
                            fn.apply(void 0, __spread(args))];

                    case 2:
                        ret = _a.sent();
                        lockRef.current = false;
                        return [2,
                            /* return */
                            ret];

                    case 3:
                        e_1 = _a.sent();
                        lockRef.current = false;
                        throw e_1;

                    case 4:
                        return [2
                            /* return */
                        ];
                }
            });
        });
    }, [fn]);
}

export default useLockFn;
