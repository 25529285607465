export default 
{
    userConsent: 'User Consent', // @NEW
    header: 'Opt in to external testing integration', // @NEW
    text: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
        when an unknown printer took a galley of type and scrambled it to make a type specimen book. 
        It has survived not only five centuries, but also the leap into electronic typesetting, 
        remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset 
        sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like
         Aldus PageMaker including versions of Lorem Ipsum.”`, // @NEW
    employeeNumber: 'Danh số',
    employeeNumberTooltip: 'This is your Employee ID, Student ID, or other identifying number assigned by the organization asking you to test.', // @NEW
    personalInfo: 'Please check your personal information', // @NEW
    consent: 'I consent', // @NEW
    noConsent: 'I don\'t consent', // @NEW
    close: 'Close', // @NEW
    success: 'Opt in Successful!', // @NEW
    testFormBanner: 'Press here to opt in to External testing integration agreement.', // @NEW
};
