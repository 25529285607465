import React from 'react';
import PropTypes from 'prop-types';
import { NavLink as NavLinkBase } from 'react-router-dom';

const NavLink = ({ to, disabled, children, bold, className, ...rest }) => {
    const classNm = (bold ? 'bold' : ' ') + className;
    if (disabled) {
        return (
            <div 
                className={classNm}
                {...rest}
            >
                {children}
            </div>
        );
    }
    
    return (
        <NavLinkBase 
            {...rest}
            to={to.toString()}
            className={classNm}
        >
            {children}
        </NavLinkBase>
    );
};

NavLink.propTypes = {
    to: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]).isRequired, 
    disabled: PropTypes.bool, 
    children: PropTypes.any.isRequired,
    bold: PropTypes.bool,
    className: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func
    ])
};

NavLink.defaultProps = {
    disabled: false, 
    bold: false,
    className: ''
};

export { NavLink };
