import { api } from 'services';

class HiddenQuestionsService {
    static create(id, data) {
        return api.post(`/locations/${id}/hiddenQuestions`, data);
    }

    static remove(id, data) {
        return api.put(`/locations/${id}/hiddenQuestions`, data);
    }
}

export { HiddenQuestionsService }; 
