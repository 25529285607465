import './banner.scss';
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Modal, Checkbox } from 'components';
import locales from 'locales';
import IcoWarn from 'assets/images/ico.warning.svg';
import { PatientActionsUser } from 'modules/patient';
import { now } from 'utils';

const MS_TO_HOURS = 1000 * 60 * 60 * 24;
const EXPIRATION_DAYS = 10;

const AutoscreeningBanner = ({ text }) => {
    const { routes: { screening: strings } } = locales;
    const { bannerHiddenDate, isSymptomPopupHidingDisabled } = useSelector(state => state.patient);
    const [loading, setLoading] = useState(false);
    const [hideBanner, setHideBanner] = useState(true);
    const [checkHideBanner, setCheckHideBanner] = useState(false);
    const dispatch = useDispatch();
    const today = now();

    const onHideBanner = async () => {
        if (checkHideBanner) {
            setLoading(true);
            await dispatch(PatientActionsUser.update({ bannerHiddenDate: today }));
            setLoading(false);
            setHideBanner(true);
        } else {
            setHideBanner(true);
        }
    };

    const onBannerCheckChange = () => {
        setCheckHideBanner(!checkHideBanner);
    };
    
    const isExpired = useCallback((date) => {
        if (!date) {
            return true;
        }
        return today.diff(date) > EXPIRATION_DAYS * MS_TO_HOURS;
    }, [today]);

    useEffect(() => {
        if (bannerHiddenDate && !isSymptomPopupHidingDisabled) {
            setHideBanner(!isExpired(bannerHiddenDate));
        }
        if (!bannerHiddenDate || isSymptomPopupHidingDisabled) {
            setHideBanner(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bannerHiddenDate, isSymptomPopupHidingDisabled]);

    return (
        <Modal
            className="questions-banner-modal"
            visible={!hideBanner}
            footer={(
                <Button
                    type="primary brand"
                    block
                    onClick={onHideBanner}
                    loading={loading}
                >
                    {strings.warning.button}
                </Button>
            )}
        >
            <img 
                className="icon-modal" 
                src={IcoWarn} 
                alt="warning"
            />
            
            <h4>{text}</h4>
            {
                !isSymptomPopupHidingDisabled && (
                    <Checkbox
                        checked={checkHideBanner}
                        onChange={onBannerCheckChange}
                    >
                        {strings.warning.checkbox}
                    </Checkbox>
                )
            }
        </Modal>
    );
};

AutoscreeningBanner.propTypes = {
    text: PropTypes.object.isRequired
};

export default AutoscreeningBanner;
