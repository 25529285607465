import { APIT } from 'utils';

const transformOut = data => ({
    ...data,
    data: data.data.map(item => ({
        ...item,
        startDate: APIT.date.out.toUTCStart(item.startDate),
        endDate: APIT.date.out.toUTCEnd(item.endDate)
    }))
});

export default transformOut;
