import './menu.scss';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation, homePath } from 'routing';
import { AlertsBadge } from 'apps/lintelio/containers';
import locales from 'locales';
import { GA } from 'utils';

const BottomMenu = () => {
    const { $modules } = useSelector(state => state.location);
        
    const { pathname } = useLocation();
    const { common: { bottomMenu } } = locales;

    const MenuItems = {
        home: {
            link: homePath,
            title: bottomMenu.home,
        },
        pandemic: {
            link: `${homePath}screen`,
            title: bottomMenu.screen,
            module: 'pandemic',
        },
        attendance: {
            link: `${homePath}attendance`,
            title: bottomMenu.attendance,
            module: 'attendance',
        },
        incident: {
            link: `${homePath}reportIncident`, 
            title: bottomMenu.incident,
            module: 'incident',
        },
        immunization: {
            link: `${homePath}immunizations`,
            title: bottomMenu.immunization,
            module: 'immunization',
            onClick: () => {
                GA.sendClientEvent('Immunization Module Menu Item Click', 'Immunization');
            }
        },
        benefits: {
            link: `${homePath}benefits`,
            title: bottomMenu.benefits,
            module: 'benefits',
        },
        integrityLine: {
            link: `${homePath}integrityLine`,
            title: bottomMenu.integrityLine,
            module: 'integrityLine',
        },
        /*
        lifeworks: {
            link: `${homePath}lifeworks`,
            title: bottomMenu.lifeworks,
            module: 'lifeworks',
        }, */
        alerts: {
            link: `${homePath}alerts`,
            title: bottomMenu.alerts,
            module: 'alerts',
            extra: AlertsBadge
        },
    };

    const isActive = (menuPath) => {
        if (menuPath === homePath) {
            return pathname === homePath ? 'active' : '';
        }
        if (pathname === menuPath) {
            return 'active';
        }
        if (pathname.startsWith(menuPath)) {
            return 'active';
        }
        return '';
    };

    return (
        <div className="bottom-menu">
            { Object.keys(MenuItems).map((key) => {
                const item = MenuItems[key];
                if (item.module && !$modules[item.module]) {
                    return null;
                }
                if (item.extra) {
                    return (
                        <Link key={key} to={item.link} onClick={item.onClick}>
                            <item.extra>
                                <div className={`menu-bttn ${key} ${isActive(item.link)}`}>
                                    <span>{item.title}</span>
                                </div>
                            </item.extra>
                        </Link>
                    );
                }
                return (
                    <Link key={key} to={item.link} onClick={item.onClick}>
                        <div className={`menu-bttn ${key} ${isActive(item.link)}`}>
                            <span>{item.title}</span>
                        </div>
                    </Link>
                );
            })}
        </div>
    );
};
export default BottomMenu;
