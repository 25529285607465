import './Input.Text.scss';
import React from 'react';
import PropTypes from 'prop-types';

import InputMUI from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';

const InpuText = ({
    prefix, suffix,
    className,
    multiline, disabled,
    value,
    ...rest
}) => {
    const Prefix = prefix ? (
        <InputAdornment position="start">
            {prefix}
        </InputAdornment>
    ) : null;

    const Suffix = suffix ? (
        <InputAdornment position="end">
            {suffix}
        </InputAdornment>
    ) : null;

    return (
        <InputMUI
            value={value || ''}
            className={`c-input ${className || ''}`}
            startAdornment={Prefix}
            endAdornment={Suffix}
            disableUnderline
            variant="filled"
            fullWidth
            multiline={multiline}
            disabled={disabled}
            inputProps={rest}
            label=""
            {...rest}

        />
    );
};

InpuText.propTypes = {
    prefix: PropTypes.any,
    suffix: PropTypes.any,
    className: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    multiline: PropTypes.bool,
    disabled: PropTypes.bool
};

InpuText.defaultProps = {
    prefix: null,
    suffix: null,
    className: '',
    value: '',
    multiline: false,
    disabled: false
};

export default InpuText;
