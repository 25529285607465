import { MODULE_PERMISSION } from 'modules/module';
import { APIT } from 'utils';

const transformUserOut = (admin) => {
    const data = { 
        ...admin,
        active: APIT.bool.out(admin.active),
    };
    if (data.locations && !Array.isArray(data.locations)) {
        data.locations = [data.locations];
    }
    if (data.permissions) {
        Object.keys(data.permissions).forEach((key) => {
            if (data.permissions[key] === MODULE_PERMISSION.NONE) {
                data.permissions[key] = undefined;
            }
        });
    }
        
    return data;
};

export default transformUserOut;
