/* eslint-disable max-len */
export default {
    Q_IS_ER: {
        title: '¿Es esta una emergencia?',
        label:
        {
            HOUSEHOLD: 'Llame al {emergencyNumber} si alguien en su hogar tiene alguno de los siguientes:',
            NOT_HOUSEHOLD: 'Llame al {emergencyNumber} si tiene alguno de los siguientes:',
        },
        label1: 'Dificultad para respirar o sensación de falta de aire.',
        label2: 'Dolor en el pecho.',
        label3: 'Presión o tensión en el pecho.',
        label4: 'Dificultad para mantenerse consciente.',
        label5: 'Confusión.',
        label6: 'Labios o rostro azulados.',
        options: {
            Y: {
                NOT_HOUSEHOLD: 'Tengo al menos uno de estos',
                HOUSEHOLD: 'Alguien tiene al menos uno de estos'
            },                            
            N: {
                NOT_HOUSEHOLD: 'No tengo ninguno de estos',
                HOUSEHOLD: 'Nadie tiene ninguno de estos'
            }
        },
    },
    Q_SYMPTOMS: {
        label: {
            NOT_HOUSEHOLD: '¿Tiene alguno de estos síntomas?',
            HOUSEHOLD: '¿Alguien en su hogar tiene alguno de estos síntomas?'
        },
        banner: `Cuando este contestando las siguientes preguntas, por favor NO elija sintomas que esten relacionados a alergias, COPD, o cualquier otra condicion cronica O reciente contagio de COVID-19 <br />
        Si ha sido autorizado por un doctor a detener el aislamiento despues de haber tenido COVID-19 dentro de los ultimos 3 meses y esta teniendo sintomas continuos, NO elija esos sintomas.`,
        options: {
            FEVER: 'Fiebre ({temperature} o mayor) o Escalofríos.',
            COUGH: 'Tos.', 
            BREATH: 'Falta de aliento o dificultad para respirar (si usted presenta estos síntomas, LLAME al {emergencyNumber}).',
            MUSCLE: 'Dolor muscular o de cuerpo o problemas relacionados con fatiga', 
            HEADACHE: 'Dolor de Cabeza.',
            TASTE: 'Pérdida del sentido del olfato o del gusto.',
            THROAT: 'Dolor de garganta.',
            NOSE: 'Congestión o secreción nasal.',
            NAUSEA: 'Náusea o vómito',
            DIARRHEA: 'Diarrea',
            NONE: {
                NOT_HOUSEHOLD: 'No tengo ninguno de estos',
                HOUSEHOLD: 'Nadie está teniendo estos' 
            }
        },
        labelNoSpecificSymptoms: {
            NOT_HOUSEHOLD: `Está presentando síntomas similares de COVID-19 como:
                <ul>
                    <li>fiebre o escalofríos</li>
                    <li>tos</li> 
                    <li>falta de aliento o dificultad para respirar</li>
                    <li>dolor muscular o de cuerpo o síntomas relacionados a fatiga</li>
                     <li>dolor de cabeza</li>
                     <li>Pérdida del gusto u olfato</li> 
                     <li>dolor de garganta</li>
                     <li>congestíon o secreción nasal</li>
                     <li>náusea o vómito</li>
                     <li>diarrea</li>
                 </ul>`,
            HOUSEHOLD: `Alguien de su casa está presentando síntomas similares de COVID-19 como:
                <ul>
                    <li>fiebre o escalofríos</li>
                    <li>tos</li> 
                    <li>falta de aliento o dificultad para respirar</li>
                    <li>dolor muscular o de cuerpo o síntomas relacionados a fatiga</li>
                    <li>dolor de cabeza</li>
                    <li>Pérdida del gusto u olfato</li> 
                    <li>dolor de garganta</li>
                    <li>congestíon o secreción nasal</li>
                    <li>náusea o vómito</li>
                    <li>diarrea</li>
                </ul>`
        },
        labelNoSpecificSymptoms_hideCongestion: {
             NOT_HOUSEHOLD: `Está presentando síntomas similares de COVID-19 como:
                 <ul>
                     <li>fiebre o escalofríos</li>
                     <li>tos</li> 
                     <li>falta de aliento o dificultad para respirar</li>
                     <li>dolor muscular o de cuerpo o síntomas relacionados a fatiga</li>
                      <li>dolor de cabeza</li>
                      <li>Pérdida del gusto u olfato</li> 
                      <li>dolor de garganta</li>
                      <li>náusea o vómito</li>
                      <li>diarrea</li>
                  </ul>`,
             HOUSEHOLD: `Alguien de su casa está presentando síntomas similares de COVID-19 como:
                 <ul>
                     <li>fiebre o escalofríos</li>
                     <li>tos</li> 
                     <li>falta de aliento o dificultad para respirar</li>
                     <li>dolor muscular o de cuerpo o síntomas relacionados a fatiga</li>
                     <li>dolor de cabeza</li>
                     <li>Pérdida del gusto u olfato</li> 
                     <li>dolor de garganta</li>
                     <li>náusea o vómito</li>
                     <li>diarrea</li>
                 </ul>`
        },
        optionsNoSpecificSymptoms: {
            Y: 'Si',
            N: 'No'
        },
    },
    Q_EXPOSURE: {
        label: {
            NOT_HOUSEHOLD: 'En los pasados {exposureDays} dias, ha estado cerca por 6 pies de cualquier individuo confirmado con COVID-19, 48 horas antes de que ellos se volvieran contagiosos, por una duracion total de 15 minutos en un periodo de 24 horas, con o sin mascarilla?', // @NEW
            HOUSEHOLD: 'En los pasados {exposureDays} dias, has estado alguien en su hogar cerca por 6 pies de cualquier individuo confirmado de COVID-19, 48 horas antes de que se volvieran contagiosos, por una duracion combinada de 15 minutos en un periodo de 24 horas, con o sin mascarilla?', // @NEW
            HCP: `Ha estado en contacto cercano (menos de 6 pies) con alguien confirmado de COVID-19, 48 horas antes de que se volvieran contagiosos, 
            (dentro o fuera de las instalaciones) o cualquier persona bajo investigacion con
            resultados no disponibles dentro de 72 horas?` // @NEW
        },
        options: {
            Y: 'Sí',
            N: 'No'
        }, 
    },
    
    Q_SYMPTOM_DATE: {
        label: '¿En qué fecha comenzaron a aparecer los sintomas? Si no esta seguro de esto, diga un aproximado.' 
    },
    Q_EXPOSURE_DATE: {
        label: '¿Cúando fue la última fecha de una posible exposición? Si no esta seguro de esto, diga un aproximado.' 
    },
    Q_EXPOSURE_HCP1: {
        label: '¿Realizó un procedimiento de producción de aerosol sin TODO el EPP necesario (bata, guantes, protección para los ojos y respirador)?',
        options: {
            Y: 'Yes',
            N: 'No'
        }
    }, 
    Q_EXPOSURE_HCP2: {
        label: '¿Se encontraba en exposición por un período prolongado (alrededor de 15 minutos o más) dentro de 24 horas?',
        options: {
            Y: 'Yes',
            N: 'No'
        }
    }, 
    Q_EXPOSURE_HCP3: {
        label: '¿Utilizó una mascarilla o respirador?',
        options: {
            Y: 'Yes',
            N: 'No'
        }
    }, 
    Q_EXPOSURE_HCP4: {
        label: 'Estaba utilizando protección en lo ojos cuando estuvo en contacto con la persona positiva de COVID-19 que no estaba utilizando cubrebocas u otro tipo de mascarilla?',
        options: {
            Y: 'Sí',
            N: 'No'
        }
    }, 
    Q_TEMPERATURE: {
        label: {
            NOT_HOUSEHOLD: '¿Cual es su temperatura ahora?',
            HOUSEHOLD: '¿Cual es la temperatura más alta que alguien en su hogar tiene en este momento?',
        },
    },
    Q_PROTECTION: { 
        label: {
            NOT_HOUSEHOLD: 'Por favor seleccione la primer opcion que aplique a usted en la lista abajo: ',
            HOUSEHOLD: 'Por favor seleccione la primer opcion que aplique a usted en la lista abajo: '
        },
        options: {
            CURRENT: 'ESTADO DE VACUNACION ACTUAL (completada la primer serie de Pfizer/Moderna dentro de 6 meses, J&J dentro de 2 meses, o refuerzo)',
            POSITIVE: 'positivo al COVID-19 dentro de 90 dias.',
            NOT_CURRENT: 'ESTADO DE VACUNACION NO ACTUAL (no vacunado, O sin refuerzo y completada la primer serie de Pfizer/Moderna hace mas de 6 meses, J&J hace mas de 2 meses)',
        }
    }
};
