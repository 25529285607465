/* eslint-disable consistent-return */
import { useEffect, useRef } from 'react';

const useInterval = (callback, delaySec) => {
    const savedCallback = useRef();

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        const handler = (...args) => savedCallback.current(...args);

        if (delaySec !== null) {
            const id = setInterval(handler, delaySec * 1000);
            return () => clearInterval(id);
        }
    },
    [delaySec]);
};

export { useInterval };
