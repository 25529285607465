import locales from 'locales';
import { history, homePath } from 'routing';

export default ({ params }) => {
    const { models: { attendance: { attendanceResults: strings } } } = locales;
    
    return {
        R_ER: {
            key: 'ER',
            icon: 'ER',
            isExit: true,
            title: locales.formatString(strings.er.title, params),
            label: strings.er.label,
            buttonLabel: strings.er.buttonLabel,
        },
        R_CLEARED: {
            key: 'PASS',
            icon: 'PASS',
            isExit: true,
            title: strings.cleared.title,
            label: strings.cleared.label,
            buttonLabel: strings.cleared.buttonLabel,
        },
        R_FAILED: {
            key: 'FAIL',
            icon: 'ISOLATE',
            isExit: true,
            title: strings.failed.title,
            label: params.$hasBenefits ? strings.failed.label : strings.failed.labelNoBenefits,
            buttonLabel: params.$hasBenefits ? strings.failed.buttonLabel : undefined, 
            onDone: () => (params.$hasBenefits ? history.push(`${homePath}benefits`) : history.push(`${homePath}attendance`)),
        },
    };
};
