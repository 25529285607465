export default {
    title: 'Nhắc nhở',
    screenReminders: 'Screening Reminders', // @NEW
    testReminders: 'Testing Reminders', // @NEW
    scheduled: 'Nhắc nhở đã được lên lịch',
    selectTypeSend: 'Please select how to send reminder', // @NEW
    selectTypeReceive: 'Please select how to receive reminder', // @NEW
    types: {
        email: 'Email', // @NEW
        sms: 'SMS', // @NEW
        alert: 'In-App', // @NEW
    },
    days: {
        Monday: 'Thứ hai',
        Tuesday: 'Thứ ba',
        Wednesday: 'Thứ tư',
        Thursday: 'Thứ năm',
        Friday: 'Thứ sáu',
        Saturday: 'Thứ bảy',
        Sunday: 'Chủ nhật',
    },
    done: 'Xong',
    set: 'Cài đặt',
    selectTime: 'Chọn giờ',
    back: 'Trở lại',
    addSchedule: '+ Thêm',
    scheduled1: 'Đã được xếp lịch',
    remove: 'Gỡ ra',
    banner: 'To receive SMS alerts and reminders, please add a phone number from your profile settings or by clicking here.', // @NEW
};
