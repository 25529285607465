/* eslint-disable max-len */
export default {
    new: 'New', // @NEW
    close: 'Đóng',
    cancel: 'Hủy bỏ',
    submit: 'Nộp',
    confirm: 'Xác nhận', 
    policy: 'Chính sách Riêng tư',
    terms: 'Điều khoản Sử dụng',
    policyLink: '/privacy',
    termsLink: '/terms',
    done: 'Xong', 
    selectDate: 'Vui lòng chọn',
    pleaseSelect: 'Vui lòng chọn',
    pleaseSpecify: 'Vui lòng nói rõ', 
    additionalComments: 'Additional Comments', // @NEW
    next: 'Tiếp thoe',
    back: 'Trở lại',
    signOut: 'Đăng xuất',
    success: 'Thành công',
    action: 'Actions', // @NEW
    screen: 'Screen', // @NEW
    report: 'Report', // @NEW
    status: 'Status', // @NEW
    optOut: 'Opt Out', // @NEW
    optOutVaccination: 'Report Exemption (Unvaccinated)', // @NEW
    viewProtocols: 'View Protocol', // @NEW
    protocol: 'Protocol', // @NEW
    year: 'Year', // @NEW
    month: 'Month', // @NEW
    day: 'Day', // @NEW
    date: 'Date', // @NEW
    proceed: 'Proceed', // @NEW
    noData: 'Nothing to be shown yet', // @NEW
    loading: 'Loading', // @NEW
    OK: 'OK', 
    menu: {
        home: 'Trang chủ',
        back: 'Trở lại',
        settings: 'Cài đặt',
        personalInfo: 'Thông tin cá nhân',
        editProfile: 'Chỉnh sửa dữ liệu',
        general: 'Chung',
        language: 'Ngôn ngữ',
        reminders: 'Thông báo',
        screenReminders: 'Screening Reminders', // @NEW
        about: 'Về việc',
        scheduled: 'Nhắc nhở đã được lên lịch',
        scheduledOne: 'Nhắc nhở đã được lên lịch',
        support: 'Hỗ trợ',
        history: 'View History', // @NEW
        installText: 'Download Lintelio to your home screen for optimal experience on your phone.', // @NEW
        installBtn: 'Download', // @NEW
        skipInstallBtn: 'Not Now', // @NEW
        integration: 'External testing integration agreement', // @NEW
    },
    bottomMenu: {
        home: 'Home', // @NEW
        benefits: 'Benefits', // @NEW
        alerts: 'Alerts', // @NEW
        reports: 'Report', // @NEW
        screen: 'Pandemic', // @NEW
        more: 'More', // @NEW
        attendance: 'Attendance', // @NEW
        incident: 'Incident', // @NEW
        lifeworks: 'LifeWorks',
        telehealth: 'Proctor', // @NEW
        integrityLine: 'Integrity Line',
        immunization: 'Immunization', // @NEW
    },
    jobCategory: {
        healthcare: 'Nhân viên y tế',
        other: 'Other', // @NEW
    },
    remindersPopup: {
        title: 'SMS reminders and alerts are enabled!', // @NEW
        subtitle: 'Your admin has enabled text message reminders and alerts for your location.', // @NEW
        subtitle1: 'Tap below to set up your phone number now, or do it later in your profile. Once you are ready, you can switch your SMS remiders on.', // @NEW
        button1: 'Set Up Phone Number', // @NEW
        button2: 'Nhắc tôi trong 7 ngày',
        button3: 'Không hiện ra lại',
    },
    medicalConsentPopup: { // @NEW
        header: 'User Consent', // @NEW
        button1: 'I authorize', // @NEW
        button2: 'I do not authorize (log me out)', // @NEW
    },
    leavePopup: {
        title: 'Are you sure?', // @NEW
        text: 'The data you\'ve entered will be lost.', // @NEW
        okButton: 'Yes, leave the page', // @NEW
        cancelButton: 'No, it was a mistake' // @NEW
    },
    fileUpload: {
        errorFileSize: 'Dung dượng tập tin lớn hơn 10MB không được phép', 
        errorMaxCount: 'Số tập tin vượt mức tối đa'
    },
    customContent: {
        holdAttendance: { // @NEW
            header: 'On Hold',
            title: 'You are not reporting to work today.',
            text: 'For assistance please reach out to your HR manager or review your list of available benefits.',
        },
        hold: {
            header: 'Tạm ngưng',
            title: 'It looks like you are not feeling well', // @NEW
            text: 'Do not report to work today. Contact your Human Resources department immediately to discuss the next steps. Thank you!', // @NEW
        },
        screen: {
            header: 'Kiểm tra',
            title: '',
            text: '',
        },
        test: {
            header: 'Xét nghiệm',
            title: '',
            text: '',
        },
        vaccination: {
            header: 'Chích ngừa',
            title: 'Bạn được yêu cầu chích ngừa',
            text: 'Có vẻ như bạn cần chích ngừa COVID-19. Vui lòng liên lạc đại diện ở tổ chức của bạn để có hướng dẫn cách tiến hành.',
        },
        protocol: { // @NEW
            header: 'Pandemic Protocol',
            title: '',
            text: '',
        },
        medical: { // @NEW
            header: 'Medical Consent',
            title: '',
            text: '',
        },
        testingReminders: { // @NEW
            header: 'Testing Reminders',
            title: 'COVID test validity expires in [x] days',
            text: 'To submit another test result please follow the below link.',
        }
    },
    modules: {
        screen: 'Pandemic', // @NEW
        test: 'Đang kiểm tra',
        vaccination: 'Chích ngừa',
        attendance: 'Attendance', // @NEW
        injury: 'Injury', // @NEW 
        optOut: 'Exemption (Unvaccinated)', // @NEW
        incident: 'Incident', // @NEW 
    },
    moduleActions: {
        screen: 'Đang kiểm tra',
        test: 'Đang kiểm tra',
        vaccination: 'Chích ngừa',
        attendance: 'Attendance', // @NEW
        injury: 'Injury' // @NEW 
    },
    moduleStatus: {
        OK: 'Ok', 
        HOLD: 'Tạm ngưng', 
        REQUIRED: 'Hành động được yêu cầu',
    },
    statusTitle: {
        noPending: 'Không có hành động chưa giải quyết',
        hold: 'Tài khoản của bạn đang bị tạm ngưng',
        pending: '1 Hành động chưa giải quyết', 
        pendingMany: '{count} Các hành động chưa giải quyết', 
        overdue: '1 Hành động quá hạn', 
        overdueMany: '{count} Các hành động quá hạn', 
    },
    statusSubtitle: {
        notRequired: 'Không có Hành động được yêu cầu', 
        pending: 'Đang giải quyết', 
        due: 'Đến hạn trong {dueDays} ngày', 
        overdue: 'Quá hạn',
    },
    // @NEW SECTION
    testStatus: {
        OK: 'No Action Required',
        NEGATIVE: 'Next test due by {date}',
        POSITIVE: 'Positive Test',
        REQUIRED: 'Test Required',
    },
    // @NEW SECTION
    vaccinationStatus: {
        UNVACC: 'Unvaccinated',
        VACC: 'Vaccinated',
        OPTOUT: 'Exemptioned',
        VACCREQUIRED: 'Vaccination Required', 
        PARTIALLYVACC: 'Partially Vaccinated',
    },
    attendanceStatus: {
        OK: 'No Pending Actions', // @NEW 
        HOLD: 'Your Account is on Hold', // @NEW 
        REQUIRED: '1 Overdue Action', // @NEW 
    }
};
