import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Form, Button, Input, Select, Phone, TextArea } from 'components';
import { HomeIcon } from 'components/Icons';
import { ValidationRules } from 'utils';
import locales from 'locales';
import { LOCATION_CALLBACKS } from 'modules/location';

const ResultTriage = ({ onSubmit, loading, wrapper: Wrapper }) => {
    const { Item } = Form;

    const [form] = Form.useForm();
    const { routes: { screening: strings } } = locales;
    const { phone, zip } = useSelector(state => state.patient);
    const { 
        current: { key: currentKey, label = strings.unknown }, 
        outcomes: { EXPOSURE, SYMPTOMS } 
    } = useSelector(state => state.screening);
    
    const location = useSelector(state => state.location);

    const viewFullIntakeQuestion = !!location.isScreenFullIntakeEnabled && (EXPOSURE || SYMPTOMS);

    const initialValues = { 
        callbackPhone: phone, 
        callbackDelay: 0, 
        zip 
    };
    
    const onFinish = (values) => {
        onSubmit({ 
            ...values,
            callback: LOCATION_CALLBACKS.NURSE
        });
    };

    const rules = ValidationRules.translate();

    const { options, resultNurseText } = strings.result;

    const CallbackTimeOptions = [
        { value: 0, label: strings.result.form.select.asap },
        { value: 15, label: strings.result.form.select.fifteen },
        { value: 30, label: strings.result.form.select.thirty },
        { value: 45, label: strings.result.form.select.fortyfive },
        { value: 60, label: strings.result.form.select.hour },
    ];
    
    const ExposedPlaceOptions = options
        .map(item => ({ value: item, label: item }));
        
    return (
        <Form
            form={form}
            onFinish={onFinish}
            initialValues={initialValues}
        >
            <Wrapper
                className="autoscreening-result-triage"
                footer={(
                    <Button
                        type="primary brand"
                        onClick={form.submit}
                        block
                        loading={loading}
                    >
                        {strings.result.form.button}
                    </Button>
            )}
            >
                <p data-qa={currentKey} className="bold">
                    {label}&nbsp;{resultNurseText}
                </p>
            
                <Item
                    label={strings.result.form.zip}
                    name="zip"
                    rules={[rules.zip, rules.required]}
                    validateTrigger="onBlur"
                >
                    <Input 
                        maxLength={5} 
                        prefix={<HomeIcon />} 
                        pattern="[0-9]*" 
                        inputMode="numeric"
                    />
                </Item>
                {
                    (viewFullIntakeQuestion) && (
                        <Item
                            name="exposedPlace"
                            label={strings.result.form.fullIntake}
                        >
                            <Select 
                                options={ExposedPlaceOptions} 
                            />
                        </Item>
                    )
                }
                <Item
                    label={viewFullIntakeQuestion 
                        ? strings.result.form.notesIntake : strings.result.form.notes}
                    name="notes"
                >
                    <TextArea maxLength={150} rows={4} />
                </Item>
                <p className="bold">
                    {strings.result.form.text}
                </p>
                <Item
                    label={strings.result.form.phoneNumber}
                    name="callbackPhone"
                    rules={[rules.required, rules.phone]}
                    validateTrigger="onBlur"
                >
                    <Phone />
                </Item>
                <Item
                    name="callbackDelay"
                    label={strings.result.form.callbackDelay}
                    rules={[rules.required]}
                >
                    <Select 
                        title={strings.result.form.callbackDelay}
                        options={CallbackTimeOptions} 
                    />
                </Item>
            
            </Wrapper>
        </Form>
    );
};

ResultTriage.propTypes = {
    loading: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    wrapper: PropTypes.any.isRequired
};

export default ResultTriage;
