import { api } from 'services';
import transformer from './jobtitle.transformer';
  
class JobTitleService {
    static create(data, locationId) {
        return api
            .post('/jobTitles', transformer.out(data, locationId));
    }
    
    static update(data) {
        return api
            .put('/jobTitles', transformer.out(data));
    }

    static remove(data) {
        return api
            .delete('/jobTitles', { data: transformer.outDelete(data) });
    }

    static list(locationId) {
        return api.get(`locations/${locationId}/jobTitles`)
            .then(transformer.in);
    }   
}

export default JobTitleService; 
