import './version.scss';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { BrandContext } from 'brands';

const AppVersion = process.env.REACT_APP_VERSION;

const Version = ({ className }) => {
    const { name } = useContext(BrandContext);
    return (
        <div
            className={`ver ${className}`}
        >
            {name} v. {AppVersion}
        </div>
    );
};

Version.propTypes = {
    className: PropTypes.string
};

Version.defaultProps = {
    className: ''
};

export { Version, AppVersion };
