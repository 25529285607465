import dayjs from 'dayjs';
import { 
    // Timezones, @RELEASE NOVEMBER/DECEMBER
    SummerTimezone 
} from 'constants';
import { formats } from './date.utils';

export const TimezoneOptions = Object.keys(SummerTimezone).map(key => ({
    value: key, 
    label: SummerTimezone[key]
}));

export const getTimeByTimezone = (timeZone) => {
    const date = new Date().toLocaleString('en-US', { timeZone });
    return dayjs(date).format(formats.time.in);
};
