import './hierarchy.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { RightOutlined } from '@ant-design/icons';
import { Link } from 'routing';

const Hierarchy = ({ locations }) => (
    <div className="hierarchy-item">
        {locations.map(item => (
            <Link to={`/locations/${item.id}`} key={item.id}>
                <RightOutlined />
                <span>{item.title}</span>
            </Link>
        ))}
    </div>
);

Hierarchy.propTypes = {
    locations: PropTypes.array.isRequired,
};

export { Hierarchy };
