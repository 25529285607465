import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FtoC, CtoF } from 'utils';
import { Input } from '../Input';

export * from './masked';

const Temperature = ({ onChange, value, unit, ...rest }) => {
    const [temperature, setTemperature] = useState();

    useEffect(() => {
        const temp = (unit === 'C') ? FtoC(value) : value;
        setTemperature(temp);
    }, [unit, value]);
    
    const onBlur = () => {
        const temp = parseFloat(temperature);
        onChange((unit === 'C') ? CtoF(temp) : temp);
    };

    return (
        <Input
            {...rest}
            onChange={e => setTemperature(e.target.value)}
            inputMode="numeric"
            value={temperature}
            onBlur={onBlur}
        />
    );
};

Temperature.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.number,
    unit: PropTypes.string,
};

Temperature.defaultProps = {
    value: undefined,
    onChange: () => {},
    unit: 'F',
};

export { Temperature };
