const SKIP_MESSAGE = 'skip';
export { SKIP_MESSAGE };

export default {
    '/me': {
        post: {
            403: 'patientInactive',
            406: 'userLimitReached', 
            410: 'registrationDisabled',
        },
    },
    '/register': {
        post: {
            403: 'patientInactive',
            406: 'userLimitReached', 
            410: 'registrationDisabled'
        }
    },
    '/locations': {
        get: {
            404: 'locationNotFound', 
        }
    },
    '/location': {
        get: {
            404: 'locationNotFound', 
            '*': 'serverError',
        }
    },
    '/account': {
        get: {
            404: 'locationNotFound', 
            '*': 'serverError',
        }
    },
    '/screenings': {
        post: {
            500: 'unknownError',
            404: 'missingLocation',
            // 400: 'EnspiriError-doNotTranslate',
        }
    },
    '/patients/schedules': {
        '*': {
            500: 'unknownError',
            404: 'missingContact',
            403: 'lockedNotifications'
        },
    },
    '/patients': {
        get: {
            500: 'unknownError',
            404: 'patientNotFound',
        },
        post: {
            500: 'unknownError'
        },
        put: {
            500: 'unknownError'
        },
    },
    '/validationCode': {
        post: {
            500: 'unknownError',
            
            200: 'codeSent', 
            201: 'lastCodeSent', 
            
            404: 'missingPhoneNumber', 
            406: 'verCodeMissing',
            407: 'codeExpired',
            410: 'codeWrong',
            409: 'lastCodeSentError',
            420: 'missingPhoneNumber',
            421: 'codeExpired'
        },
    },
    '/token': {
        post: {
            401: 'invalidCredentials'
        }
    },
    '/': {
        '*': {
            418: 'networkError',
            401: SKIP_MESSAGE, 
            503: 'serverError'
        }
    },
};
