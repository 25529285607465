export default 
{
    title: 'Attendance', // @NEW
    attendanceStatus: 'Attendance Status', // @NEW
    questions: { // @NEW
        title: 'Attendance', // @NEW
        none: 'None', // @NEW
        back: 'Back', // @NEW
        cancel: 'Cancel', // @NEW
        continue: 'Next' // @NEW
    },
};
