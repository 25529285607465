import './TextArea.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { Input } from '../Input';

const TextArea = ({
    showCount, maxLength,
    className,
    value, onChange,
    disabled,
    ...rest
}) => (
    <>
        <Input
            className={`c-textarea ${className}`}
            value={value}
            onChange={onChange}
            maxLength={maxLength}
            multiline
            disabled={disabled}
            {...rest}
        />
        {showCount && maxLength && (
            <div className="c-textarea-count">
                {(value || '').length}/{maxLength}
            </div>
        )}
    </>
);

TextArea.propTypes = {
    showCount: PropTypes.bool,
    maxLength: PropTypes.number,
    value: PropTypes.string,
    rows: PropTypes.number,
    className: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool
};

TextArea.defaultProps = {
    showCount: true,
    maxLength: undefined,
    rows: 6,
    value: '',
    className: '',
    onChange: () => { },
    disabled: false
};

export { TextArea };
