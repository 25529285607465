import './icons.scss';
import React from 'react';
import IconWarn from 'assets/images/ico.warning.svg';

const IconWarning = props => (
    <img
        className="icon-warning"
        src={IconWarn} 
        width={40} 
        alt="Warning" 
        {...props}
    />
);

export {
    IconWarning
};
