// Alert => custom, Done
import './Banner.scss';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CloseIcon } from 'components/Icons';

export const Banner = ({ children, className, closable }) => {
    const [visible, setVisible] = useState(true);
    const onClose = () => setVisible(false);
    if (!visible) {
        return null;
    }
    return (
        <div 
            className={`c-banner ${className} ${closable ? 'c-banner-closable' : ''}`}
        >
            {closable && (
                <CloseIcon onClick={onClose} className="close" />
            )}
            {children}
        </div>
    );
};

Banner.propTypes = {
    children: PropTypes.any.isRequired,
    className: PropTypes.string,
    closable: PropTypes.bool
};

Banner.defaultProps = {
    className: '',
    closable: false
};
