import './Alert.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { Alert as AlertBase } from 'antd';

const Alert = ({ children, description, ...rest }) => (
    <AlertBase
        {...rest}
        description={children || description}
    />
);

Alert.propTypes = {
    children: PropTypes.any,
    description: PropTypes.any,
    icon: PropTypes.any,
    closable: PropTypes.bool,
    showIcon: PropTypes.bool,
    type: PropTypes.string,
};

Alert.defaultProps = {
    children: null,
    description: null,
    icon: null, // <InfoCircleFilled />,
    closable: true,
    showIcon: true,
    type: 'info'
};

export { Alert };
