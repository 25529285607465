import { MODULE_PERMISSION } from 'modules/module';
import { ModulesConfig } from 'apps/administration/constants';
import { APIT } from 'utils';
import ModulesTranformer from './modules';
import { ROLE_SYS_ADMIN, ROLE_ADMIN, ROLE_PATIENT } from '../user.model';

const transformUserIn = (data) => {
    const { 
        firstName, lastName, 
        roles, permissions
    } = data;
    const avatar = (firstName && firstName[0].toUpperCase())
        + (lastName && lastName[0].toUpperCase());
    
    const fullName = `${data.firstName } ${ data.lastName}`;
    const $role = roles.length ? roles[0] : ROLE_PATIENT; // the role should be max here?
    const language = (data.language || 'en').toLowerCase();
    
    const $modules = ModulesTranformer.user(data.modules, permissions);
    const $locationModules = ModulesTranformer.location(data.modules, permissions);
    const $isAdmin = $role === ROLE_ADMIN;
    const $isSysAdmin = $role === ROLE_SYS_ADMIN;

    Object.values(ModulesConfig).forEach(({ id }) => {
        if (Object.isUndefined(permissions[id])) {
            permissions[id] = MODULE_PERMISSION.NONE;
        }
    });
    
    return {
        ...data,
        active: APIT.bool.in(data.active),
        avatar,
        roles,
        role: $role,
        language,
        fullName,
        // @READONLY
        $modules,
        $locationModules,
        $role, 
        $isAdmin,
        $isSysAdmin
    };
};

export default transformUserIn;
