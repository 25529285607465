/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable react/sort-comp */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable class-methods-use-this */
/* eslint-disable camelcase */
import React from 'react';
import { Auth } from '@aws-amplify/auth';
import { toQueryParams } from 'utils';
import { AuthState, Challenges } from './constants';
import { AuthContext } from './context';
import config from './config';

class AuthComponent extends React.Component {
    _validAuthStates;

    constructor(props) {
        super(props);

        this._validAuthStates = [];
        this.changeState = this.changeState.bind(this);
        this.error = this.error.bind(this);
    }

    errorMessage(err) {
        if (typeof err === 'string') {
            return err;
        }
        return err.message ? err.message : JSON.stringify(err);
    }

    triggerAuthEvent(event) {
        const { authState, onAuthEvent } = this.context;
        if (onAuthEvent) {
            onAuthEvent(authState, event);
        }
    }

    async changeState(state, data) {
        const { authData, changeState } = this.context;

        if (changeState) {
            await changeState(state, {
                ...authData,
                ...data
            });
        }

        this.triggerAuthEvent({
            type: 'stateChange',
            data: state,
        });
    }

    error(err) {
        this.triggerAuthEvent({
            type: 'error',
            data: this.errorMessage(err),
        });
    }

    async updateUser(data) {
        const user = await Auth.currentAuthenticatedUser();
        const newUser = await Auth.updateUserAttributes(user, data);
        return newUser;
    }

    signIn = async (signInData) => {
        const { username, password, meta } = signInData;
        try {
            const user = await Auth.signIn(username, password, meta);
            const data = { ...signInData, user };

            switch (user.challengeName) {
                case Challenges.SMS_MFA:
                    this.changeState(AuthState.ConfirmSignIn, data);
                    break;
                case Challenges.SOFTWARE_TOKEN_MFA:
                    this.changeState(AuthState.ConfirmSignIn, data);
                    break;
                case Challenges.NEW_PASSWORD_REQUIRED:
                    this.changeState(AuthState.RequireNewPassword, data);
                    break;
                case Challenges.MFA_SETUP:
                    this.changeState(AuthState.MFASetup, data);
                    break;
                case Challenges.CUSTOM_CHALLENGE: {
                    this.changeState(AuthState.CustomConfirmSignIn, data);
                    break;
                }
                default: 
                    // choose MFA type is disabled 
                    // await checkUser({ checkMFA: true, bypassCache: true });
            }
        } catch (err) {
            if (err.code === 'UserNotConfirmedException') {
                this.changeState(AuthState.ConfirmSignUp, signInData);
            } else if (err.code === 'PasswordResetRequiredException') {
                this.changeState(AuthState.ForgotPassword, signInData);
            } else {
                this.error(err);
            }
        }
    };

    sendCustomChallengeAnswer = async (code) => {
        const { authData: { user } } = this.context;
        Auth.sendCustomChallengeAnswer(user, code)
            .then((data) => {
                if (!data.signInUserSession) {
                    this.error('Invalid Verification Code');
                } else {
                    this.changeState(AuthState.SignedIn, data);
                }
            })
            .catch((err) => {
                if (err.code === 'NotAuthorizedException') {
                    this.error('Too many attempts or expired code. Please go back and try again.');
                } else this.error(err);
            });
    };

    signUp = async (signUpData) => {
        this.changeState(AuthState.ConfirmSignUp, signUpData);

        return Auth.signUp(signUpData)
            .then((data) => {
                this.changeState(AuthState.ConfirmSignUp, data);
            })
            .catch(this.error);
    };

    confirmSignUp = (code) => {
        const { authData } = this.context;
        const { username } = authData;

        return Auth.confirmSignUp(username, code)
            .then(async (status) => {
                if (status === 'SUCCESS') {
                    await this.signIn(authData);
                }
                return status;
            })
            .catch(this.error);
    };

    sendPasswordResetCode = (username) => {
        if (!username) {
            return this.error('Username is empty');
        }
        return Auth.forgotPassword(username)
            .then((data) => {
                const delivery = data.CodeDeliveryDetails;
                this.changeState(AuthState.ForgotPasswordCode, { username, delivery });
            })
            .catch(this.error);
    };

    forgotPasswordSubmit = ({ username, password, code }) =>
        Auth.forgotPasswordSubmit(username, code, password)
            .then(() => this.signIn({ username, password }))
            .catch(this.error);

    signInSAML = (idProvider) => {
        const { Auth: AuthConfig } = config;

        const data = {
            response_type: AuthConfig.oauth.responseType,
            client_id: AuthConfig.userPoolWebClientId,
            redirect_uri: AuthConfig.oauth.redirectSignIn,
            identity_provider: idProvider,
            scope: AuthConfig.oauth.scope.join('+'),

        };
        const url = `https://${AuthConfig.oauth.domain}/oauth2/authorize?${toQueryParams(data)}`;
        window.location.assign(url);
    };

    render() {
        const { authState } = this.context;
        if (!this._validAuthStates.includes(authState)) {
            return null;
        }
        return this.showComponent();
    }

    showComponent() {
        throw new Error('You must implement showComponent() and don\'t forget to set this._validAuthStates.');
    }
}

AuthComponent.contextType = AuthContext;

export { AuthComponent };
