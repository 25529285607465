import './code.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { getCountryCallingCode } from 'react-phone-number-input';

// Flag Source: \node_modules\country-flag-icons\1x1
import BG from 'assets/images/flags/BG.svg';
import HN from 'assets/images/flags/HN.svg';
import MX from 'assets/images/flags/MX.svg';
import US from 'assets/images/flags/US.svg';
import DE from 'assets/images/flags/DE.svg';
import NL from 'assets/images/flags/NL.svg';

import { Select } from '../Select';

const icons = { BG, HN, MX, US, DE, NL };

const defaultCountry = 'US';

const SelectCode = (props) => {
    const { onChange, setCode, options, value, disabled } = props;

    const handleChange = (country) => {
        onChange(country);
        setCode(country);
    };

    const optionsMapped = options.filter(i => !!i.value)
        .map(item => ({
            value: item.value,
            label: (
                <>
                    <span className="country-label">{item.label}</span>
                    <span className="country-code">+{getCountryCallingCode(item.value || 'US')}</span>
                </>
            ),
            prefix: <img src={icons[item.value || 'US']} alt="country" />
        }));

    return (
        <div className="PhoneInputCountry">
            <div className="PhoneInputCountryIcon">
                <Select
                    disabled={disabled}
                    aria-label="Phone number country"
                    className="PhoneInputCountrySelect"
                    dropdownClassName="phone-input-dropdown"
                    value={value}
                    onChange={handleChange}
                    options={optionsMapped}
                    renderValue={selected => (
                        <img
                            className="PhoneInputCountryIconImg"
                            src={icons[selected] || icons[defaultCountry]}
                            alt="country"
                        />
                    )}
                />
            </div>
        </div>
    );
};

SelectCode.propTypes = {
    onChange: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired,
    setCode: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    value: PropTypes.string,
};

SelectCode.defaultProps = {
    value: undefined,
    disabled: false
};

export default SelectCode;
