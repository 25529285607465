import './Carousel.scss';
import React from 'react';
import PropTypes from 'prop-types';
import CarouselNuka from 'nuka-carousel';

// https://github.com/FormidableLabs/nuka-carousel

const Carousel = ({ children, className, beforeSlide, autoplay }) => (
    <span className={`c-carousel ${className}`}>
        <CarouselNuka
            enableKeyboardControls
            wrapAround
            autoplay={autoplay}
            autoplayInterval={4000}
            pauseOnHover
            swiping
            beforeSlide={beforeSlide}
            defaultControlsConfig={{
                pagingDotsStyle: {
                    fill: 'white'
                }
            }}
        >
            {children}
        </CarouselNuka>
    </span>
);

Carousel.propTypes = {
    children: PropTypes.array.isRequired,
    className: PropTypes.string,
    beforeSlide: PropTypes.func,
    autoplay: PropTypes.bool
};

Carousel.defaultProps = {
    className: '',
    beforeSlide: undefined,
    autoplay: true
};

export { Carousel };
