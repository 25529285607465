const ProviderConstants = {
    ERROR: 'PROVIDER_ERROR',
    LOADING: 'PROVIDER_LOADING',
    LOADED: 'PROVIDER_LOADED',
    LOGOUT: 'PROVIDER_LOGOUT',
    CLEAR: 'PROVIDER_CLEAR',
};

export {
    ProviderConstants
};
