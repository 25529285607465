import { api } from 'services';
import { LangService } from 'modules/lang';

import { ReminderService } from 'modules/reminder';
import { QuestionService } from 'modules/question';

import { AnnouncementService } from 'modules/announcement';
import { toQueryParams } from 'utils';
import Transformer from './transformers';

class LocationService {
    static get(id, getTranslations) {
        return api
            .get(`/locations/${id}`)
            .then(async (res) => {
                if (getTranslations) {
                    await LangService.translations(res.locationHash);
                }
                return res;
            })
            .then(Transformer.in);
    }
    
    static async getByCid(cid) {
        await LangService.translations(cid);
        return api.get(`/location/${cid}`)
            .then(Transformer.in);
    }

    static async getByDomain(subdomain) {
        return api.get(`/location/subdomain/${subdomain}`) 
            .then(Transformer.in);
    }

    static remove(id) {
        return api
            .delete(`locations/${id}`);
    }

    static update(id, data, getTranslations) {
        return api
            .put(`/locations/${id}`, Transformer.out(data))
            .then(async (res) => {
                if (getTranslations) {
                    await LangService.translations(res.locationHash);
                }
                return res;
            })
            
            .then(Transformer.in);
    }
    
    static list(...args) {
        return api.list('/locations', ...args)
            .then(Transformer.list);
    }

    static listInactive(...args) {
        return api.list('/locations/inactive', ...args)
            .then(Transformer.list);
    }

    static options(filters = {}) {
        const params = toQueryParams(filters);
        return api.get(`/locations/options?${ params}`);
    }

    static getByFilters(value) {
        return LocationService.list(1, 10000, value)
            .then(data => data.data);
    }

    static questions(...args) {
        return QuestionService.list(...args);
    }

    static questionsUpdate(...args) {
        return QuestionService.update(...args);
    }

    static reminders(id, data) {
        if (data) {
            return ReminderService.update(id, data);
        }
        return ReminderService.get(id);
    }

    static remindersUpdate(id, data) {
        return ReminderService.update(id, data);
    }

    static announcements(id, data) {
        if (data) {
            return AnnouncementService.update(id, data);
        }
        return AnnouncementService.list(id);
    }

    static downloadPoster(id, ...rest) {
        return api.download(`/locations/${id}/poster`, ...rest);
    }

    static registrationFields(id, data) {
        if (!data) {
            return api.get(`/locations/${id}/customFields`)
                .then(Transformer.registrationFields.in);
        }
        const dataOut = Transformer.registrationFields.out(data);
        return api.post(`/locations/${id}/customFields`, { data: dataOut })
            .then(Transformer.registrationFields.in);
    }

    static getImmunizationTypes(id) {
        return api.get(`/locations/${id}/immunizationTypes`)
            .then(d => d.data);
    }

    static updateImmunizationTypes(id, data) {
        return api.patch(`/locations/${id}/immunizationTypes`, data)
            .then(d => d.data);
    }
}

export { LocationService };
