import { APIT } from 'utils';
import { LOCATION_DEFAULT } from '../../location.model';
import transformAuth from '../auth';
import transformModules from '../modules';
import transformHiddenQuestions from '../hiddenQuestions';

const transformLocationInByCid = data => ({
    ...data,
    id: data.id,
    parentId: data.parentId,
    title: data.title,
    subdomain: data.subdomain,
    locationHash: data.locationHash,
    useColor: data.useColor || LOCATION_DEFAULT.color,
    useLogo: data.useLogo || LOCATION_DEFAULT.logo,
    flowType: data.flowType || LOCATION_DEFAULT.flowType,
    identityProviderName: data.identityProviderName,
    
    temperatureThreshold: parseFloat(data.temperatureThreshold), 
    temperatureUnit: data.temperatureUnit || LOCATION_DEFAULT.temperatureUnit,
    emergencyNumber: data.emergencyNumber || LOCATION_DEFAULT.emergencyNumber,
    exposureDays: data.exposureDays ?? 14,

    screenValidityHours: data.screenValidityHours,
    screenValidityHoursForVaccinated: data.screenValidityHoursForVaccinated,
    testValidityDays: data.testValidityDays,
    testValidityDaysForVaccinated: data.testValidityDaysForVaccinated,
    
    smsSendingEnabled: APIT.bool.in(data.smsSendingEnabled),
    emailSendingEnabled: APIT.bool.in(data.emailSendingEnabled),
    isSpecificSymptomsEnabled: APIT.bool.in(data.isSpecificSymptomsEnabled),
    isScreeningDetailsEnabled: APIT.bool.in(data.isScreeningDetailsEnabled),
    hideCongestionOption: APIT.bool.in(data.hideCongestionOption),
    isScreenFullIntakeEnabled: APIT.bool.in(data.isScreenFullIntakeEnabled),
    isSymptomPopupHidingDisabled: APIT.bool.in(data.isSymptomPopupHidingDisabled), 
    showBenefitsTile: APIT.bool.in(data.showBenefitsTile),
    areAttachmentsMandatory: APIT.bool.in(data.areAttachmentsMandatory),
    isMedicalConsentEnabled: APIT.bool.in(data.isMedicalConsentEnabled),
    isDateOfBirthFieldEnabled: APIT.bool.in(data.isDateOfBirthFieldEnabled),
    isEmployeeIdMandatory: APIT.bool.in(data.isEmployeeIdMandatory),
    isAddressFieldEnabled: APIT.bool.in(data.isAddressFieldEnabled),
    isGenderFieldEnabled: APIT.bool.in(data.isGenderFieldEnabled),
    allowCustomQuestions: APIT.bool.in(data.allowCustomQuestions),
    passIfVaccinatedEnabled: APIT.bool.in(data.passIfVaccinatedEnabled),
    contentManagement: data.contentManagement,
    useIntegrityLineUrl: data.useIntegrityLineUrl,
    children: data.children || [],
    isTriage: APIT.bool.in(data.isTriage),
      
    ...transformAuth.in(data.authenticationTypes), 
    // Readonly props
    $locationTitle: data.title,
    $modules: transformModules.in(data.modules),
    $hiddenQuestions: transformHiddenQuestions.in(data), // hiddenQuestions, tempCheck
});

export default transformLocationInByCid;
