import { formats } from 'utils';

const ColumnTypes = {
    string: 'string',
    number: 'number',
    date: 'date',
    dateExact: 'dateExact',
    dateTime: 'dateTime',
    dateTimeExact: 'dateTimeExact',
    bool: 'bool',
};
const renderFn = {
    [ColumnTypes.date]: (d) => {
        if (!d) return '';
        return d.format(formats.date.in);
    },
    [ColumnTypes.dateExact]: (d) => {
        if (!d) return '';
        return d.format(formats.date.in); 
    },
    [ColumnTypes.dateTime]: (d) => {
        if (!d) return '';
        return d.format(formats.dateTime.in);
    },
    [ColumnTypes.dateTimeExact]: (d) => {
        if (!d) return '';
        return d.format(formats.dateTime.in);
    },
    [ColumnTypes.bool]: text => (text ? 'Yes' : 'No'),
};

export { 
    ColumnTypes, 
    renderFn
};
