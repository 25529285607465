import './statusIcon.scss';
import React from 'react';
import PropTypes from 'prop-types';

const PathCircle = (
    <>
        <circle cx="25" cy="25" r="23" className="circle-big" transform="rotate(-133 25 25)" />
        <circle cx="25" cy="25" r="19" className="circle-small" transform="rotate(-121 25 25)" />
        <circle cx="11.7" cy="11.7" r=".2" className="circle-dot" />
    </>
);

const PathTriangle = (
    <>
        <path d="M 18.1645 3.83677 C 19.4801 1.55873 21.9271 0.140137 24.5603 0.140137 C 27.192 0.140137 29.6404 1.55871 30.956 3.83677 L 47.5735 32.6213 C 48.2197 33.74 48.5596 35.0145 48.5596 36.3065 C 48.5596 40.3773 45.2485 43.6884 41.1777 43.6884 H 7.94143 C 3.87064 43.6884 0.55957 40.3773 0.55957 36.3065 C 0.55957 35.0143 0.901004 33.7414 1.54553 32.6216 L 18.1645 3.83677 Z" className="warning-big" />
        <path d="M 21.4578 15.4166 L 27.9061 4.2128 C 28.2126 3.68237 28.6573 3.23927 29.1872 2.93334 C 30.8624 1.96615 33.0165 2.54333 33.9837 4.21855 L 50.6776 33.1332 L 50.6712 33.1369 C 51.5013 34.7838 50.9078 36.8207 49.291 37.7542 C 48.7611 38.0601 48.155 38.2237 47.5424 38.2239 L 14.3538 38.224 C 13.1061 38.2243 11.931 37.5517 11.3068 36.4705 C 10.6817 35.3879 10.6876 34.0353 11.3117 32.9549 L 15.4603 25.7694 L 17.5346 22.1766" className="warning-small" />
        <circle cx="13.8" cy="22.3" r=".2" className="circle-dot" />
    </>
);

const Paths = {
    hold: PathCircle,
    ok: PathCircle,
    required: PathTriangle,
    okpending: PathTriangle
};

const StatusIcon = ({ type }) => (
    <div className={`status-icon ${type}`}>
        <svg 
            width={50} 
            height={50} 
            viewBox="0 0 50 50" 
            className="lines"
        >
            <defs>
                <linearGradient 
                    id={`gradient-${type}`} 
                    x1="0%" 
                    y1="0%" 
                    x2="0%" 
                    y2="100%"
                >
                    <stop offset="0%" />
                    <stop offset="100%" />
                </linearGradient>
            </defs>
            <g 
                fill="none" 
                strokeLinecap="round" 
                stroke={`url(#gradient-${type})`} 
                strokeWidth="2"
            >
                {Paths[type]}
            </g>
        </svg>
        <div className="mark" />
    </div>
);

StatusIcon.propTypes = {
    type: PropTypes.oneOf([
        'ok', 
        'hold', 
        'required',
        'okpending',
        'failed'
    ]).isRequired
};

export { StatusIcon };
