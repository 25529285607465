import './InputHTML.scss';

import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import ReactQuill, { Quill } from 'react-quill';
import ImageResize from 'quill-image-resize-module-react';

const ToolbarDefault = [
    [{ header: [1, 2, 3, 4, 5, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ color: [] }, { background: [] }],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    ['link'],
    ['clean']
];

const InputHtmlToolbarExtended = [
    [{ header: [1, 2, 3, 4, 5, false] }, 'bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ color: [] }, { background: [] }],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    ['link', 'image', 'clean']
];

const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'color', 'background',
    'list', 'bullet', 'indent',
    'link', 'image'
];

Quill.register('modules/imageResize', ImageResize);

const InputHtml = ({
    onChange, value = '',
    disabled, container,
    maxCount, counter,
    rows, height,
    toolbar,
    theme,
    className,
    ...rest
}) => {
    const modules = {
        toolbar,
        clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false
        },
        imageResize: {
            parchment: Quill.import('parchment'),
            modules: ['Resize', 'DisplaySize']
        }
    };

    const quill = useRef();
    const isEmpty = v => v.replace(/<(.|\n)*?>/g, '')
        .trim().length === 0;

    const strippedValue = () => {
        const regex = /(<([^>]+)>)/ig;
        return value?.replace(regex, '') || '';
    };

    const handleChange = (html) => {
        if (isEmpty(html)) {
            onChange(null);
        } else if (html !== `<p>${value}</p>`) {
            onChange(html);
        }
    };

    const strippedLength = strippedValue()?.length;

    const handleOnKeyDown = (event) => {
        if (!!maxCount && strippedLength >= maxCount && event.key !== 'Backspace') event.preventDefault();
    };

    const thisClassName = `${className} ${disabled ? 'input-html-disabled' : ''}`;

    const thisHeight = height || rows * 32;
    return (
        <>
            <ReactQuill
                ref={quill}
                onKeyDown={handleOnKeyDown}
                className={thisClassName}
                style={{ height: thisHeight }}
                theme={theme}
                onChange={handleChange}
                value={value || ''}
                modules={modules}
                formats={formats}
                bounds={container}
                readOnly={disabled}
                {...rest}
            />
            {counter && (
                <div className="input-html-counter">
                    <b>{strippedLength}</b> characters
                </div>
            )}
        </>
    );
};

InputHtml.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    maxCount: PropTypes.number,
    disabled: PropTypes.bool,
    counter: PropTypes.bool,
    rows: PropTypes.number,
    container: PropTypes.string,
    height: PropTypes.string,
    toolbar: PropTypes.array,
    theme: PropTypes.string,
    className: PropTypes.string
};

InputHtml.defaultProps = {
    value: '',
    maxCount: 0,
    onChange: () => { },
    disabled: false,
    counter: false,
    rows: 3,
    height: undefined,
    container: '#admin-app',
    theme: 'bubble',
    toolbar: ToolbarDefault,
    className: 'ant-input input-html'
};

export { InputHtml, InputHtmlToolbarExtended };
