import './Form.scss';
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Form as FormBase } from 'antd';
import Block from './Block';

const Form = ({ 
    onValuesChange, form, disabled, module, 
    block, initialValues,
    children, onFinish, size, layout, ...rest 
}) => {
    const [isBlocked, setIsBlocked] = useState(false);
    const { $modules } = useSelector(state => state.user);
    const moduleIsReadonly = module ? $modules[module]?.view : false;
    
    const beforeValuesChange = (...args) => {
        setIsBlocked(true && block);
        if (onValuesChange) {
            onValuesChange(...args);
        }
    };

    const beforeFinish = (...args) => {
        setIsBlocked(false);
        if (onFinish) {
            onFinish(...args);
        }
    };

    const formReset = useCallback((fieldPath, isPath) => {
        let keysToReset = fieldPath;
        if (fieldPath && isPath) {
            keysToReset = Object.keys(form.getFieldValue(fieldPath))
            .map(key => ([...fieldPath, key]));
        }
        form.resetFields(keysToReset);
        setIsBlocked(false);
    }, [form]);
    
    useEffect(() => {
        if (form && form.resetFields) {
            // eslint-disable-next-line no-param-reassign
            form.reset = formReset;
        }
    }, [form, formReset]);
    
    /* useEffect(() => {
        if (form) {
            form.resetFields();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialValues]); */
    
    return (
        <>
            <Block
                isBlocked={isBlocked}
            />
            <FormBase
                form={form}
                layout={layout} 
                size={size}
                colon={false}
                scrollToFirstError
                hideRequiredMark={false}
                onFinish={beforeFinish}
                onValuesChange={beforeValuesChange}
                initialValues={initialValues}
                {...rest}
            >
                <fieldset disabled={disabled || moduleIsReadonly}>
                    {children}
                </fieldset>
            </FormBase>
        </>

    );
};

Form.useForm = FormBase.useForm;
Form.List = FormBase.List;

Form.propTypes = {
    onValuesChange: PropTypes.func,
    onFinish: PropTypes.func.isRequired,
    form: PropTypes.any,
    layout: PropTypes.string,
    size: PropTypes.string,
    disabled: PropTypes.bool, 
    children: PropTypes.any,
    module: PropTypes.string,
    colon: PropTypes.bool,
    block: PropTypes.bool,
    initialValues: PropTypes.object
};

Form.defaultProps = {
    onValuesChange: undefined,
    form: undefined,
    layout: 'vertical',
    size: 'middle',
    disabled: false,
    children: null,
    module: undefined,
    colon: false,
    block: true,
    initialValues: {}
};

export { Form };
