import locales from 'locales';
import { MODULE_AUTOSCREEN_STATUS } from './autoscreen';
import { MODULE_TEST_STATUS } from './test';
import { MODULE_VACCINATION_STATUS } from './vaccination';

const MODULE_PANDEMIC_STATUS_CONST = {
    OK: 0, // @DEFAULT
    HOLD: 1,
    REQUIRED: 4,  
};

export const MODULE_PANDEMIC_STATUS_VALUE = {
    OK: 'OK', 
    HOLD: 'HOLD',
    REQUIRED: 'REQUIRED',  
};

export const MODULE_PANDEMIC_STATUS = {
    OK: { 
        key: 'OK',
        value: MODULE_PANDEMIC_STATUS_CONST.OK,
        label: 'OK',
        color: '#318f6f'
    },
    HOLD: {
        key: 'HOLD',
        value: MODULE_PANDEMIC_STATUS_CONST.HOLD,
        label: 'On Hold',
        color: '#fe3b3b'
    },
    REQUIRED: {
        key: 'REQUIRED',
        value: MODULE_PANDEMIC_STATUS_CONST.REQUIRED,
        label: 'Action Required',
        color: '#FAD24D'
    },
};

export const ModulePandemicStatusOptions = Object.keys(MODULE_PANDEMIC_STATUS)
    .map(key => MODULE_PANDEMIC_STATUS[key])
    .filter(item => (typeof item.value !== 'undefined'));

export const isCovidScreenEnabled = (patient) => {
    const pandemicStatus = patient.$moduleStatus.pandemic; 
    // const autoscreenStatus = patient.$moduleStatus.autoscreen; 
    const isOnHold = pandemicStatus === MODULE_PANDEMIC_STATUS_VALUE.HOLD;
    // const isFailed = autoscreenStatus === MODULE_AUTOSCREEN_STATUS.FAILED.key;
    const disabled = isOnHold; // || isFailed
    return !disabled;
};

export const getPandemicStatusAttrs = ({ $moduleStatus, $modules }) => {
    if (!$modules || !$moduleStatus || !Object.keys($moduleStatus).length) {
        return {};
    }

    const { common: strings } = locales;
    const { 
        pandemic: pandemicStatus, 
        autoscreen: autoscreenStatus,
        test: testStatus,
        vaccination: vacciantionStatus,
    } = $moduleStatus;
    
    // HOLD
    if (pandemicStatus === MODULE_PANDEMIC_STATUS.HOLD.key) {
        return {
            label: strings.statusTitle.hold,
            icon: pandemicStatus,
        };
    } 

    // ACTIONS
    const isAutoscreenRequired = $modules.autoscreen 
        && autoscreenStatus === MODULE_AUTOSCREEN_STATUS.REQUIRED.key;
    const isTestRequired = $modules.test
        && testStatus === MODULE_TEST_STATUS.REQUIRED.key;

    const isVaccinationRequired = $modules.vaccination
        && vacciantionStatus === MODULE_VACCINATION_STATUS.VACCREQUIRED.key;

    const required = isAutoscreenRequired + isTestRequired + isVaccinationRequired;
    if (required && required > 1) {
        return {
            label: locales.formatString(
                strings.statusTitle.pendingMany, 
                { count: required }
            ),
            icon: MODULE_PANDEMIC_STATUS.REQUIRED.key
        };
    }

    if (required) {
        return {
            label: strings.statusTitle.pending,
            icon: MODULE_PANDEMIC_STATUS.REQUIRED.key
        };
    }

    return {
        label: strings.statusTitle.noPending, 
        icon: pandemicStatus?.toLowerCase()
    };
};
