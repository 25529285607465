import './profile.scss';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Button } from 'components';
import { useSelector } from 'react-redux';
import { PATIENT_STATUS } from 'modules/patient';
import { isOTPUser } from 'security';
import locales from 'locales';
import UserProfileFields from './fields';

const Profile = ({ onFinish, loading }) => {
    const [form] = Form.useForm();
    const { routes: { profile: strings } } = locales;
    
    const patient = useSelector(state => state.patient);
    const [isUserOTP, setIsUserOTP] = useState(true);

    useEffect(() => {
        async function fetchOtpUser() {
            const res = await isOTPUser();
            setIsUserOTP(res);
        }
        fetchOtpUser();
    }, []);

    const isRegister = patient.status !== PATIENT_STATUS.ACTIVE;
    const buttonLabel = isRegister ? strings.labels.button : strings.labels.buttonSave;
    
    return (
        <Form
            form={form}
            onFinish={onFinish}
            initialValues={patient}
            className="profile-form"
        >
            <UserProfileFields
                isPhoneDisabled={isUserOTP}
                isRegister={isRegister}
            />
            
            <div className="footer">
                <div className="container">
                    <Button
                        type="primary brand"
                        onClick={form.submit}
                        loading={patient.$loading || loading}
                        block
                    >
                        {buttonLabel}
                    </Button>
                </div>
            </div>
        </Form>
    );
};

Profile.propTypes = {
    onFinish: PropTypes.func.isRequired,
    loading: PropTypes.bool
};

Profile.defaultProps = {
    loading: false
};

export { Profile };
