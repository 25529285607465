import React from 'react';
import { useSelector } from 'react-redux';
import { Form, Checkbox } from 'components';
import { TermsLink, PolicyLink } from 'brands';
import locales from 'locales';
import { ValidationRules } from 'utils';
import MedicalConsentCheckbox from './medicalConsent';

const UserRegisterCheckboxes = () => {
    const { Item } = Form;
    const { routes: { profile: strings } } = locales;
    const rules = ValidationRules.translate();
    const { 
        smsSendingEnabled, 
        emailSendingEnabled,
        isMedicalConsentEnabled
    } = useSelector(state => state.location);

    const sendLocationReminders = emailSendingEnabled || smsSendingEnabled;

    return (
        <>
            <Item
                name="terms"
                rules={[rules.terms]}
                valuePropName="checked"
            >
                <Checkbox>
                    {locales.formatString(strings.labels.terms, {
                        termsLink: <TermsLink />,
                        policyLink: <PolicyLink />
                    })}
                </Checkbox>
            </Item>
            {isMedicalConsentEnabled && (
                <MedicalConsentCheckbox />
            )}
            {sendLocationReminders && (
                <Item
                    name="sendScreenReminders"
                    valuePropName="checked"
                >
                    <Checkbox>
                        {strings.labels.optin.screen}
                    </Checkbox>
                </Item>
            )}
        </>
    );
};

export default UserRegisterCheckboxes;
