import locales from 'locales';
import { now } from 'utils';
import { MODULE_VACCINATION_STATUS } from './vaccination';

export const MODULE_TEST_STATUS_CONST = {
    OK: 0, // @DEFAULT
    REQUIRED: 1,
    NEGATIVE: 2,
    POSITIVE: 3,
};

export const MODULE_TEST_STATUS = {
    OK: {
        key: 'OK',
        value: MODULE_TEST_STATUS_CONST.OK, 
        label: 'Not Required',
    },
    NEGATIVE: {
        key: 'NEGATIVE',
        value: MODULE_TEST_STATUS_CONST.NEGATIVE,
        label: 'Negative',
    },
    POSITIVE: {
        key: 'POSITIVE',
        value: MODULE_TEST_STATUS_CONST.POSITIVE,
        label: 'Positive',
    },
    REQUIRED: {
        key: 'REQUIRED',
        value: MODULE_TEST_STATUS_CONST.REQUIRED,
        label: 'Test Required',
    },
};

export const getTestStatusTitle = ({ patient, location }) => {
    const { lastTestDate, $moduleStatus: { test, vaccination } } = patient;
    const { testValidityDays, testValidityDaysForVaccinated } = location;
     
    const { common } = locales;
    
    const Status = MODULE_TEST_STATUS[test || 'OK'];
    const isVaccinated = vaccination === MODULE_VACCINATION_STATUS.VACC.key;
    const validityDays = isVaccinated ? testValidityDaysForVaccinated : testValidityDays;

    const nextTestDate = (lastTestDate || now()).add(validityDays, 'days');
    const date = nextTestDate.format('DD MMM');
    
    return locales.formatString(common.testStatus[Status.key], { date });
};

export const isTestFailed = ({ $moduleStatus }) => 
    $moduleStatus.test === MODULE_TEST_STATUS.POSITIVE.key;
