export default {
    Q_IS_ER: {
        title: 'Đây có phải trường hợp khẩn cấp không?',
        label:
        {
            HOUSEHOLD: 'Dừng lại và gọi {emergencyNumber} nếu bạn đang có những triệu chứng sau:',
            NOT_HOUSEHOLD: 'Dừng lại và gọi {emergencyNumber} nếu bạn đang có những triệu chứng sau:',
        },
        label1: 'Khó thở nghiêm trọng',
        label2: 'Đau ngực',
        label3: 'Nặng và thắt ngực',
        label4: 'Khó duy trì sự tỉnh táo',
        label5: 'Lú lẫn',
        label6: 'Môi hoặc mặt tím tái',
        options: {
            Y: {
                NOT_HOUSEHOLD: 'Tôi đang trải nghiệm ít nhất một trong những điều này.',
                HOUSEHOLD: 'Ai đó đang trải nghiệm ít nhất một trong những điều này.'
            },
            N: {
                NOT_HOUSEHOLD: 'Tôi không có bất kỳ những biểu hiện này.',
                HOUSEHOLD: 'Không ai có bất kỹ những biểu hiện này. '
            }
        },
    },
    Q_SYMPTOMS: {
        label: {
            NOT_HOUSEHOLD: 'Bạn đang có bất kỳ triệu chứng nào trong những triệu chứng này không?',
            HOUSEHOLD: 'Có ai trong gia đình bạn có bất kỳ những triệu chứng này?'
        },
        // @NEW
        banner: `When answering the following questions, please DO NOT check symptoms that are related to allergies, COPD, other chronic conditions OR recent COVID-19 vaccination.<br />
        If you have been cleared from a medical provider to stop isolation after having COVID-19 within the past 3 months and are having continued symptoms, please DO NOT check those symptoms here.`,
        options: {
            FEVER: 'Sốt ({temperature} hoặc cao hơn) hoặc ớn lạnh',
            COUGH: 'Ho',
            BREATH: 'Hơi thở ngắn hoặc khó thở (nếu bạn bị hơi thở ngắn hoặc cực kỳ khó thở), Gọi {emergencyNumber})',
            MUSCLE: 'Đau cơ bắp hoặc đau toàn thân; HOẶC mệt mỏi liên quan đến bệnh lý',
            HEADACHE: 'Nhức đầu',
            TASTE: 'Mất vị giác hoặc khứu giác',
            THROAT: 'Đau họng',
            NOSE: 'Nghẹt mũi hoặc chảy nước mũi',
            NAUSEA: 'Buồn ói hoặc ói mửa',
            DIARRHEA: 'Tiêu chảy',
            NONE: {
                NOT_HOUSEHOLD: 'Tôi không có bất kỳ triệu chứng nào nói trên',
                HOUSEHOLD: 'Không ai có những triệu chứng này'
            }
        },
        labelNoSpecificSymptoms: {
            NOT_HOUSEHOLD: `Bạn có gặp phải bất kỳ triệu chứng COVID-19 nào không, chẳng hạn như:
                <ul>
                    <li>Sốt hoặc ớn lạnh </li>
                    <li>Ho </li> 
                    <li>Hơi thở ngắn hoặc khó thở </li>
                    <li>Đau cơ bắp hoặc đau toàn thân; HOẶC mệt mỏi liên quan đến bệnh lý </li>
                    <li>Nhức đầu </li>
                    <li>Mất vị giác hoặc khứu giác</li> 
                    <li>Đau họng </li>
                    <li>Nghẹt mũi hoặc chảy nước mũi</li>
                    <li>Buồn ói hoặc ói mửa</li>
                    <li>Tiêu chảy </li>
                </ul>`,
            HOUSEHOLD: `Có ai trong gia đình bạn gặp phải bất kỳ triệu chứng COVID-19 nào không, chẳng hạn như:
                <ul>
                    <li>Sốt hoặc ớn lạnh</li>
                    <li>Ho</li> 
                    <li>Hơi thở ngắn hoặc khó thở</li>
                    <li>Đau cơ bắp hoặc đau toàn thân; HOẶC mệt mỏi liên quan đến bệnh lý</li>
                    <li>Nhức đầu</li>
                    <li>Mất vị giác hoặc khứu giác</li> 
                    <li>Đau họng</li>
                    <li>Nghẹt mũi hoặc chảy nước mũi</li>
                    <li>Buồn ói hoặc ói mửa</li>
                    <li>Tiêu chảy</li>
                </ul>`
        },
        labelNoSpecificSymptoms_hideCongestion: {
            NOT_HOUSEHOLD: `Bạn có gặp phải bất kỳ triệu chứng COVID-19 nào không chẳng hạn như:
                <ul>
                    <li>Sốt hoặc ớn lạnh</li>
                    <li>Ho</li> 
                    <li>Hơi thở ngắn hoặc khó thở</li>
                    <li>Đau cơ bắp hoặc đau toàn thân; HOẶC mệt mỏi liên quan đến bệnh lý</li>
                    <li>Nhức đầu</li>
                    <li>Mất vị giác hoặc khứu giác</li> 
                    <li>Đau họng</li>
                    <li>Buồn ói hoặc ói mửa</li>
                    <li>Tiêu chảy</li>
                </ul>`,
            HOUSEHOLD: `Có ai trong gia đình bạn gặp phải bất kỳ triệu chứng COVID-19 nào không, chẳng hạn như: 
                <ul>
                    <li>Sốt hoặc ớn lạnh</li>
                    <li>Ho</li> 
                    <li>Hơi thở ngắn hoặc khó thở</li>
                    <li>Đau cơ bắp hoặc đau toàn thân; HOẶC mệt mỏi liên quan đến bệnh lý</li>
                    <li>Nhức đầu</li>
                    <li>Mất vị giác hoặc khứu giác</li> 
                    <li>Đau họng</li>
                    <li>Buồn ói hoặc ói mửa</li>
                    <li>Tiêu chảy</li>
                </ul>`
        },
        optionsNoSpecificSymptoms: {
            Y: 'Có Yes',
            N: 'Không No'
        },
    },
    Q_EXPOSURE: {
        label: {
            NOT_HOUSEHOLD: `In the past {exposureDays} days, have you been within 6ft of any individuals confirmed to 
            have COVID-19, beginning 48 hours prior to them becoming infectious, for a combined duration of 15 minutes in a 24hr period, with or without a mask?`, // @NEW
            HOUSEHOLD: `In the past {exposureDays} days, has anyone in your household been within 6ft of any individuals confirmed 
            to have COVID-19, beginning 48 hours prior to them becoming infectious, for a combined duration of 15 minutes in a 24hr period, with or without a mask?`, // @NEW
            HCP: `Have you had close contact (less than 6 feet) with anyone with confirmed COVID-19, beginning 48 hours prior to them becoming infectious, 
            (in or outside the facility) or any person under investigation with lab 
            results not expected back within 72 hours?` // @NEW
        },
        options: {
            Y: 'Có',
            N: 'Không'
        },
    },
    Q_SYMPTOM_DATE: {
        label: 'Các triệu chứng lần đầu tiên xuất hiện vào ngày nào? Nếu không chắc, hãy đưa ra ước tính tốt nhất của bạn.'
    },
    Q_EXPOSURE_DATE: {
        label: 'Ngày gần nhất có khả năng tiếp xúc là ngày nào? Nếu không chắc, hãy đưa ra ước tính tốt nhất của bạn.'
    },
    Q_EXPOSURE_HCP1: {
        label: 'Bạn có thực hiện quy trình tạo khí dung mà không có TẤT CẢ thiết bị Bảo hộ Cá nhân PPE cần thiết (áo choàng, găng tay, kính bảo vệ mắt và mặt nạ phòng độc)?',
        options: {
            Y: 'Có',
            N: 'Không'
        }
    },
    Q_EXPOSURE_HCP2: {
        label: 'Bạn có tiếp xúc kéo dài (khoảng từ 15 phút trở lên) trong khoảng 24 giờ không?',
        options: {
            Y: 'Có',
            N: 'Không'
        }
    },
    Q_EXPOSURE_HCP3: {
        label: 'Bạn có đeo khẩu trang hay mặt nạ phòng độc không?',
        options: {
            Y: 'Có',
            N: 'Không'
        }
    },
    Q_EXPOSURE_HCP4: {
        label: 'Bạn có đeo kính bảo vệ mắt khi tiếp xúc với người dương tính COVID-19 mà họ không đeo khăn che mặt hoặc khẩu trang không?',
        options: {
            Y: 'Có',
            N: 'Không'
        }
    },
    Q_TEMPERATURE: {
        label: {
            NOT_HOUSEHOLD: 'Nhiệt độ của bạn lúc này là bao nhiêu?',
            HOUSEHOLD: 'Nhiệt độ cao nhất mà ai đó có trong gia đình bạn hiện tại là bao nhiêu?'
        },
    },
    Q_PROTECTION: { 
        // @NEW
        label: {
            NOT_HOUSEHOLD: 'Please select the first option in the list below that applies to you:',
            HOUSEHOLD: 'Please select the first option in the list below that applies to you:'
        },
        // @NEW
        options: {
            CURRENT: 'VACCINATION STATUS CURRENT (completed primary series of Pfizer/Moderna within 6 months, J&J within 2 months, or boosted)',
            POSITIVE: 'COVID-19 positive in the last 90 days',
            NOT_CURRENT: 'VACCINATION STATUS NOT CURRENT (unvaccinated, OR not boosted and completed primary series of Pfizer/Moderna more than 6 months ago, J&J more than 2 months ago)',
        }
    }
};
