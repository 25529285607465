import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, ListItem } from 'components';
import { PatientActionsUser } from 'modules/patient';
import locales from 'locales';
import { Link, homePath } from 'routing';
import iconNotif from 'apps/lintelio/assets/icons/ico-notification.svg';
import iconSMSNotif from 'apps/lintelio/assets/icons/ico-sms.svg';
import iconShedule from 'apps/lintelio/assets/icons/ico-calendar.svg';

const icons = {
    reminders: <div className="icon small lila"><img src={iconNotif} alt="Reminders" /></div>,
    smsreminders: <div className="icon small"><img src={iconSMSNotif} alt="SMS Reminders" /></div>,
    emailreminders: <div className="icon small"><img src={iconNotif} alt="Email Reminders" /></div>,
    emailschedule: <div className="icon small"><img src={iconShedule} alt="Email Schedule" /></div>,
};

const ReminderMenuItems = ({ onClose }) => {
    const { common: { menu: strings } } = locales;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const dispatch = useCallback(useDispatch(), []);

    const {
        smsSendingEnabled,
        emailSendingEnabled,
        $modules
    } = useSelector(state => state.location);

    const {
        sendScreenReminders,
        reminders, email
    } = useSelector(state => state.patient);

    const [loadingReminders, setLoadingReminders] = useState(false);

    const emailRemindersEnabled = emailSendingEnabled && email;

    const locationScreeningRemindersAllowed = (smsSendingEnabled || emailRemindersEnabled)
        && $modules.autoscreen;

    useEffect(() => {
        if (locationScreeningRemindersAllowed) {
            dispatch(PatientActionsUser.reminders());
        }
    }, [dispatch, locationScreeningRemindersAllowed]);
    
    const onSendScreenRemindersChange = (value) => {
        setLoadingReminders(true);
        dispatch(PatientActionsUser.update({ sendScreenReminders: value }))
            .finally(() => setLoadingReminders(false));
    };

    const screenLabel = reminders.screen.length === 1
        ? `${reminders.screen.length} ${strings.scheduledOne}`
        : `${reminders.screen.length} ${strings.scheduled}`;

    if (!locationScreeningRemindersAllowed) {
        return null;
    }

    return (
        <>
            <div className="title">
                {strings.reminders}
            </div>
            <ListItem
                suffix={(
                    <Switch
                        value={sendScreenReminders}
                        onChange={onSendScreenRemindersChange}
                        loading={loadingReminders}
                        title="Switch Reminders"
                    />
                )}
            >
                {strings.screenReminders}
            </ListItem>
            {sendScreenReminders && (
                <ListItem
                    arrow
                    onClick={onClose}
                    prefix={icons.reminders}
                >
                    <Link to={`${homePath}reminders/screen`}>
                        {screenLabel}
                    </Link>

                </ListItem>
            )}
        </>
    );
};

ReminderMenuItems.propTypes = {
    onClose: PropTypes.func.isRequired,
};

export default ReminderMenuItems;
