import { Timezones } from 'constants';
import { MODULE_TEST, MODULE_SCREEN } from 'modules/module';

const SEPT = '-';
const SEPH = '|';

const transformRemindersIn = (res) => {
    const { data, timeZone } = res; 
    const sortedData = data.map(item => ({
        ...item, 
        type: Array.isArray(item.type) ? item.type.sort((a, b) => (a > b ? 1 : -1)) : []
    }));

    const uniqueFilter = (item, idx, self) => self.indexOf(item) === idx;

    const uniqueField = item => 
        item.timeLocal + SEPH 
        + item.type.join(SEPT) + SEPH 
        + item.daysBefore + SEPH 
        + item.module;

    const getDays = (time, types) => sortedData
        .filter(item => 
            item.type.join(SEPT) === types && item.timeLocal === time)
        .map(it => it.dayLocal);

    const all = sortedData
        .map(uniqueField)
        .filter(uniqueFilter)
        .map((item, idx) => {
            const [time, types, daysBefore, module] = item.split(SEPH);
            const days = getDays(time, types);
            return {
                key: idx,
                time: time.substr(0, 5), 
                types: types ? types.split(SEPT) : [],
                days,
                daysBefore: daysBefore ? parseInt(daysBefore, 10) : null,
                module: parseInt(module, 10)
            };
        });
    
    const screen = all.filter(item => item.module === MODULE_SCREEN);
    const test = all.filter(item => item.module === MODULE_TEST);
    const timezoneName = Timezones[timeZone] || timeZone;
    
    return {
        screen,
        test,
        timeZone,
        timezoneName,
    };
};

export default transformRemindersIn;
