/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'components';
import { PatientActionsUser } from 'modules/patient';
import { ScreeningActions } from 'modules/screening';
import locales from 'locales';
import PropTypes from 'prop-types';
import { useNavigate } from 'routing';
import { Wrapper, BackButton } from 'apps/lintelio/layout';
import renderQuickQuestions from './questions';
import QuickScreenBanner from './banner';

const QuickAutoscreening = ({ setIsQuick }) => {
    const { routes: { screening: strings } } = locales;
    const navigate = useNavigate();
    const { current, questions } = useSelector(state => state.screening); 
    const { quickScreening } = useSelector(state => state.patient); 
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [confirmPageLeave, setConfirmPageLeave] = useState(false);
    const { createdAt, id: screeningId } = quickScreening;

    useEffect(() => {
        if (confirmPageLeave) {
            navigate(confirmPageLeave);
            setConfirmPageLeave(false);
        }
    }, [confirmPageLeave, navigate]);
    
    const cancel = () => dispatch(ScreeningActions.cancel());

    if (!current) return null;

    const hideQuick = () => {
        setIsQuick(false);
    };

    const submitQuick = async () => {
        const data = { screeningId };
        setLoading(true);
        await dispatch(ScreeningActions.quickScreen(data));
        await dispatch(PatientActionsUser.get());
        setLoading(false);
    };
    
    const ButtonCancel = (
        <Button
            type="link right"
            onClick={cancel}
        >
            {strings.questions.cancel}
        </Button>
    );

    const ButtonQuickSubmit = (
        <Button
            type="primary brand"
            block
            onClick={submitQuick}
            loading={loading}
        >
            {strings.quickScreening.submit}
        </Button>

    );

    const ButtonQuickCancel = (
        <Button
            type="link"
            block
            onClick={hideQuick}
        >
            {strings.quickScreening.cancel}
        </Button>
    );

    const wrapperProps = {
        title: strings.quickScreening.title,
        left: <BackButton disabled />,
        right: ButtonCancel,
        footer: (
            <>
                {ButtonQuickSubmit}
                {ButtonQuickCancel}
            </>
        ),
        className: 'quick'
    };

    return (
        <Wrapper {...wrapperProps}>
            <QuickScreenBanner createdAt={createdAt} />

            <div className="questions">
                {
                    renderQuickQuestions(quickScreening, questions)
                }
            </div>
        </Wrapper>
    );
};

QuickAutoscreening.propTypes = {
    setIsQuick: PropTypes.func.isRequired
};

export default QuickAutoscreening;
