export default {
    userConsent: 'Contenido de usuario',
    header: 'Optar por la integración de pruebas externas',
    text: `Lorem Ipsum es simplemente texto de relleno de la industria de la impresión y la composición tipográfica.
        Lorem Ipsum ha sido el texto de relleno estándar de la industria desde el siglo XVI.
        cuando un impresor desconocido tomó una galera de tipos y la mezcló para hacer un libro de muestras tipográficas.
        Ha sobrevivido no solo a cinco siglos, sino también al salto a la composición tipográfica electrónica,
        permaneciendo esencialmente sin cambios. Se popularizó en la década de 1960 con el lanzamiento de Letraset.
        hojas que contienen pasajes de Lorem Ipsum y, más recientemente, con software de autoedición como
         Aldus PageMaker que incluye versiones de Lorem Ipsum.”`,
    employeeNumber: 'Número de Identificación',
    employeeNumberTooltip: 'Esta es su identificación de empleado, identificación de estudiante u otro número de identificación asignado por la organización que le pide que realice la prueba..',
    personalInfo: 'Porfavor revise su informacion personal',
    consent: 'Yo doy consentimiento',
    noConsent: 'No doy consentimiento',
    close: 'Cerrar',
    success: 'Opcion exitosa!',
    testFormBanner: 'Presione aquí para suscribirse al acuerdo de integración de pruebas externas.',
};
