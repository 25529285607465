import ListTransformer from './list';
import transformPatientIn from './in';
import transformPatientOut from './out';
import transformPatientOutBulk from './outBulk';

export default {
    in: transformPatientIn,
    out: transformPatientOut,
    list: ListTransformer,
    outBulk: transformPatientOutBulk
};
