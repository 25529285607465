/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showError } from 'utils';
import { PatientServiceAdmin } from '../patient.service.admin';
import { PatientConstants } from '../patient.constants';
import { LocationService } from '../../location/location.service';

export const usePatient = () => {
    const patient = useSelector(state => state.patient);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(patient);

    const clearMethod = () => dispatch(({ type: PatientConstants.CLEAR }));

    const fetchMethod = (id, getLocation = true) => {
        setLoading(true);
        return PatientServiceAdmin.get(id)
            .then(async (res) => {
                let location;
                if (getLocation) {
                    location = await LocationService.get(res.locationId);
                }
                dispatch(({ type: PatientConstants.LOADED, data: { ...res, location } }));
                return res;
            })
            .then(setData)
            .catch((err) => {
                showError(err);
                return Promise.reject(err);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const stableFetch = useCallback(fetchMethod, []);
    const stableClear = useCallback(clearMethod, []);
    return [stableFetch, stableClear, { data, loading }];
};
