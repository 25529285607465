/* eslint-disable class-methods-use-this */
import { api, ApiHeaders } from 'services';
import Transformer from './transformers';

const ApiBaseUrl = process.env.REACT_APP_API;

class UserServiceClass {
    id;

    setVars = (data = {}) => {
        const { id } = data;
        if (id) {
            this.id = id;
        }
        return data;
    };

    me() {
        return api.get('/me')
            .then(Transformer.in)
            .then(this.setVars);
    }

    get(id) {
        return api.get(`/users/${id}`)
            .then(Transformer.in);
    }

    update(id, data) {
        return api.put(`/users/${id}`, Transformer.out(data))
            .then(Transformer.in);
    }

    create(data) {
        return api
            .post('/users', Transformer.out(data))
            .then(Transformer.in);
    }

    list(...args) {
        return api.list('/users', ...args)
            .then(Transformer.list);
    }

    alertList() {
        return api.get('/users/alertList')
            .then(({ data }) => data);
    }

    sendInvitation(id) {
        return api.post(`/users/${id}/invite`);
    }

    resendInvitation(id) {
        return api.post(`/users/${id}/resend`);
    }

    delete(id) {
        return api.delete(`/users/${id}`)
            .catch((e) => {
                // Skip user not in Cognito message
                if (e && e.message && !e.message.includes('UserNotFoundException')) {
                    throw e;
                }
            });
    }

    // users verification
    availability({ phone }) {
        return api
            .post('/users/availability', { phone });
    }

    checkMerge({ phone }) {
        return api
            .get(`/users/merging?phone=${encodeURIComponent(phone)}`);
    }

    merge(data) {
        return api
            .put('/users/merging', data);
    }

    async import() {
        const token = await api.token();
        return {
            action: `${ApiBaseUrl}users/bulk`,
            method: 'POST',
            showUploadList: false,
            headers: {
                Authorization: `Bearer ${token}`,
                ...ApiHeaders
            },
            name: 'file',
            previewFile: () => null,
        }; 
    }
}

const UserService = new UserServiceClass();
export { UserService }; 
