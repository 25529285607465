import ReactGA from 'react-ga';

const Version = process.env.REACT_APP_VERSION;

const SW_CATEGORY = 'service_worker';
const ADMIN_CATEGORY = 'ADMINISTRATION';
const CLIENT_CATEGORY = 'LINTELIO';

export const GA = {
    sendSWEvent: (action) => {
        ReactGA.event({
            category: SW_CATEGORY,
            label: Version,
            action, // Version
        });
    },
    sendAdminEvent: (action, module) => {
        ReactGA.event({
            category: ADMIN_CATEGORY,
            label: module,
            action,
        });
    },
    sendClientEvent: (action, module) => {
        ReactGA.event({
            category: CLIENT_CATEGORY,
            label: module,
            action,
        });
    },
    version: () => {
        ReactGA.set({ ver_dimension: Version });
        // window.gtag('event', 'ver_dimension', { Version });
    }
};
