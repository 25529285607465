/* eslint-disable max-len */
import locales from 'locales';
import { HIDDEN_QUESTION_GROUPS } from 'modules/hiddenQuestions';
import { AUTOCREENING_RESULT_CONST } from '../../screening.model';
import { PROTECTION_TYPES } from './helpers';

export default (args) => {
    const { models: { screening: { results: strings } } } = locales;

    const { 
        flowType, 
        ignoreExposure,
        params, 
    } = args;

    const ExposureExitKey = ignoreExposure 
        ? AUTOCREENING_RESULT_CONST.VACCINATED_WITH_EXPOSURE : 'FAIL';
    
    return {
        R_ER: {
            key: 'ER', 
            icon: 'ER',
            isExit: true,
            title: locales.formatString(strings.titles.er, params),
            label: `<p class="ta-center">${strings.er[flowType]}</p>`,
            group: HIDDEN_QUESTION_GROUPS.ER
        },
        R_NURSE: { 
            key: 'RN', 
            icon: 'NURSE',
            isExit: true,
            label: strings.nurse,
            group: HIDDEN_QUESTION_GROUPS.TRIAGE
        },
        R_CLEARED: {
            key: 'PASS', 
            icon: 'PASS',
            isExit: true,
            title: strings.titles.pass,
            label: strings.cleared[flowType]
        },
        R_ISOLATE_SYMPTOMS_HOUSEHOLD_OR_EVENT: {
            [PROTECTION_TYPES.CURRENT]: { 
                key: 'FAIL', 
                icon: 'ISOLATE',
                isExit: true,
                title: strings.titles.isolate,
                label: locales.formatString(strings.isolateSymptomsHOUSEHOLD_CURRENT + strings.isolateBanner, params),
                group: HIDDEN_QUESTION_GROUPS.SYMPTOMS,
            },
            [PROTECTION_TYPES.POSITIVE]: { 
                key: 'FAIL', 
                icon: 'ISOLATE',
                isExit: true,
                title: strings.titles.isolate,
                label: locales.formatString(strings.isolateSymptomsHOUSEHOLD_POSITIVE + strings.isolateBanner, params),
                group: HIDDEN_QUESTION_GROUPS.SYMPTOMS,
            },
            [PROTECTION_TYPES.NOT_CURRENT]: { 
                key: 'FAIL', 
                icon: 'ISOLATE',
                isExit: true,
                title: strings.titles.isolate,
                label: locales.formatString(strings.isolateSymptomsHOUSEHOLD_NOT_CURRENT + strings.isolateBanner, params),
                group: HIDDEN_QUESTION_GROUPS.SYMPTOMS,
            },
        },
        R_ISOLATE_SYMPTOMS_COMMUNITY: {
            [PROTECTION_TYPES.CURRENT]: { 
                key: 'FAIL', 
                icon: 'ISOLATE',
                isExit: true,
                title: strings.titles.isolate,
                label: locales.formatString(strings.isolateSymptomsCOMMUNITY_CURRENT + strings.isolateBanner, params),
                group: HIDDEN_QUESTION_GROUPS.SYMPTOMS,
            },
            [PROTECTION_TYPES.POSITIVE]: { 
                key: 'FAIL', 
                icon: 'ISOLATE',
                isExit: true,
                title: strings.titles.isolate,
                label: locales.formatString(strings.isolateSymptomsCOMMUNITY_POSITIVE + strings.isolateBanner, params),
                group: HIDDEN_QUESTION_GROUPS.SYMPTOMS,
            },
            [PROTECTION_TYPES.NOT_CURRENT]: { 
                key: 'FAIL', 
                icon: 'ISOLATE',
                isExit: true,
                title: strings.titles.isolate,
                label: locales.formatString(strings.isolateSymptomsCOMMUNITY_NOT_CURRENT + strings.isolateBanner, params),
                group: HIDDEN_QUESTION_GROUPS.SYMPTOMS,
            },
        },
        R_ISOLATE_SYMPTOMS_HCP: {
            key: 'FAIL', 
            icon: 'ISOLATE',
            isExit: true,
            title: strings.titles.isolate,
            label: locales.formatString(strings.isolateSymptomsHCP, params),
            group: HIDDEN_QUESTION_GROUPS.SYMPTOMS,
        },
        R_ISOLATE_SYMPTOMS_DEFAULT: {
            key: 'FAIL', 
            icon: 'ISOLATE',
            isExit: true,
            title: strings.titles.isolate,
            label: locales.formatString(strings.isolateSymptomsDEFAULT, params),
            group: HIDDEN_QUESTION_GROUPS.SYMPTOMS,
        },
        R_ISOLATE_EXPOSURE_HOUSEHOLD: {
            [PROTECTION_TYPES.CURRENT]: { 
                key: AUTOCREENING_RESULT_CONST.VACCINATED_WITH_EXPOSURE, 
                icon: 'CAUTION',
                isExit: true,
                title: strings.titles.caution, 
                label: locales.formatString(strings.isolateExposureHOUSEHOLD_CURRENT + strings.isolateBanner, params),
                group: HIDDEN_QUESTION_GROUPS.EXPOSURE,
            },
            [PROTECTION_TYPES.POSITIVE]: { 
                key: AUTOCREENING_RESULT_CONST.VACCINATED_WITH_EXPOSURE, 
                icon: 'CAUTION',
                isExit: true,
                title: strings.titles.caution, 
                label: locales.formatString(strings.isolateExposureHOUSEHOLD_POSITIVE + strings.isolateBanner, params),
                group: HIDDEN_QUESTION_GROUPS.EXPOSURE,
            },
            [PROTECTION_TYPES.NOT_CURRENT]: { 
                key: ExposureExitKey, 
                icon: 'ISOLATE',
                isExit: true,
                title: strings.titles.isolate,
                label: locales.formatString(strings.isolateExposureHOUSEHOLD_NOT_CURRENT + strings.isolateBanner, params),
                group: HIDDEN_QUESTION_GROUPS.EXPOSURE,
            },
        },
        R_ISOLATE_EXPOSURE_COMMUNITY: {
            [PROTECTION_TYPES.CURRENT]: { 
                key: AUTOCREENING_RESULT_CONST.VACCINATED_WITH_EXPOSURE, 
                icon: 'CAUTION',
                isExit: true,
                title: strings.titles.caution, 
                label: locales.formatString(strings.isolateExposureCOMMUNITY_CURRENT + strings.isolateBanner, params),
                group: HIDDEN_QUESTION_GROUPS.EXPOSURE,
            },
            [PROTECTION_TYPES.POSITIVE]: { 
                key: AUTOCREENING_RESULT_CONST.VACCINATED_WITH_EXPOSURE, 
                icon: 'CAUTION',
                isExit: true,
                title: strings.titles.caution,
                label: locales.formatString(strings.isolateExposureCOMMUNITY_POSITIVE + strings.isolateBanner, params),
                group: HIDDEN_QUESTION_GROUPS.EXPOSURE,
            },
            [PROTECTION_TYPES.NOT_CURRENT]: { 
                key: ExposureExitKey, 
                icon: 'ISOLATE',
                isExit: true,
                title: strings.titles.isolate,
                label: locales.formatString(strings.isolateExposureCOMMUNITY_NOT_CURRENT + strings.isolateBanner, params),
                group: HIDDEN_QUESTION_GROUPS.EXPOSURE,
            },
        },
        R_ISOLATE_EXPOSURE_HCP: {
            key: ExposureExitKey, 
            icon: 'ISOLATE',
            isExit: true,
            title: strings.titles.isolate,
            label: locales.formatString(strings.isolateExposureHCP, params),
            group: HIDDEN_QUESTION_GROUPS.EXPOSURE,
        },
        R_ISOLATE_EXPOSURE_DEFAULT: { 
            key: ExposureExitKey, 
            icon: 'ISOLATE',
            isExit: true,
            title: strings.titles.isolate,
            label: locales.formatString(strings.isolateExposureDEFAULT, params),
            group: HIDDEN_QUESTION_GROUPS.EXPOSURE,
        }
    };
};
