import './Button.scss';
import { Button as ButtonBase } from 'antd';
import { ModulesConfig } from 'apps/administration/constants';

import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

const Button = ({ disabled, module, ...rest }) => {
    const { $modules } = useSelector(state => state.user);
    const moduleIsReadonly = module ? $modules[module]?.view : false;
    if (module && !$modules[module]) {
        return null;
    }
    return (
        <ButtonBase
            {...rest}
            disabled={disabled || moduleIsReadonly}
        />
    );
};

Button.propTypes = {
    disabled: PropTypes.bool,
    module: PropTypes.oneOf(Object.values(ModulesConfig).map(item => item.module)),
};

Button.defaultProps = {
    disabled: false,
    module: undefined
};

export { Button };
