import './Menu.scss';
import React from 'react';
import PropTypes from 'prop-types';

import MenuMUI from '@mui/material/Menu';
import MenuItemMUI from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';

const Menu = ({ children, className, maskClosable, anchor: Anchor }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const onClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const onClose = () => {
        setAnchorEl(null);
    };

    const onMenuItemClick = onClickDefault => () => {
        if (onClickDefault) {
            onClickDefault();
            onClose();
        }
    };

    const childrenWithKeys = children
        .filter(item => !!item)
        .map((item, index) => ({
            ...item, key: index
        }));

    return (
        <>
            <MenuMUI
                anchorEl={anchorEl}
                className={`c-menu ${className}`}
                open={open}
                onClose={onClose}
                onBackdropClick={maskClosable ? onClose : null}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {childrenWithKeys.map(item => (
                    <item.type
                        key={item.key}
                        {...item.props}
                        onClick={onMenuItemClick(item.props?.onClick)}
                    >{item.props?.children}
                    </item.type>
                ))}

            </MenuMUI>
            <Anchor onClick={onClick} />
        </>
    );
};

Menu.propTypes = {
    children: PropTypes.array.isRequired,
    className: PropTypes.string,
    maskClosable: PropTypes.bool,
    cancelButton: PropTypes.bool,
    anchor: PropTypes.any.isRequired,
};

Menu.defaultProps = {
    className: '',
    maskClosable: true,
    cancelButton: true,
};

const MenuItem = ({ prefix, children, ...rest }) => (
    <MenuItemMUI {...rest}>
        {prefix && (
            <ListItemIcon>
                {prefix}
            </ListItemIcon>
        )}
        {children}
    </MenuItemMUI>
);

MenuItem.propTypes = {
    prefix: PropTypes.object,
    children: PropTypes.any.isRequired,
};

MenuItem.defaultProps = {
    prefix: null,
};

export { Menu, MenuItem };
