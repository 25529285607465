import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Spin, Select } from 'apps/administration/components';
import { PatientServiceAdmin } from 'modules/patient';
import { UserOutlined } from '@ant-design/icons';
import { debounce } from 'utils';

const debounseMs = 1000;

const DropdownContent = ({ loading }) => (loading
    ? <Spin spinning className="center" />
    : <>No data found</>);

DropdownContent.propTypes = {
    loading: PropTypes.bool.isRequired
};

const UserAutocomplete = (props) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const { withLocation, ...rest } = props;

    const getUsers = (value, callback) => {
        const filters = { 'fullName|email|phone': { value, pattern: 'LIKE' } };
    
        return PatientServiceAdmin.list(1, 500, filters)
            .then(res => callback(res.data));
    };
    
    const debouncedSearch = debounce(
        (str) => {
            setLoading(true);
            getUsers(str, setData)
                .finally(() => setLoading(false));
        },
        debounseMs
    );

    const onSearch = (str) => {
        if (str && str.length > 2) {
            debouncedSearch(str);
        }
    };

    const userOptions = data.map(item => ({
        label: item.fullName + (withLocation ? ` (${item.locationTitle})` : ''),
        value: item.id
    }));

    const placeholder = (
        <>
            <UserOutlined />
            Search by Name, Email or Phone number
        </>
    );

    return (
        <Select
            className="user-autocomplete"
            placeholder={placeholder}
            showSearch
            defaultActiveFirstOption={false}
            onSearch={onSearch}
            notFoundContent={<DropdownContent loading={loading} />}
            showArrow={false}
            filterOption={false}
            popupMatchSelectWidth={false}
            prefix={<UserOutlined />}
            options={userOptions}
            getPopupContainer={null}
            {...rest}
        />
    );
};

UserAutocomplete.propTypes = {
    withLocation: PropTypes.bool
};

UserAutocomplete.defaultProps = {
    withLocation: false
};

export { UserAutocomplete };
