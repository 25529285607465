/* eslint-disable no-console */
import React from 'react';
import socketio from 'socket.io-client';

const PROCTOR_SESSION_EVENT = 'proctoringSession';

const socketUrl = process.env.REACT_APP_SOCKET_ENDPOINT;

/* to use with token 
    const getSocket = () => {
        const token = getAuthToken(); // get token from local storage 
        if (token) {
            return socketio.connect(socketUrl, {
                query: { token }
            });
        }
        return socketio.connect(socketUrl);
    };
*/

const socket = socketUrl ? socketio(socketUrl, {
    path: '/socket.io',
    port: 8080,
    autoConnect: false,
    transports: ['websocket'],
    'connect timeout': 5000,
    secure: true,
    reconnection: !!socketUrl,
    rejectUnauthorized: false
}) : { on: () => {} };

socket.on('connect_error', console.error);

if (!!socketUrl) {
    socket.connect();
}

const SocketContext = React.createContext();

export {
    socket,
    SocketContext,
    PROCTOR_SESSION_EVENT
};
