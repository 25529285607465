import './sideMenu.scss';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const MenuItemKeys = [
    'first',
    'second',
    'third',
];

const SlideContainer = ({ children, current, onChange }) => {
    const [active, setActive] = useState();
    
    useEffect(() => {
        if (current) {
            setActive(current);
        }
    }, [current]);

    useEffect(() => {
        if (active) {
            onChange(active);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [active]);

    const childrenWithProps = React.Children.map(children, (child, idx) => {
        const props = {
            key: MenuItemKeys[idx],
            onBack: () => setActive(MenuItemKeys[idx - 1]),
            onNext: () => setActive(MenuItemKeys[idx + 1])
        };
        return (
            <div className={`m-item m-item-${MenuItemKeys[idx]}`}>
                {React.cloneElement(child, props)}
            </div>
        );
      });
    
    const style = { gridTemplateColumns: `repeat(${children.length}, 100%)` };

    return (
        <div 
            className={`slide-container ${active} `}
            style={style}
        >
            {childrenWithProps}
        </div>
    );
};

SlideContainer.propTypes = {
    children: PropTypes.array.isRequired,
    current: PropTypes.any,
    onChange: PropTypes.func,
};

SlideContainer.defaultProps = {
    current: MenuItemKeys[0],
    onChange: () => {}
};

export { SlideContainer };
