import { APIT } from 'utils';

const transformList = (data) => {
    if (data && !data.data) {
        return data;
    }
    return {
        ...data,
        data: data.data.map(item => ({
            ...item,
            screeningTime: APIT.date.in.toLocal(item.screeningTime)
        }))
    };
};

export default transformList;
