import './moduleInfo.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Spin, Button } from 'apps/administration/components';
import { EditableContent } from 'apps/administration/containers';
import { SETTINGS_KEYS } from 'modules/settings';
import { ModulesConfig } from 'apps/administration/constants';
import { history } from 'routing';
import EditableContentTab from './EditableContentTab';

const ModuleInfo = ({ module }) => {
    const { $isSysAdmin, $locationModules } = useSelector(state => state.user);
    
    const Module = ModulesConfig[module];
    const userHasAccess = $locationModules[module];
    const { hasContent } = Module;
    const activeModule = SETTINGS_KEYS[`${module.toUpperCase()}_ACTIVE`];
    const inactiveModule = SETTINGS_KEYS[`${module.toUpperCase()}`];

    const onClick = () => {
        history.push('/support', { about: Module.title, config: 'module' });
    };

    const checkIfSysAdmin = () => (
        $isSysAdmin ? (
            <EditableContentTab
                options={[
                    {
                        editable: $isSysAdmin,
                        tab: 'Active Content',
                        onClick,
                        name: activeModule,
                        key: `${module}-active-content`,
                        module
                    },
                    {
                        editable: $isSysAdmin,
                        tab: 'Inactive Content',
                        onClick,
                        name: inactiveModule,
                        key: `${module}-inactive-content`,
                        module
                    }
                ]}
            />
        )
            : (
                <div className={`page-scroll module-info module-info-${module}`}>
                    <EditableContent
                        name={userHasAccess ? activeModule : inactiveModule}
                        editable={$isSysAdmin}
                        footer={(
                            <Button
                                type="primary"
                                onClick={onClick}
                            >
                                Contact Us
                            </Button>
                        )}
                    />
                </div>
            )
    );

    const generateTabs = () => (
        hasContent ? (
            <div className={`page-scroll module-info module-info-${module}`}>
                <EditableContent
                    name={activeModule}
                    editable={$isSysAdmin}
                    footer={!$isSysAdmin ? (
                        <Button
                            type="primary"
                            onClick={onClick}
                        >
                            Contact Us
                        </Button>
                    ) : null}
                />
            </div>
        ) : checkIfSysAdmin()
    );

    return (
        <Spin spinning={false}>
            <div className="page-header">
                <h1>{Module.title} Module</h1>
            </div>

            {userHasAccess && !$isSysAdmin ? (
                <div className={`page-scroll module-info module-info-${module}`}>
                    <EditableContent
                        name={activeModule}
                        editable={$isSysAdmin}
                        footer={(
                            <Button
                                type="primary"
                                onClick={onClick}
                            >
                                Contact Us
                            </Button>
                        )}
                    />
                </div>
            ) : generateTabs()}

        </Spin>
    );
};

ModuleInfo.propTypes = {
    module: PropTypes.string.isRequired
};

export default ModuleInfo;
