import './Switch.scss';
import React from 'react';
import PropTypes from 'prop-types';
import SwitchMUI from '@mui/material/Switch';

export const Switch = ({ value, onChange, loading, title, ...rest }) => {
    const handleChange = () => {
        onChange(!value);
    };
    return (
        <SwitchMUI
            checked={value}
            onChange={handleChange}
            className="c-switch"
            disabled={loading}
            inputProps={{ 'aria-label': title }}
            {...rest}
        />
    );
};

Switch.propTypes = {
    loading: PropTypes.bool,
    title: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.bool
};

Switch.defaultProps = {
    loading: false,
    title: 'Switch',
    value: false
};
