const PatientConstants = {
    LOADED: 'PATIENT_LOADED',
    REMINDERS_LOADED: 'PATIENT_REMINDERS_LOADED',
    ERROR: 'PATIENT_ERROR',
    LOADING: 'PATIENT_LOADING',
    LOGOUT: 'PATIENT_LOGOUT',
    CLEAR: 'PATIENT_CLEAR',
};

export { PatientConstants };
