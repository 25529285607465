import { isAdminPortal } from 'utils';
import transformInAdmin from './inAdministration';
import transformInClient from './inClient';

const transformerIn = (data) => {
    if (isAdminPortal) {
        return transformInAdmin(data);
    }
    return transformInClient(data);
};

export default transformerIn;
