import React, { useEffect } from 'react';
import { Route, Routes, cidInPath } from 'routing';
import { getSubDomain, isAdminPortal, hardReload } from 'utils';
import { Spin, Notifier } from 'components';
import { local } from 'services';
import { useSelector } from 'react-redux';

const LintelioApp = React.lazy(() => import('./lintelio'));
const AdministrationApp = React.lazy(() => import('./administration'));
const LandingApp = React.lazy(() => import('./landing'));
const IncidentDefaultApp = React.lazy(() => import('./incident/default'));
const IncidentLinApp = React.lazy(() => import('./incident/lin'));
const IncidentReportingApp = React.lazy(() => import('./incident/reporting'));

const linkToNewAdmin = `https://admin.${process.env.REACT_APP_DOMAIN}`;

const App = () => {
    const { isApiVersionUpdated } = useSelector(state => state.layout);

    const cid = cidInPath;
    const subDomain = getSubDomain();
    const isClientPortal = !!subDomain || !!cid;

    useEffect(() => {
        const lastApiVersion = local.apiVersion();
        if (isApiVersionUpdated && isApiVersionUpdated !== lastApiVersion) {
            // eslint-disable-next-line no-console
            console.log('New Version Detected:', isApiVersionUpdated);
            local.apiVersion(isApiVersionUpdated);
            hardReload();
        }
    }, [isApiVersionUpdated]);

    if (isAdminPortal) {
        return <AdministrationApp />;
    }

    function RedirectToNewAdmin() {
        window.location.replace(linkToNewAdmin);
    }

    return (
        <React.Suspense fallback={<Spin className="center" />}>
            <Routes>
                <Route
                    path="/admin/*"
                    exact={false}
                    element={<RedirectToNewAdmin />}
                />
                <Route
                    path="/incident/*"
                    element={<IncidentDefaultApp />}
                />
                <Route
                    path="/lin/*"
                    element={<IncidentLinApp />}
                />
                <Route
                    path="/incidentreport/*"
                    element={<IncidentReportingApp />}
                />
                {!isClientPortal && (
                    <Route
                        path="/*"
                        element={<LandingApp />}
                    />
                )}
                <Route
                    path={!!cid ? '/:cid/*' : '/*'}
                    element={<LintelioApp />}
                />
            </Routes>
            <Notifier />
        </React.Suspense>
    );
};

export default App;
