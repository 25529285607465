import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '../Button';
import { Tree } from '../Tree';

const TreeSelectDropdown = ({
    options, loading, value, onChange,
    label, searchLabel,
    onDone,
}) => {
    const [values, setValues] = useState([]);

    const onClose = () => {
        onDone(false);
    };

    useEffect(() => {
        setValues(value);
    }, [value]);

    const onSelect = () => {
        onChange(values);
        onClose();
    };
    return (
        <>
            <Tree
                checkedKeys={values}
                multiple
                loading={loading}
                showSearch
                searchLabel={searchLabel}
                itemLabel={label}
                height={280}
                options={options}
                onCheck={setValues}
            />
            <div className="dropdown-footer">
                <div />
                <Button type="default" onClick={onClose}>
                    Cancel
                </Button>
                <Button type="primary" onClick={onSelect}>
                    Select
                </Button>
            </div>
        </>
    );
};

TreeSelectDropdown.propTypes = {
    options: PropTypes.array.isRequired,
    loading: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string,
    onDone: PropTypes.func.isRequired,
    value: PropTypes.array,
    searchLabel: PropTypes.string
};

TreeSelectDropdown.defaultProps = {
    loading: false,
    label: '',
    value: [],
    searchLabel: undefined,
};

export default TreeSelectDropdown;
