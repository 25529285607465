import { APIT } from 'utils';
import { TELEHEALTH_SESSION_STATUS_CONST } from '../telehealth.model';

const transformTelehealthSessionIn = item => ({
    ...item,
    createdAt: APIT.date.in.toLocal(item.createdAt),
    scheduledDateTime: APIT.date.in.toLocal(item.scheduledDateTime),
    startTime: APIT.date.in.toLocal(item.startTime),
    endTime: APIT.date.in.toLocal(item.endTime),
    timerStartedAt: APIT.date.in.toLocal(item.timerStartedAt),
    $status: Object.keys(TELEHEALTH_SESSION_STATUS_CONST)
        .find(key => TELEHEALTH_SESSION_STATUS_CONST[key].toString() === item.status.toString())
});

export default transformTelehealthSessionIn;
