const transformIn = ({ data }) => data.map(item => ({
    id: item.id,
    label: item.label,
    type: item.type,
    placeholder: item.placeholder,
    isRequired: item.isRequired,
    options: item.options?.split(',').map(option => option.trim())
}));

const transformOut = data => data.map(item => ({
    id: item.id,
    label: item.label,
    type: item.type, 
    placeholder: item.placeholder,
    isRequired: item.isRequired,
    options: item.options?.join(',') || ''
}));

export default {
    in: transformIn,
    out: transformOut
};
