import { showError } from 'utils';
import { history } from 'routing';
import { UserService as service } from './user.service';
import { UserConstants } from './user.constants';

class UserActions {
    static me() {
        return (dispatch) => {
            dispatch(({ type: UserConstants.LOADING }));
            return service.me()
                .then((data) => {
                    dispatch({
                        type: UserConstants.LOADED,
                        data
                    });
                    return data;
                })
                .catch((error) => {
                    dispatch({
                        type: UserConstants.ERROR,
                        error: showError(error)
                    });
                });
        };
    }

    static signOut() {
        return (dispatch) => {
            dispatch(({ type: UserConstants.LOGOUT }));

            history.push('/');
            return Promise.resolve(true);
        };
    }
}

export { UserActions };
