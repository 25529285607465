import { AUTH_TYPE } from 'security';

const EmailBasedAuthTypes = [
    AUTH_TYPE.EMAIL, 
    AUTH_TYPE.SSO, 
    AUTH_TYPE.GOOGLE,
    AUTH_TYPE.APPLE,
    AUTH_TYPE.FACEBOOK
];

const transformAuthIn = (values = [AUTH_TYPE.OTP]) => {
    let authTypes = values;
    if (Object.isString(authTypes)) {
        authTypes = authTypes.split(', ');
    }

    if (!authTypes || !authTypes.length) {
        // eslint-disable-next-line no-console
        // console.warn('Location misconfiguration: Authentication Types are missing for', title);
        return {};
    }
    
    const $authType = authTypes[0];
    const $socialLoginsEnabled = authTypes.includes(AUTH_TYPE.GOOGLE); 
    return {
        authTypes,
        $authType,
        $socialLoginsEnabled,
        $authMixedMode: authTypes.length > 1,
        $authIsByEmail: EmailBasedAuthTypes.includes($authType),
    }; 
};

const transformAuthOut = ({ $authType, $authMixedMode, $socialLoginsEnabled }) => {
    if (!$authType) return undefined;
    const authenticationTypes = [$authType];
    if ($authMixedMode) {
        authenticationTypes.push(AUTH_TYPE.PHONE);
    }
    if ($socialLoginsEnabled) {
        authenticationTypes.push(AUTH_TYPE.GOOGLE);
        authenticationTypes.push(AUTH_TYPE.APPLE);
        authenticationTypes.push(AUTH_TYPE.FACEBOOK);
    }
    return { authenticationTypes }; 
};

export default {
    in: transformAuthIn,
    out: transformAuthOut
};
