import { MODULE_SCREEN, MODULE_TEST } from 'modules/module';

const transformRemindersOut = ({ data, ...rest }) => {
    const testReminders = (data.test || []).map(item => ({
        dayLocal: null, 
        timeLocal: item.time, 
        type: item.types,
        daysBefore: item.daysBefore,
        module: MODULE_TEST
    }));
    
    const screenReminders = [];
    (data.screen || []).forEach((item) => {
        item.days.forEach((day) => {
            screenReminders.push({ 
                dayLocal: day, 
                timeLocal: item.time, 
                type: item.types,
                module: MODULE_SCREEN 
            });
        });
    });
    const res = [...screenReminders, ...testReminders];
    
    return { data: res, ...rest };
};

export default transformRemindersOut;
