export default {
    screening: {
        title: 'Evaluación',
        outcome: 'Resultado',
        date: 'Fecha',
    },
    testing: {
        title: 'Prueba',
        verified: 'Verificado',
        unverified: 'No Verificado',
        desease: 'Enfermedad/Agente',
        verification: 'Verificacion extendida por',
        status: 'Estado de examen',
        testResult: 'Informe de evaluación',
        date: 'Fecha',
        reportDate: 'Fecha de Reporte', 
        location: 'Lugar',
        facility: 'Instalacion',
        admin: 'Admin',
        POSITIVE: 'Positivo',
        NEGATIVE: 'Negativo',
        INCONCLUSIVE: 'Inconcluso',
        type: 'Tipo', // @NEW
        takenAt: 'Realizado en', // @NEW
        antigen: 'Antigeno', // @NEW
        pcr: 'PCR', // @NEW
        lab: 'Laboratorio', // @NEW
        home: 'Casa', // @NEW
        'Home-Proctored': 'Supervisado en Casa', // @NEW
    },
    vaccination: {
        title: 'Vacunación',
        manufacturer: 'Fabricante',
        shotDate: 'Fecha de inyeccion',
        firstShotDate: 'Fecha de la primer inyeccion',
        secondShotDate: 'Fecha de la segunda inyeccion',
        status: 'Estado de Vacunación',
        verified: 'Verificado',
        unverified: 'No verificado',
        desease: 'Enfermedad/Agente',
        vaccine: 'Vacuna',
        lot: 'Numero de Lote',
        date: 'Fecha de vacuna',
        lot1: 'Lote de primera dosis',
        lot2: 'Lote de segunda dosis',
        date1: 'Fecha de primera dosis',
        date2: 'Fecha de segunda dosis',
        facility: 'Instalaciones',
        facility1: 'Instalaciones de primera dosis',
        facility2: 'KInstalaciones de segunda dosis',
        verification: 'Verificacion emitida por',
        admin: 'Admin',
    },
    vaccinationOptOut: {
        reason: 'Razon',
        date: 'Fecha',
        verified: 'Verificado',
        unverified: 'No verificado',
        status: 'Estado de Exención (No vacunado)',
        reasonDetails: 'Detalles de razon', 
    }
};
