import { FieldTypes, now } from 'utils';
import React from 'react';
import locales from 'locales';

import { HIDDEN_QUESTION_GROUPS } from 'modules/hiddenQuestions';
import { PROTECTION_TYPES } from './helpers';

const stringsEn = locales.getContent().en.models.screening.questions;

export default (args) => {
    const { models: { screening: { questions: strings } } } = locales;

    const {
        typeHousehold,
        typeHouseholdHCP,
        jobCategory: JC,
        temperatureUnit,
        params
    } = args;

    return {
        Q_IS_ER: {
            key: 'Q_IS_ER',
            group: HIDDEN_QUESTION_GROUPS.ER,
            static: true,
            type: FieldTypes.RADIO,
            label: `
                <div>
                    <div class="image-circle mb-s screening-WARN"><div></div></div>
                    <h1 class="ta-center">${strings.Q_IS_ER.title}</h1>
                    <p class="error">${locales.formatString(strings.Q_IS_ER.label[typeHousehold], params)}</p>
                    
                    <ul>
                        <li>${strings.Q_IS_ER.label1}</li>
                        <li>${strings.Q_IS_ER.label2}</li>
                        <li>${strings.Q_IS_ER.label3}</li>
                        <li>${strings.Q_IS_ER.label4}</li>
                        <li>${strings.Q_IS_ER.label5}</li>
                        <li>${strings.Q_IS_ER.label6}</li>
                    </ul>
                </div>
            `,
            labelToPost: `
                ${locales.formatString(stringsEn.Q_IS_ER.label[typeHousehold], params)}
                ${stringsEn.Q_IS_ER.label1},
                ${stringsEn.Q_IS_ER.label2},
                ${stringsEn.Q_IS_ER.label3},
                ${stringsEn.Q_IS_ER.label4},
                ${stringsEn.Q_IS_ER.label5},
                ${stringsEn.Q_IS_ER.label6}.
            `,
            options: [
                {
                    value: 'Yes',
                    text: strings.Q_IS_ER.options.Y[typeHousehold],
                    outcome: true,
                },
                {
                    value: 'No',
                    text: strings.Q_IS_ER.options.N[typeHousehold]
                },
            ],
        },
        Q_SYMPTOMS_SPECIFIC: {
            key: 'Q_SYMPTOMS',
            group: HIDDEN_QUESTION_GROUPS.SYMPTOMS,
            static: true,
            type: FieldTypes.CHECKBOXES,
            label: strings.Q_SYMPTOMS.label[typeHousehold],
            labelToPost: stringsEn.Q_SYMPTOMS.label[typeHousehold],
            banner: <div dangerouslySetInnerHTML={{ __html: strings.Q_SYMPTOMS.banner }} />,
            options: [
                {
                    code: `${JC}_SYMPTOMS`,
                    value: locales.formatString(stringsEn.Q_SYMPTOMS.options.FEVER, params),
                    text: locales.formatString(strings.Q_SYMPTOMS.options.FEVER, params),
                    outcome: true,
                },
                {
                    code: `${JC}_SYMPTOMS`,
                    value: stringsEn.Q_SYMPTOMS.options.COUGH,
                    text: strings.Q_SYMPTOMS.options.COUGH,
                    outcome: true,
                },
                {
                    code: `${JC}_SYMPTOMS`,
                    value: locales.formatString(stringsEn.Q_SYMPTOMS.options.BREATH, params),
                    text: locales.formatString(strings.Q_SYMPTOMS.options.BREATH, params),
                    outcome: true,
                },
                {
                    code: `${JC}_SYMPTOMS`,
                    value: stringsEn.Q_SYMPTOMS.options.MUSCLE,
                    text: strings.Q_SYMPTOMS.options.MUSCLE,
                    outcome: true,
                },
                {
                    code: `${JC}_SYMPTOMS`,
                    value: stringsEn.Q_SYMPTOMS.options.HEADACHE,
                    text: strings.Q_SYMPTOMS.options.HEADACHE,
                    outcome: true,
                },
                {
                    code: `${JC}_SYMPTOMS`,
                    value: stringsEn.Q_SYMPTOMS.options.TASTE,
                    text: strings.Q_SYMPTOMS.options.TASTE,
                    outcome: true,
                },
                {
                    code: `${JC}_SYMPTOMS`,
                    value: stringsEn.Q_SYMPTOMS.options.THROAT,
                    text: strings.Q_SYMPTOMS.options.THROAT,
                    outcome: true,
                },
                {
                    code: `${JC}_SYMPTOMS`,
                    value: stringsEn.Q_SYMPTOMS.options.NOSE,
                    text: strings.Q_SYMPTOMS.options.NOSE,
                    outcome: true,
                },
                {
                    code: `${JC}_SYMPTOMS`,
                    value: stringsEn.Q_SYMPTOMS.options.NAUSEA,
                    text: strings.Q_SYMPTOMS.options.NAUSEA,
                    outcome: true,
                },
                {
                    code: `${JC}_SYMPTOMS`,
                    value: stringsEn.Q_SYMPTOMS.options.DIARRHEA,
                    text: strings.Q_SYMPTOMS.options.DIARRHEA,
                    outcome: true,
                },
                {
                    code: `${JC}_NO_SYMPTOMS`,
                    value: 'None',
                    text: strings.Q_SYMPTOMS.options.NONE[typeHousehold],
                }
            ],
        },
        Q_SYMPTOMS_SPECIFIC_HIDE_CONGESTION: {
            key: 'Q_SYMPTOMS',
            group: HIDDEN_QUESTION_GROUPS.SYMPTOMS,
            static: true,
            type: FieldTypes.CHECKBOXES,
            label: strings.Q_SYMPTOMS.label[typeHousehold],
            labelToPost: stringsEn.Q_SYMPTOMS.label[typeHousehold],
            banner: <div dangerouslySetInnerHTML={{ __html: strings.Q_SYMPTOMS.banner }} />,
            options: [
                {
                    code: `${JC}-FEVER`,
                    value: locales.formatString(stringsEn.Q_SYMPTOMS.options.FEVER, params),
                    text: locales.formatString(strings.Q_SYMPTOMS.options.FEVER, params),
                    outcome: true,
                },
                {
                    code: `${JC}-COUGH`,
                    value: stringsEn.Q_SYMPTOMS.options.COUGH,
                    text: strings.Q_SYMPTOMS.options.COUGH,
                    outcome: true,
                },
                {
                    code: `${JC}-BREATH`,
                    value: locales.formatString(stringsEn.Q_SYMPTOMS.options.BREATH, params),
                    text: locales.formatString(strings.Q_SYMPTOMS.options.BREATH, params),
                    outcome: true,
                },
                {
                    code: `${JC}-MUSCLE`,
                    value: stringsEn.Q_SYMPTOMS.options.MUSCLE,
                    text: strings.Q_SYMPTOMS.options.MUSCLE,
                    outcome: true,
                },
                {
                    code: `${JC}-HEADACHE`,
                    value: stringsEn.Q_SYMPTOMS.options.HEADACHE,
                    text: strings.Q_SYMPTOMS.options.HEADACHE,
                    outcome: true,
                },
                {
                    code: `${JC}-TASTE`,
                    value: stringsEn.Q_SYMPTOMS.options.TASTE,
                    text: strings.Q_SYMPTOMS.options.TASTE,
                    outcome: true,
                },
                {
                    code: `${JC}-THROAT`,
                    value: stringsEn.Q_SYMPTOMS.options.THROAT,
                    text: strings.Q_SYMPTOMS.options.THROAT,
                    outcome: true,
                },
                {
                    code: `${JC}-NAUSEA`,
                    value: stringsEn.Q_SYMPTOMS.options.NAUSEA,
                    text: strings.Q_SYMPTOMS.options.NAUSEA,
                    outcome: true,
                },
                {
                    code: `${JC}-DIARRHEA`,
                    value: stringsEn.Q_SYMPTOMS.options.DIARRHEA,
                    text: strings.Q_SYMPTOMS.options.DIARRHEA,
                    outcome: true,
                },
                {
                    value: 'None',
                    text: strings.Q_SYMPTOMS.options.NONE[typeHousehold],
                }
            ],
        },
        Q_SYMPTOMS_NON_SPECIFIC: {
            key: 'Q_SYMPTOMS',
            group: HIDDEN_QUESTION_GROUPS.SYMPTOMS,
            static: true,
            type: FieldTypes.RADIO,
            label: strings.Q_SYMPTOMS.labelNoSpecificSymptoms[typeHousehold],
            labelToPost: stringsEn.Q_SYMPTOMS.labelNoSpecificSymptoms[typeHousehold],
            banner: <div dangerouslySetInnerHTML={{ __html: strings.Q_SYMPTOMS.banner }} />,
            options: [
                {
                    code: `${JC}_SYMPTOMS`,
                    value: 'Yes',
                    text: strings.Q_SYMPTOMS.optionsNoSpecificSymptoms.Y,
                    outcome: true,
                },
                {
                    code: `${JC}_NO_SYMPTOMS`,
                    value: 'No',
                    text: strings.Q_SYMPTOMS.optionsNoSpecificSymptoms.N,
                    outcome: false,
                }
            ],
        },
        Q_SYMPTOMS_NON_SPECIFIC_HIDE_CONGESTION: {
            key: 'Q_SYMPTOMS',
            group: HIDDEN_QUESTION_GROUPS.SYMPTOMS,
            static: true,
            type: FieldTypes.RADIO,
            label: strings.Q_SYMPTOMS.labelNoSpecificSymptoms_hideCongestion[typeHousehold],
            labelToPost: stringsEn.Q_SYMPTOMS.labelNoSpecificSymptoms_hideCongestion[typeHousehold],
            banner: <div dangerouslySetInnerHTML={{ __html: strings.Q_SYMPTOMS.banner }} />,
            options: [
                {
                    code: `${JC}-SYMPTOMS`,
                    value: 'Yes',
                    text: strings.Q_SYMPTOMS.optionsNoSpecificSymptoms.Y,
                    outcome: true,
                },
                {
                    value: 'No',
                    text: strings.Q_SYMPTOMS.optionsNoSpecificSymptoms.N,
                    outcome: false,
                }
            ],
        },
        Q_EXPOSURE: {
            key: 'Q_EXPOSURE',
            group: HIDDEN_QUESTION_GROUPS.EXPOSURE,
            static: true,
            type: FieldTypes.RADIO,
            label: `${locales.formatString(strings.Q_EXPOSURE.label[typeHouseholdHCP], params)}`,
            labelToPost: `${locales.formatString(stringsEn.Q_EXPOSURE.label[typeHouseholdHCP], params)}`,
            options: [
                {
                    code: `${JC}_EXPOSURE`,
                    value: 'Y',
                    text: strings.Q_EXPOSURE.options.Y,
                    outcome: true,
                },
                {
                    code: `${JC}_NO_EXPOSURE`,
                    value: 'N',
                    text: strings.Q_EXPOSURE.options.N,
                    outcome: false,
                }
            ],
        },
        Q_EXPOSURE_HCP1: {
            key: 'Q_EXPOSURE_HCP1',
            group: HIDDEN_QUESTION_GROUPS.EXPOSURE,
            static: true,
            type: FieldTypes.RADIO,
            label: strings.Q_EXPOSURE_HCP1.label,
            labelToPost: stringsEn.Q_EXPOSURE_HCP1.label,
            options: [
                {
                    code: `${JC}_NO_EXPOSURE`,
                    value: 'Y',
                    text: strings.Q_EXPOSURE_HCP1.options.Y,
                    outcome: true,
                },
                {
                    code: `${JC}_EXPOSURE`,
                    value: 'N',
                    text: strings.Q_EXPOSURE_HCP1.options.N,
                },
            ],
        },
        Q_EXPOSURE_HCP2: {
            key: 'Q_EXPOSURE_HCP2',
            group: HIDDEN_QUESTION_GROUPS.EXPOSURE,
            static: true,
            type: FieldTypes.RADIO,
            label: strings.Q_EXPOSURE_HCP2.label,
            labelToPost: stringsEn.Q_EXPOSURE_HCP2.label,
            options: [
                {
                    code: `${JC}_PROLONGED_EXPOSURE`,
                    value: 'Y',
                    text: strings.Q_EXPOSURE_HCP2.options.Y,
                    outcome: true,
                },
                {
                    value: 'N',
                    text: strings.Q_EXPOSURE_HCP2.options.N,
                    outcome: false,
                },
            ],
        },
        Q_EXPOSURE_HCP3: {
            key: 'Q_EXPOSURE_HCP3',
            group: HIDDEN_QUESTION_GROUPS.EXPOSURE,
            static: true,
            type: FieldTypes.RADIO,
            label: strings.Q_EXPOSURE_HCP3.label,
            labelToPost: stringsEn.Q_EXPOSURE_HCP3.label,
            options: [
                {
                    value: 'Y',
                    text: strings.Q_EXPOSURE_HCP3.options.Y,
                    outcome: false,
                },
                {
                    value: 'N',
                    code: `${JC}_EXPOSURE_NO_MASK`,
                    text: strings.Q_EXPOSURE_HCP3.options.N,
                    outcome: true,
                },
            ],
        },
        Q_EXPOSURE_HCP4: {
            key: 'Q_EXPOSURE_HCP4',
            group: HIDDEN_QUESTION_GROUPS.EXPOSURE,
            static: true,
            type: FieldTypes.RADIO,
            label: strings.Q_EXPOSURE_HCP4.label,
            labelToPost: stringsEn.Q_EXPOSURE_HCP4.label,
            options: [
                {
                    code: `${JC}_EXPOSURE_NO_EYES_PROTECTION`,
                    value: 'Y',
                    text: strings.Q_EXPOSURE_HCP4.options.Y,
                    outcome: false,
                },
                {
                    value: 'N',
                    text: strings.Q_EXPOSURE_HCP4.options.N,
                    outcome: true,
                },
            ],
        },
        Q_SYMPTOM_DATE: {
            key: 'Q_SYMPTOM_DATE',
            group: HIDDEN_QUESTION_GROUPS.SYMPTOM_DATE,
            static: true,
            type: FieldTypes.DATE,
            label: strings.Q_SYMPTOM_DATE.label,
            labelToPost: stringsEn.Q_SYMPTOM_DATE.label,
            props: {
                maxDate: now()
            },
        },
        Q_EXPOSURE_DATE: {
            key: 'Q_EXPOSURE_DATE',
            group: HIDDEN_QUESTION_GROUPS.EXPOSURE_DATE,
            static: true,
            type: FieldTypes.DATE,
            label: strings.Q_EXPOSURE_DATE.label,
            labelToPost: stringsEn.Q_EXPOSURE_DATE.label,
            props: {
                maxDate: now()
            },
        },
        Q_TEMPERATURE: {
            key: 'Q_TEMPERATURE',
            group: HIDDEN_QUESTION_GROUPS.TEMPERATURE,
            static: true,
            code: `${JC}-TEMPERATURE`,
            type: FieldTypes.TEMPERATURE,
            label: strings.Q_TEMPERATURE.label[typeHousehold],
            labelToPost: stringsEn.Q_TEMPERATURE.label[typeHousehold],
            props: {
                unit: temperatureUnit
            },
        },
        Q_PROTECTION: { 
            key: 'Q_PROTECTION',
            group: HIDDEN_QUESTION_GROUPS.PROTECTION,
            static: true,
            type: FieldTypes.RADIO,
            label: strings.Q_PROTECTION.label[typeHousehold],
            labelToPost: stringsEn.Q_PROTECTION.label[typeHousehold],
            options: [
                {
                    code: `${JC}_PROTECTION`,
                    value: 'CURRENT',
                    text: strings.Q_PROTECTION.options.CURRENT,
                    outcome: PROTECTION_TYPES.CURRENT,
                },
                {
                    code: `${JC}_PROTECTION`,
                    value: 'POSITIVE',
                    text: strings.Q_PROTECTION.options.POSITIVE,
                    outcome: PROTECTION_TYPES.POSITIVE,
                },
                {
                    code: `${JC}_PROTECTION`,
                    value: 'NOT_CURRENT',
                    text: strings.Q_PROTECTION.options.NOT_CURRENT,
                    outcome: PROTECTION_TYPES.NOT_CURRENT,
                },
            ],
        },
    };
};
