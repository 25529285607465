import './Group.scss';
import React from 'react';
import PropTypes from 'prop-types';
import MUIRadioGroup from '@mui/material/RadioGroup';

const RadioGroup = ({ className, initialValue, value, children, disabled, name, onChange }) => (
    <MUIRadioGroup
        defaultValue={initialValue}
        name={name}
        value={value}
        onChange={(e, val) => onChange(val)}
        className={`c-radio-group ${className}`}
    >
        {React.Children
            .map(
                children,
                child => React.cloneElement(child, { disabled, groupValue: value })
            )}
    </MUIRadioGroup>
);

RadioGroup.propTypes = {
    className: PropTypes.string,
    children: PropTypes.any,
    value: PropTypes.any,
    disabled: PropTypes.bool,
    name: PropTypes.string,
    onChange: PropTypes.func,
    initialValue: PropTypes.any
};

RadioGroup.defaultProps = {
    name: '',
    className: '',
    initialValue: '',
    children: null,
    value: '',
    disabled: false,
    onChange: () => { }
};

export default RadioGroup;
