export default {
    titles: {
        er: 'Llame al {emergencyNumber}.',
        isolate: 'Haga clic debajo en el botón \'Devuelta al inicio\' para los siguientes pasos',
        caution: 'Precaución',
        pass: 'Aprobado'
    },
    er: {
        EVENT: 'Puede que tengan una emergencia medica.',
        EMPLOYER: 'Puede que tengan una emergencia medica.',
        HOUSEHOLD: 'Esta puede ser una emergencia medica',
    },
    cleared: {
        title: 'Aprobado',
        HOUSEHOLD: 'Según el Centro para Control y Prevención de Enfermedades (CDC), sus respuestas indican que nadie en su hogar esta experimentando sintomas parecidos al COVID-19. Si alguien comienza a sentirse enfermo,  por favor reportelo a la escuela inmediatamente.',
        EMPLOYER: 'Según el Centro para Control y Prevención de Enfermedades (CDC), sus respuestas indican que usted no tiene síntomas parecidos al COVID-19. Usted puede reportarse a trabajar el dia de hoy. Si usted comienza a sentirse enfermo durante su turno, por favor reportelo a su supervisor inmediatamente y deje su trabajo.',
        EVENT: 'Según el Centro para Control y Prevención de Enfermedades (CDC), sus respuestas indican que usted no tiene síntomas parecidos al COVID-19. Si usted comienza a sentirse enfermo durante su turno, retírese inmediatamente y reportelo al contacto de su organización.'
    },
    nurse: 'Creemos que es buena idea que hable con un Profesional de la salud.',

isolateSymptomsHCP: `Según el Centro de Control y Prevención de Enfermedades (CDC), sus respuestas indican que usted debe auto-aislarse:
        * Por al menos 10 dias desde el dia que sus sintomas comenzaron
        * Hasta que sus sintomas mejoren
        * y no haya tenido fiebre por lo menos por 24 horas (sin haber usado ningun medicamento para reducir la fiebre)

1. Mantenga distacia social de otros (mas de 6 pies). Use cubre bocas de tela en lugares publicos. No se reúna en grupos, aléjese de los lugares donde haya mucha gente y evite las reuniones masivas.

2. Revise su temperatura dos veces al dia..

3. Mantengase vigilando cambios en sintomas: fiebre ({temperatureHCP} o mayor) o escalofrios, tos reciente o que se agrava, falta de aliento o dificultad para respirar, dolor muscular o de cuerpo, dolor de cabeza, perdida reciente del gusto u olfato, dolor de garganta, congestión o secreción nasal. nausea, vomito o diarrea. Si sus sintomas empeoran o no se le quitan, contacte a su proveedor médico o al departamento de salud local para obtener más instrucciones. 

4. LLAME AL {emergencyNumber} Si tiene o desarrolla alguno de los siguientes:
* Dificultad para respirar severa
* Dolor en el pecho
* Presión o tensión en el pecho
* Dificultad para mantenerse consciente
* Labios o rostro azulados 

5. Una prueba puede ser recomendada. El acceso a las pruebas varia. Puede contactar a su proveedor médico o departamento de salud local para ver si están disponibles en su área.

6. No debe suspender el auto-aislamiento hasta que usted haya recibido autorización de un proveedor médico.                                  

7. Contacte a su instalación de salud laboral o programa de control de infección para ontener instrucciones acerca de su regreso al trabajo.
`,
isolateExposureHCP: `Según la CDC, sus respuestas indican que su hogar debe aislarse durante 14 días desde su última exposición.

        1. Mantenga una distancia física (superior a 6 pies) de los demás. Use una cubierta facial de tela en lugares públicos. No se reúnan en grupos, manténgase alejado de lugares concurridos y evite las reuniones masivas.
        
        2. Monitoree si hay presencia de fiebre o escalofríos ({temperatureHCP} para personal de salud), tos, falta de aire o dificultad para respirar, dolores musculares o corporales, fatiga relacionada con la enfermedad, dolor de cabeza, pérdida del gusto u olfato, dolor de garganta, congestión o secreción nasal, náuseas o vómitos o diarrea al menos una vez al día. Si presenta algún síntoma compatible con COVID-19, debe notificar a su centro de salud ocupacional o control de infecciones para obtener más instrucciones.
        
        3. LLAME {emergencyNumber} Si alguien presenta lo siguiente:
        * Dificultad severa para respirar
        * Dolor de pecho
        * Presión en el pecho
        * Dificultad para mantenerse consciente
        * Labios o cara azulados
`,
isolateSymptomsDEFAULT: `Según el Centro de Control y Prevención de Enfermedades (CDC), sus respuestas indican que usted debe auto-aislarse por:
        * Al menos 10 dias desde el dia que sus sintomas comenzaron
        * Y que sus sintomas mejoraron
        * Y que no han tenido fiebre por lo menos por 24 horas (sin haber usado ningun medicamento para reducir la fiebre)

1. Mantenga distacia social de otros (mas de 6 pies). Use cubre bocas de tela en lugares publicos. No se reúna en grupos, aléjese de los lugares donde haya mucha gente y evite las reuniones masivas.

2. Revise su temperatura dos veces al dia..

3. Mantengase vigilando cambios en sintomas: fiebre ({temperature} o mayor) o escalofrios, tos reciente o que se agrava, falta de aliento o dificultad para respirar, dolor muscular o de cuerpo, dolor de cabeza, perdida reciente del gusto u olfato, dolor de garganta, congestión o secreción nasal. nausea, vomito o diarrea. Si sus sintomas empeoran o no se le quitan, contacte a su proveedor médico o al departamento de salud local para obtener más instrucciones. 

4. LLAME AL {emergencyNumber} Si tiene o desarrolla alguno de los siguientes:
* Dificultad para respirar severa
* Dolor en el pecho
* Presión o tensión en el pecho
* Dificultad para mantenerse consciente
* Labios o rostro azulados 

5. Una prueba puede ser recomendada. El acceso a las pruebas varia. Puede contactar a su proveedor médico o departamento de salud local para ver si están disponibles en su área.

6. No debe suspender el auto-aislamiento hasta que usted haya recibido autorización de un proveedor médico.                                   

`,
isolateExposureDEFAULT: `Según la CDC, sus respuestas indican que debe aislarse por sí mismo durante 14 días desde su última exposición.

        1. Mantenga una distancia física (superior a 6 pies) de los demás. Use una cubierta facial de tela en lugares públicos. No se reúnan en grupos, manténgase alejado de lugares concurridos y evite las reuniones masivas.
        
        2. Revise su temperatura dos veces al día.
        
        3. Monitoree los síntomas, incluida la fiebre ({temperature} or higher) o escalofríos, tos nueva o que empeora, falta de aliento o dificultad para respirar, dolores musculares o corporales, fatiga relacionada con enfermedades, dolor de cabeza, pérdida del gusto u olfato, dolor de garganta, congestión o secreción nasal, náuseas o vómitos, diarrea. Si presenta síntomas, comuníquese con su proveedor médico o con el departamento de salud local para obtener más instrucciones.
        
        4. LLAME {emergencyNumber} Si alguien presenta lo siguiente:
        * Dificultad severa para respirar
        * Dolor de pecho
        * Presión en el pecho
        * Dificultad para mantenerse consciente
        * Labios o cara azulados
        
        5. Se recomienda realizarse pruebar y su acceso puede variar. Puede comunicarse con su proveedor médico o con el departamento de salud local para ver si está disponible en su área.
        
        6. No debe detener el autoaislamiento hasta que haya recibido la autorización de un proveedor médico.
`,

isolateExposureCOMMUNITY_CURRENT: `
1. Siga direcciones especificas de su compañia para examinaciones, cuarentena y reportarse al trabajo o escuela.
2. Según lineamientos de la CDC, lleve una mascarilla alrededor de otras persona por 10  dias.
3. Realice un examen de COVID al dia 5 si es posible.
4. Si desarrolla sintomas o actualmente tiene sintomas, realice un examen de COVID y permanezca en casa
5. Estas recomendaciones no reemplazan leyes estatales, locales, o tribales, reglas o regulaciones.
6. Contacte a su medico primario para mas preguntas.
7. Dirigase a CDC.gov/coronavirus o al sitio web de su departamento local de salud para respuestas adicionales a preguntas frecuentes y mayor informacion.
`,
isolateSymptomsCOMMUNITY_CURRENT: `
1. Siga direcciones especificas de su compañia para examinaciones, cuarentena y reportarse al trabajo o escuela.
2. Según las guías del CDC, usted debe autoaislarse y realizarse una prueba de COVID. Permanezca en casa por 5 días si sus síntomas están relacionados con COVID.
3. Si sus sintomas mejoran despues de 5 dias, puede dejar su hogar.
4. Continue utilizando una mascarilla alrededor de otros por 5 dias adicionales.
5. Estas recomendaciones no reemplazan leyes estatales, locales, o tribales, reglas o regulaciones.
6. Contacte a su medico primario para mas preguntas.
7. Dirigase a CDC.gov/coronavirus o al sitio web de su departamento local de salud para respuestas adicionales a preguntas frecuentes y mayor informacion.
`,
isolateExposureHOUSEHOLD_CURRENT: `
1. Siga direcciones especificas de su compañia para examinaciones, cuarentena y reportarse al trabajo o escuela.
2. Según lineamientos de la CDC, lleve una mascarilla alrededor de otras persona por 10  dias.
3. Realice un examen de COVID al dia 5 si es posible.
4. Si desarrolla sintomas o actualmente tiene sintomas, realice un examen de COVID y permanezca en casa
5. Estas recomendaciones no reemplazan leyes estatales, locales, o tribales, reglas o regulaciones.
6. Contacte a su medico primario para mas preguntas.
7. Dirigase a CDC.gov/coronavirus o al sitio web de su departamento local de salud para respuestas adicionales a preguntas frecuentes y mayor informacion.
`,
isolateSymptomsHOUSEHOLD_CURRENT: `
1. Siga direcciones especificas de su compañia para examinaciones, cuarentena y reportarse al trabajo.
2. Según las guías del CDC, usted debe autoaislarse y realizarse una prueba de COVID. Permanezca en casa por 5 días si sus síntomas están relacionados con COVID.
3. Si sus sintomas mejoran despues de 5 dias, su auto-aislamiento puede acabar.
4. Continue utilizando una mascarilla alrededor de otros por 5 dias adicionales.
5. Estas recomendaciones no reemplazan leyes estatales, locales, o tribales, reglas o regulaciones.
6. Contacte a su medico primario para mas preguntas.
7. Dirigase a CDC.gov/coronavirus o al sitio web de su departamento local de salud para respuestas adicionales a preguntas frecuentes y mayor informacion.
`,
isolateExposureCOMMUNITY_POSITIVE: `
1. Siga direcciones especificas de su compañia para examinaciones, cuarentena y reportarse al trabajo.
2. Según lineamientos de la CDC, no necesita realizarse un examen a menos que desarrolle nuevos sintomas
3. Si desarrolla o actualmente tiene algun nuevo sintoma, reportelo con su gerente, auto-aislece inmediatamente y realice un examen de COVID.
4. Estas recomendaciones no reemplazan leyes estatales, locales, o tribales, reglas o regulaciones.
5. Contacte a su medico primario para mas preguntas.
6. Dirigase a CDC.gov/coronavirus o al sitio web de su departamento local de salud para respuestas adicionales a preguntas frecuentes y mayor informacion.
`,
isolateSymptomsCOMMUNITY_POSITIVE: `
1. Siga direcciones especificas de su compañia o escuela para examinaciones, cuarentena y reportarse al trabajo o escuela.
2. Según las guías del CDC, usted debe autoaislarse y realizarse una prueba de COVID. Permanezca en casa por 5 días si sus síntomas están relacionados con COVID.
3. Si sus sintomas mejoran despues de 5 dias, puede dejar su hogar.
4. Continue utilizando una mascarilla alrededor de otros por 5 dias adicionales.
5. Estas recomendaciones no reemplazan leyes estatales, locales, o tribales, reglas o regulaciones.
6. Contacte a su medico primario para mas preguntas.
7. Dirigase a CDC.gov/coronavirus o al sitio web de su departamento local de salud para respuestas adicionales a preguntas frecuentes y mayor informacion.
`,
isolateExposureHOUSEHOLD_POSITIVE: `
1. Siga direcciones especificas de su compañia o escuela para examinaciones, cuarentena y reportarse al trabajo o escuela.
2. Según lineamientos de la CDC, no necesita realizarse un examen a menos que desarrolle nuevos sintomas
3. Si desarrolla o actualmente tiene algun nuevo sintoma, reportelo con su gerente o administrador, auto-aislece inmediatamente y realice un examen de COVID.
4. Estas recomendaciones no reemplazan leyes estatales, locales, o tribales, reglas o regulaciones.
5. Contacte a su medico primario para mas preguntas.
6. Dirigase a CDC.gov/coronavirus o al sitio web de su departamento local de salud para respuestas adicionales a preguntas frecuentes y mayor informacion.
`,
isolateSymptomsHOUSEHOLD_POSITIVE: `
1. Siga direcciones especificas de su compañia o escuela para examinaciones, cuarentena y reportarse al trabajo o escuela.
2. Según las guías del CDC, usted debe autoaislarse y realizarse una prueba de COVID. Permanezca en casa por 5 días si sus síntomas están relacionados con COVID.
3. Si sus sintomas mejoran despues de 5 dias, puede detener el auto-aislamiento.
4. Continue utilizando una mascarilla alrededor de otros por 5 dias adicionales.
5. Estas recomendaciones no reemplazan leyes estatales, locales, o tribales, reglas o regulaciones.
6. Contacte a su medico primario para mas preguntas.
7. Dirigase a CDC.gov/coronavirus o al sitio web de su departamento local de salud para respuestas adicionales a preguntas frecuentes y mayor informacion.
`,
// @NEW
isolateExposureCOMMUNITY_NOT_CURRENT: `
1. Follow company-specific directions for testing, quarantine and for reporting to work. 
2. If in CA, per California Department of Health, as long as you are not having symptoms, you do not need to isolate.
3. If your organization is following CDC guidelines, stay home for 5 days. Then continue to wear a mask around others for an additional 5 days. If unable to quarantine, wear a mask for 10 days. 
4. Get a COVID test on day 5 if possible. 
5. If you develop OR currently have symptoms, get a COVID test and stay home. 
6. These recommendations do not take the place of state, local or triable laws, rules, and regulations.
7. Contact your Primary Care provider for further questions.
8. Go to CDC.gov/coronavirus or your local health department website for additional answers to frequently asked questions and further information.
`,
isolateSymptomsCOMMUNITY_NOT_CURRENT: `
1. Siga direcciones especificas de su compañia para examinaciones, cuarentena y reportarse al trabajo.
2. Según las guías del CDC, usted debe autoaislarse y realizarse una prueba de COVID. Permanezca en casa por 5 días si sus síntomas están relacionados con COVID.
3. Si sus sintomas mejoran despues de 5 dias, puede dejar su hogar.
4. Continue utilizando una mascarilla alrededor de otros por 5 dias adicionales.
5. Estas recomendaciones no reemplazan leyes estatales, locales, o tribales, reglas o regulaciones.
6. Contacte a su medico primario para mas preguntas.
7. Dirigase a CDC.gov/coronavirus o al sitio web de su departamento local de salud para respuestas adicionales a preguntas frecuentes y mayor informacion.
`,
// @NEW
isolateExposureHOUSEHOLD_NOT_CURRENT: `
1. Follow company and school-specific directions for testing, quarantine and for reporting to work or school. 
2. If in CA, per California Department of Health, as long as you are not having symptoms, you do not need to isolate.
3. If your organization is following CDC guidelines, stay home for 5 days. Then continue to wear a mask around others for an additional 5 days. If unable to quarantine, wear a mask for 10 days. 
4. Get a COVID test on day 5 if possible. 
5. If you develop OR currently have symptoms, get a COVID test and stay home. 
6. These recommendations do not take the place of state, local or triable laws, rules, and regulations.
7. Contact your Primary Care provider for further questions.
8. Go to CDC.gov/coronavirus or your local health department website for additional answers to frequently asked questions and further information.
`,
isolateSymptomsHOUSEHOLD_NOT_CURRENT: `
1. Siga direcciones especificas de su compañia o escuela para examinaciones, cuarentena y reportarse al trabajo o escuela.
2. Según las guías del CDC, usted debe autoaislarse y realizarse una prueba de COVID. Permanezca en casa por 5 días si sus síntomas están relacionados con COVID.
3. Si sus sintomas mejoran despues de 5 dias, puede detener el auto-aislamiento.
4. Continue utilizando una mascarilla alrededor de otros por 5 dias adicionales.
5. Estas recomendaciones no reemplazan leyes estatales, locales, o tribales, reglas o regulaciones.
6. Contacte a su medico primario para mas preguntas.
7. Dirigase a CDC.gov/coronavirus o al sitio web de su departamento local de salud para respuestas adicionales a preguntas frecuentes y mayor informacion.
`,

isolateBanner: ''
};
