import React from 'react';
import {
    MODULE_PANDEMIC, MODULE_TEST, MODULE_VACCINATION, MODULE_SCREEN,
    MODULE_ALERTS,
    MODULE_BENEFITS,
    MODULE_WECONNECT,
    MODULE_INCIDENT, MODULE_WC_INCIDENT, MODULE_AUTO_INCIDENT, 
    MODULE_LIABILITY_INCIDENT, MODULE_PROPERTY_INCIDENT,
    MODULE_ACCESS_ASSISTANCE_INCIDENT,
    MODULE_HP_LIABILITY_INCIDENT,
    MODULE_WORKPLACE_VIOLENCE_INCIDENT,
    MODULE_INTEGRITY_LINE,
    MODULE_MESSAGE_CENTER_INCIDENT
} from 'modules/module';

const Alerts = React.lazy(() => import('apps/administration/routes/alerts'));
const Pandemic = React.lazy(() => import('apps/administration/routes/pandemic'));
const Incidents = React.lazy(() => import('apps/administration/routes/incidents'));
const IntegrityLine = React.lazy(() => import('apps/administration/routes/integrityLine'));
const ModuleInfo = React.lazy(() => import('apps/administration/routes/moduleInfo'));

const IncidentsWC = React.lazy(() => import('apps/administration/routes/incidents/list/listWc'));
const IncidentsAuto = React.lazy(() => import('apps/administration/routes/incidents/list/listAuto'));
const IncidentsLiability = React.lazy(() => import('apps/administration/routes/incidents/list/listLiability'));
const IncidentsProperty = React.lazy(() => import('apps/administration/routes/incidents/list/listProperty'));
const IncidentsAccessAssistance = React.lazy(() => import('apps/administration/routes/incidents/list/listAccessAssistance'));
const IncidentsHighProfileLiability = React.lazy(() => import('apps/administration/routes/incidents/list/listHighProfileLiability'));
const IncidentsWorkplaceViolence = React.lazy(() => import('apps/administration/routes/incidents/list/listWorkplaceViolence'));
const IncidentsMessageCenter = React.lazy(() => import('apps/administration/routes/incidents/list/listMessageCenter'));

/* VisibleOn
userMenu: true,
locationMenu: true,
adminPermissions: true,
adminListPermissions: true,
locationList,
*/

export const ModulesConfig = {
    incident: {
        module: 'incident',
        id: MODULE_INCIDENT,
        title: 'Incidents',
        desc: 'Incidents Module',
        route: '/incidents',
        menu: 'incident',
        hasContent: true,
        component: Incidents,
        visibleOn: {
            locationMenu: true,
            adminPermissions: true,
        },
    },
    incidentWc: {
        module: 'incidentWc',
        parentId: MODULE_INCIDENT,
        id: MODULE_WC_INCIDENT,
        title: 'Workers` Compensation',
        desc: 'WC Incidents Module',
        route: '/Workers` Compensation',
        component: IncidentsWC,
        prefix: 'I',
        hasContent: true,
        visibleOn: {
            ladminPermissions: true,
            adminListPermissions: true,
            locationList: true,
        },
    },
    incidentAuto: {
        module: 'incidentAuto',
        parentId: MODULE_INCIDENT,
        id: MODULE_AUTO_INCIDENT,
        title: 'Auto',
        desc: 'Auto Incidents Module',
        route: '/Auto',
        component: IncidentsAuto,
        prefix: 'A',
        hasContent: true,
        visibleOn: {
            adminPermissions: true,
            adminListPermissions: true,
            locationList: true,
        },
    },
    incidentLiability: {
        module: 'incidentLiability',
        parentId: MODULE_INCIDENT,
        id: MODULE_LIABILITY_INCIDENT,
        title: 'Liability',
        desc: 'Liability Incidents Module',
        route: '/Liability',
        component: IncidentsLiability,
        prefix: 'L',
        hasContent: true,
        visibleOn: {
            adminPermissions: true,
            adminListPermissions: true,
            locationList: true,
        },
    },
    /*
    incidentStudent: {
        module: 'incidentStudent',
        parentId: MODULE_INCIDENT,
        id: MODULE_STUDENT_INCIDENT,
        title: 'Student Incidents',
        desc: 'Student Incidents Module',
        route: '/Student incidents',
        component: IncidentsStudent,
        hasContent: true,
        visibleOn: {
            adminPermissions: true,
            adminListPermissions: true,
            locationList: true,
        },
    }, */
    incidentProperty: {
        module: 'incidentProperty',
        parentId: MODULE_INCIDENT,
        id: MODULE_PROPERTY_INCIDENT,
        title: 'Property',
        desc: 'Property Incidents Module',
        route: '/Property',
        component: IncidentsProperty,
        prefix: 'P',
        hasContent: true,
        visibleOn: {
            adminPermissions: true,
            adminListPermissions: true,
            locationList: true,
        },
    },
    incidentAccessAssistance: {
        module: 'incidentAccessAssistance',
        parentId: MODULE_INCIDENT,
        id: MODULE_ACCESS_ASSISTANCE_INCIDENT,
        title: 'Access Assistance',
        desc: 'Access Assistance Incidents Module',
        route: '/Access Assistance',
        component: IncidentsAccessAssistance, 
        prefix: 'AA',
        hasContent: true,
        visibleOn: {
            adminPermissions: true,
            adminListPermissions: true,
            locationList: true,
        },
    },
    incidentHighProfileLiability: {
        module: 'incidentHighProfileLiability',
        parentId: MODULE_INCIDENT,
        id: MODULE_HP_LIABILITY_INCIDENT,
        title: 'High Profile Liability',
        desc: 'High Profile Liability Incidents Module',
        route: '/High Profile Liability',
        component: IncidentsHighProfileLiability, 
        prefix: 'HP',
        hasContent: true,
        visibleOn: {
            adminPermissions: true,
            adminListPermissions: true,
            locationList: true,
        },
    },
    incidentWorkplaceViolence: {
        module: 'incidentWorkplaceViolence',
        parentId: MODULE_INCIDENT,
        id: MODULE_WORKPLACE_VIOLENCE_INCIDENT,
        title: 'Workplace Violence',
        desc: 'Workplace Violence Incidents Module',
        route: '/Workplace Violence',
        component: IncidentsWorkplaceViolence,
        prefix: 'WV',
        hasContent: true,
        visibleOn: {
            adminPermissions: true,
            adminListPermissions: true,
            locationList: true,
        },
    },
    incidentMessageCenter: {
        module: 'incidentMessageCenter',
        parentId: MODULE_INCIDENT,
        id: MODULE_MESSAGE_CENTER_INCIDENT,
        title: 'Message Center',
        desc: 'Message Center Module',
        route: '/Message Center',
        component: IncidentsMessageCenter, 
        prefix: 'MC',
        hasContent: true,
        visibleOn: {
            adminPermissions: true,
            adminListPermissions: true,
            locationList: true,
        },
    },
    pandemic: {
        module: 'pandemic',
        id: MODULE_PANDEMIC,
        title: 'Pandemic',
        route: '/pandemic',
        desc: 'Pandemic Module',
        menu: 'pandemic',
        hasContent: true,
        component: Pandemic,
        visibleOn: {
            userMenu: true,
            locationMenu: true,
            adminPermissions: true,
            adminListPermissions: true,
            locationList: true,
        },
    },
    autoscreen: {
        module: 'autoscreen',
        id: MODULE_SCREEN,
        parentId: MODULE_PANDEMIC,
        title: 'Screenings',
        visibleOn: {
            adminPermissions: true,
        },
    },
    test: {
        module: 'test',
        id: MODULE_TEST,
        parentId: MODULE_PANDEMIC,
        title: 'Tests',
        visibleOn: {
            adminPermissions: true,
        },
    },
    vaccination: {
        module: 'vaccination',
        id: MODULE_VACCINATION,
        parentId: MODULE_PANDEMIC,
        title: 'Vaccinations',
        visibleOn: {
            adminPermissions: true,
        },
    },
    alerts: {
        module: 'alerts',
        id: MODULE_ALERTS,
        title: 'Alerts & Newsfeed',
        desc: 'Alerts/Newsfeed Module',
        route: '/alerts',
        hasContent: true,
        component: Alerts,
        visibleOn: {
            userMenu: true,
            locationMenu: true,
            adminPermissions: true,
            adminListPermissions: true,
            locationList: true,
        },
    },
    /*
    immunization: {
        module: 'immunization',
        id: MODULE_IMMUNIZATION,
        title: 'Immunizations',
        desc: 'Immunization Module',
        route: '/immunizations',
        hasContent: true,
        component: Immunizations,
        visibleOn: {
            userMenu: true,
            locationMenu: true,
            adminPermissions: true,
            adminListPermissions: true,
            locationList: true,
        },
        onMenuItemClick: () => {
            GA.sendAdminEvent('Immunization Module: Menu Item Click', 'Immunization');
        },
        onActivate: () => {
            GA.sendAdminEvent('Immunization Module: Enabled', 'Immunization');
        },
        onDisactivate: () => {
            GA.sendAdminEvent('Immunization Module: Disabled', 'Immunization');
        },
    },
    attendance: {
        module: 'attendance',
        id: MODULE_ATTENDANCE,
        title: 'Attendance',
        desc: 'Attendance Module',
        route: '/attendance',
        hasContent: false,
        component: () => <ModuleInfo module="attendance" />,
        visibleOn: {
            userMenu: true,
            adminPermissions: true,
            adminListPermissions: true,
            locationList: true,
        },
    }, */
    benefits: {
        module: 'benefits',
        id: MODULE_BENEFITS,
        title: 'Benefits',
        desc: 'Benefits Module',
        route: '/benefits',
        hasContent: false,
        component: () => <ModuleInfo module="benefits" />,
        visibleOn: {
            locationMenu: true,
            adminPermissions: true,
            adminListPermissions: true,
            locationList: true,
        },
    },
    /*
    lifeworks: {
        module: 'lifeworks',
        id: MODULE_LIFEWORKS,
        title: 'LifeWorks',
        desc: 'LifeWorks Module',
        route: '/lifeworks',
        hasContent: false,
        component: () => <ModuleInfo module="lifeworks" />,
        visibleOn: {
            adminPermissions: true,
            adminListPermissions: true,
            locationList: true,
        },
    }, */
    integrityLine: {
        module: 'integrityLine',
        id: MODULE_INTEGRITY_LINE,
        title: 'Integrity Line',
        desc: 'Integrity Line module',
        route: '/integrityLine',
        hasContent: false,
        component: IntegrityLine,
        visibleOn: {
            locationMenu: true,
            adminPermissions: true,
            adminListPermissions: true,
            locationList: true,
        },
    },
    WEconnect: {
        module: 'WEconnect',
        id: MODULE_WECONNECT,
        title: 'WEconnect',
        desc: 'WEconnect module',
        route: '/WEconnect',
        hasContent: false,
        component: () => <ModuleInfo module="WEconnect" />,
        visibleOn: {},
    },
};

export const PandemicModuleRoute = ModulesConfig.pandemic.route;

export const IncidentModuleRoute = ModulesConfig.incident.route;

export const CREATE_ROUTE_PARAM = 'new';

export const IncidentSubmodules = Object.values(ModulesConfig)
    .filter(item => item.parentId === MODULE_INCIDENT);
