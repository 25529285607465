export default {
    title: 'Proctor',
    subtitle: 'Here is the list of your appointments',
    itemTitle: 'Proctored Test Session',
    startCall: 'Start Call',
    cancelAppointment: 'Cancel Appointment',
    startProctorTesting: 'Start Proctored Testing Now',
    status: {
        INIT: 'New',
        QUEUED: 'Queued',
        IN_PROGRESS: 'In Progress',
        PENDING_TEST_RESULT: 'Pending Test Result',
        PENDING_VERIFICATION: 'Pending Verification',
        COMPLETED: 'Finished',
        ABANDONED: 'Abandoned',
    },
    instructions: 'Instructions',
    instructionsContent: `
        <p>Before proceed, please make sure that you:</p><br/>
        <ul>
            <li>Are in a location with a stable internet connection (not driving, walking, etc).</li>
            <li>Have a minimum of 4 minutes uninterrupted time available to complete this process.</li>
            <li>Have a photo ID available (driver’s license or passport).</li>
            <li>Have thoroughly reviewed the instructions for your antigen test.</li>
            <li>Have the authorization code provided by your retailer, if applicable.</li>
        </ul>
`,
    sessionTitle: 'Lobby',
    addImage: 'Add image of your test result',
    waitToAddImage: 'You will be able to upload an image of your test result in:'
};
