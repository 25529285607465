export default {
    Q_IS_ER: {
        title: 'Is this an emergency?',
        label:
        {
            HOUSEHOLD: 'Stop and call {emergencyNumber} if anyone in your household is experiencing any of the following:',
            NOT_HOUSEHOLD: 'Stop and call {emergencyNumber} if you are experiencing any of the following:',
        },
        label1: 'Severe difficulty breathing',
        label2: 'Chest Pain',
        label3: 'Pressure or tightness in the chest',
        label4: 'Difficulty remaining conscious',
        label5: 'Confusion',
        label6: 'Bluish lips or face',
        options: {
            Y: {
                NOT_HOUSEHOLD: 'I am experiencing at least one of these',
                HOUSEHOLD: 'Someone is experiencing at least one of these'
            },
            N: {
                NOT_HOUSEHOLD: 'I do not have any of these',
                HOUSEHOLD: 'No one has any of these'
            }
        },
    },
    Q_SYMPTOMS: {
        label: {
            NOT_HOUSEHOLD: 'Are you experiencing any of these symptoms?',
            HOUSEHOLD: 'Is anyone in your household experiencing any of these symptoms?'
        },
        banner: `When answering the following questions, please <b>DO NOT</b> check symptoms that are related to allergies, COPD, other chronic conditions OR recent COVID-19 vaccination.<br />
        If you have been cleared from a medical provider to stop isolation after having COVID-19 within the past 3 months and are having continued symptoms, please <b>DO NOT</b> check those symptoms here.`,
        options: {
            FEVER: 'Fever ({temperature} or higher) or chills',
            COUGH: 'Cough',
            BREATH: 'Shortness of breath or difficulty breathing (if you have severe shortness of breath or severe difficulty breathing, CALL {emergencyNumber})',
            MUSCLE: 'Muscle or body aches; OR illness related fatigue',
            HEADACHE: 'Headache',
            TASTE: 'New loss of taste or smell',
            THROAT: 'Sore throat',
            NOSE: 'Congestion or runny nose',
            NAUSEA: 'Nausea or vomiting',
            DIARRHEA: 'Diarrhea',
            NONE: {
                NOT_HOUSEHOLD: 'I do not have any of these',
                HOUSEHOLD: 'No one is having these'
            }
        },
        labelNoSpecificSymptoms: {
            NOT_HOUSEHOLD: `Are you experiencing any COVID-19 like symptoms such as:
                <ul>
                    <li>fever or chills</li>
                    <li>cough</li> 
                    <li>shortness of breath or difficulty breathing</li>
                    <li>muscle/body aches OR illness related fatigue</li>
                    <li>headache</li>
                    <li>new loss of taste or smell</li> 
                    <li>sore throat</li>
                    <li>congestion or runny nose</li>
                    <li>nausea or vomiting</li>
                    <li>diarrhea</li>
                </ul>`,
            HOUSEHOLD: `Is anyone in your household experiencing any COVID-19 like symptoms such as:
                <ul>
                    <li>fever or chills</li>
                    <li>cough</li> 
                    <li>shortness of breath or difficulty breathing</li>
                    <li>muscle/body aches OR illness related fatigue</li>
                    <li>headache</li>
                    <li>new loss of taste or smell</li> 
                    <li>sore throat</li>
                    <li>congestion or runny nose</li>
                    <li>nausea or vomiting</li>
                    <li>diarrhea</li>
                </ul>`
        },
        labelNoSpecificSymptoms_hideCongestion: {
            NOT_HOUSEHOLD: `Are you experiencing any COVID-19 like symptoms such as:
                <ul>
                    <li>fever or chills</li>
                    <li>cough</li> 
                    <li>shortness of breath or difficulty breathing</li>
                    <li>muscle/body aches OR illness related fatigue</li>
                    <li>headache</li>
                    <li>new loss of taste or smell</li> 
                    <li>sore throat</li>
                    <li>nausea or vomiting</li>
                    <li>diarrhea</li>
                </ul>`,
            HOUSEHOLD: `Is anyone in your household experiencing any COVID-19 like symptoms such as:
                <ul>
                    <li>fever or chills</li>
                    <li>cough</li> 
                    <li>shortness of breath or difficulty breathing</li>
                    <li>muscle/body aches OR illness related fatigue</li>
                    <li>headache</li>
                    <li>new loss of taste or smell</li> 
                    <li>sore throat</li>
                    <li>nausea or vomiting</li>
                    <li>diarrhea</li>
                </ul>`
        },
        optionsNoSpecificSymptoms: {
            Y: 'Yes',
            N: 'No'
        },
    },
    Q_EXPOSURE: {
        label: {
            NOT_HOUSEHOLD: 'In the past {exposureDays} days have you had close contact with a COVID positive person(s) for 15 minutes or longer?',
            HOUSEHOLD: 'In the past {exposureDays} days have you had close contact with a COVID positive person(s) for 15 minutes or longer?',
            HCP: `Have you had close contact (less than 6 feet) with anyone with confirmed COVID-19, beginning 48 hours prior to them becoming infectious, 
            (in or outside the facility) or any person under investigation with lab 
            results not expected back within 72 hours?`
        },
        options: {
            Y: 'Yes',
            N: 'No'
        },
    },
    Q_SYMPTOM_DATE: {
        label: 'What date did the symptom(s) first appear? If unsure, give your best estimate.'
    },
    Q_EXPOSURE_DATE: {
        label: 'What was the latest date of the possible exposure? If unsure, give your best estimate.'
    },
    Q_EXPOSURE_HCP1: {
        label: 'Did you perform an aerosol generating producing procedure without ALL necessary PPE (gown, gloves, eye protection, and respirator)?',
        options: {
            Y: 'Yes',
            N: 'No'
        }
    },
    Q_EXPOSURE_HCP2: {
        label: 'Were you exposed for a prolonged (cumulative of 15 minutes or longer) within a 24 hour period?',
        options: {
            Y: 'Yes',
            N: 'No'
        }
    },
    Q_EXPOSURE_HCP3: {
        label: 'Were you wearing facemask or respirator?',
        options: {
            Y: 'Yes',
            N: 'No'
        }
    },
    Q_EXPOSURE_HCP4: {
        label: 'Were you wearing eye protection when in contact with the COVID-19 positive person who was not wearing a cloth face covering or facemask?',
        options: {
            Y: 'Yes',
            N: 'No'
        }
    },
    Q_TEMPERATURE: {
        label: {
            NOT_HOUSEHOLD: 'What is your temperature right now?',
            HOUSEHOLD: 'What is the highest temperature someone has in your household right now?'
        },
    },
    Q_PROTECTION: {
        label: {
            NOT_HOUSEHOLD: 'Please select the first option in the list below that applies to you:',
            HOUSEHOLD: 'Please select the first option in the list below that applies to you:'
        },
        options: {
            CURRENT: 'VACCINATION STATUS CURRENT (completed primary series of Pfizer/Moderna within 6 months, J&J within 2 months, or boosted)',
            POSITIVE: 'COVID-19 positive in the last 90 days',
            NOT_CURRENT: 'VACCINATION STATUS NOT CURRENT (unvaccinated, OR not boosted and completed primary series of Pfizer/Moderna more than 6 months ago, J&J more than 2 months ago)',
        }
    }
};
