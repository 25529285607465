import './noDataFound.scss';
import React from 'react';
import PropTypes from 'prop-types';
import imgNoData1 from 'apps/lintelio/assets/images/no-data-1.svg';
import imgNoData2 from 'apps/lintelio/assets/images/no-data-2.svg';

const images = [
    imgNoData1,
    imgNoData2
];

const NoDataFound = ({ text, img }) => (
    <div className="no-data-found">
        <img
            className="no-data-found-img"
            src={images[img]}
            alt="No Data Found"
        />
        <h1>{text}</h1>
    </div>
);

NoDataFound.propTypes = {
    text: PropTypes.string.isRequired,
    img: PropTypes.number
};

NoDataFound.defaultProps = {
    img: 0
};

export { NoDataFound };
