import { message as AntDMessage } from 'antd';
import { message as MuiMessage } from 'components';
import { isAdminPortal } from './uri.utils';

export const showError = (error, duration = 10) => {
    if (error && (error.$message || error.message)) {
        if (Object.isDefined(error.$message) && error.$message === false) {
            return false;
        }
        const text = error.$message || error.message;
        if (isAdminPortal) {
            AntDMessage.error(text, duration);
        } else {
            MuiMessage.error(text, duration);
        }
        return {};
    }
    return (error);
};

export const showMsg = (text, type = 'info', duration = 10) => {
    if (isAdminPortal) {
        AntDMessage[type](text, duration);
    } else {
        MuiMessage[type](text, duration);
    }
};
