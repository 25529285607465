import { UserConstants } from './user.constants';

const initialState = Object.freeze({ 
    language: 'en',
    locations: [],
    accounts: [],
    $loading: false,
    $modules: {},
    $locationModules: {},
    $isSysAdmin: false
});

const admin = (state = initialState, action = {}) => {
    switch (action.type) {
        case UserConstants.LOGOUT: {
            return initialState;
        } 
        case UserConstants.LOADED: {
            return {
                ...state,
                ...action.data,
                $loading: false,
            };
        }
        case UserConstants.LOADING: {
            return {
                ...state,
                ...action.data,
                $loading: true,
            };
        }
        case UserConstants.ERROR: {
            return {
                ...state,
                ...action.data,
                error: action.error,
                $loading: false, 
            };
        }
        default:
            return state;
    }
};

export default admin;
