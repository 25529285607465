const transformBenefitsOut = benefits => 
    (typeof (benefits) === 'undefined' ? undefined 
    : benefits.map((item) => {
        // if creating
        if (item.id < 0) {
            return { ...item,
                id: null,
                updatedAt: undefined, 
                deletedAt: undefined, 
                createdAt: undefined,
            };
        }

        return { ...item,
            updatedAt: undefined, 
            deletedAt: undefined, 
            createdAt: undefined,
        };
}));

export default {
    out: transformBenefitsOut
};
