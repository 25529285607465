export default {
    validation: {
        name: 'Carácteres inválidos',
        manychars: 'El nombre debe contener al menos dos caracteres.', 
        string: 'Carácteres inválidos',
        zip: 'Código postal inválido',
        email: 'Correo electrónico inválido',
        number: 'Carácteres inválidos',
        required: 'Campo obligatorio',
        terms: 'Debe aceptar los términos',
        phoneNumber: 'Número de teléfono inválido',
        temperature: 'Ingrese un valor entre {min} °{unit} y {max} °{unit}',
        password: 'Password must be at least 8 characters including one uppercase letter, one lowercase letter and 1 number.', 
        phoneExists: 'Este numero telefonico ya ha sido tomado',
        subdomainExists: 'Este nombre ya ha sido tomado. Porfavor intente otro.',
    }
};
