import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { IconButton } from 'components';
import { BackIcon } from 'components/Icons';
import { useNavigate } from 'routing';
import locales from 'locales';
import { LayoutConstants } from 'modules/layout';

const BackButton = ({ openMenu, text, ...rest }) => {
    const { common: { menu: strings } } = locales;
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onClickDefault = () => {
        navigate(-1);
        if (openMenu) {
            dispatch({ type: LayoutConstants.MENU_OPEN, menu: openMenu });
        }
    };

    return (
        <IconButton 
            onClick={onClickDefault}
            style={{ paddingLeft: 0 }}
            {...rest}
        >
            <BackIcon fontSize="inherit" />
            {text ? <span style={{ fontSize: 12, fontWeight: 700 }}>{strings.back}</span> : ''}
        </IconButton>
    );
};

BackButton.propTypes = {
    openMenu: PropTypes.string,
    text: PropTypes.bool
};

BackButton.defaultProps = {
    openMenu: undefined,
    text: false
};

export default BackButton;
