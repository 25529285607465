export default {
    Q_WORK: {
        label: 'Se esta reportando a trabajar hoy?',
        options: {
            Y: 'Si',
            N: 'No'
        },
    },
    Q_IS_ER: {
        title: '¿Es esta una emergencia?',
        label: 'Pare y llame al {emergencyNumber} si esta experimentando cualquiera de lo siguiente:',
        label1: 'Severa dificultad para respirar',
        label2: 'Dolor de pecho',
        label3: 'Presion u opresion en el pecho',
        label4: 'Dificultad para permanecer conciente',
        label5: 'Confusion',
        label6: 'Labios o cara azulados',
        options: {
            Y: 'Estoy experimentando al menos uno de estos',
            N: 'No tengo ninguno de estos'
        },
    },
    Q_NO_WORK: {
        label: '¿Porque no se esta reportando a trabajar?',
        options: {
            SICK: 'Me estoy sintiendo enfermo/a hoy',
            INJURED: 'Fui herido recientemente y no puedo trabajar',
            NOT_SCHEDULED: 'No estoy agendado para trabajar el dia de hoy',
            PTO: 'Estoy en PTO (Vacaciones)',
            OTHER: 'Otro (por favor especificar)'
        },
    },
};
