export default {
    back: 'Back to Homescreen',
    report: 'Report Injury', 
    resultTitle: 'Please stay by your phone', 
    resultText: 'You have been added to the queue. You will receive a call back in approximately <strong>12 minutes.</strong>', 
    labels: {
        date: 'When did the injury occur?',
        location: 'Where did the accident happen?',
        callbackPhone: 'What is your contact number?',
        reason: 'How did the accident happen?<br><small>(Optional)</small>',
        description: 'What object, equipment, or substance was involved?<br><small>(Optional)</small>',
        activityDetails: 'Describe what you were doing at the time?<br><small>(Optional)</small>',
        medicalCompliant: 'Describe your medical complaint<br><small>(Optional)</small>',
    }
};
