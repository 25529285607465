import './Accordion.scss';
import React from 'react';
import PropTypes from 'prop-types';
import AccordionMUI from '@mui/material/Accordion';
import AccordionDetailsMUI from '@mui/material/AccordionDetails';
import AccordionSummaryMUI from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Accordion = ({ header, children }) => (
    <AccordionMUI disableGutters className="c-accordion">
        <AccordionSummaryMUI
            expandIcon={<ExpandMoreIcon />}
        >
            {header}
        </AccordionSummaryMUI>
        <AccordionDetailsMUI>
            {children}
        </AccordionDetailsMUI>
    </AccordionMUI>
);

Accordion.propTypes = {
    header: PropTypes.any.isRequired,
    children: PropTypes.any.isRequired
};

export { Accordion, };
