import './editableContent.scss';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { SettingsService } from 'modules/settings';
import { showError } from 'utils';
import EditableField from './field';

const EditableContent = ({ name, editable, footer }) => {
    const [item, setItem] = useState();

    useEffect(() => {
        SettingsService.get(name)
            .then((data) => {
                if (data.length) {
                    setItem(data[0]);
                } else {
                    setItem({ key: name });
                }
            })
            .catch(showError);
    }, [name]);
    
    return (
        <EditableField
            item={item}
            editable={editable}
            footer={footer}
        />
    );
};

EditableContent.propTypes = {
    name: PropTypes.string.isRequired,
    editable: PropTypes.bool.isRequired,
    footer: PropTypes.element
};

EditableContent.defaultProps = {
    footer: null
};

export { EditableContent };
