import dayjs from 'dayjs';
import { formats } from './date.utils';

const checkDate = val => (val === null ? null : undefined);

export const APIT = {
    date: {
        in: {
            toLocal: s => (s ? dayjs.utc(s).local() : undefined),

            toUTC: s => (s ? dayjs.utc(s) : undefined),
        },
        out: {
            toUTCStart: d => (d ? d
                    .startOf('day').utc()
                    .format(formats.dateTime.out) : checkDate(d)),
            toUTCEnd: d => (d ? d.endOf('day').utc()
                    .format(formats.dateTime.out) : checkDate(d)),
            toUTC: d => (d ? d.utc().format(formats.dateTime.out) : checkDate(d)),
            toUTCSec: d => (d ? d.utc().format(formats.dateTimeSec.out) : checkDate(d))
        },
    },
    bool: {
        in: (n, reverse) => {
            if (!Object.isDefined(n) || n === null) {
                return undefined;
            }
            if (reverse) return !n;
            return !!n;
        },
        out: (b, reverse) => {
            if (!Object.isDefined(b)) {
                return undefined;
            }
            if (reverse) return b ? 0 : 1;
            return b ? 1 : 0;
        },

    }
};
