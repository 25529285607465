import { api, ApiHeaders } from 'services';
import { ReminderService } from 'modules/reminder';
import { NotificationService } from 'modules/notification';
import Transformer from './transformers';

const ApiBaseUrl = process.env.REACT_APP_API;

class PatientServiceAdmin {
    static create(data) {
        return api
            .post('/patients', Transformer.out(data));
    }
    
    static update(id, data) {
        const dataOut = Transformer.out(data);
        return api
            .put(`/patients/${id}`, dataOut)
            .then(Transformer.in);
    }

    static bulkUpdate(data) {
        const dataOut = Transformer.outBulk(data);
        return api
            .put('/patients/bulk', dataOut);
    }

    static bulkPandemicUpdate(data) {
        const dataOut = Transformer.outBulk(data);
        return api
            .put('/pandemic/patients/bulk', dataOut);
    }

    static updateStatus(id, data) {
        const dataOut = Transformer.out(data);
        return api
            .put(`/patients/${id}/moduleStatuses`, dataOut);
    }

    static get(id) {
        return api.get(`/patients/${id}`)
            .then(Transformer.in);
    }

    static remove(id) {
        return api
            .delete(`./patients/${id}`);
    }

    static list(...args) {
        return api.list('/patients', ...args)
            .then(Transformer.list);
    }
  
    static pandemicList(...args) {
        return api.list('/pandemic/patients', ...args)
            .then(Transformer.list);
    }    
    
    static async import(locationId) {
        const token = await api.token();
        return {
            action: `${ApiBaseUrl}patients/bulk`,
            method: 'POST',
            data: { locationId },
            showUploadList: false,
            headers: {
                Authorization: `Bearer ${token}`,
                ...ApiHeaders
            },
            name: 'file',
            previewFile: () => null,
        }; 
    }

    static reminders(id) {
        return ReminderService.getByPatient(id);
    }

    static remindersUpdate(id, data) {
        return ReminderService.updateByPatient(id, data);
    }

    static notifications(id) {
        return NotificationService.getByPatient(id);
    }
}

export { PatientServiceAdmin }; 
