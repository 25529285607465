/* eslint-disable class-methods-use-this */

import { getTokenPayload, isCognitoUser, isOTPUser } from 'security';
import { api, local } from 'services';
import Transformer from './transformers';
import { PatientServiceAdmin } from './patient.service.admin';

class PatientServiceClass {
    id;

    constructor() {
        this.get = this.get.bind(this);
    }

    setVars = (data = {}) => {
        const { id, token } = data;
        if (token) {
            local.token(token);
        } 
        if (id) {
            this.id = id;
        }
        return data;
    };

    async get() {
        const token = await api.token();
        if (!this.id && token) {
            this.id = getTokenPayload(token).patientId;
        }
        if (!this.id || !token) {
            return Promise.resolve({});
        }
            
        return PatientServiceAdmin.get(this.id)
            .then(this.setVars);
    }

    update(data) {
        return PatientServiceAdmin.update(this.id, data)
            .then(this.setVars);
    } 
    
    // Auth
    // After Cognito SignIn -> get (create if not exists)
    async register(data) {
        const isCognito = await isCognitoUser();
        if (!isCognito) {
            return Promise.resolve({});
        }
        const postData = Transformer.out(data);
        return api.post('/register', postData) 
            .then(Transformer.in)
            .then(this.setVars);
    }

    // Check in username exists in Cognito and create the patient if not
    check(data) {
        const postData = Transformer.out(data);
        return api.post('/me', postData) 
            .then(this.setVars)
            .then(res => ({
                ...res,
                userExists: res.userExists,
                language: res.language.toLowerCase(),
            }));
    }

    sync(data) {
        return api.put(`/patients/${this.id}/sync`, data); 
    }

    async signOut() {
        this.id = undefined;
        const isOTP = await isOTPUser();
        
        if (isOTP) {
            local.token(false);
        } 
        return Promise.resolve(true);
    }

    // Custom AUTH: by Phone OTP
    // validate the code send to phone
    validateCode(data) {
        const postData = Transformer.out(data);
        return api
            .post('/validationCode', postData)
            .then(this.setVars)
            .then(this.get);
    }

    // try again to send the code
    resendValidationCode(data) {
        const postData = Transformer.out(data);
        return api
            .post('/validationCode/newSms', postData);
    }

    // Reminders
    reminders() {
        return PatientServiceAdmin.reminders(this.id);
    }

    remindersUpdate(data) {
        return PatientServiceAdmin.remindersUpdate(this.id, data);
    }
}

const PatientServiceUser = new PatientServiceClass();

export { PatientServiceUser }; 
