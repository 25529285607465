// Routes
import err404 from './vn.routes.404';
import err503 from './vn.routes.503';
import alerts from './vn.routes.alerts';
import attendance from './vn.routes.attendance';
import auth from './vn.routes.auth';
import benefits from './vn.routes.benefits';
import history from './vn.routes.history';
import home from './vn.routes.home';
import pandemicReportConfirm from './vn.routes.pandemicReportConfirm';
import profile from './vn.routes.profile';
import reminders from './vn.routes.reminders';
import reports from './vn.routes.reports';
import screening from './vn.routes.screening';
import screenReport from './vn.routes.screenReport';
import support from './vn.routes.support';
import telehealth from './vn.routes.telehealth';
import testingIntegration from './vn.routes.testingIntegration';
import workplaceInjury from './vn.routes.workplaceInjury';
import integrityLine from './vn.routes.integrityLine';
import immunizations from './vn.routes.immunizations';
// import lifeworks from './vn.routes.lifeworks';

import api from './vn.api';
import fields from './vn.fields';
import questions from './vn.screening.questions';
import results from './vn.screening.results';
import attendanceQuestions from './vn.attendance.questions';
import attendanceResults from './vn.attendance.results';
import common from './vn.common';

export default {
    api,
    common,
    fields,
    routes: {
        404: err404,
        503: err503,
        alerts,
        attendance,
        auth,
        benefits,
        history,
        home,
        pandemicReportConfirm,
        profile,
        reminders,
        reports,
        screening,
        screenReport,
        support,
        telehealth,
        testingIntegration,
        workplaceInjury,
        integrityLine,
        immunizations,
        // lifeworks
    },
    models: {
        screening: {
            questions,
            results
        },
        attendance: {
            attendanceQuestions,
            attendanceResults
        },
    },
};
