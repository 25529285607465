import { FtoC } from 'utils';
import { LOCATION_FLOW_TYPE } from 'modules/location';
import { JOB_CATEGORY } from 'modules/jobtitle';
import { PROTECTION_TYPES, hasSymptomsOrExposure, hasExposure, hasSymptoms } from './helpers';
import getQuestions from './questions';
import getExits from './exits';

import { QUESTION_OUTCOMES } from '../../screening.model';

const { HOUSEHOLD, EVENT } = LOCATION_FLOW_TYPE;
const { HEALTHCARE_PROVIDER, COMMUNITY_EXPOSURE } = JOB_CATEGORY;

const CovidFlow = ({
    customQuestions = [],
    emergencyNumber,
    exposureDays,
    flowType,
    jobCategory,
    hideCongestionOption,
    isSpecificSymptomsEnabled,
    temperatureUnit, 
    temperatureThreshold,
    $locationTitle,
    passIfVaccinatedEnabled,
    isTriage,
    $isVaccinated
}) => {
    const temperatureThresholdInUnit = temperatureUnit === 'C' ? FtoC(temperatureThreshold) : temperatureThreshold;
    const ignoreExposure = passIfVaccinatedEnabled && $isVaccinated;

    const isHCP = jobCategory === HEALTHCARE_PROVIDER;
    const isCommunity = jobCategory === COMMUNITY_EXPOSURE;
    const isHouseholdOrEvent = [HOUSEHOLD, EVENT].includes(flowType);
    
    const tempThresholdHCP = temperatureUnit === 'C' ? FtoC(100) : 100;
    const tempThreshold = isHCP ? tempThresholdHCP : temperatureThresholdInUnit;
    
    const temperature = `${tempThreshold.toFixed(1)} °${temperatureUnit}`;
    const temperatureHCP = `${tempThresholdHCP.toFixed(1)} °${temperatureUnit}`;

    const typeHousehold = isHouseholdOrEvent ? 'HOUSEHOLD' : 'NOT_HOUSEHOLD';
    const typeHouseholdHCP = isHCP ? 'HCP' : typeHousehold;

    const qn = getQuestions({
        typeHousehold,
        typeHouseholdHCP,
        jobCategory,
        temperatureUnit,
        params: {
            emergencyNumber,
            temperature,
            exposureDays,
        }
    });

    const rs = getExits(
        {
            flowType,
            ignoreExposure,
            params: {
                $locationTitle,
                emergencyNumber,
                temperature,
                temperatureHCP
            }
        }
    );

    const filterIt = outcome => customQuestions
        .filter(item => item.outcome === outcome);

    const CustomSymptomQuestions = filterIt(QUESTION_OUTCOMES.SYMPTOMS);
    const CustomExposureQuestions = filterIt(QUESTION_OUTCOMES.EXPOSURE);
    const CustomIgnoreQuestions = filterIt(QUESTION_OUTCOMES.IGNORE);
    const CustomOnFailQuestions = filterIt(QUESTION_OUTCOMES.ON_FAIL);
    const CustomOtherQuestions = customQuestions
        .filter(item =>
            ![
                QUESTION_OUTCOMES.SYMPTOMS, 
                QUESTION_OUTCOMES.EXPOSURE, 
                QUESTION_OUTCOMES.IGNORE,
                QUESTION_OUTCOMES.ON_FAIL
            ]
                .includes(item.outcome));

    return [
        {
            ...qn.Q_IS_ER,
            outcome: QUESTION_OUTCOMES.ER,
        },
        {
            ...rs.R_ER,
            show: ({ ER }) => ER
        },
        {
            ...qn.Q_SYMPTOMS_SPECIFIC_HIDE_CONGESTION,
            show: () => isSpecificSymptomsEnabled && hideCongestionOption,
            outcome: QUESTION_OUTCOMES.SYMPTOMS,
        },
        {
            ...qn.Q_SYMPTOMS_SPECIFIC,
            show: () => isSpecificSymptomsEnabled && !hideCongestionOption,
            outcome: QUESTION_OUTCOMES.SYMPTOMS,
        },
        {
            ...qn.Q_SYMPTOMS_NON_SPECIFIC_HIDE_CONGESTION,
            show: () => !isSpecificSymptomsEnabled && hideCongestionOption,
            outcome: QUESTION_OUTCOMES.SYMPTOMS,
        },
        {
            ...qn.Q_SYMPTOMS_NON_SPECIFIC,
            show: () => !isSpecificSymptomsEnabled && !hideCongestionOption,
            outcome: QUESTION_OUTCOMES.SYMPTOMS,
        },
        {
            ...qn.Q_TEMPERATURE,
            outcome: QUESTION_OUTCOMES.SYMPTOMS,
            outcomeFn: value => value >= tempThreshold,
        },
        ...CustomSymptomQuestions,
        {
            ...qn.Q_SYMPTOM_DATE,
            show: ({ SYMPTOMS }) => SYMPTOMS,
        },
        {
            ...qn.Q_EXPOSURE,
            show: () => !isHCP,
            outcome: QUESTION_OUTCOMES.EXPOSURE,
        },
        {
            ...qn.Q_EXPOSURE,
            show: () => !!isHCP,
            outcome: QUESTION_OUTCOMES.EXPOSURE_HCP,
        },
        {
            ...qn.Q_EXPOSURE_HCP1,
            show: ({ EXPOSURE_HCP }) => 
                isHCP && EXPOSURE_HCP,
            outcome: QUESTION_OUTCOMES.EXPOSURE_HCP1,
        },
        {
            ...qn.Q_EXPOSURE_HCP2,
            show: ({ EXPOSURE_HCP, EXPOSURE_HCP1 }) =>  
                isHCP && EXPOSURE_HCP && !EXPOSURE_HCP1,
            outcome: QUESTION_OUTCOMES.EXPOSURE_HCP2,
        },
        {
            ...qn.Q_EXPOSURE_HCP3,
            show: ({ EXPOSURE_HCP, EXPOSURE_HCP1, EXPOSURE_HCP2 }) => 
                isHCP && EXPOSURE_HCP && !EXPOSURE_HCP1 && EXPOSURE_HCP2,
            outcome: QUESTION_OUTCOMES.EXPOSURE_HCP3,
            // Y = No expose, N = HCP expose which will affect the showing of next qs
        },
        {
            ...qn.Q_EXPOSURE_HCP4,
            show: ({ EXPOSURE_HCP, EXPOSURE_HCP1, EXPOSURE_HCP2, EXPOSURE_HCP3 }) => 
                isHCP
                && EXPOSURE_HCP && !EXPOSURE_HCP1 && EXPOSURE_HCP2 && !EXPOSURE_HCP3,
            outcome: QUESTION_OUTCOMES.EXPOSURE,
        },
        ...CustomExposureQuestions,
        {
            ...qn.Q_EXPOSURE_DATE,
            show: outcomes => hasExposure(outcomes),
        },
        ...CustomOtherQuestions,
        ...CustomIgnoreQuestions,
        {
            ...rs.R_NURSE,
            show: (outcomes) => {
                if (!isTriage) return false;
                if (ignoreExposure) {
                    return hasSymptoms(outcomes);
                } 
                return hasSymptomsOrExposure(outcomes);
            }
        },
        ...CustomOnFailQuestions.map(item => ({
            ...item, 
            show: hasSymptomsOrExposure,
            outcome: undefined
        })),
        {
            ...qn.Q_PROTECTION,
            show: outcomes => (isHouseholdOrEvent || isCommunity) 
                && hasSymptomsOrExposure(outcomes),
            outcome: QUESTION_OUTCOMES.PROTECTION,
            outcomeFn: value => value,
        },
        // Household with symptoms
        {
            ...rs.R_ISOLATE_SYMPTOMS_HOUSEHOLD_OR_EVENT[PROTECTION_TYPES.CURRENT],
            show: outcomes => isHouseholdOrEvent 
                && outcomes.PROTECTION === PROTECTION_TYPES.CURRENT 
                && hasSymptoms(outcomes)
        },
        {
            ...rs.R_ISOLATE_SYMPTOMS_HOUSEHOLD_OR_EVENT[PROTECTION_TYPES.POSITIVE],
            show: outcomes => isHouseholdOrEvent 
                && outcomes.PROTECTION === PROTECTION_TYPES.POSITIVE 
                && hasSymptoms(outcomes)
        },
        {
            ...rs.R_ISOLATE_SYMPTOMS_HOUSEHOLD_OR_EVENT[PROTECTION_TYPES.NOT_CURRENT],
            show: outcomes => isHouseholdOrEvent 
                && outcomes.PROTECTION === PROTECTION_TYPES.NOT_CURRENT 
                && hasSymptoms(outcomes)
        },
        // Community with symptoms
        {
            ...rs.R_ISOLATE_SYMPTOMS_COMMUNITY[PROTECTION_TYPES.CURRENT],
            show: outcomes => isCommunity 
                && outcomes.PROTECTION === PROTECTION_TYPES.CURRENT 
                && hasSymptoms(outcomes)
        },
        {
            ...rs.R_ISOLATE_SYMPTOMS_COMMUNITY[PROTECTION_TYPES.POSITIVE],
            show: outcomes => isCommunity 
                && outcomes.PROTECTION === PROTECTION_TYPES.POSITIVE 
                && hasSymptoms(outcomes)
        },
        {
            ...rs.R_ISOLATE_SYMPTOMS_COMMUNITY[PROTECTION_TYPES.NOT_CURRENT],
            show: outcomes => isCommunity 
                && outcomes.PROTECTION === PROTECTION_TYPES.NOT_CURRENT 
                && hasSymptoms(outcomes)
        },
        {
            ...rs.R_ISOLATE_SYMPTOMS_HCP,
            show: outcomes => isHCP && hasSymptoms(outcomes)
        },
        {
            ...rs.R_ISOLATE_SYMPTOMS_DEFAULT,
            show: hasSymptoms
        },
        // Household with exposure
        {
            ...rs.R_ISOLATE_EXPOSURE_HOUSEHOLD[PROTECTION_TYPES.CURRENT],
            show: outcomes => isHouseholdOrEvent 
                    && outcomes.PROTECTION === PROTECTION_TYPES.CURRENT 
                    && hasExposure(outcomes)
        },
        {
            ...rs.R_ISOLATE_EXPOSURE_HOUSEHOLD[PROTECTION_TYPES.POSITIVE],
            show: outcomes => isHouseholdOrEvent 
                && outcomes.PROTECTION === PROTECTION_TYPES.POSITIVE 
                && hasExposure(outcomes)
        },
        {
            ...rs.R_ISOLATE_EXPOSURE_HOUSEHOLD[PROTECTION_TYPES.NOT_CURRENT],
            show: outcomes => isHouseholdOrEvent 
                && outcomes.PROTECTION === PROTECTION_TYPES.NOT_CURRENT 
                && hasExposure(outcomes)
        },
        // Community with exposure
        {
            ...rs.R_ISOLATE_EXPOSURE_COMMUNITY[PROTECTION_TYPES.CURRENT],
            show: outcomes => isCommunity 
                && outcomes.PROTECTION === PROTECTION_TYPES.CURRENT 
                && hasExposure(outcomes)
        },
        {
            ...rs.R_ISOLATE_EXPOSURE_COMMUNITY[PROTECTION_TYPES.POSITIVE],
            show: outcomes => isCommunity 
                && outcomes.PROTECTION === PROTECTION_TYPES.POSITIVE 
                && hasExposure(outcomes)
        },
        {
            ...rs.R_ISOLATE_EXPOSURE_COMMUNITY[PROTECTION_TYPES.NOT_CURRENT],
            show: outcomes => isCommunity 
                && outcomes.PROTECTION === PROTECTION_TYPES.NOT_CURRENT 
                && hasExposure(outcomes)
        },
        {
            ...rs.R_ISOLATE_EXPOSURE_HCP,
            show: outcomes => isHCP 
                && hasExposure(outcomes)
        },
        {
            ...rs.R_ISOLATE_EXPOSURE_DEFAULT,
            show: hasExposure
        },
        rs.R_CLEARED
    ];
};

export default CovidFlow;
