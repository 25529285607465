import './noAccess.scss';
import React from 'react';
import PropTypes from 'prop-types';

import { Wrapper } from 'apps/lintelio/layout';

const NoAccess = ({ to }) => (
    <Wrapper 
        title={to}
        className="no-access"
    >
        <div className="no-access-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="111" height="100" viewBox="0 0 111 100" fill="none" className="back">
                <g fill="none" strokeLinecap="round" stroke="none" strokeWidth="2">
                    <path d="M56.8476 0.218971L53.2359 1.1539L8.73847 12.6725L5.12672 13.6074C3.37362 14.0633 1.87228 15.1987 0.95206 16.7643C0.0318431 18.33 -0.232092 20.1982 0.218159 21.9589L15.8795 82.9206C16.3337 84.6803 17.4647 86.1874 19.0245 87.1111C20.5842 88.0348 22.4453 88.2997 24.1994 87.8478L24.2088 87.8454L75.911 74.4618L75.9204 74.4594C77.6735 74.0034 79.1748 72.8681 80.095 71.3024C81.0152 69.7367 81.2792 67.8686 80.8289 66.1078L65.1676 5.1462C64.7134 3.38644 63.5824 1.87939 62.0226 0.955672C60.4628 0.0319563 58.6018 -0.232986 56.8476 0.218971Z" fill="#F2F2F2" />
                    <path d="M57.3377 2.12956L53.0598 3.23693L9.8945 14.4107L5.6167 15.518C4.36828 15.8427 3.29913 16.6512 2.64382 17.7661C1.98851 18.8811 1.80055 20.2114 2.12118 21.4653L17.7825 82.4269C18.1059 83.6801 18.9114 84.7533 20.0221 85.4111C21.1329 86.0689 22.4582 86.2576 23.7073 85.9357L23.7167 85.9333L75.4189 72.5497L75.4283 72.5473C76.6767 72.2226 77.7458 71.4141 78.4011 70.2991C79.0564 69.1842 79.2444 67.8538 78.9238 66.6L63.2624 5.63836C62.939 4.38521 62.1336 3.31201 61.0229 2.65421C59.9121 1.99641 58.5868 1.80773 57.3377 2.12956V2.12956Z" fill="white" />
                    <path d="M54.1077 21.8702L27.9528 28.6407C27.6607 28.7163 27.3506 28.6723 27.0907 28.5185C26.8309 28.3646 26.6426 28.1134 26.5673 27.8202C26.492 27.5269 26.5358 27.2157 26.689 26.9549C26.8423 26.694 27.0926 26.505 27.3847 26.4294L53.5396 19.659C53.8316 19.5837 54.1415 19.6278 54.4011 19.7817C54.6607 19.9356 54.8488 20.1867 54.9241 20.4797C54.9994 20.7728 54.9557 21.0839 54.8026 21.3446C54.6495 21.6053 54.3996 21.7944 54.1077 21.8702V21.8702Z" fill="#F2F2F2" />
                    <path d="M59.6097 24.4257L28.9113 32.3722C28.6192 32.4478 28.3091 32.4039 28.0492 32.25C27.7894 32.0961 27.6011 31.8449 27.5258 31.5517C27.4505 31.2585 27.4942 30.9472 27.6475 30.6864C27.8008 30.4256 28.0511 30.2366 28.3432 30.161L59.0416 22.2144C59.3337 22.1388 59.6438 22.1827 59.9037 22.3366C60.1635 22.4905 60.3518 22.7417 60.4271 23.0349C60.5024 23.3281 60.4586 23.6394 60.3053 23.9002C60.1521 24.161 59.9018 24.35 59.6097 24.4257V24.4257Z" fill="#F2F2F2" />
                    <path d="M23.488 35.5828L14.232 37.9788C14.0919 38.0149 13.9433 37.9937 13.8188 37.9199C13.6942 37.8462 13.6039 37.7259 13.5676 37.5853L11.4429 29.3149C11.407 29.1743 11.428 29.0252 11.5015 28.9001C11.575 28.7751 11.6949 28.6845 11.8349 28.6481L21.0909 26.2521C21.2309 26.216 21.3795 26.2371 21.5041 26.3109C21.6286 26.3847 21.7189 26.505 21.7552 26.6455L23.8799 34.9159C23.9159 35.0565 23.8948 35.2057 23.8213 35.3307C23.7478 35.4557 23.6279 35.5464 23.488 35.5828V35.5828Z" fill="#E6E6E6" />
                    <path d="M27.7839 52.3054L18.5279 54.7014C18.3878 54.7375 18.2392 54.7164 18.1147 54.6426C17.9901 54.5688 17.8998 54.4485 17.8635 54.308L15.7388 46.0376C15.7029 45.897 15.724 45.7478 15.7974 45.6228C15.8709 45.4978 15.9908 45.4071 16.1308 45.3707L25.3867 42.9747C25.5268 42.9386 25.6754 42.9598 25.8 43.0336C25.9245 43.1073 26.0148 43.2277 26.0511 43.3682L28.1758 51.6386C28.2117 51.7792 28.1907 51.9283 28.1172 52.0534C28.0437 52.1784 27.9238 52.269 27.7839 52.3054V52.3054Z" fill="#E6E6E6" />
                    <path d="M32.0798 69.0286L22.8238 71.4246C22.6837 71.4607 22.5351 71.4395 22.4106 71.3657C22.286 71.292 22.1957 71.1717 22.1594 71.0311L20.0347 62.7607C19.9988 62.6201 20.0198 62.471 20.0933 62.346C20.1668 62.2209 20.2867 62.1303 20.4267 62.0939L29.6827 59.6979C29.8227 59.6618 29.9713 59.6829 30.0959 59.7567C30.2204 59.8305 30.3107 59.9508 30.347 60.0913L32.4717 68.3617C32.5077 68.5023 32.4866 68.6515 32.4131 68.7765C32.3396 68.9015 32.2197 68.9922 32.0798 69.0286V69.0286Z" fill="#E6E6E6" />
                </g>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="111" height="100" viewBox="0 0 111 100" className="lines">
                <g fill="none" strokeLinecap="round" stroke="#E6E6E6" strokeWidth="2">
                    <path d="M92.9194 17.645H39.5064C38.2167 17.6465 36.9803 18.1614 36.0684 19.0767C35.1565 19.9921 34.6435 21.2332 34.6421 22.5278V85.4838C34.6435 86.7783 35.1565 88.0194 36.0684 88.9348C36.9803 89.8502 38.2167 90.3651 39.5064 90.3665H92.9194C94.2091 90.365 95.4455 89.8501 96.3574 88.9348C97.2693 88.0194 97.7822 86.7783 97.7836 85.4838V22.5278C97.7822 21.2332 97.2693 19.9921 96.3574 19.0768C95.4455 18.1614 94.2091 17.6465 92.9194 17.645V17.645Z" fill="white" className="paper" />
                    <line x1="65" y1="32" x2="85" y2="32" className="line-1" />
                    <line x1="65" y1="36" x2="90" y2="36" className="line-2" />
                    <line x1="58" y1="51" x2="85" y2="51" className="line-3" />
                    <line x1="58" y1="55" x2="90" y2="55" className="line-4" />
                    <line x1="58" y1="68" x2="85" y2="68" className="line-5" />
                    <line x1="58" y1="72" x2="90" y2="72" className="line-6" />
                </g>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="111" height="100" viewBox="0 0 111 100" className="front">
                <g fill="#E6E6E6" stroke="none">
                    <path d="M60.5898 41.5379H42.231C42.0864 41.5378 41.9477 41.4801 41.8455 41.3774C41.7432 41.2748 41.6857 41.1356 41.6855 40.9905V26.608C41.6857 26.4629 41.7432 26.3237 41.8455 26.2211C41.9477 26.1184 42.0864 26.0607 42.231 26.0605H60.5898C60.7344 26.0607 60.873 26.1184 60.9753 26.2211C61.0775 26.3237 61.1351 26.4629 61.1352 26.608V40.9905C61.1351 41.1356 61.0775 41.2748 60.9753 41.3774C60.873 41.4801 60.7344 41.5378 60.5898 41.5379V41.5379Z" className="square-1" />
                    <path d="M51.8195 57.842H42.2608C42.1162 57.8418 41.9775 57.7841 41.8753 57.6815C41.773 57.5788 41.7155 57.4397 41.7153 57.2945V48.7536C41.7155 48.6084 41.773 48.4692 41.8753 48.3666C41.9775 48.2639 42.1162 48.2062 42.2608 48.2061H51.8195C51.9642 48.2062 52.1028 48.2639 52.2051 48.3666C52.3073 48.4692 52.3648 48.6084 52.365 48.7536V57.2945C52.3648 57.4397 52.3073 57.5788 52.2051 57.6815C52.1028 57.7841 51.9642 57.8418 51.8195 57.842V57.842Z" className="square-2" />
                    <path d="M51.8195 75.112H42.2608C42.1162 75.1119 41.9775 75.0541 41.8753 74.9515C41.773 74.8489 41.7155 74.7097 41.7153 74.5645V66.0236C41.7155 65.8784 41.773 65.7393 41.8753 65.6366C41.9775 65.534 42.1162 65.4762 42.2608 65.4761H51.8195C51.9642 65.4762 52.1028 65.534 52.2051 65.6366C52.3073 65.7393 52.3648 65.8784 52.365 66.0236V74.5645C52.3648 74.7097 52.3073 74.8489 52.2051 74.9515C52.1028 75.0541 51.9642 75.1119 51.8195 75.112V75.112Z" className="square-3" />
                </g>
            </svg>
        </div>
        <div className="text">
            <h2>No Access</h2>
            <h3>Sorry, you are not authorized to access this page</h3>
        </div>
    </Wrapper>
);

NoAccess.propTypes = {
    to: PropTypes.string
};

NoAccess.defaultProps = {
    to: 'Page'
};

export default NoAccess;
