/* eslint-disable key-spacing */
export default {
    titles: {
        er: 'Call {emergencyNumber}.',
        isolate: 'Please click the \'Back to Homescreen\' button below for your next steps.',
        caution: 'Caution',
        pass: 'Pass'
    },
    er: {
        HOUSEHOLD: 'This may be a medical emergency',
        EVENT: 'You may be having a medical emergency.',
        EMPLOYER: 'You may be having a medical emergency.',
    },
    cleared: {
        title: 'Pass',
        HOUSEHOLD: 'Per CDC, your answers indicate no one in your household is experiencing any COVID-19 like symptoms. If someone should start to feel sick, please report to the school immediately.',
        EMPLOYER: 'Per CDC, your answers indicate you do not have any COVID-19 like symptoms. You may report to work today. If you start to feel sick during your shift, please report to your manager immediately and leave work.',
        EVENT: 'Per CDC, your answers indicate you do not have any COVID-19 like symptoms. If you start to feel sick, leave immediately and report to the organization contact.'
    },
    nurse: 'We think it`s a good idea for you to talk to a healthcare professional. ',

isolateSymptomsHCP: `Per CDC, your answers indicate you should self-isolate for:
* At least 10 days from the day your symptoms started
* AND your symptoms have improved
* AND You have not had a fever for at least 24 hours (and not used any fever reducing medications)      

1. Maintain physical distance (greater than 6 feet) from others. Wear a cloth face covering in public settings. Do not gather in groups, stay out of crowded places, and avoid mass gatherings.

2. Check your temperature twice daily.

3. Continue to monitor for changes in your symptoms: fever ({temperatureHCP} or higher) or chills, new or worsening cough, shortness of breath or difficulty breathing, muscle or body aches, illness related fatigue, headache, new loss of taste or smell, sore throat, congestion or runny nose, nausea or vomiting, diarrhea. If your symptoms worsen or do not resolve, contact your medical provider or local health department for further instructions.

4. CALL {emergencyNumber} if you have or develop any of the following:
* Severe difficulty breathing
* Chest pain
* Pressure or tightness in the chest
* Difficulty remaining conscious
* Bluish lips or face  

5. Testing might be recommended. Access to testing varies. You can contact your medical provider or local health department to see if this is available in your area.

6. You should not stop self-isolation until you have received clearance from a medical provider.                                   

7. Contact facility occupational health or infection control program for further direction about returning to work
`,
isolateExposureHCP: `Per CDC, your answers indicate you should self-isolate for 14 days from your last exposure.

1. Maintain physical distance (greater than 6 feet) from others. Wear a cloth face covering in public settings. Do not gather in groups, stay out of crowded places, and avoid mass gatherings.

2. Monitor for presence of fever or chills ({temperatureHCP} for healthcare personnel), cough, shortness of breath or difficulty breathing, muscle or body aches, illness related fatigue, headache, new loss of taste or smell, sore throat, congestion or runny nose, nausea or vomiting, or diarrhea at least once daily. If you develop any symptoms consistent with COVID-19, you should notify your facility occupational health or infection control for further direction.

3. CALL {emergencyNumber} you have or develop any of the following:
* Severe difficulty breathing
* Chest pain
* Pressure or tightness in the chest
* Difficulty remaining conscious
* Bluish lips or face
`,
isolateSymptomsDEFAULT: `Per CDC, your answers indicate you should self-isolate for:
* At least 10 days from the day your symptoms started
* AND your symptoms have improved
* AND You have not had a fever for at least 24 hours (and not used any fever reducing medications)    

1. Maintain physical distance (greater than 6 feet) from others. Wear a cloth face covering in public settings. Do not gather in groups, stay out of crowded places, and avoid mass gatherings.

2. Check your temperature twice daily.

3. Continue to monitor for changes in your symptoms: fever ({temperature} or higher) or chills, new or worsening cough, shortness of breath or difficulty breathing, muscle or body aches, illness related fatigue, headache, new loss of taste or smell, sore throat, congestion or runny nose, nausea or vomiting, diarrhea. If your symptoms worsen or do not resolve, contact your medical provider or local health department for further instructions.

4. CALL {emergencyNumber} if you have or develop any of the following:
* Severe difficulty breathing
* Chest pain
* Pressure or tightness in the chest
* Difficulty remaining conscious
* Bluish lips or face

5. Testing might be recommended. Access to testing varies. You can contact your medical provider or local health department to see if this is available in your area.

6. You should not stop self-isolation until you have received clearance from a medical provider.                                   

`,
isolateExposureDEFAULT: `Per CDC, your answers indicate you should self-isolate for 14 days from your last exposure.

1. Maintain physical distance (greater than 6 feet) from others. Wear a cloth face covering in public settings. Do not gather in groups, stay out of crowded places, and avoid mass gatherings.

2. Check your temperature twice daily.

3. Monitor for symptoms including fever ({temperature} or higher) or chills, new or worsening cough, shortness of breath or difficulty breathing, muscle or body aches, illness related fatigue, headache, new loss of taste or smell, sore throat, congestion or runny nose, nausea or vomiting, diarrhea. If you develop symptoms, contact your medical provider or local health department for further instructions.

4. CALL {emergencyNumber} if you have or develop any of the following:
* Severe difficulty breathing
* Chest pain
* Pressure or tightness in the chest
* Difficulty remaining conscious
* Bluish lips or face

5. Testing might be recommended. Access to testing varies. You can contact your medical provider or local health department to see if this is available in your area.

6. You should not stop self-isolation until you have received clearance from a medical provider.
`,

isolateExposureCOMMUNITY_CURRENT: `
1. Follow company-specific directions for testing, quarantine and for reporting to work.
2. Wear a mask when around others for 10 days.
3. Get a COVID test on day 5 if possible. 
4. If you develop OR currently have symptoms, get a COVID test and stay home. 
5. These recommendations do not take the place of state, local, or tribal laws, rules, and regulations. 
6. Contact your Primary Care provider for further questions.
7. Go to CDC.gov/coronavirus or your local health department website for additional answers to frequently asked questions and further information.
`,
isolateSymptomsCOMMUNITY_CURRENT: `
1. Follow company-specific directions for testing, quarantine and for reporting to work.
2. Wear a mask when around others for 10 days. 
3. If your symptoms are resolving after 5 days, you can leave your home. 
4. Continue to wear a mask around others for an additional 5 days. 
5. These recommendations do not take the place of state, local, or tribal laws, rules, and regulations.
6. Contact your Primary Care provider for further questions.
7. Go to CDC.gov/coronavirus or your local health department website for additional answers to frequently asked questions and further information.
`,
isolateExposureHOUSEHOLD_CURRENT: `
1. Follow company and school-specific directions for testing, quarantine and for reporting to work or school.
2. Wear a mask when around others for 10 days. 
3. Get a COVID test on day 5 if possible. 
4. If you develop OR currently have symptoms, get a COVID test and stay home. 
5. These recommendations do not take the place of state, local, or tribal laws, rules, and regulations.
6. Contact a Primary Care provider for further questions.
7. Go to CDC.gov/coronavirus or your local health department website for additional answers to frequently asked questions and further information.
`,
isolateSymptomsHOUSEHOLD_CURRENT: `
1. Follow company and school-specific directions for testing, quarantine and for reporting to work or school.
2. Wear a mask when around others for 10 days. 
3. If symptoms are resolving after 5 days, self-isolation can be ended.
4. Continue to wear a mask around others for an additional 5 days. 
5. These recommendations do not take the place of state, local, or tribal laws, rules, and regulations.
6. Contact a Primary Care provider for further questions.
7. Go to CDC.gov/coronavirus or local health department website for additional answers to frequently asked questions and further information.
`,
isolateExposureCOMMUNITY_POSITIVE: `
1. Follow company-specific directions for testing, quarantine and for reporting to work.
2. Per CDC guidelines, you do not need to get a test unless you develop new symptoms. 
3. If you develop or currently have any new symptoms, report to your manager, self-isolate immediately and take a COVID test. 
4. These recommendations do not take the place of state, local, or tribal laws, rules, and regulations.  
5. Contact your Primary Care provider for further questions. 
6. Go to CDC.gov/coronavirus or your local health department website for additional answers to frequently asked questions and further information.
`,
isolateSymptomsCOMMUNITY_POSITIVE: `
1. Follow company-specific directions for testing, quarantine and for reporting to work.
2. Wear a mask when around others for 10 days. 
3. If your symptoms are resolving after 5 days, you can leave your home. 
4. Continue to wear a mask around others for an additional 5 days. 
5. These recommendations do not take the place of state, local, or tribal laws, rules, and regulations.
6. Contact your Primary Care provider for further questions.
7. Go to CDC.gov/coronavirus or your local health department website for additional answers to frequently asked questions and further information.
`,
isolateExposureHOUSEHOLD_POSITIVE: `
1. Follow company and school-specific directions for testing, quarantine and for reporting to work or school.
2. Per CDC guidelines, a COVID test is not needed unless new symptoms develop.
3. If currently symptomatic or new symptoms develop, report to administration or manager, self-isolate immediately and take a COVID test. 
4. These recommendations do not take the place of state, local, or tribal laws, rules, and regulations.
5. Contact a Primary Care provider for further questions. 
6. Go to CDC.gov/coronavirus or your local health department website for additional answers to frequently asked questions and further information.
`,
isolateSymptomsHOUSEHOLD_POSITIVE: `
1. Follow company and school-specific directions for testing, quarantine and for reporting to work or school.
2. Wear a mask when around others for 10 days.
3. If symptoms are resolving after 5 days, self-isolation can be ended.
4. Continue to wear a mask around others for an additional 5 days. 
5. These recommendations do not take the place of state, local, or tribal laws, rules, and regulations.
6. Contact a Primary Care provider for further questions.
7. Go to CDC.gov/coronavirus or local health department website for additional answers to frequently asked questions and further information.
`,
isolateExposureCOMMUNITY_NOT_CURRENT: `
1. Follow company-specific directions for testing, quarantine and for reporting to work. 
2. If in CA, per California Department of Health, as long as you are not having symptoms, you do not need to isolate.
3. Wear a mask when around others for 10 days. 
4. Get a COVID test on day 5 if possible. 
5. If you develop OR currently have symptoms, get a COVID test and stay home. 
6. These recommendations do not take the place of state, local or triable laws, rules, and regulations.
7. Contact your Primary Care provider for further questions.
8. Go to CDC.gov/coronavirus or your local health department website for additional answers to frequently asked questions and further information.
`,
isolateSymptomsCOMMUNITY_NOT_CURRENT: `
1. Follow company-specific directions for testing, quarantine and for reporting to work.
2. Wear a mask when around others for 10 days.. 
3. If your symptoms are resolving after 5 days, you can leave your home. 
4. Continue to wear a mask around others for an additional 5 days. 
5. These recommendations do not take the place of state, local, or tribal laws, rules, and regulations.
6. Contact your Primary Care provider for further questions.
7. Go to CDC.gov/coronavirus or your local health department website for additional answers to frequently asked questions and further information.
`,
isolateExposureHOUSEHOLD_NOT_CURRENT: `
1. Follow company and school-specific directions for testing, quarantine and for reporting to work or school. 
2. If in CA, per California Department of Health, as long as you are not having symptoms, you do not need to isolate.
3. Wear a mask when around others for 10 days. 
4. Get a COVID test on day 5 if possible. 
5. If you develop OR currently have symptoms, get a COVID test and stay home. 
6. These recommendations do not take the place of state, local or triable laws, rules, and regulations.
7. Contact your Primary Care provider for further questions.
8. Go to CDC.gov/coronavirus or your local health department website for additional answers to frequently asked questions and further information.

`,
isolateSymptomsHOUSEHOLD_NOT_CURRENT: `
1. Follow company and school-specific directions for testing, quarantine and for reporting to work or school.
2. Wear a mask when around others for 10 days.
3. If symptoms are resolving after 5 days, self-isolation can be ended.
4. Continue to wear a mask around others for an additional 5 days. 
5. These recommendations do not take the place of state, local, or tribal laws, rules, and regulations. 
6. Contact a Primary Care provider for further questions.
7. Go to CDC.gov/coronavirus or local health department website for additional answers to frequently asked questions and further information.
`,

isolateBanner: ''
};
