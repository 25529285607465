export default {
    back: 'Regresar a la pantalla de Inicio',
    report: 'Reportar herida',
    resultTitle: 'Por favor mantengase cerca del telefono',
    resultText: 'Ha sido añadido a la lista. Estara recibiendo una llamada devuelta en aproximadamente <strong>12 minutos.</strong>',
    labels: {
        date: 'Donde ocurrio la lesion?',
        location: 'Donde ocurrio el accidente?',
        callbackPhone: 'Cual es su numero de contacto?',
        reason: 'Como ocurrio el accidente?<br><small>(Optional)</small>',
        description: 'Que objeto, equipo, o sustancia estuvo involucrada?<br><small>(Optional)</small>',
        activityDetails: 'Describa lo que estaba haciendo en ese momento?<br><small>(Optional)</small>',
        medicalCompliant: 'Describa su queja medica<br><small>(Optional)</small>',
    }
};
