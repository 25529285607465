import './Block.scss';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useBlocker, useLocation, useNavigate } from 'routing';
import IcoWarning from 'assets/images/ico.warning.svg';
import { Modal } from '../Modal';

const BlockModal = ({ isBlocked }) => {
    const [visible, setVisible] = useState(false);
    const [when, setWhen] = useState(false);

    const location = useLocation();
    const navigate = useNavigate();
    const [lastLocation, setLastLocation] = useState(location);
    const [leaveConfirmed, setLeaveConfirmed] = useState(false);
    
    useEffect(() => {
        setWhen(isBlocked);
    }, [isBlocked]);

    const handleBlockedRoute = (nextLocation) => {
        if (!leaveConfirmed && isBlocked) {
            setVisible(true);
            setLastLocation(nextLocation);
            return false;
        }
        return true;
    };

    const onLeave = () => {
        setVisible(false);
        setLeaveConfirmed(true);
    };
    
    const onCancel = () => {
        setVisible(false);
    };

    useEffect(() => {
        if (leaveConfirmed && lastLocation) {
            navigate(lastLocation.location?.pathname);
        }
    }, [leaveConfirmed, lastLocation, navigate]);

    useBlocker(
        handleBlockedRoute,
        when
    ); 

    return visible && (
        <Modal
            className="block-modal"
            open={visible}
            onOk={onLeave}
            onCancel={onCancel}
            okText="Leave Page"
        >
            <img src={IcoWarning} width={40} alt="Warning" />
            <h2>Unsaved Changes</h2>
            <p>
                All changes you have made will be lost. 
                Do you want to proceed anyway?
            </p>
        </Modal>
    );
};

BlockModal.propTypes = {
    isBlocked: PropTypes.bool.isRequired
};

export default BlockModal;
