export default {
    title: 'Support',
    successMessage: 'Got it. We\'ll get back to you as soon as we can.',
    title1: 'Need help? Many issues can be solved by doing one of the following:',
    titleRequest: 'Request Support',
    button: 'Send Request',
    email: 'Email',
    textArea: 'Describe the issue you have',
    reportSuccess: 'Support Requested Successfully!',
    success: 'Success',
    thankYouNote: 'Thank you, your request has been received, we will contact you shortly. Please don\'t forget to check your Spam folder, just in case!',
    panel1: {
        header: 'What if I am experiencing symptoms due to allergies, COPD, etc.?',
        text: `As stated before your screenings,  users who are experiencing symptoms due to allergies,
        COPD, or a chronic illness should not check off the symptom(s) in the questions.`
    }, 
    panel2: {
        header: 'What if multiple users are screening on the same device?',
        text: `If you and others are completing the screening on the same device, please sign out by
        clicking the three lines in the top right corner of your screen to go to your
        settings and then clicking “Sign Out.”`
    }, 
    panel3: {
        header: 'Why am I not able to screen more than once every four hours?',
        text: `In an effort to avoid duplicate reports,
        users can only screen every four hours.`
    }, 
    panel4: {
        header: 'What does “Your Account is On Hold” mean?',
        text: `If you see a screen stating “Your Account is on hold,” this means you did not
        pass your screening. Your screening program coordinator will need to remove the hold.`
    }, 
    panel5: {
        header: 'What if I have other technical issues?',
        text: `If you have technical issues, please try clearing your browser
        cache or email {email}. You can clear your cache by going to your
        browser’s history, choosing to clear browsing data, and clearing the cache. 
        Here’s how to clear your cache on 
        <strong>
            <a 
                href="https://9to5mac.com/2018/05/24/iphone-ipad-how-to-clear-cache/" 
                target="_blank"
                rel="noopener noreferrer"
            >your iPhone
            </a>
        </strong>
        and
        <strong>
            <a 
                href="https://support.google.com/accounts/answer/32050?co=GENIE.Platform%3DAndroid&amp;hl=en" 
                target="_blank"
                rel="noopener noreferrer"
            >
                your android
            </a>
        </strong>
        .`
    },
    panel6: {
        header: 'Didn\'t find the answer?',
        text: 'Click on the link below to describe the issue you\'re seeing.'
    }
};
