import { ProviderConstants as constants } from './provider.constants';

const initialState = Object.freeze({ 
    $loading: false,
});

const provider = (state = initialState, action = {}) => {
    switch (action.type) {
        case constants.LOADED: {
            return {
                ...state,
                 ...action.data,
                error: undefined,
                $loading: false,
            };
        }
        
        case constants.CLEAR: {
           return {
                ...initialState,
            };
        }
        case constants.LOADING: {
            return {
                ...state,
                 ...action.data,
                $loading: true,
            };
        }
        case constants.ERROR: {
            return {
                ...state,
                ...action.data,
                error: action.error,
                $loading: false, 
            };
        }
        default:
            return state;
    }
};

export default provider;
