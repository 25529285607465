import { transformTransItemIn } from 'modules/lang';

const transformLocalesIn = (data) => {
    const getText = value => 
        (Object.isString(value) 
            ? transformTransItemIn(value, value.split('_').splice(-1)[0]) 
            : '');

    let res = {};
    const { contentManagement } = data;
    if (contentManagement && contentManagement.length) {
        contentManagement.forEach(({ title, description }) => {
            if (title) {
                const titleVal = getText(title);
                res = { ...res, ...titleVal };
            }
            if (description) {
                const descriptionVal = getText(description);
                res = { ...res, ...descriptionVal };
            }
        });
    }
    return res;
};

const transformLocalesOut = (data, keys) => {
    const transformTransItemFormOut = (field) => {
        const res = {
            type: field,
            title: [],
            description: []
        };
        Object.keys(data)
            .filter(name => name.startsWith(`${field}Page`))
            .forEach((key) => {
                const lang = key.split('$')[1];
                const param = key.split('$')[0].split(`${field}Page`)[1].toLowerCase();
                res[param].push({ [lang]: data[key] });
            });
        return (res.title.length || res.description.length) ? res : undefined;
    };

    const contentManagement = [];

    keys.forEach((key) => {
        const transformed = transformTransItemFormOut(key);
        if (transformed) contentManagement.push(transformTransItemFormOut(key));
    });
    if (!contentManagement.length) {
        return undefined;
    }
    return { contentManagement };
};

export default {
    in: transformLocalesIn,
    out: transformLocalesOut
};
