import { HIDDEN_QUESTION_GROUPS } from 'modules/hiddenQuestions';

const {
    SYMPTOMS,
    EXPOSURE,
    TEMPERATURE,
    ER,
} = HIDDEN_QUESTION_GROUPS;

const transformHiddenQuestionsIn = ({ hiddenQuestions, tempCheck }, isAdmin) => {
    const res = {
        [SYMPTOMS]: hiddenQuestions?.includes(SYMPTOMS),
        [EXPOSURE]: hiddenQuestions?.includes(EXPOSURE),
        [ER]: hiddenQuestions?.includes(ER),
       // [TEMPERATURE]: !tempCheck,
    };
    // Workaround - the temperature in administration is a location.prop.
    if (!isAdmin) {
        res[TEMPERATURE] = !tempCheck;
    }
    return res;
};

export default {
    in: transformHiddenQuestionsIn
};
