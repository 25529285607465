import { MODULE_PERMISSION } from 'modules/module';
import { ModulesConfig } from 'apps/administration/constants';

const Modules = Object.values(ModulesConfig);

const transformModulesIn = (modules, permissions) => {
    const $modules = {};
    Modules.forEach((item) => {
        const modulePermission = permissions[item.id];
        const moduleForbidden = modulePermission === MODULE_PERMISSION.NONE || !modulePermission;
        const moduleView = modulePermission === MODULE_PERMISSION.VIEW;

        const moduleAllowed = modules?.includes(item.id) && !moduleForbidden;
        // eslint-disable-next-line no-nested-ternary
        $modules[item.module] = moduleAllowed ? (moduleView ? { view: true } : true) : false;
    });
    return $modules;
};

const transformLocationModulesIn = (modules) => {
    const $modules = {};
    Modules.forEach((item) => {
        $modules[item.module] = modules?.includes(item.id);
    });
    return $modules;
};

export default {
    user: transformModulesIn,
    location: transformLocationModulesIn,
}; 
