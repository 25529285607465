import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Spin } from 'components';
import NoAccess from 'apps/lintelio/routes/noAccess';

/// Lintelio
const ModuleRoute = ({ component: Component, module, ...rest }) => {
    const { $modules, $loading } = useSelector(state => state.location);

    let hasAccess = true;
    if (module) {
        hasAccess = $modules[module];
    }
    
    let comp = () => <NoAccess to={module} />;

    if ($loading) {
        comp = () => <Spin type="center" />;
    } else if (hasAccess) {
        comp = Component;
    }

    return React.createElement(comp, rest);
};

ModuleRoute.propTypes = {
    component: PropTypes.any.isRequired,
    module: PropTypes.string
};

ModuleRoute.defaultProps = {
    module: undefined
};

export { ModuleRoute };
