import './Tabs.scss';
import React from 'react';
import PropTypes from 'prop-types';
import TabMUI from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

const Tabs = ({ children, className, defaultActiveValue }) => {
    const [value, setValue] = React.useState(defaultActiveValue);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <TabContext value={value}>
            <TabList
                className={`c-tabs ${className}`}
                onChange={handleChange}
                aria-label="lab API tabs example"
            >
                {children.map(tab => (
                    <TabMUI
                        label={tab.props.label}
                        key={tab.props.value}
                        value={tab.props.value}
                        aria-label={tab.props.label}
                    />
                ))}
            </TabList>
            {children.map(tab => (
                <TabPanel
                    className="c-tab-panel"
                    key={tab.props.value}
                    value={tab.props.value}
                >
                    {tab.props.children}
                </TabPanel>
            ))}
        </TabContext>
    );
};

Tabs.propTypes = {
    children: PropTypes.arrayOf(
        PropTypes.shape({
            props: PropTypes.object.isRequired,
        })
    ).isRequired,
    defaultActiveValue: PropTypes.string.isRequired,
    className: PropTypes.string
};

Tabs.defaultProps = {
    className: ''
};

const Tab = ({ label, value }) => <div label={label} key={value} />;

Tab.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
};

Tabs.Tab = Tab;
export { Tabs };
