import './Button.scss';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ButtonMUI from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';

const Button = (props) => {
    const [typeButton, setTypeButton] = useState('');
    const [classNameButton, setClassNameButton] = useState('');

    const {
        type, block, shape,
        className, loading, disabled,
        size, icon,
        ...rest
    } = props;

    useEffect(() => {
        if (type) {
            const types = type.split(' ');
            const firstType = types[0];
            if (firstType !== 'link') {
                setTypeButton(types[0]);
            }
            setClassNameButton(types.join(' '));
        }
    }, [type]);

    if (Object.isDefined(loading)) {
        return (
            <LoadingButton
                loading={loading}
                variant="outlined"
                startIcon={icon}
                disabled={disabled}
                type={typeButton}
                size={size}
                className={`c-btn ${className} ${classNameButton} ${shape} ${block ? 'block' : ''}`}
                tabIndex={0}
                {...rest}

            />
        );
    }
    return (
        <ButtonMUI
            {...rest}
            variant="outlined"
            startIcon={icon}
            disabled={disabled || loading}
            type={typeButton}
            size={size}
            className={`c-btn ${className} ${classNameButton} ${shape} ${block ? 'block' : ''}`}
            tabIndex={0}
        />
    );
};

Button.propTypes = {
    type: PropTypes.string,
    block: PropTypes.bool,
    shape: PropTypes.string,
    className: PropTypes.string,
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    size: PropTypes.string,
    icon: PropTypes.element
};

Button.defaultProps = {
    type: '',
    block: false,
    shape: '',
    className: '',
    loading: false,
    disabled: false,
    size: '',
    icon: null
};

export { Button, IconButton };
