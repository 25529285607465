import { Auth } from '@aws-amplify/auth';
import store from 'modules/store';
import { local } from 'services';

const strOffset = process.env.REACT_APP_AWS_REFRESH_TOKEN_OFFSET_IN_SEC;
const refreshTokenOffset = parseInt(strOffset || 60, 10);

// @TODONEXT invalid token doesn't call signOut
// For OTP Users returns true - OTP Users phone is already verified
const isUserAttrVerified = (userAttr, bypassCache) => 
    Auth.currentAuthenticatedUser({ bypassCache })
        .then((user) => {
            if (user.attributes && user.attributes[`${userAttr}_verified`] === true) { 
                return user.attributes[userAttr]; 
            }
            return false;
        })
        .catch(() => true);

const updateUserAttribute = async (data) => {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.updateUserAttributes(user, data);
};

const signOut = () => Auth.signOut();
 
const getToken = () => new Promise((resolve) => {
    const useToken = session => session.getIdToken();

    Auth.currentSession()
        .then((session) => {
            const idTokenExpire = useToken(session).getExpiration();
            const refreshToken = session.getRefreshToken();
            const currentTimeSeconds = Math.round(+new Date() / 1000);
            const tokenWillExpire = idTokenExpire < currentTimeSeconds + refreshTokenOffset;
            
            if (tokenWillExpire) {
                Auth.currentAuthenticatedUser()
                    .then((user) => {
                        user.refreshSession(refreshToken, (err, data) => {
                            if (err) {
                                signOut();
                            } else {
                                const jwt = useToken(data).getJwtToken();
                                resolve(jwt);
                            }
                        });
                    });
            } else {
                const jwt = useToken(session).getJwtToken();
                resolve(jwt);
            }
        })
        .catch(() => {
            // Custom Flow
            resolve(local.token());
        });
});

export const getTokenPayload = (token) => {
    const [, payload] = token.split('.');
    if (payload) { 
        try {
            return JSON.parse(window.atob(payload)); 
        } catch (e) {
            return {};
        }
    }
    return {};
};

export const isCognitoUser = async (token) => {
    const t = token || await getToken();
    if (!t) return false;
    const payload = getTokenPayload(t);
    return !payload.patientId;
};

export const isOTPUser = async (token) => {
    const t = token || await getToken();
    if (!t) return false;
    const payload = getTokenPayload(t);
    return !!payload.patientId;
};

const hasGrant = (roles, user) => {
    let currentUser = { ...user };
    if (!roles.length) return true;
    if (!user) {
        const state = store.getState();
        currentUser = state.user;
    }
        
    const { $role } = currentUser;
    if (!$role) return false;
    
    return roles.includes($role);
};
export { 
    getToken,
    hasGrant,
    isUserAttrVerified,
    updateUserAttribute,
    signOut,
};
