const transformQuestionsListIn = ({ data }) => data
    .map(item => ({
        title: item.title,
        key: item.name,
        type: 'string',
        sort: false,
        filter: false,
        width: 50 + (4 * item.title.length),
        hide: true
    }));

export default transformQuestionsListIn;
