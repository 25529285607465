import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Spin } from 'components';
import { hasGrant } from 'security';
import { LayoutConstants } from 'modules/layout';
import AccessDenied from 'apps/administration/routes/accessDenied';
import ModuleInfo from 'apps/administration/routes/moduleInfo';

import { ROLE_SYS_ADMIN, ROLE_ADMIN } from 'modules/user';

// Lintelio administration
const PrivateRoute = ({ component: Component, module, roles, menu, ...rest }) => {
    const user = useSelector(state => state.user);
    const location = useLocation();
    const dispatch = useDispatch();
    const { computedMatch } = rest;
  
    const { $locationModules = {}, $modules, $loading } = user;

    let comp;
    if ($loading) {
        comp = () => <Spin type="center" />;
    }
    
    if (!comp && module) {
        const toEditModule = location.state?.moduleInfo;
        if (toEditModule) {
            comp = () => <ModuleInfo module={toEditModule} />;
        }
    }
    if (!comp && roles) {
        if (!hasGrant(roles, user)) {
            comp = AccessDenied;
        }
    }
    if (!comp && module) {
        const userModuleAccess = $modules[module];
        const locationModuleAccess = $locationModules[module];

        if (!userModuleAccess) {
            comp = AccessDenied;
        }
        if (!locationModuleAccess) {
            comp = () => <ModuleInfo module={module} />;
        }
    }
    if (!comp) {
        comp = Component;
    }
            
    useEffect(() => {
        if (Object.isDefined(menu)) {
            dispatch({ 
                type: LayoutConstants.ADMIN_SUBMENU, 
                submenu: menu,
                params: computedMatch,
            });
        }
    }, [computedMatch, dispatch, menu]);
    
    return React.createElement(comp);
};

PrivateRoute.propTypes = {
    component: PropTypes.any.isRequired,
    module: PropTypes.string,
    roles: PropTypes.array,
    menu: PropTypes.string
};

PrivateRoute.defaultProps = {
    module: undefined,
    roles: [ROLE_SYS_ADMIN, ROLE_ADMIN],
    menu: undefined
};

export { PrivateRoute };
