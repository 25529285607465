import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'apps/administration/components';
import { UsergroupAddOutlined } from '@ant-design/icons';
import { JobTitleService } from 'modules/jobtitle';
import { showError } from 'utils';

const SelectJobTitle = ({ onChange, value, locationId }) => {
    const [options, setOptions] = useState([]);

    useEffect(() => {
        JobTitleService.list(locationId)
            .then((data) => {
                const jobTitles = data && data
                    .map(({ title, id }) => ({ label: title, value: id }));
                setOptions(jobTitles);
            })
            .catch(showError);
    }, [locationId]);
    
    return (
        <Select
            prefix={<UsergroupAddOutlined />}
            value={value}
            showSearch
            placeholder="Search and Select Job Title"
            filterOption
            options={options}
            popupMatchSelectWidth={false}
            className="select"
            onChange={onChange}
        />
    );
};

SelectJobTitle.propTypes = {
    value: PropTypes.PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.number,
    ]),
    locationId: PropTypes.number.isRequired,
    onChange: PropTypes.func,
};

SelectJobTitle.defaultProps = {
    onChange: () => {},
    value: undefined
};

export { SelectJobTitle };
