export default {
    validation: {
        name: 'Invalid characters.',
        manychars: 'Name should contain at least two characters.',
        string: 'Invalid characters',
        zip: 'Invalid Zip',
        email: 'Invalid Email',
        number: 'Invalid characters',
        required: 'Required field',
        terms: 'Should accept terms',
        phoneNumber: 'Invalid Phone Number',
        temperature: 'Enter value between {min} °{unit} and {max} °{unit}',
        password: 'Password must be at least 8 characters including one uppercase letter, one lowercase letter and 1 number.',
        phoneExists: 'This phone number is already taken',
        subdomainExists: 'This name is already taken. Please try another.', // en only
    }
};
