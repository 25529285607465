import React from 'react';

const UsersGuidlineText = () => (
    <div className="body p-bulk">
        <span className="bold">Overview:</span>
        <p>
            - Please fill in all mandatory fields for
            uploaded users in the &quot;Bulk upload input sheet&quot;
        </p>
        <p>
            - Please follow the below guidelines for data
            in the specific fields
        </p>
        <p className="mb-s">
            - If there are missing mandatory fields or data formats
            of the fields are incorrect, no users will be uploaded
            and an error message will be given
        </p>

        <span className="bold">Mandatory fields:</span>
        <p>- First name
            <em>(max: 20 characters, excluding special symbols)</em>
        </p>
        <p>- Last name
            <em>(max: 20 characters, excluding special symbols)</em>
        </p>
        <p className="mb-s">
            - Phone number <em>(max: 20 characters)</em> <span className="bold">OR </span>
            Email address <em>(max 50 characters)</em>.
            System can not accept both.
        </p>

        <span className="bold">Guidelines for specific fields:</span>
        <p>
            - Phone number: please enter all phone numbers together with
            their country codes. Format of country codes does not matter,
            all special symbols will be accepted and processd by the system
        </p>
        <p>
            - Worker type: this is an optional field to
            indicate whether a worker is a
            Healthcare, Standard or any other
            predefined type of worker.
        </p>
        <p>
            These job titles are defined on 
            location level and can be found in the Occupation Mapping tab.
        </p>
        <p>
            - H maps to Healthcare
        </p>
        <p>
            - Empty defaults to Neither of these
        </p>
        <p>
            - Any other has to correspond to
            already existing job title as defined in Occupation Mapping tab
        </p>
        <p>
            - Supervisor: Only already existing users
            can be set as Supervisors.
            Provide their email address in order
            to give them Supervisor privileges.
        </p>
        <p>
            - Send Welcome Message:
            &quot;Yes&quot; indicates that user
            will receive a welcome message.
            &quot;No&quot; means no message will be sent
        </p>
        <p>
            - Gender: possible values: &quot;Male&quot;,
            &quot;Female&quot;, &quot;Other&quot; &
            &quot;Prefer not to specify&quot;
        </p>
        <p>
            - Date of birth: Date format is &quot;MM/DD/YYYY&quot;
            e.g. 07/04/1987 July 4th 1987
        </p>
        <p>
            - Address fields: includes Address 1,
            Address 2, City, State, ZIP
        </p>
        <p>
            - Custom Fields: custom fields that could
            be configured for each location
        </p>
    </div>
);

export { UsersGuidlineText };
