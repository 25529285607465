const UserConstants = {
    ERROR: 'USER_ERROR',
    LOADING: 'USER_LOADING',
    LOADED: 'USER_LOADED',
    LOGOUT: 'USER_LOGOUT',
};

export {
    UserConstants
};
