/* eslint-disable consistent-return */
import { useEffect, useState } from 'react';

const SCRIPT_STATUS = {
    error: 'error',
    ready: 'ready',
    loading: 'loading'
};

const useScript = (src) => {
    const [loaded, setLoaded] = useState(!!src);
    const [error, setError] = useState();
    
    useEffect(() => {
        const existing = document.querySelector(`script[src="${src}"]`);
        if (existing) {
            setLoaded(existing.status !== SCRIPT_STATUS.loading);
            return;
        }

        const script = document.createElement('script');
        script.async = true;
        script.status = SCRIPT_STATUS.loading;

        const handleLoad = () => {
            script.status = SCRIPT_STATUS.ready;
            setLoaded(true);
        };
        const handleError = (err) => {
            script.status = SCRIPT_STATUS.error;
            setError(err);
        };

        script.addEventListener('load', handleLoad);
        script.addEventListener('error', handleError);
        script.src = src;
        document.body.appendChild(script);

        return () => {
            script.removeEventListener('load', handleLoad);
            script.removeEventListener('error', handleError);
        };
    }, [error, loaded, src]);

    return { loaded, error };
};

export { useScript };
