import './Modal.scss';
import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import { CloseIcon } from 'components/Icons';

export const Modal = (props) => {
    const {
        children, className,
        footer, title,
        visible, onClose,

    } = props;

    // @TODONEXT Google Screen Reader does not read the modal Title and Content
    return (
        <Dialog
            className={`c-modal ${className}`}
            role="dialog"
            aria-modal="true"
            aria-labelledby="dialog-title"
            aria-describedby="dialog-description"
            onClose={() => {
                if (onClose) {
                    onClose();
                }
            }}
            open={visible}
            onBackdropClick={() => {
                if (onClose) {
                    onClose();
                }
                return false;
            }}
        >
            {(title || onClose) && (
                <DialogTitle 
                    className="c-modal-title"
                    id="dialog-title"
                >
                    {title}
                    {onClose && (
                        <IconButton
                            className="c-modal-close-button"
                            aria-label="close"
                            onClick={onClose}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    )}
                </DialogTitle>
            )}
            <DialogContent id="dialog-description">
                {children}
            </DialogContent>

            {footer && (
                <DialogActions className="c-modal-footer">
                    {footer}
                </DialogActions>
            )}
        </Dialog>
    );
};

Modal.propTypes = {
    children: PropTypes.any.isRequired,
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    className: PropTypes.string,
    footer: PropTypes.any,
    title: PropTypes.any,
};

Modal.defaultProps = {
    onClose: undefined,
    className: '',
    footer: undefined,
    title: '',
};
