import './Spin.scss';
import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';

const Spin = ({ children, spinning, className }) => (
    <>
        {spinning && (
            <div className={`c-spin ${className}`}>
                <CircularProgress />
            </div>
        )}
        {children}
    </>
);

Spin.propTypes = {
    children: PropTypes.any,
    spinning: PropTypes.bool,
    className: PropTypes.string,
};

Spin.defaultProps = {
    children: null,
    spinning: true,
    className: '',
};

export { Spin };
