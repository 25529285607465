import { toQueryParams, formats } from 'utils'; 

const transformSort = (sorter) => {
    if (Object.isEmpty(sorter)) return '';
    const dir = sorter.order === 'descend' ? 'DESC' : 'ASC';
    return `${sorter.field}+${dir}`;
};

const transformFilters = (data, encode = true) => {
    const res = {};
    Object.keys(data)
        .forEach((key) => {
            const value = data[key];
            if (value && Array.isArray(value)) {
                const pattern = value[0];
                const values = value.slice(1, value.length);
                switch (pattern) {
                    case 'BETWEENDATE': {
                        res[key] = {
                            startRange: values[0].startOf('day').utc().format(formats.dateTime.out),
                            endRange: values[1].endOf('day').utc().format(formats.dateTime.out), 
                            pattern: 'BETWEEN'
                        };
                        break;
                    }
                    case 'BETWEENDATE_EXACT': {
                        res[key] = {
                            startRange: values[0].format(formats.date.out),
                            endRange: values[1].format(formats.date.out), 
                            pattern: 'BETWEEN'
                        };
                        break;
                    }
                    case 'BETWEEN': {
                        res[key] = {
                            startRange: values[0].toString(),
                            endRange: values[1].toString(),
                            pattern
                        };
                        break;
                    }
                    case 'BOOL': {
                        if (values.length === 1) {
                            const [val] = values;
                            res[key] = val;
                        }
                        break;
                    }
                    case 'LIKE': {
                        const val = values.toString().toLowerCase();
                        res[key] = {
                            value: encode ? encodeURIComponent(val) : val,
                            pattern
                        };
                        break;
                    }
                    default: {
                        res[key] = {
                            value: value.map(val => (val === 'null' ? null : val)),
                            pattern: 'IN'
                        };
                    }
                }
            } else if (value || value === 0) {
                res[key] = value;
            }
        });
    return res;
};

const apiList = (
    api, apiUrl, 
    page = 1, take = 500, filters = {}, sort = {}, 
    params = {}
) => {
    const data = {
        page,
        perPage: take,
        order_by: transformSort(sort),
        filters: transformFilters(filters),
        columns: params.columns
    };
    const query = toQueryParams(data);

    const url = `${apiUrl}?${query}`;
    
    return api.get(url, params);
};

export { apiList, transformFilters };
