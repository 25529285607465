import './global.utils';

export * from './api.utils';
export * from './dom.utils';
export * from './date.utils';
export * from './uri.utils';
export * from './temperature.utils';
export * from './browser.utils';
export * from './timezone.utils';
export * from './feedback.utils';
export * from './data.utils';
export * from './ga.utils';
export * from './fields.utils';
export * from './cookie.utils';

export const isDevelopment = process.env.NODE_ENV === 'development';

export const getRandomId = (range = 100000000000) => Math.floor(Math.random() * range);

const SelectText = (element) => {
    const doc = document;
    if (doc.body.createTextRange) {
        const range = document.body.createTextRange();
        range.moveToElementText(element);
        range.select();
    } else if (window.getSelection) {
        const selection = window.getSelection();
        const range = document.createRange();
        range.selectNodeContents(element);
        selection.removeAllRanges();
        selection.addRange(range);
    }
};

export const copyCodeToClipboard = (path, value) => {
    if (!value) {
        const img = document.createElement('img');
        const div = document.createElement('div');
        const QR = document.querySelector(path).toDataURL('image/png');
        img.src = QR;
        div.contentEditable = true;
        div.appendChild(img);
        document.body.appendChild(div);
        SelectText(div);
        document.execCommand('copy');
        document.body.removeChild(div);
        return true;
    }
    const temporaryElement = document.createElement('textarea');
    temporaryElement.value = value;
    document.body.appendChild(temporaryElement);
    temporaryElement.select();
    document.execCommand('copy');
    document.body.removeChild(temporaryElement);
    return true;
};

export function debounce(func, interval = 200) {
    let timeout;
    return function d1(...args) {
        const context = this;
        const later = function d2() {
            timeout = null;
            func.apply(context, args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, interval);
    };
}

export function throttle(func, wait, options) {
    let leading = true;
    let trailing = true;
  
    if (typeof func !== 'function') {
      throw new TypeError('Expected a function');
    }
    if (Object.isObject(options)) {
        leading = 'leading' in options ? !!options.leading : leading;
        trailing = 'trailing' in options ? !!options.trailing : trailing;
    }
    return debounce(func, wait, {
        leading,
        trailing,
        maxWait: wait
    });
}
  
export const changeBrandColor = (color) => {
    if (color) {
        const root = document.querySelector('body');
        root.style.setProperty('--brand-color', color);
    }
};

export const nameClassName = (firstName, lastName) => 
    (((firstName || '').length > 14 || (lastName || '').length > 14) ? 'small' : '');

export const statusClassName = status => 
    (((status || '').length > 11) ? 'small' : '');
