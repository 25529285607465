import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'apps/administration/components';
import { usePatientUpdate } from 'modules/patient';

export const MarkAsScreened = ({ onAfterMarked, ...props }) => {
    const [update, { loading }] = usePatientUpdate();
    const [marked, setMarked] = useState(false);
    const { patientId, ...rest } = props;

    const onMarkAsScreened = () => {
        update(patientId, { markAsScreened: 1 })
            .then((res) => {
                setMarked(true);
                onAfterMarked(patientId, res);
            });
    };

    return (
        <Button
            block
            loading={loading}
            type={`${marked ? 'primary' : 'default'}`}
            onClick={onMarkAsScreened}
            module="pandemic"
            {...rest}
        >
            Mark as Screened
        </Button>
    );
};

MarkAsScreened.propTypes = {
    patientId: PropTypes.number.isRequired,
    onAfterMarked: PropTypes.func,
};

MarkAsScreened.defaultProps = {
    onAfterMarked: () => {}
};
