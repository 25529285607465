import './card.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, Tooltip } from 'apps/administration/components';
import { LOCATION_DEFAULT } from 'modules/location';

const LocationCard = ({ title, titles, color, bold, count, label }) => {
    if (!title) return null;
    const total = count || titles.split(',').length;
    
    if (total < 2) {
        const arr = (title || ' ').match(/[a-zA-Z0-9]/);
        const letter = arr.pop();
        return (
            <div
                title={title}
                className={`location-card ${bold ? ' bold' : ''}`}
            >
                <Avatar
                    style={{ backgroundColor: color || LOCATION_DEFAULT.color }}
                    shape="circle"
                    size="small"
                >
                    {letter.toUpperCase()}
                </Avatar>
            
                <span>{title}</span>
            </div>
        );
    }
    const tooltipTitle = titles ? titles.replace(/,/g, ', ') : '';
    const sColor = (color || '').split(',')[0];
    
    return (
        <Tooltip
            placement="topLeft"
            title={tooltipTitle}
            className={`location-card block ${bold ? ' bold' : ''}`}
        >
            <Avatar
                style={{ backgroundColor: sColor || LOCATION_DEFAULT.color }}
                shape="circle"
                size="small"
            >
                {((title || ' ')[0]).toUpperCase()}
            </Avatar>
            <span>
                <div>{title}</div>
                <div>{total} {label}</div>
            </span>
        </Tooltip>
    );
};

LocationCard.propTypes = {
    title: PropTypes.string,
    color: PropTypes.string,
    bold: PropTypes.bool,
    titles: PropTypes.string,
    count: PropTypes.number,
    label: PropTypes.string 
};

LocationCard.defaultProps = {
    title: ' ',
    color: LOCATION_DEFAULT.color,
    bold: false,
    titles: '',
    count: 0,
    label: 'Location(s)'
};

export { LocationCard };
