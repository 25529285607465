/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-continue */
/* eslint-disable no-unused-vars */

Object.equals = (x, y) => {
    if (x === y) return true;
    if (!(x instanceof Object) || !(y instanceof Object)) return false;
    if (x.constructor !== y.constructor) return false;
    for (const p in x) {
        if (!x.hasOwnProperty(p)) continue;
        if (!y.hasOwnProperty(p)) return false;
        if (x[p] === y[p]) continue;
        if (typeof (x[p]) !== 'object') return false;
        if (!Object.equals(x[p], y[p])) return false;
    }

    for (const p in y) {
        if (y.hasOwnProperty(p) && !x.hasOwnProperty(p)) return false;
    }
    return true;
};

Object.exists = (x, ...path) => {
    let temp = { ...x };
    for (let i = 0; i < path.length; i++) {
        if (!temp || !temp.hasOwnProperty(path[i])) {
        return false;
      }
      temp = temp[path[i]];
    }
    return temp;
}; 

Object.find = (x, prop, value) => {
    const key = Object.keys(x).find(k => x[k][prop] === value);
    return key ? x[key] : undefined;
};

Object.isObject = x => Object.prototype.toString.call(x) === '[object Object]';

Object.isFunction = x => Object.prototype.toString.call(x) === '[object Function]';

Object.isEmpty = x => Object.keys(x).length === 0; 

Object.isDefined = x => (typeof x !== 'undefined');

Object.isUndefined = x => (typeof x === 'undefined');

Object.isBool = x => (typeof x === 'boolean');

Object.isString = x => Object.prototype.toString.call(x) === '[object String]';

// Array
Array.hasMatches = (x, y) => x
    .reduce((acc, value) => (acc || y.includes(value)), false);

Array.equals = (x, y) => {
    const type = Object.prototype.toString.call(x);
    if (type !== Object.prototype.toString.call(y)) return false;

    if (['[object Array]', '[object Object]'].indexOf(type) < 0) return false;

    const xLen = x.length;
    const yLen = y.length;
    if (xLen !== yLen) return false;
    
    for (let i = 0; i < xLen; i++) {
        if (Object.equals(x[i], y[i]) === false) return false;
    }

    return true;
};
// eslint-disable-next-line 
Array.prototype.unique = function unique() {
    return this.filter((value, index, self) => self.indexOf(value) === index);
};

Array.getFirst = x => (Array.isArray(x) && x?.length ? x[0] : undefined);

Array.includesEvery = (x, y) => y.every(r => x.includes(r));

Array.toText = (arr) => {
    const len = arr.length;
    return arr.slice(0, len - 1).join(', ')
        + (len > 1 ? ' and ' : '')
        + arr[len - 1];
};

Array.filterFirst = (arr, filterFn) => {
    const res = arr.filter(filterFn);
    if (res && res.length) {
        return res[0];
    }
    return undefined;
};

const arrayMoveMutate = (array, from, to) => {
    const startIndex = to < 0 ? array.length + to : to;

    if (startIndex >= 0 && startIndex < array.length) {
        const item = array.splice(from, 1)[0];
        array.splice(startIndex, 0, item);
    }
};

Array.move = (array, from, to) => {
    const arr = [...array];
    arrayMoveMutate(arr, from, to);
    return arr;
};

Array.remove = (array, item, prop = 'id') => {
    const idx = Object.isObject(item)
         ? array.findIndex(i => i[prop] === item[prop])
         : array.indexOf(item);
    const items = [...array];
    items.splice(idx, 1);
    return items;
};

Array.changeItem = (array, item, prop = 'id') => {
    const idx = array.findIndex(i => i[prop] === item[prop]);
    const arr = [...array];
    if (idx > -1) {
        arr.splice(idx, 1, item);
    } else arr.push(item);
    return arr;
};

Array.create = x => ([...Array(x).keys()]);

// String
String.capitalize = (x) => {
    if (!Object.isString(x) || !x.length) return x;
    return x.charAt(0).toUpperCase() + x.slice(1);
};

String.isEmpty = x => (x.length === 0 || !x.trim());

String.isDate = x => !!Date.parse(x);
