import './InputDate.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from 'antd';
// import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';
// import generatePicker from 'antd/es/date-picker/generatePicker';

import { CalendarOutlined } from '@ant-design/icons';
import { formats } from 'utils';

// const GeneratedPicker = generatePicker(dayjsGenerateConfig);

const InputDate = ({ getPopupContainer, ...rest }) => (
    <DatePicker
        format={formats.date.in} 
        getPopupContainer={getPopupContainer}
        prefix={<CalendarOutlined />}
        showToday={false}
        inputReadOnly
        aria-readonly
        placeholder="Select Date"
        {...rest}
    />
);

InputDate.propTypes = {
    getPopupContainer: PropTypes.func,
};
InputDate.defaultProps = {
    getPopupContainer: trigger => trigger.parentNode
};

const InputDateTime = props => (
    <DatePicker
        showTime
        format={formats.dateTime.in}
        {...props}
    />
);

const { RangePicker } = DatePicker;

export { RangePicker, InputDateTime, InputDate };
