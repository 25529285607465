import './Select.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { Select as SelectBase } from 'antd';

const { Option } = SelectBase;

const Select = ({ prefix, options, disabledOptions, getPopupContainer, ...rest }) => (
    <SelectBase
        getPopupContainer={getPopupContainer}
        {...rest} 
    >
        {options.map(({ value, label }) => (
            <Option 
                key={value} 
                value={value} 
                label={label}
                disabled={disabledOptions.includes(value)}
            >
                {prefix}
                {label}
                
            </Option>
        ))}
    </SelectBase>
);

Select.propTypes = {
    prefix: PropTypes.any,
    options: PropTypes.array.isRequired,
    disabledOptions: PropTypes.array,
    getPopupContainer: PropTypes.func
};

Select.defaultProps = {
    prefix: undefined,
    disabledOptions: [],
    getPopupContainer: trigger => trigger.parentNode
};

export { Select, SelectBase };
