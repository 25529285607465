import './SanitizedHtml.scss';
import React from 'react';
import PropTypes from 'prop-types';
import sanitizeHtml from 'sanitize-html';

const DefaultOptions = {
    allowedTags: [
        'address', 'article', 'aside', 'footer', 'header', 'h1', 'h2', 'h3', 'h4',
        'h5', 'h6', 'hgroup', 'main', 'nav', 'section', 'blockquote', 'dd', 'div',
        'dl', 'dt', 'figcaption', 'figure', 'hr', 'li', 'main', 'ol', 'p', 'pre',
        'ul', 'a', 'abbr', 'b', 'bdi', 'bdo', 'br', 'cite', 'code', 'data', 'dfn',
        'em', 'i', 'kbd', 'mark', 'q', 'rb', 'rp', 'rt', 'rtc', 'ruby', 's', 'samp',
        'small', 'span', 'strong', 'sub', 'sup', 'time', 'u', 'var', 'wbr', 'caption',
        'col', 'colgroup', 'table', 'tbody', 'td', 'tfoot', 'th', 'thead', 'tr',
        'img'
      ],
      disallowedTagsMode: 'discard',
      allowedAttributes: {
        a: ['href', 'name', 'target'],
        img: ['src', 'srcset', 'alt', 'title', 'width', 'height', 'loading'],
        '*': ['style', 'class', 'id'],
      },
      selfClosing: ['img', 'br', 'hr', 'area', 'base', 'basefont', 'input', 'link', 'meta'],
      allowedSchemes: ['http', 'https', 'ftp', 'mailto', 'tel', 'data'],
      allowedSchemesByTag: { img: ['data'] },
      allowedSchemesAppliedToAttributes: ['href', 'src', 'cite'],
      allowProtocolRelative: true,
      enforceHtmlBoundary: false
};

const sanitize = (dirty, options) => ({
    __html: sanitizeHtml(
        dirty,
        options
    )
});

const SanitizedHtml = ({ children, options, className, type, ...rest }) => React
    .createElement(type, {
        dangerouslySetInnerHTML: sanitize(children, options),
        className: `${className} sanitized-html ql-editor`,
        ...rest
    });

SanitizedHtml.propTypes = {
    children: PropTypes.string,
    type: PropTypes.string,
    options: PropTypes.object,
    className: PropTypes.string
};

SanitizedHtml.defaultProps = {
    children: '',
    type: 'div',
    options: DefaultOptions,
    className: ''
};

export { SanitizedHtml };
