import locales from 'locales';

export const JOB_CATEGORY = {
    COMMUNITY_EXPOSURE: 'COMMUNITY_EXPOSURE',
    HEALTHCARE_PROVIDER: 'HEALTHCARE_PROVIDER',
};

export const JobCategoryOptions = () => {
    const strings = locales.common.jobCategory;
    return [
        { value: JOB_CATEGORY.COMMUNITY_EXPOSURE, label: strings.other },
        { value: JOB_CATEGORY.HEALTHCARE_PROVIDER, label: strings.healthcare },
    ];
};
