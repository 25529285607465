import './copy.scss';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Tooltip, message } from 'apps/administration/components';
import { CheckOutlined, CopyOutlined } from '@ant-design/icons';
import { copyCodeToClipboard } from 'utils';

const defaultCopied = { value: false, el: '' };

const CopyButton = ({ name, value, label, type, children }) => {
    const [copied, setCopied] = useState(false);

    useEffect(() => {
        if (copied && copied.value) {
            setCopied({ ...copied, value: false });
            message.success('Copied', 3, () => setCopied(defaultCopied));
        }
    }, [copied]);

    const isCopied = (el) => {
        if (copied && el === copied.el) return true;
        return false;
    };

    const onClick = () => {
        const val = copyCodeToClipboard('.qr-svg > canvas', value);
        setCopied({ value: val, el: name });
    };

    return (
        <span className="copy-btn">
            {children}
            <Button
                onClick={onClick}
                type={type}
            >
                {isCopied(name)
                ? <CheckOutlined />
                : (
                    <Tooltip placement="top" title="Copy to Clipboard">
                        <CopyOutlined onClick={onClick} />
                    </Tooltip>
                )}
                {label || ''}
            </Button>
        </span>
    );
};

CopyButton.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    type: PropTypes.string,
    children: PropTypes.any,
    label: PropTypes.string
};

CopyButton.defaultProps = {
    value: undefined,
    type: 'link',
    children: '',
    label: ''
};

export { CopyButton };
