export default {
    locationNotFound: 'Looks like you`re using an invalid link. Please contact a representative of your organization to get the correct one.',
    patientNotFound: 'Error loading user.',
    codeSent: 'We have sent you another code. Please check your messages.',
    lastCodeSent: 'We have sent you another code. You have reached the maximum number of text messages for the day.',
    lastCodeSentError: 'You have reached the maximum number of text messages for the day.',
    missingPhoneNumber: 'Phone number is not registered.',
    verCodeMissing: 'Missing verification code',
    codeExpired: 'Code expired.',
    codeWrong: 'Hmmm... that code didn`t work. Check it and try entering it again. You have {message} attempts remaining.',
    networkError: 'Network Error',
    unknownError: 'An unknown error occurred. Please try again.',
    missingLocation: 'Please populate valid location id or slug', 
    lockedNotifications: 'Notifications are locked. Please unlock',
    missingContact: 'Please complete your first screening before editing profile settings.',
    serverError: 'Oops, we`re having trouble reaching the server right now. Please try again in a few minutes.',
    invalidCredentials: 'Invalid email or password',
    userLimitReached: 'User limit has been reached for your account. Reach out to the responsible person in your organization.',
    patientInactive: 'Your status is inactive, please contact your site administrator.',
    registrationDisabled: 'Registration is not allowed. Please contact your HR representative for more details.'
};
