// @NEW
export default {
    title: 'Supeervisor de Prueba',
    subtitle: 'Aqui esta una lista de sus citas',
    itemTitle: 'sesion de examen supervisada',
    startCall: 'comenzar llamada',
    cancelAppointment: 'Cancelar cita',
    startProctorTesting: 'Comenzar examen supervisado ahora',
    status: {
        INIT: 'Nuevo',
        QUEUED: 'En Lista',
        IN_PROGRESS: 'En Progreso',
        PENDING_TEST_RESULT: 'Resultados de Examen Pedientes',
        PENDING_VERIFICATION: 'Verificacion pendiente',
        COMPLETED: 'Terminado',
        ABANDONED: 'Abandonada',
    },
    instructions: 'Instrucciones',
    instructionsContent: `
    <p>Antes de seguir, Por favor asegúrese de que usted: </p>
    <br/>
    <ul>
        <li>Se encuentre en un lugar con conexión estable a internet. </li>
        <li>Tenga un mínimo de 4 minutos sin interrupción para completar el proceso. </li>
        <li>Tenga un ID con foto disponible (Licencia de conducir o Pasaporte). </li>
        <li>Haya revisado cuidadosamente las instrucciones para su examen de antígenos. </li>
        <li>Tenga el código de autorización provisto por el comerciante, si aplica.</li>
    </ul>

    `,
    sessionTitle: 'Lobby',
    addImage: 'Agregue la imagen de los resultados de su examen',
    waitToAddImage: 'Usted podra subir una imagen de los resultados de su examen en:'
};
