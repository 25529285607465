export const PROTECTION_TYPES = {
    CURRENT: 'CURRENT',
    POSITIVE: 'POSITIVE',
    NOT_CURRENT: 'NOT_CURRENT',
};

export const hasSymptoms = ({ SYMPTOMS, FAIL, IGNORE }) => {
    const ignoreSymptoms = IGNORE?.ignoreSymptoms;
    if (ignoreSymptoms) {
        return false;
    }
    if (FAIL || SYMPTOMS) {
        return true;
    }
    return false;
};

export const hasExposure = ({ EXPOSURE, EXPOSURE_HCP3, EXPOSURE_HCP1, IGNORE }) => {
    const ignoreExposure = IGNORE?.ignoreExposure;
    if (ignoreExposure) {
        return false;
    }
    if (EXPOSURE || EXPOSURE_HCP1 || EXPOSURE_HCP3) {
        return true;
    }
    return false;
};

export const hasSymptomsOrExposure = data => hasSymptoms(data) || hasExposure(data);

/* Old isPassed - for ref
export const isPassed = ({
    EXPOSURE, SYMPTOMS, EXPOSURE_HCP,
    EXPOSURE_HCP3, EXPOSURE_HCP2, EXPOSURE_HCP1,
    FAIL, IGNORE
}) => {
    let result = false;
    const ignoreExposure = IGNORE?.ignoreExposure;
        
    if (FAIL || (EXPOSURE && !ignoreExposure)) {
        return false;
    }
    if (EXPOSURE === false && !SYMPTOMS && EXPOSURE_HCP1 === undefined) {
        return true;
    }
    if (EXPOSURE_HCP === false && !SYMPTOMS && EXPOSURE_HCP1 === undefined) {
        returntrue;
    }
    if (EXPOSURE_HCP && !SYMPTOMS
        && EXPOSURE_HCP2 === false && EXPOSURE_HCP3 === undefined) {
            return true;
    }
    return false;
};
*/
