import { FieldTypes } from 'utils';
import locales from 'locales';

export default () => {
    const { models: { attendance: { attendanceQuestions: strings } } } = locales;

    return {
        Q_1: {
            key: 'Q_1',
            static: true,
            type: FieldTypes.RADIO,
            label: 'Has the incident occured in the previous 24 hours?',
            labelToPost: strings.Q_WORK.label,
            options: [
                {
                    value: 'Yes',
                    text: 'Yes',
                    outcome: true,
                },
                {
                    value: 'No',
                    text: 'No',
                    outcome: false,
                }
            ],
        },
        Q_2: {
            key: 'Q_2',
            static: true,
            type: FieldTypes.CHECKBOXES,
            label: 'Are any of the following criteria true?',
            options: [
                {
                    value: 1,
                    text: 'Medical transport from the scene',
                    outcome: true,
                },
                {
                    value: 2,
                    text: 'Mode then one third party vehicle was involved in the incident',
                    outcome: true,
                },
                {
                    value: 3,
                    text: 'Driver was taken to the custody',
                    outcome: true,
                },
                {
                    value: 4,
                    text: 'Media is preset',
                    outcome: true,
                },
                {
                    value: 'None',
                    text: 'None of the above criteria are true',
                    outcome: true,
                    type: FieldTypes.TEXTAREA,
                },
            ],
        },
        Q_3: {
            key: 'Q_3',
            static: true,
            type: FieldTypes.RADIO,
            label: 'Are you the driver or their supervisor? ',
            options: [
                {
                    value: 'Yes',
                    text: 'I\'m the driver',
                    outcome: true,
                },
                {
                    value: 'No',
                    text: 'I\'m the supervisor'
                },
            ],
        },
        
    };
};
