export default {
    title: 'Asistencia',
    attendanceStatus: 'Estado de Asistencia',
    questions: {
        title: 'Asistencia',
        none: 'Ninguno',
        back: 'Anterior',
        cancel: 'Cancelar',
        continue: 'Siguiente'
    },
};
