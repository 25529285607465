import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'components';
import { useSelector, useDispatch } from 'react-redux';
import { ImageCircle } from 'apps/lintelio/containers';
import locales from 'locales';
import { ScreeningActions } from 'modules/screening';
import { LOCATION_CALLBACKS } from 'modules/location';

const ResultDigital = ({ loading, onSubmit, wrapper: Wrapper }) => {
    const { routes: { screening: { result: strings } } } = locales;
    const { current } = useSelector(state => state.screening);
    const { key: currentKey, icon, label = strings.unknown, title, buttonLabel, onDone } = current;

    const dispatch = useDispatch();
    
    useEffect(() => {
        onSubmit({ callback: LOCATION_CALLBACKS.DIGITAL });
    }, [onSubmit]);
    
    const onEnd = () => {
        dispatch(ScreeningActions.end());
        if (onDone) {
            onDone();
        }
    };
    
    return (
        <Wrapper
            footer={(
                <Button
                    type="primary brand"
                    block
                    onClick={onEnd}
                    loading={loading}
                >
                    {buttonLabel || strings.buttonSubmit}
                </Button>
            )}
        >
            <ImageCircle type={`screening-${icon} mv`} />
            {
                title && (
                    <h1 className="ta-center mb-s">{title}</h1>
                )
            }
            <p
                data-qa={currentKey}
                dangerouslySetInnerHTML={{ __html: label }}
            />
        </Wrapper>
    );
};

ResultDigital.propTypes = {
    loading: PropTypes.bool.isRequired,
    wrapper: PropTypes.any.isRequired,
    onSubmit: PropTypes.func.isRequired
};

export default ResultDigital;
