// Routes
import err404 from './en.routes.404';
import err503 from './en.routes.503';
import alerts from './en.routes.alerts';
import attendance from './en.routes.attendance';
import auth from './en.routes.auth';
import benefits from './en.routes.benefits';
import history from './en.routes.history';
import home from './en.routes.home';
import pandemicReportConfirm from './en.routes.pandemicReportConfirm';
import profile from './en.routes.profile';
import reminders from './en.routes.reminders';
import reports from './en.routes.reports';
import screening from './en.routes.screening';
import screenReport from './en.routes.screenReport';
import support from './en.routes.support';
import telehealth from './en.routes.telehealth';
import testingIntegration from './en.routes.testingIntegration';
import workplaceInjury from './en.routes.workplaceInjury';
import integrityLine from './en.routes.integrityLine';
import immunizations from './en.routes.immunizations';
// import lifeworks from './en.routes.lifeworks';

import api from './en.api';
import fields from './en.fields';
import questions from './en.screening.questions';
import results from './en.screening.results';
import attendanceQuestions from './en.attendance.questions';
import attendanceResults from './en.attendance.results';
import common from './en.common';

export default {
    api,
    common,
    fields,
    routes: {
        404: err404,
        503: err503,
        alerts,
        attendance,
        auth,
        benefits,
        history,
        home,
        pandemicReportConfirm,
        profile,
        reminders,
        reports,
        screening,
        screenReport,
        support,
        telehealth,
        testingIntegration,
        workplaceInjury,
        // lifeworks,
        integrityLine,
        immunizations
    },
    models: {
        screening: {
            questions,
            results
        },
        attendance: {
            attendanceQuestions,
            attendanceResults
        },
    },
};
