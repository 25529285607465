export default {
    title: 'Soporte',
    successMessage: 'Entendido. Nos comunicaremos con usted tan pronto como podamos.',
    title1: '¿Necesitas ayuda? Muchos problemas se pueden resolver siguiendo estos pasos:',
    titleRequest: 'Solicitar ayuda',
    button: 'Enviar peticion',
    email: 'Correo electrónico',
    textArea: 'Describa el problema que presenta',
    reportSuccess: 'Soporte solicitado con exito!',
    success: 'Exito',
    thankYouNote: 'Gracias, su solicitud ha sido recibida, nos comunicaremos con usted en breve. Por favor, no olvide revisar su carpeta de correo no deseado, ¡por si acaso!',
    panel1: {
        header: '¿Qué pasa si tengo síntomas debido a alergias, EPOC, entre otros?',
        text: `Como se indicó antes de sus pruebas, los usuarios que tengan síntomas debido a alergias,
        EPOC o una enfermedad crónica, no deben marcar. Los síntomas están en las preguntas.`
    },
    panel2: {
        header: '¿Qué pasa si hay otros usuarios haciendo la evaluación en el mismo dispositivo?',
        text: `Si usted y otros usuarios están completando la
        evaluación en el mismo dispositivo, por favor salga de sesión después de terminar
        presionando las tres líneas en la parte superior derecha de su pantalla, luego vaya a
        configuraciones y seleccione cerrar sesión.”`
    },
    panel3: {
        header: '¿Por qué no puedo hacer la prueba más de una vez cada cuatro horas?',
        text: `En un esfuerzo por evitar reportes duplicados,
        los usuarios solo pueden evaluar cada cuatro horas.`
    },
    panel4: {
        header: '¿Qué significa que tu cuenta está "en espera"?',
        text: `Si miras una notificación que tu cuenta está en espera, esto significa que no
        has completado tu evaluación. Tu cooordinaror de programa de evaluación necesita removerte de la lista de espera.`
    },
    panel5: {
        header: '¿Qué pasa si tengo otros problemas técnicos?',
        text: `Si tiene problemas técnicos, intente limpiar el caché
        del navegador o envíe un correo a {email}. Puede limpiar su caché yendo al
        historial de navegación, seleccionando en limpiar datos de navegación. He aquí unos ejemplos de cómo hacerlo:
        <strong>
            <a 
                href="https://support.apple.com/es-co/HT201265" 
                target="_blank"
                rel="noopener noreferrer"
            >iPhone
            </a>
        </strong>
        and
        <strong>
            <a 
                href="https://support.google.com/accounts/answer/32050?co=GENIE.Platform&amp%3Bhl=en&hl=es-419" 
                target="_blank"
                rel="noopener noreferrer"
            >
                Android
            </a>
        </strong>
        .`
    },
    panel6: {
        header: 'No encontró lo que buscaba?',
        text: 'Haga click en el link inferior para describir el problema que está experimentando.' 
    }
};
