import './List.scss';
import React from 'react';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import ListItemMUI from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';

const ListItem = ({ arrow, prefix, suffix, onClick, className, children, disabled, ...rest }) => (
    <ListItemMUI
        onClick={onClick}
        onKeyPress={onClick}
        className={`c-list-item ${className} ${arrow ? 'c-list-item-arrow' : ''}`}
        disabled={disabled}
        {...rest}
        secondaryAction={suffix}
    >
        {prefix && (
            <ListItemIcon>
                {prefix}
            </ListItemIcon>
        )}
        {children}
    </ListItemMUI>
);

ListItem.propTypes = {
    arrow: PropTypes.bool, 
    prefix: PropTypes.element, 
    suffix: PropTypes.element, 
    onClick: PropTypes.func, 
    className: PropTypes.string, 
    children: PropTypes.any, 
    disabled: PropTypes.bool
};
ListItem.defaultProps = {
    arrow: false,
    prefix: null,
    suffix: null,
    onClick: undefined,
    className: '',
    children: null,
    disabled: false
};

export { List, ListItem };
