import PropTypes from 'prop-types';
import { hasGrant } from '../utils';
// Administration
const Secured = ({ grant, children }) => {
    if (hasGrant(grant)) {
        return children;
    }
    return null;
};

Secured.propTypes = {
    grant: PropTypes.array,
    children: PropTypes.any
};

Secured.defaultProps = {
    children: null,
    grant: []
};

export { Secured };
