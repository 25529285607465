/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';

const MAX_ROWS = 5;
    
const ShowMoreText = (value) => {
    const [visible, setVisible] = useState(false);
    // eslint-disable-next-line react/destructuring-assignment
    const rows = value.split('\n');
    const firstPart = rows.slice(0, MAX_ROWS).join('\n');
    const hiddenPart = rows.slice(MAX_ROWS, rows.length).join('\n');
    const hasHidden = hiddenPart && !visible;
    const onClick = (e) => {
        if (!hasHidden) {
            return;
        }
        e.stopPropagation();
        setVisible(!visible);
    };

    return (
        <div 
            role="button"
            tabIndex="0"
            className={`ta-left pre ${hasHidden ? 'has-more' : ''}`} 
            style={{ maxWidth: 600 }}
            onClick={onClick}
        >
            {firstPart}
            {visible && <div>{hiddenPart}</div>}
        </div>
    );
};

export { ShowMoreText };
