import './badge.scss';
import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Badge } from 'components';
import PropTypes from 'prop-types';
import { NOTIFICATION_TYPE, NotificationActions } from 'modules/notification';

const HIDE_AFTER_MS = 5 * 1000;
// const GET_EVERY_MS = 10 * 1000;
const ExcludedTypes = [
    NOTIFICATION_TYPE.exportReady,
    NOTIFICATION_TYPE.importReady,
];

const AlertsBadge = ({ className, children }) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const dispatch = useCallback(useDispatch(), []);

    const alerts = useSelector(state => state.alerts);

    const filteredAlerts = alerts
        .filter(item => !ExcludedTypes.includes(item.type));    

    const unread = filteredAlerts.filter(item => !item.readAt);
    const unseen = unread;// .filter(item => !item.show);
    
    useEffect(() => {
        const timer = setTimeout(() => 
            dispatch(NotificationActions.getAlertsUnread()),
        500);
        return () => {
            clearTimeout(timer);
        };
        /* Removed, it is called on every route change
        const interval = setInterval(getAlerts, GET_EVERY_MS); 
        return () => clearInterval(interval);
        */
    }, [dispatch]);

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (unseen.length) {
            const item = unseen[0];
            const timer = setTimeout(() => {
                dispatch(NotificationActions.markShow(item.id));
            }, HIDE_AFTER_MS);
            return () => clearTimeout(timer);
        }
    }, [unseen, dispatch]);

    return (
        <Badge
            badgeContent={unread.length}
            variant="dot"
            className={`${className}`}
        >
            {children}
        </Badge>
    );
};

AlertsBadge.propTypes = {
    className: PropTypes.string,
    children: PropTypes.any,
};

AlertsBadge.defaultProps = {
    className: '',
    children: null,
};

export { AlertsBadge };
