export default {
    er: {
        title: 'Call {emergencyNumber}.',
        label: '<p class="ta-center">You may be having a medical emergency.</p>'
    },
    cleared: {
        title: 'Pass',
        label: '<p class="ta-center">Thank you for your screening.</p>',
    },
    failed: {
        title: 'Not attending',
        label: '<p class="ta-center">Sorry you are not able to report to work today. Please click below to access your benefits.</p>',
        labelNoBenefits: '<p class="ta-center">Sorry you are not able to report to work today.</p>', 
        buttonLabel: 'Go to Benefits Page'
    }
};
