import './assets/less/index.scss';

import React, { Suspense, useEffect } from 'react';
import ReactGA from 'react-ga';
import { Spin } from 'components';
import { Provider as ReduxProvider } from 'react-redux';
import store from 'modules/store';
import { BrandProvider } from 'brands';
import { Router } from 'routing';
import { createRoot } from 'react-dom/client';
import { ServiceWorkerProvider, useServiceWorker } from './providers/serviceWorker';
import Apps from './apps';

const GoogleAnalyticsKey = process.env.REACT_APP_GA;

const LintelioDS = () => {
    const { isUpdateAvailable, update } = useServiceWorker();

    useEffect(() => {
        if (isUpdateAvailable) {
            update();
        }
    }, [isUpdateAvailable, update]);

    useEffect(() => {
        if (GoogleAnalyticsKey) {
            ReactGA.initialize(GoogleAnalyticsKey, {
                debug: false,
                titleCase: false,
            });
        }
    }, []);

    return (
        <ReduxProvider store={store}>
            <BrandProvider>
                <Suspense fallback={<Spin className="center" />}>
                    <Router>
                        <Apps />
                    </Router>
                </Suspense>
            </BrandProvider>
        </ReduxProvider>
    );
};

const container = document.getElementById('app');
const root = createRoot(container);
root.render(<ServiceWorkerProvider><LintelioDS /></ServiceWorkerProvider>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
