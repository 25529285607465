import './SelectTags.scss';
import PropTypes from 'prop-types';
import React from 'react';
import { Select } from 'antd';

const SelectTags = props => (
    (
        <Select
            {...props}
            mode="tags"
            className="select-tags"
            popupClassName="select-tags-dropdown"
            getPopupContainer={trigger => trigger.parentNode}
        />
    )
);

SelectTags.propTypes = {
    props: PropTypes.object
};
SelectTags.defaultProps = {
    props: {}
};
export { SelectTags };
