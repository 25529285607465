export default {
    vaccination: {
        title: 'Estas seguro/a?',
        text: 'Yo doy fe de que esta declaración sobre el de estado de mi vacunación es veridico y correcto. Entiendo que proveer información falsa sobre mi estado de vacunación puede sujetarme a penitencias criminales.',
        okButton: 'Si, Yo confirmo',
    },
    exempt: {
        title: 'Estas seguro/a?',
        text: 'Yo doy fe de que el reporte que estoy introduciendo es veridico y correcto',
        okButton: 'Si, Yo confirmo',
    },
    testing: {
        title: 'Estas seguro/a?',
        text: 'Yo doy fe de que el reporte que estoy introduciendo es veridico y correcto',
        okButton: 'Si, Yo confirmo',
    },
    booster: {
        title: 'Estas seguro/a?',
        text: 'Yo doy fe de que esta declaración sobre el de estado de mi vacunación es veridico y correcto. Entiendo que proveer información falsa sobre mi estado de vacunación puede sujetarme a penitencias criminales.',
        okButton: 'Si, Yo confirmo',
    },
    // @NEW
    immunization: {
        title: 'Are you sure?',
        text: 'I certify that this statement about my vaccination status is true and accurate. I understand that knowingly providing false information regarding my vaccination status may subject me to criminal penalties.',
        okButton: 'Yes, I certify',
    },
};
