import './Tabs.scss';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Tabs as TabsBase } from 'antd';
import { history } from 'routing';

const TabPane = ({ children, ...rest }) => (
    <div className="tabs-scroll" {...rest}>
        {children}
    </div>
);

TabPane.propTypes = {
    children: PropTypes.any,
};

TabPane.defaultProps = {
    children: null,
};

const Tabs = ({ routing, children, className, destroyInactiveTabPane, ...rest }) => {
    const tabs = Array.isArray(children) ? children : [children];

    const items = tabs
        .filter(item => !!item)
        .map(item => ({
            key: item.key,
            label: item.props.tab,
            children: item.props.children
        }));
    
    const [activeKey, setActiveKey] = useState();

    useEffect(() => {
        if (routing) {
            setActiveKey(decodeURI(history.location.pathname));
        }
    }, [routing]);
    
    const onChange = (key) => {
        if (routing) {
            history.push(key);
        }
    };

    history.listen(({ location: { pathname } }) => {
        if (routing) {
            setActiveKey(decodeURI(pathname));
        }
    });

    return (
        <TabsBase
            {...rest}
            destroyInactiveTabPane={destroyInactiveTabPane}
            className={`ltabs ${className}`}
            items={items}
            activeKey={activeKey}
            onChange={onChange}
        />
    );
};

Tabs.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    destroyInactiveTabPane: PropTypes.bool,
    routing: PropTypes.bool
};

Tabs.defaultProps = {
    destroyInactiveTabPane: true,
    children: [],
    className: '',
    routing: false
};

Tabs.TabPane = TabPane;

export { Tabs, TabsBase };
