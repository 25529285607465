import './Upload.scss';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, message } from 'components';
import { UploadIcon } from 'components/Icons';
import { isMobile } from 'utils';
import locales from 'locales';

const AntdUpload = require('antd/lib/upload').default;

const { Dragger } = AntdUpload;

const MAX_FILE_SIZE = 10000000;

const Upload = ({ maxCount, loading, multiple, onChange, disabled, label, ...rest }) => {
    const { common: { fileUpload: strings } } = locales;
    const [fileList, setFileList] = useState([]);
    const [errors, setErrors] = useState([]);

    useEffect(() => {
        if (errors.length) {
            const uniqueErrors = [...new Set(errors)];
            uniqueErrors.map(i => message.error(i));
            setErrors([]);
        }
    }, [errors, errors.length]);

    const beforeUpload = (file, files) => {
        let list = [...fileList, ...files];

        const lengthBeforeFiltering = list.length;
        
        list = list.filter(({ size }) => {
            if (size <= MAX_FILE_SIZE) return true;
            setErrors(oldState => [...oldState, strings.errorFileSize]);
            return false;
        });
        
        if (lengthBeforeFiltering > maxCount) {
            list = list.slice(0, maxCount);
            setErrors(oldState => [...oldState, strings.errorMaxCount]);
        }

        setFileList(list);
        onChange(list);
        return false;
    };

    const onRemove = (file) => {
        const list = Array.remove(fileList, file);
        setFileList(list);
        onChange(list);
    };

    const thisProps = {
        ...rest,
        beforeUpload,
        onRemove,
        multiple,
        maxCount,
        fileList,
    };

    if (!isMobile) {
        return (
            <Dragger {...thisProps} disabled={disabled} className="c-upload-dragger">
                <p className="upload-drag-icon">
                    <UploadIcon />
                </p>
                <p className="ant-upload-text">
                    Click or drag files to this area to upload
                </p>
                <p className="ant-upload-hint">
                    File limit is {maxCount}
                </p>
            </Dragger>
        );
    }
    return (
        <AntdUpload {...thisProps} disabled={disabled}>
            <Button
                block
                loading={loading}
                className="c-upload"
                icon={<UploadIcon />}
                disabled={disabled}
                type="button"
            >{label}
            </Button>
        </AntdUpload>
    );
};

Upload.propTypes = {
    label: PropTypes.string,
    maxCount: PropTypes.number,
    multiple: PropTypes.bool,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    loading: PropTypes.bool
};

Upload.defaultProps = {
    label: 'Tap to Upload',
    maxCount: 1,
    multiple: false,
    onChange: null,
    disabled: false,
    loading: false
};

export { Upload };
