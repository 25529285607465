import { MODULE_PANDEMIC, MODULES } from 'modules/module';

const transformModuleStatusesIn = ({ patientModuleStatuses, screeningStatus }) => {
    const getStatusKey = (moduleStatuses, statusId) => Object.keys(moduleStatuses)
        .find(key => moduleStatuses[key].value === statusId);

    const inStatuses = [
        ...patientModuleStatuses,
        { module: MODULE_PANDEMIC, status: screeningStatus },
    ];

    const getStatusId = moduleId => inStatuses
        .find(item => item.module === moduleId)?.status || 0;

    const statuses = {};
    MODULES
        .filter(item => item.statuses)
        .forEach((module) => {
            const statusId = getStatusId(module.id, inStatuses);
            const statusKey = getStatusKey(module.statuses, statusId);
            statuses[module.key] = statusKey;
        });

    return statuses;
};

export default {
    in: transformModuleStatusesIn,
};
