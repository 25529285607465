const MODULE_VACCINATION_STATUS_CONST = {
    UNVACC: 0, // @DEFAULT
    VACC: 1,
    OPTOUT: 2,
    PARTIALLYVACC: 3,
    VACCREQUIRED: 4, 
};

export const MODULE_VACCINATION_STATUS = {
    UNVACC: {
        key: 'UNVACC',
        value: MODULE_VACCINATION_STATUS_CONST.UNVACC, 
        label: 'Unvaccinated',
        color: '#fe3b3b'
    },
    VACC: {
        key: 'VACC',
        value: MODULE_VACCINATION_STATUS_CONST.VACC,
        label: 'Vaccinated',
        color: '#318f6f'
    },
    OPTOUT: {
        key: 'OPTOUT',
        value: MODULE_VACCINATION_STATUS_CONST.OPTOUT,
        label: 'Opted Out',
        color: '#FAD24D'
    },
    PARTIALLYVACC: {
        key: 'PARTIALLYVACC',
        value: MODULE_VACCINATION_STATUS_CONST.PARTIALLYVACC,
        label: 'Partially',
        color: '#FAD24D'
    },
    VACCREQUIRED: {
        key: 'VACCREQUIRED',
        value: MODULE_VACCINATION_STATUS_CONST.VACCREQUIRED,
        label: 'Vaccination Required',
        color: '#FAD24D'
    }
};

export const VaccinationStatusOptions = [
    { text: 'Unvaccinated', value: MODULE_VACCINATION_STATUS.UNVACC.value },
    { text: 'Vaccination Required', value: MODULE_VACCINATION_STATUS.VACCREQUIRED.value },
    { text: 'Partially Vaccinated', value: MODULE_VACCINATION_STATUS.PARTIALLYVACC.value },
    { text: 'Vaccinated', value: MODULE_VACCINATION_STATUS.VACC.value },
    { text: 'Exemption', value: MODULE_VACCINATION_STATUS.OPTOUT.value },
];

export const isStatusVaccinationRequired = vaccinationStatus => 
    vaccinationStatus === MODULE_VACCINATION_STATUS.VACCREQUIRED.key;
