import './filter.search.scss';
import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import { Button, Input, Checkbox } from 'apps/administration/components';
import { debounce, stopEvent } from 'utils';
import PropTypes from 'prop-types';

const debounseMs = 1500;

const FilterSearch = ({
    service, 
    clearFilters, 
    setSelectedKeys, selectedKeys,
    confirm, visible
}) => {
    const textInput = useRef();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selected, setSelected] = useState([]);
    const [searchValue, setSearchValue] = useState();
    
    useEffect(() => {
       setSelected(selectedKeys); 
    }, [selectedKeys]);
   
    useEffect(() => {
        if (textInput.current) {
            setTimeout(() => {
                textInput.current.focus();
            });
        }
    }, [visible]);

    const getEntities = useCallback((value) => {
        setLoading(true);
        service({ title: value })
            .then(res => setData(res.data))
            .finally(() => setLoading(false));
    }, [service]);

    const debouncedSearch = useMemo(() => debounce(
        getEntities, 
        debounseMs
    ), [getEntities]);

    const onSearchChange = (str) => {
        setSearchValue(str);
        if (str && str.length > 2) {
            debouncedSearch(str);
        }
    };
    
    const onSearchClick = (str) => {
        setData([]);
        getEntities(str);
    };

    const onSearchEnter = (e) => {
        stopEvent(e);
        onSearchClick(e.target.value);
    };

    const handleClearFilters = () => {
        setData([]);
        setSearchValue();
        setSelected();
        clearFilters();
        confirm();
    };

    const handleSearch = () => {
        if (selected.length) {
            setSelectedKeys(selected);
            confirm();
        } else {
            clearFilters();
        }
    };

    return (
        <div className="table-filter-dropdown-search">
            <div>
                <Input.Search
                    ref={textInput}
                    value={searchValue}
                    placeholder="Search by Name"
                    onChange={e => onSearchChange(e.target.value)}
                    onPressEnter={onSearchEnter}
                    onSearch={onSearchClick}
                    loading={loading}
                />
                {!data && selectedKeys.length > 0 && (
                    <h5>{selectedKeys.length} Item/s Selected</h5>
                )}
                {!loading && data?.length === 0 && (
                    <div className="empty">
                        No Data
                    </div>
                )}
                {data?.length > 0 && (
                    <Checkbox.Group
                        onChange={setSelected}
                        value={selected}
                        className="checkbox-group-vertical"
                    >
                        {data.map(item => (
                            <Checkbox
                                key={item.id}
                                value={item.id}
                                title={item.title}
                            >
                                {item.title}
                            </Checkbox>
                        ))}
                    </Checkbox.Group>
                )}
            </div>
            <div className="ant-table-filter-dropdown-btns">
                <Button
                    type="link"
                    size="small"
                    onClick={handleClearFilters}
                    className="ant-table-filter-dropdown-link clear"
                >
                    Reset
                </Button>
                <Button
                    type="primary"
                    size="small"
                    onClick={handleSearch}
                    className="ant-table-filter-dropdown-link confirm"
                    disabled={!selected?.length}
                >
                    OK
                </Button>
            </div>
        </div>
    );
};

FilterSearch.propTypes = {
    service: PropTypes.func.isRequired,
    clearFilters: PropTypes.func.isRequired,
    setSelectedKeys: PropTypes.func.isRequired,
    confirm: PropTypes.func.isRequired,
    selectedKeys: PropTypes.array.isRequired,
    visible: PropTypes.bool.isRequired
};

export default FilterSearch;
