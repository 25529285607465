export default 
{
    back: 'Quay lại màn hình chính',
    report: 'Report Injury', // @NEW
    resultTitle: 'Please stay by your phone', // @NEW
    resultText: 'You have been added to the queue. You will receive a call back in approximately <strong>12 minutes.</strong>', // @NEW
    labels: {
        date: 'When did the injury occur?', // @NEW
        location: 'Where did the accident happen?', // @NEW
        callbackPhone: 'What is your contact number?', // @NEW
        reason: 'How did the accident happen?<br><small>(Optional)</small>', // @NEW
        description: 'What object, equipment, or substance was involved?<br><small>(Optional)</small>', // @NEW
        activityDetails: 'Describe what you were doing at the time?<br><small>(Optional)</small>', // @NEW
        medicalCompliant: 'Describe your medical complaint<br><small>(Optional)</small>', // @NEW
    }
};
