import LocalizedStrings from 'react-localization';
import { setDateLocale } from 'utils';
import HTTP_MESSAGES, { SKIP_MESSAGE } from './api.config';
import en from './en';
import es from './es';
import vn from './vn';
import { langs } from './config';

if (process.env.NODE_ENV === 'development') {
    import('./.test')
        .then((test) => {
            test.default();
        });
}

export * from './config';

const staticTranslations = { en, es, vn };

const locales = new LocalizedStrings(
    staticTranslations, 
    {
        logsEnabled: false,
        pseudo: false,
        pseudoMultipleLanguages: false
    }
);

export const updateTranslations = (data, lang) => {
    const res = {};

    langs.forEach(({ key }) => {
        res[key] = { ...staticTranslations[key], ...data[key] };
    });
    locales.setContent(res); 
    locales.setLanguage(lang);
    return lang; 
};

export const replaceTranslations = (data) => {
    const lang = locales.getLanguage();
    locales.setContent(staticTranslations);
    return updateTranslations(data, lang);
};

export const translateResponse = (response, isError) => {
    const { $path = '/', $method = '*', $code = '*', ...rest } = response;
    const key = Object.keys(HTTP_MESSAGES).find(item => $path.indexOf(item) === 0);
    if (key) {
        const config = HTTP_MESSAGES[key];
        let localeKey = Object.exists(config, $method, $code);
        
        if (isError) {
            if (!localeKey) {
                localeKey = Object.exists(config, $method, '*');
            }
            if (!localeKey) {
                localeKey = Object.exists(config, '*', $code);
            }
        }
    
        if (localeKey) {
            if (localeKey === SKIP_MESSAGE) {
                return { ...response, $message: false };
            }
            const $message = locales.formatString(locales.api[localeKey], rest);
            return { ...response, $message };
        }
    }
    return response;
};

export const setLocale = (lang) => {
    locales.setLanguage(lang);
    setDateLocale(lang);
};

export default locales;
