export default {
    labels: {
        next: 'Kế tiếp',
        back: 'Trở lại',
        phone: 'Số điện thoại',
        password: 'Mật mã',
        email: 'Email',
        tryAgain: 'Thử lại',
        forgotPassword: 'Quên mật khẩu của bạn?',
        resetPassword: 'Cài lại mật khẩu',
    },
    // @NEW section
    social: {
        subtitle: 'Sign in with your Social Accounts',
        or: 'or'
    },
    preSignIn: {
        title: 'Welcome to the <div>{title}</div> Health and Safety Service', // @NEW
        labels: {
            button: 'Chúng ta hãy bắt đầu',
        },
        placeholders: {
            location: 'Chọn Địa điểm' 
        }
    },
    signInPhone: {
        title: 'Thêm Số Điện thoại',
        subtitle: 'Nhập vào số điện thoại di động bên dưới để bắt đầu.',
        popupHelp: {
            linknote: 'Tại sao chúng tôi cần số điện thoại?',
            note: 'Chúng tôi cần gửi mã vào số điện thoại này để xác minh đó chính là bạn. Phí tin nhắn từ nhà cung cấp có thể áp dụng (gần như không bị vào lúc này, nhưng chúng tôi phải nói).',
            noteClose: 'Đã hiểu',
        }
    },
    signInEmail: {
        title: 'Thêm Email',
        subtitle: 'Nhập email của bạn bên dưới để bắt đầu.',
    },
    code: {
        title: 'Xác minh',
        subtitle: 'Nhập vào mã xác minh của bạn',
        subtitleUser: 'Chào mừng, {firstName}!',
        notes: {
            email: 'Chúng tôi vừa gửi mã xác minh tới email {maskedUsername}. Mã sẽ hết hạn năm phút sau khi bạn yêu cầu.',
            phone_number: 'Chúng tôi vừa gửi mã xác minh đến số điện thoại có số cuối là {maskedUsername}. Mã sẽ hết hạn năm phút sau khi bạn yêu cầu.'
        },
        tryAgainNote: 'Bạn không nhận được mã?',
        codeSent: {
            email: 'Chúng tôi đã gửi một mã khác. Vui lòng kiểm tra email.',
            phone_number: 'Chúng tôi đã gửi một mã khác. Vui lòng kiểm tra tin nhắn.'
        },
    },
    signInPassword: {
        title: 'Mật khẩu',
        subtitle: 'Vui lòng nhập mật khẩu của bạn.',
    },
    signUpPassword: {
        title: 'Mật khẩu mới',
        subtitle: 'Vui lòng bảo đảm rằng mật khẩu chứa ít nhất 8 ký tự bao gồm một chữ cái viết hoa, một chữ cái viết thường và 1 con số.',
    },
    forgotPassword: {
        title: 'Quên mật khẩu?',
        subtitle: 'Nhập nhãn {label} bên dưới và chúng tôi sẽ gửi tin nhắn để tái lập mật khẩu của bạn',
    },
    popupSwitch: {
        title: 'Chuyển sang xác thực dựa trên mật khẩu',
        text1: 'Mệt mỏi vì nhập mã xác thực? Bạn có thể chuyển sang sử dụng mật khẩu. Chúng tôi sẽ hướng dẫn bạn suốt tiến trình.',
        text2: 'Nớ rằng đâylà CÔNG TẮC MỘT LẦN. Khi bạn quyết định sử dụng mật khẩu, bạn không thể quay lại mã xác thực một lần. Điều này cho biết, chúng tôi biết đây là sự chuyển động tốt.',
        text3: 'Bạn có muốn chuyển sang xác thực dựa trên mật khẩu không?',
        text: 'Chuyển sang xác thực dựa tên mật khẩu?',
        textlink: 'Nhấn vào đây'
    },
    verify: {
        title: 'Thêm số điện thoại',
        subtitle: 'Chúng tôi cần xác minh số điện thoại trước tiên. Đây là hành động một lần.',
        label: 'Điện thoại',
        titleSuccess: 'Tất cả đã xong',
        subtitleSuccess: 'Số điện thoại đã được xác minh thành công. Bạn có thể mở Nhắc nhở SMS.',
    },
};
