import { APIT } from 'utils';

const transformLocationListIn = (list) => {
    if (list && !list.data) {
        return list;
    }
    
    return {
        ...list, 
        data: list.data.map(item => 
            ({
                ...item, 
                updatedAt: APIT.date.in.toLocal(item.updatedAt),
            }))
        
    };
};

export default transformLocationListIn;
