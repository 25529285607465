import icon01 from 'assets/images/announcements/ico-01.svg';
import icon02 from 'assets/images/announcements/ico-02.svg';
import icon03 from 'assets/images/announcements/ico-03.svg';
import icon04 from 'assets/images/announcements/ico-04.svg';
import icon05 from 'assets/images/announcements/ico-05.svg';
import icon06 from 'assets/images/announcements/ico-06.svg';
import icon07 from 'assets/images/announcements/ico-07.svg';
import icon08 from 'assets/images/announcements/ico-08.svg';
import icon09 from 'assets/images/announcements/ico-09.svg';
import icon10 from 'assets/images/announcements/ico-10.svg';

import icon11 from 'assets/images/announcements/ico-11.svg';
import icon12 from 'assets/images/announcements/ico-12.svg';
import icon13 from 'assets/images/announcements/ico-13.svg';
import icon14 from 'assets/images/announcements/ico-14.svg';
import icon15 from 'assets/images/announcements/ico-15.svg';
import icon16 from 'assets/images/announcements/ico-16.svg';
import icon17 from 'assets/images/announcements/ico-17.svg';
import icon18 from 'assets/images/announcements/ico-18.svg';
import icon19 from 'assets/images/announcements/ico-19.svg';
import icon20 from 'assets/images/announcements/ico-20.svg';

export const ICON_TYPE_FILENAME = {
    icon01,
    icon02,
    icon03,
    icon04,
    icon05,
    icon06,
    icon07,
    icon08,
    icon09,
    icon10,
    icon11,
    icon12,
    icon13,
    icon14,
    icon15,
    icon16,
    icon17,
    icon18,
    icon19,
    icon20,
};

export const COLOR_FILENAME = [
    'yellow',
    'green',
    'gray',
    'lightblue',
    'blue',
    'darkblue',
    'purple',
    'darkred',
    'red',
    'orange',
];
