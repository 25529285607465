export default 
{
    alerts: 'Alerts', // @NEW
    newAlerts: 'New Alerts', // @NEW
    noNewAlerts: 'No New Alerts', // @NEW
    subtitleUser: 'Chào mừng, {userName}!',
    unreadAlerts: 'unread Alerts', // @NEW
    latestAlert: 'Latest Alert', // @NEW
    viewAll: 'View All', // @NEW
    tapScreen: 'Tap for screen', // @NEW
    tapInfo: 'Tap for info', // @NEW
    reports: 'Reports', // @NEW
    status: 'Tình trạng',
    id: 'ID', // @NEW
    notAvailable: 'Not Available', // @NEW
    incident: 'Incident', // @NEW
    screenStatus: 'Screen Status', // @NEW
    idNumber: 'Số ID',
    messageWait: 'Có vẻ như bạn đã hoàn thành kiểm tra! Quay lại sau để kiểm tra lại!.',
    details: 'Chi tiết',
    buttonScreen: 'Kiểm tra',
    buttonAction: 'Actions', // @NEW
    title: 'Pandemic', // @NEW
    buttonReport: 'Báo cáo',
    buttonTesting: 'Báo cáo Kiểm tra',
    buttonVaccination: 'Báo cáo Chích ngừa',
    buttonVaccinationBooster: 'Report Vaccination Booster', // @NEW
    buttonSecondVaccination: 'Chích ngừa mũi thứ hai',
    proctoredTest: 'Proctored Test', // @NEW
    buttonGuidelines: 'Nhấn vào đây để biết thêm chi tiết',
    buttonShowDetails: 'Trình bày chi tiết',
    buttonHideDetails: 'Giấu chi tiết',
    buttonWorkplaceInjury: 'Workplace Injury',
    buttonMore: 'More', // @NEW
    buttonNewReport: 'New Report', // @NEW
    total: 'Total', // @NEW
    ok: 'Ok', // @NEW
    benefits: 'Benefits', // @NEW
};
