import './DatePicker.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { CalendarIcon } from 'components/Icons';
import locales from 'locales';
import { Input } from '../Input';

/*
// mode,
// icon,
title,
disabled,
minDate,
maxDate,
placeholder,
// className,
onChange,
value,
*/
const DatePicker = ({ 
    value, onChange,
    title, disabled, 
    minDate, maxDate, 
    className, placeholder
}) => {
    const { common } = locales;

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileDatePicker
                minDate={minDate}
                maxDate={maxDate}
                value={value}
                onChange={onChange}
                toolbarTitle={title}
                okText={common.OK}
                clearText={common.clear}
                cancelText={common.cancel}
                disabled={disabled}
                readOnly={disabled}
                DialogProps={{ className: 'c-date-dialog' }}
                renderInput={({ inputRef, inputProps }) => (
                    <Input 
                        className={className}
                        prefix={<CalendarIcon />}
                        ref={inputRef}
                        placeholder={placeholder}
                        {...inputProps}
                    />
                )}
            />
        </LocalizationProvider>
    );
};

DatePicker.propTypes = {
    value: PropTypes.object,
    onChange: PropTypes.func,
    title: PropTypes.string, 
    disabled: PropTypes.bool, 
    minDate: PropTypes.object, 
    maxDate: PropTypes.object, 
    className: PropTypes.string,
    placeholder: PropTypes.string

};

DatePicker.defaultProps = {
    value: null,
    onChange: () => {},
    title: '', 
    disabled: false, 
    minDate: undefined, 
    maxDate: undefined, 
    className: '',
    placeholder: ''
};

export { DatePicker };
