import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { showError } from 'utils';
import { ShowMessage } from 'services';
import { PatientServiceAdmin } from '../patient.service.admin';
import { PatientConstants } from '../patient.constants';
import { LocationService } from '../../location';

export const usePatientUpdate = () => {
    const dispatch = useDispatch();
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);

    const fetchMethod = (id, values, getLocation = false) => {
        const { reminders, ...rest } = values;

        const promises = [];
        if (!Object.isEmpty(rest)) {
            promises.push(PatientServiceAdmin.update(id, values)
                .then(async (res) => {
                    if (getLocation) {
                        const location = await LocationService.get(res.locationId);
                        dispatch(({ type: PatientConstants.LOADED, data: { ...res, location } }));
                    } else {
                        dispatch(({ type: PatientConstants.LOADED, data: res }));
                    }
                    return res;
                }));
        }
        if (reminders) {
            promises.push(PatientServiceAdmin.remindersUpdate(id, { data: reminders }));
        }

        setLoading(true);
        return Promise.all(promises)
            .then((data) => {
                ShowMessage.saved();
                return data;
            })
            .catch((err) => {
                setError(err);
                showError(err);
                return Promise.reject(err);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const stableFetch = useCallback(fetchMethod, []);
    return [stableFetch, { loading, error }];
};
