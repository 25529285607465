import './ShowMore.scss';
import React from 'react';
import AnimatedShowMore from 'react-animated-show-more';
import PropTypes from 'prop-types';

export * from './ShowMoreText';

const ShowMore = ({ className, children, height, speed, toggle, shadowColor }) => (
    <AnimatedShowMore
        height={height}
        speed={speed}
        toggle={toggle}
        shadowColor={shadowColor}
    >
        {
            typeof children === 'object' && (
                <div className={className}>
                    {children}
                </div>
            )
        }
        {
            typeof children === 'string' && (
                <div
                    className={className}
                    dangerouslySetInnerHTML={{ __html: children }}
                />
            )
        }
    </AnimatedShowMore>
);

ShowMore.propTypes = {
    height: PropTypes.number,
    speed: PropTypes.number,
    className: PropTypes.string,
    toggle: PropTypes.any,
    children: PropTypes.any.isRequired,
    shadowColor: PropTypes.string
};

ShowMore.defaultProps = {
    height: 53,
    speed: 200,
    className: '',
    toggle: undefined,
    shadowColor: '#ffffff'
};

export { ShowMore };
