export default {
    title: 'Editar Perfil',
    registration: 'Regístrese',
    subtitle: 'Al parecer es nuevo aquí.',
    notes1: ' Necesitamos saber un poco más sobre usted, después le evaluaremos lo más pronto posible.',
    notes2: 'Si está experimentando síntomas severos o que amenazan su vida, debe BUSCAR ATENCIÓN MÉDICA INMEDIATAMENTE LLAMANDO AL {emergencyNumber}',
    placeholders: {
        employeeNumber: 'Ingrese su número de identificación',
        firstName: 'Ingrese su primer nombre',
        lastName: 'Ingrese su apellido',
        jobCategory: 'Por favor seleccione',
        address: 'Agregue su dirección', 
        address2: 'Agregue su dirección',
        gender: 'Por favor seleccione',
    },
    labels: {
        employeeNumber: 'Numero de ID', 
        employeeNumberTooltip: 'Este es su número de identificación de empleado, identificación de estudiante u otro número de identificación  asignado por la organización que le pidio que se evaluara.',
        firstName: 'Primer Nombre',
        lastName: 'Apellido',
        jobCategory: 'Yo soy...',
        jobCategoryTooltip: `Trabajadores esenciales incluyen:
            
            • Fuerzas policiales  locales, estatales y federales
            • Empleados del centro de llamadas del {emergencyNumber}
            • Empleados de centros de fusión de inteligencia
            • Equipos de respuesta ante materiales peligrosos (Gubernamentales y Privados)
            • Personal de limpieza y otro personal de vigilancia
            • Trabajadores (incluyendo distribuidores contratados) de comida y agricultura
            • Fabricaciones esenciales, tecnologias de informacion, transporte, energía e instalaciones del gobierno
        `,
        button: 'Comencemos',
        buttonSave: 'Guardar Cambios',
        optin: {
            screen: 'Si, por favor envieme recordatorios y notificaciones de los administradores via SMS o correo! Siempre puede salirme luego. Cargos por mensajes y datos pueden aplicar.',
        },
        terms: 'Al continuar usted está de acuerdo con los {termsLink} y {policyLink}',
        phone: 'Número de Teléfono',
        dateOfBirth: 'Fecha de Nacimiento',
        address: 'dirección 1',
        address2: 'dirección 2', 
        city: 'Ciudad', 
        state: 'Estado', 
        zip: 'Código Zip', 
        medicalConsentBefore: 'Al continuar, usted autoriza el {locationTitle}\'s', 
        medicalConsentMiddle: 'Uso y Revelación de información medica confidencial',
        medicalConsentAfter: 'por Personal Autorizado.',
        gender: 'género', 
    },
    // @NEW section
    mergeUsers: {
        buttonSave: 'Save Changes',
        text: `Our system indicates that this phone number {phone} is already in use with two different user profiles.
        Please select from below the user profile
        that you will be using from now onwards.`,
        current: 'Current',
        note: 'All your user data will be saved on the selected user profile.'
    },
};
