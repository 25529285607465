import { createBrowserHistory } from 'history';

const history = createBrowserHistory({ window });

// LintelioOnly

const paths = window.location.pathname.split('/');
    
export const cidInPath = ((paths.length > 1) && paths[1].length >= 20) ? paths[1] : '';

export const homePath = cidInPath ? `/${cidInPath}/` : '/';

export const navigateHome = () => {
    history.push(homePath);
};

export { history };
