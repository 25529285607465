import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import useLockFn from 'hooks/useLockFn';

const overflowScrollReg = /scroll|auto|overlay/i;

const isElement = (node) => {
    const ELEMENT_NODE_TYPE = 1;
    return node.nodeType === ELEMENT_NODE_TYPE;
};

const getScrollParent = (el, root = window) => {
    let node = el;

    while (node && node !== root && isElement(node)) {
        const {
            overflowY
        } = window.getComputedStyle(node);

        if (overflowScrollReg.test(overflowY)) {
            return node;
        }

        node = node.parentNode;
    }

    return root;
};
const isWindow = element => element === window;

const InfiniteScrollDetect = ({ loadMore, threshold, hasMore, children }) => {
    const doLoadMore = useLockFn(() => loadMore());
    const elementRef = useRef(null);
    const checkTimeoutRef = useRef();

    const check = React.useMemo(() => () => {
        window.clearTimeout(checkTimeoutRef.current);
        checkTimeoutRef.current = window.setTimeout(() => {
            if (!hasMore) return;
            const element = elementRef.current;
            if (!element) return;
            if (!element.offsetParent) return;
            const parent = getScrollParent(element);
            if (!parent) return;
            const rect = element.getBoundingClientRect();
            const elementTop = rect.top;
            const current = isWindow(parent)
                ? window.innerHeight : parent.getBoundingClientRect().bottom;
            if (current >= elementTop - threshold) {
                doLoadMore();
            }
        });
    }, [hasMore, doLoadMore, threshold]);

    useEffect(() => {
        check();
    });

    useEffect(() => {
        const element = elementRef.current;
        if (!element) return;
        const parent = getScrollParent(element);
        if (!parent) return;

        const onScroll = () => {
            check();
        };

        parent.addEventListener('scroll', check);
        // eslint-disable-next-line consistent-return
        return () => {
            parent.removeEventListener('scroll', onScroll);
        };
    }, [check]);

    return (
        <div ref={elementRef}>
            {children}
        </div>
    );
};

InfiniteScrollDetect.propTypes = {
    loadMore: PropTypes.func.isRequired,
    threshold: PropTypes.number,
    hasMore: PropTypes.bool.isRequired,
    children: PropTypes.any.isRequired
};

InfiniteScrollDetect.defaultProps = {
    threshold: 250
};

export default InfiniteScrollDetect;
