import React from 'react';
import { Radio, CheckboxGroup, TextArea, DatePicker, MaskedTemperature, AdvancedRadio } from 'components';
import { FieldTypes } from 'utils';

const renderField = (config, value, setValue) => {
    const onCheckboxChange = (val) => {
        let newVal = val;
        const none = 'None';
        const indexOfNone = val.indexOf(none);
        if (val.length === 0) return setValue(val);
        if (indexOfNone === val.length - 1) {
            newVal = [none];
        } else if (indexOfNone > -1) {
            newVal = val.filter(item => item !== none);
        }
        return setValue(newVal);
    };

    const onRadioChange = (val) => {
        setValue(val);
    };

    const { type, options, props = {} } = config;

    switch (type) {
        case FieldTypes.RADIO:
            return (
                <Radio.Group
                    onChange={onRadioChange}
                    value={value}
                    {...props}
                >
                    {options.map(item => (
                        <Radio
                            key={item.key || item.value}
                            value={item.value}
                        >
                            {item.text}
                        </Radio>
                    ))}
                </Radio.Group>
            );
        case FieldTypes.ADVANCED_RADIO:
            return (
                <AdvancedRadio options={options} onChange={setValue} {...props} />
            );
        case FieldTypes.CHECKBOXES:
            return (
                <CheckboxGroup
                    className="screen-checkbox-group"
                    value={value}
                    onChange={onCheckboxChange}
                    options={options}
                    {...props}
                />
            );
        case FieldTypes.TEMPERATURE:
            return (
                <MaskedTemperature
                    onChange={setValue}
                    {...props}
                />
            );
        case FieldTypes.DATE:
            return (
                <DatePicker 
                    value={value}
                    onChange={setValue}
                    {...props}
                />
            );
        case FieldTypes.TEXTAREA:
            return (
                <TextArea
                    value={value}
                    onChange={e => setValue(e.target.value)}
                    {...props}
                />
            );
        default:
            return null;
    }
};

export default renderField;
