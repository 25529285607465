import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Input, Form, Select, Help, Phone, DatePicker } from 'components';
import { PersonIcon, IdCardIcon } from 'components/Icons';
import { GENDER } from 'modules/patient';
import { LOCATION_FLOW_TYPE, LocationActions } from 'modules/location';
import locales from 'locales';
import { ValidationRules, now } from 'utils';
import { ModulesConfig } from 'apps/administration/constants';
import CustomFields from './custom';
import AddressFields from './address';
import RegistrationCheckboxes from './checkboxes';

const UserDetailsForm = ({ isRegister, isPhoneDisabled }) => {
    const { Item } = Form;
    const { routes: { profile: strings } } = locales;
    const [jobTitleOptions, setJobTitleOptions] = useState([]);
    const rules = ValidationRules.translate();
    const [isPandemicActive, setPandemicActive] = useState(false);
    const pandemicConstant = ModulesConfig.pandemic.module;

    const {
        id: locationId,
        flowType,
        emergencyNumber,
        isEmployeeIdMandatory,
        isAddressFieldEnabled,
        isDateOfBirthFieldEnabled, isGenderFieldEnabled, $modules
    } = useSelector(state => state.location);

    useEffect(() => {
        const activeModules = Object.keys($modules)
            .filter(key => !!$modules[key])
            .map(key => ({ module: key }))
            .filter(i => !!i && i.module === pandemicConstant);
        setPandemicActive(activeModules.length === 1);
    }, [$modules, pandemicConstant]);

    useEffect(() => {
        if (locationId) {
            LocationActions.jobtitles(locationId)
            .then((data) => {
                const res = data.map(({ title, id }) =>
                    ({ label: title, value: id }));
                setJobTitleOptions(res);
            });
        }
    }, [locationId]);

    const tooltips = {
        warning: locales.formatString(strings.notes2, { emergencyNumber }),
        jobTitleId: locales.formatString(strings.labels.jobCategoryTooltip, { emergencyNumber }),
        employeeNumber: strings.labels.employeeNumberTooltip,
    };

    const isEmployerFlow = flowType === LOCATION_FLOW_TYPE.EMPLOYER;
    const allowJobTitleField = isPandemicActive && isEmployerFlow;
    
    return (
        <>
            <Item
                name="firstName"
                label={strings.labels.firstName}
                rules={[rules.required, rules.manychars, rules.name]}
            >
                <Input
                    maxLength={20}
                    prefix={<PersonIcon />}
                    placeholder={strings.placeholders.firstName}
                />
            </Item>
            <Item
                name="lastName"
                label={strings.labels.lastName}
                rules={[rules.required, rules.manychars, rules.name]}
            >
                <Input
                    maxLength={20}
                    prefix={<PersonIcon />}
                    placeholder={strings.placeholders.lastName}
                />
            </Item>
            {isDateOfBirthFieldEnabled && (
                <Item
                    name="dateOfBirth"
                    label={strings.labels.dateOfBirth}
                    rules={[rules.required]}
                >
                    <DatePicker
                        title={strings.labels.dateOfBirth}
                        maxDate={now()}
                        minDate={now('1900-01-01')}
                    />
                </Item>
            )}
            {isGenderFieldEnabled && (
                <Item
                    name="gender"
                    label={strings.labels.gender}
                    rules={[rules.required]}
                >
                    <Select
                        prefix={<PersonIcon />}
                        title={strings.labels.gender}
                        options={GENDER}
                        placeholder={strings.placeholders.gender}
                    />
                </Item>
            )}
            {!isRegister && (
                <Item
                    name="phone"
                    label={strings.labels.phone}
                    rules={[rules.phone, rules.phoneUnique]}
                    validateTrigger="onBlur"
                >
                    <Phone disabled={isPhoneDisabled} />
                </Item>
            )}
            <Item
                name="employeeNumber"
                rules={isEmployeeIdMandatory ? [rules.required] : []}
                label={(
                    <>
                        <span>{strings.labels.employeeNumber}</span>
                        <Help>{tooltips.employeeNumber}</Help>
                    </>
                )}
            >
                <Input
                    maxLength={10}
                    prefix={<IdCardIcon />}
                    placeholder={strings.placeholders.employeeNumber}
                />
            </Item>
            {allowJobTitleField && (
                <Item
                    name="jobTitleId"
                    label={(
                        <>
                            <span>{strings.labels.jobCategory}</span>
                            <Help>{tooltips.jobTitleId}</Help>
                        </>
                    )}
                    rules={[rules.required]}
                >
                    <Select
                        prefix={<PersonIcon />}
                        title={strings.labels.jobCategory}
                        options={jobTitleOptions}
                        placeholder={strings.placeholders.jobCategory}
                    />
                </Item>
            )}

            {isAddressFieldEnabled && <AddressFields />}
            <CustomFields locationId={locationId} />
            {isRegister && ( 
                <RegistrationCheckboxes />
            )}
        </>
    );
};

UserDetailsForm.propTypes = {
    isRegister: PropTypes.bool.isRequired,
    isPhoneDisabled: PropTypes.bool.isRequired
};

export default UserDetailsForm;
