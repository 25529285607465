/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { showError } from 'utils';
import { LocationService } from '../location.service';
import { LocationConstants } from '../location.constants';

export const useLocation = () => {
    const dispatch = useDispatch();
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);

    const clearMethod = () => dispatch({ type: LocationConstants.CLEAR });

    const fetchMethod = (id, getTranslations) => {
        setLoading(true);
        dispatch(({ type: LocationConstants.LOADING }));
        return LocationService.get(id, getTranslations)
            .then((data) => {
                dispatch(({ type: LocationConstants.LOADED_IN_ADMIN, data }));
                return data;
            })
            .catch((err) => {
                setError(err);
                showError(err);
                return Promise.reject(err);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const stableFetch = useCallback(fetchMethod, []);
    const stableClear = useCallback(clearMethod, []);
    return [stableFetch, stableClear, { loading, error }];
};
