import React from 'react';
import { IconButton } from 'components';
import { CloseIcon } from 'components/Icons';
import { useNavigate } from 'routing';

const CloseButton = ({ ...rest }) => {
    const navigate = useNavigate();

    const onClickDefault = () => {
        navigate(-1);
    };
    return (
        <IconButton 
            onClick={onClickDefault}
            size="large"
            {...rest}
        >
            <CloseIcon fontSize="inherit" />
        </IconButton>
    );
};

export default CloseButton;
