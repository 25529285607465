export default {
    // title: 'Report Test Result', // Not used?
    testingTitle: 'Report Test',
    vaccinationTitle: 'Report Vaccination',
    boosterTitle: 'Report Booster',
    vaccinations: 'Vaccinations',
    tests: 'Tests',
    exemptTitle: 'Report Exemption (Unvaccinated)',
    message: 'Test report was sent successfully!',
    messageVaccination: 'Vaccination report was sent successfully!',
    testSuccessReportTitle: 'Test reported successfully!', 
    testSuccessReportText: 'Thank you for reporting your test.', 
    vaccineSuccessReportTitle: 'Vaccination reported successfully!', 
    vaccineSuccessReportText: 'Thank you for reporting your vaccination.',
    boosterSuccessReportTitle: 'Booster reported successfully!', 
    boosterSuccessReportText: 'Thank you for reporting your booster.',
    optOutSuccessReportTitle: 'Exemption (Unvaccinated) reported successfully!', 
    optOutSuccessReportText: 'Thank you for reporting.', 
    backToHomeBtnText: 'Back to Homepage',
    noTests: 'No reported tests yet',
    noVaccinations: 'No reported vaccinations yet',
    viewMore: 'View More',
    viewLess: 'View Less',
    tooltip: {
        testLocation: 'Please provide the location where you were tested or the ID of your testing proctor.',
    },
    form: {
        manufacturer: 'Vaccine Manufacturer',
        firstShot: "(change if it's not today)", 
        shot: 'Date', 
        vaccineLocation: 'Vaccine Location',
        vaccineManufacturer: 'Please select manufacturer first', 
        testResult: 'Test Result',
        dateOfTest: 'Date of Test',
        testReportDate: 'Test Report Date',
        testLocation: 'Location/Proctor ID',
        attachments: 'Attachments',
        attachmentsTooltip: 'Supported file types: .pdf, .xls, .xlsx, .doc, .docx, .jpg, .jpeg, .png, .heif',
        attachmentsTab: 'Tap to upload',
        lotNumber: 'Lot Number', 
        secondShotSection: 'Second Dose', 
        firstShotSection: 'First Dose',
        boosterDose: 'Booster Dose',
        doseSection: 'Dose',
        testNegative: 'COVID-19 Negative',
        testPositive: 'COVID-19 Positive',
        testInconclusive: 'Inconclusive',
        antigen: 'Antigen',
        pcr: 'PCR',
        home: 'Home',
        lab: 'Laboratory',
        homeProctored: 'Home-Proctored',
        testType: 'Test Type',
        testTakenAt: 'Test Taken At',
    },
    formExempt: {
        reason: 'Reason',
        religion: 'Religious',
        disability: 'Medical',
        other: 'Other'
    }
};
