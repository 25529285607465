import React, { useState, useContext } from 'react';
import { Button, Tooltip } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import Context from '../context';

const RefreshButton = () => {
    const { fetch } = useContext(Context);
    const [loading, setLoading] = useState(false);

    const onClick = () => {
        setLoading(true);
        fetch()
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Tooltip
            placement="bottom"
            title="Refresh View"
        >
            <Button
                type="link"
                onClick={onClick}
                icon={<ReloadOutlined />}
                loading={loading}
            >
                Refresh View
            </Button>
        </Tooltip>
    );
};

export { RefreshButton };
