export const ADMIN_SUBDOMAIN = 'admin';

export const RESERVED_SUBDOMAINS = [
    'localhost:4000', // dev
    'localhost:8443', // local
    'localhost:3000', // dev build
    'localhost:8080', // dev container
    'admin', // administration
    'dev', 'qa', 'stage', 'demo', 'load', // * lintelio.com
    'dev-screen', 'development-screen', 'qa-screen', 'stage-screen', 'screen', // * companynurse.com, deprecated
    'www', 'lintelio', // prod www.lintelio.com
    'mylabbox-stage', // stage mylabbox,
    'prod' // fargate subdomains
];
