export const TempConfig = {
    C: {
        min: 32.2,
        max: 43.2,
        maskDefault: '99',
        maskMax: '99',
        startWith: ['3', '4', '_']
    },
    F: {
        min: 90,
        max: 109.9,
        maskDefault: '99',
        maskMax: '999',
        startWith: ['1', '9', '_']
    }
};

const roundIt = n => (Math.round((n + Number.EPSILON) * 10)) / 10;

export const FtoC = f => roundIt(((f - 32) * 5) / 9);

export const CtoF = c => roundIt((c * 9) / 5) + 32;
