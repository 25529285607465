import { FieldTypes } from 'utils';
import { QUESTION_OUTCOMES } from '../screening.model';

const transformIncidentOut = (answers = []) => {
    const { ER, SYMPTOMS, EXPOSURE } = QUESTION_OUTCOMES;
    const find = (key, prop = 'key') => answers.find(item => item[prop] === key);

    const has = (arr, outcome) => arr
        .filter(item => item.outcome === outcome)
        .some(item => item.isOutcome === true);
    
    // ER
    const hasEr = has(answers, ER);
    if (hasEr) {
        return 'Reports severe symptoms. Advised to call 911.';
    }

    const res = [];
    const custom = answers.filter(item => !item.static); 
    
    // Symptoms
    const hasSymptoms = has(answers, SYMPTOMS);

    if (hasSymptoms) {
        const staticSymptoms = find('Q_SYMPTOMS');
        if (staticSymptoms) {
            if (Array.isArray(staticSymptoms.value)) {
                res.push(`Reports experiencing ${Array.toText(staticSymptoms.value)}`);
            } else {
                res.push('Reports experiencing COVID-19 symptoms.');
            }
        }

        const hasCustomSymptoms = has(custom, SYMPTOMS);
        if (hasCustomSymptoms) {
            res.push('Reports location specified symptoms (details at the bottom).');
        }
    } else {
        res.push('Denies any current COVID-19 symptoms.');
    }

    // Temperature
    const temp = find('Q_TEMPERATURE');
    if (temp) {
        res.push(`Reports temperature of ${temp.value} F today.`);
    }

    // Exposure
    const hadEsposure = has(answers, EXPOSURE);
    if (hadEsposure) {
        res.push('Reports exposure to COVID-19 positive individual.');
    } else {
        res.push('Denies exposure to COVID-19 positive individual.');
    }
    
    // Custom Questions
    if (custom.length) {
        res.push('\nCustom Questions');
        custom.forEach((answer) => {
            switch (answer.type) {
                case FieldTypes.TEXTAREA:
                case FieldTypes.RADIO: {
                    res.push(`  · ${answer.label}: ${answer.value}\n`);
                    break;
                }
                case FieldTypes.CHECKBOXES: {
                    res.push(`  · ${answer.label}: ${Array.toText(answer.value)}\n`);
                    break; 
                }
                default: 
            }
        });
    }
    return `\n${res.join('\n')}`;
};

export default {
    out: transformIncidentOut
};
