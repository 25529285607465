// Avatar => custom, @DONE
import './Avatar.scss';
import PropTypes from 'prop-types';
import React from 'react';

export const Avatar = ({ children, className }) => (
    <div 
        className={`c-avatar ${className}`}
    >
        {children}
    </div>
);

Avatar.propTypes = {
    children: PropTypes.any.isRequired,
    className: PropTypes.string
};

Avatar.defaultProps = {
    className: ''
};
