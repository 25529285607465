import React, { useState, useEffect, useContext } from 'react';
import { Button, Modal, Checkbox } from 'antd';
import { Tooltip, Input } from 'apps/administration/components';
import { SettingFilled, SearchOutlined } from '@ant-design/icons';
import Context from '../context';

const SettingsButton = () => {
    const { updateUserPrefs, setColumns, columns } = useContext(Context);
    const [visible, setVisible] = useState();
    const [filter, setFilter] = useState();
    const [checked, setChecked] = useState([]);
    const [cols, setCols] = useState([]);

    useEffect(() => {
        const visibleKeys = columns
            .filter(item => !item.hide && item.key)
            .map(item => item.key);

        setCols(columns.filter(item => !!item.key && !item.hidden));
        setChecked(visibleKeys);
    }, [columns, visible]);

    const onConfirm = () => {
        const newColumns = columns.map(item => ({
            ...item,
            hide: !!item.key && !checked.includes(item.key)
        }));
        setColumns(newColumns);
        updateUserPrefs(newColumns);
        setVisible(false);
    };

    const onCancel = () => {
        setVisible(false);
    };

    const filterLower = (filter || '').toLowerCase();
    const filterFn = col => !filter || col.titleLower.includes(filterLower);
    const usedColumns = cols
        .map((col) => {
            const title = col.display ? col.display : col.title;
            return {
                ...col,
                title,
                titleLower: title.toLowerCase()
            };
        });

    return (
        <>
            <Tooltip
                title="Show/Hide Columns"
                placement="bottom"
            >
                <Button
                    type="link"
                    onClick={() => setVisible(true)}
                    icon={<SettingFilled />}
                    title=""
                />
            </Tooltip>
            <Modal
                title={(
                    <>
                        Show/Hide Columns
                        {cols.length > 5 && (
                            <Input
                                placeholder="Filter Columns"
                                allowClear
                                value={filter}
                                onChange={e => setFilter(e.target.value)}
                                style={{ margin: '18px 0 8px 0' }}
                                suffix={<SearchOutlined />}
                            />
                        )}
                    </>
                )}
                open={visible}
                onCancel={onCancel}
                okText="Apply"
                onOk={onConfirm}
            >
                <Checkbox.Group
                    style={{ width: '100%' }}
                    className="checkbox-group-vertical"
                    onChange={setChecked}
                    value={checked}
                >
                    {usedColumns
                        .map(col => (
                            <div
                                key={col.dataIndex}
                                style={{ display: (filterFn(col) ? 'block' : 'none') }}
                            >
                                <Checkbox
                                    value={col.dataIndex}
                                >
                                    {col.title}
                                </Checkbox>
                            </div>
                        ))}
                </Checkbox.Group>
            </Modal>
        </>
    );
};

export { SettingsButton };
