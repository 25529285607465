import './editableContent.scss';
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Tabs, Spin } from 'apps/administration/components';
import { SettingsService } from 'modules/settings';
import { history } from 'routing';
import { showError } from 'utils';
import EditableField from './field';

const getTabKey = (idx, title) => (idx === 0 ? '/help' : `/help/${title}`);

const EditableTabsContent = ({ name, editable }) => {
    const [content, setContent] = useState([]);
    const [loading, setLoading] = useState(false);

    const load = useCallback((tabData) => {
        setLoading(true);
        return SettingsService.get(name)
            .then((data) => {
                const newData = data.map((item, index) => ({
                    ...item,
                    tabKey: getTabKey(index, item.title),
                    index,
                }));
                setContent(newData);
            })
            .then(() => {
                if (tabData && tabData.title) {
                    history.push(`/help/${tabData.title}`);
                }
            })
            .finally(() => setLoading(false))
            .catch(showError);
    }, [name]);

    useEffect(() => {
        load();
    }, [load]);

    const onDeleteSuccess = (index) => {
        load().then(() => {
            const prevItem = content[index > 0 ? index - 1 : 0];
            history.push(prevItem.tabKey);
        });
    };

    const onAddClick = () => {
        const idx = content.length;
        const title = `New Tab ${idx + 1}`;
        const tabKey = getTabKey(idx, title);
        setContent(old => [...old, {
            tabKey,
            title,
            key: name,
            index: idx + 1
        }]);

        history.push(tabKey);
    };

    return (
        <Spin spinning={loading}>
            <Tabs
                tabBarExtraContent={editable && (
                    <Button
                        size="small"
                        type="default"
                        onClick={onAddClick}
                    >New Tab
                    </Button>
                )}
                
            >
                {content.map(item => (
                    <Tabs.TabPane
                        tab={item.title}
                        key={item.tabKey}
                    >
                        <EditableField
                            onDeleteSuccess={onDeleteSuccess}
                            onSaveSuccess={load}
                            item={item}
                            editable={editable}
                        />
                    </Tabs.TabPane>
                ))}
            </Tabs>
        </Spin>
    );
};

EditableTabsContent.propTypes = {
    name: PropTypes.string.isRequired,
    editable: PropTypes.bool.isRequired
};

export { EditableTabsContent };
