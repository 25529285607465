/* eslint-disable camelcase */
import { APIT } from 'utils';

const transformAlertIn = ({
    type, id,
    created_at, read_at, data
}) => ({
    id,
    type: type?.split('\\').pop(),
    readAt: APIT.date.in.toLocal(read_at),
    createdAt: APIT.date.in.toLocal(created_at),
    title: data?.title || '',
    text: data?.text || '',
    link: data?.link || '',
    sendBy: `${data?.notifier?.firstName || ''} ${data?.notifier?.lastName || ''}`,
    fullLink: data?.fullLink || undefined,
    downloadLink: data?.downloadLink || undefined,
    password: data?.password || undefined,
    route: data?.route || '',

});

export default transformAlertIn;
