/* eslint-disable max-len */
export default {
    new: 'New', 
    close: 'Close',
    cancel: 'Cancel',
    submit: 'Submit',
    confirm: 'Confirm', 
    action: 'Actions', 
    policyLink: '/privacy',
    termsLink: '/terms',
    policy: 'Privacy Policy',
    terms: 'Terms of Use',
    done: 'Done', 
    next: 'Next',
    back: 'Back',
    signOut: 'Sign Out',
    success: 'Success',
    selectDate: 'Select Date',
    pleaseSelect: 'Please Select',
    pleaseSpecify: 'Please Specify',
    additionalComments: 'Additional Comments',
    screen: 'Screen', 
    report: 'Report', 
    status: 'Status',
    optOut: 'Opt Out',
    optOutVaccination: 'Report Exemption (Unvaccinated)',
    viewProtocols: 'View Protocol',
    protocol: 'Protocol',
    year: 'Year',
    month: 'Month',
    day: 'Day',
    date: 'Date', 
    noData: 'Nothing to be shown yet',
    proceed: 'Proceed',
    loading: 'Loading',
    OK: 'OK', 
    menu: {
        home: 'Home',
        back: 'Back',
        settings: 'Settings',
        personalInfo: 'Personal info',
        editProfile: 'Edit profile',
        general: 'General',
        language: 'Language',
        reminders: 'Reminders',
        screenReminders: 'Screening Reminders', 
        about: 'About',
        scheduled: 'Reminders scheduled',
        scheduledOne: 'Reminder scheduled',
        support: 'Support',
        history: 'View History', 
        installText: 'Download Lintelio to your home screen for optimal experience on your phone.', 
        installBtn: 'Download', 
        skipInstallBtn: 'Not Now',
        integration: 'External testing integration agreement',
    },
    bottomMenu: {
        home: 'Home', 
        benefits: 'Benefits', 
        alerts: 'Alerts', 
        reports: 'Report', 
        screen: 'Pandemic',
        more: 'More', 
        attendance: 'Attendance', 
        incident: 'Incident', 
        telehealth: 'Proctor',
        lifeworks: 'LifeWorks',
        integrityLine: 'Integrity Line',
        immunization: 'Immunization',
    },
    jobCategory: {
        healthcare: 'Healthcare personnel',
        other: 'Other',
    },
    remindersPopup: {
        title: 'SMS reminders and alerts are enabled!',
        subtitle: 'Your admin has enabled text message reminders and alerts for your location.',
        subtitle1: 'Tap below to set up your phone number now, or do it later in your profile. Once you are ready, you can switch your SMS remiders on.',
        button1: 'Set Up Phone Number',
        button2: 'Remind me in 7 days',
        button3: 'Do not show again',
    },
    medicalConsentPopup: {
        header: 'User Consent',
        button1: 'I authorize',
        button2: 'I do not authorize (log me out)',
    },
    leavePopup: {
        title: 'Are you sure?',
        text: 'The data you\'ve entered will be lost.',
        okButton: 'Yes, leave the page',
        cancelButton: 'No, it was a mistake'
    },
    fileUpload: {
        errorFileSize: 'File size bigger than 10MB is not allowed', 
        errorMaxCount: 'Max file count has been exceeded'  
    },
    customContent: {
        holdAttendance: {
            header: 'On Hold',
            title: 'You are not reporting to work today.',
            text: 'For assistance please reach out to your HR manager or review your list of available benefits.',
        },
        hold: {
            header: 'On Hold',
            title: 'It looks like you are not feeling well',
            text: 'Do not report to work today. Contact your Human Resources department immediately to discuss the next steps. Thank you!',
        },
        screen: {
            header: 'Screening',
            title: '',
            text: '',
        },
        test: {
            header: 'Testing',
            title: '',
            text: '',
        },
        vaccination: {
            header: 'Vaccination', 
            title: 'You are required to vaccinate', 
            text: 'Looks like you need to vaccinate for COVID-19. Please contact a representative of your organizatiоn for instructions on how to proceed.',
        },
        protocol: {
            header: 'Pandemic Protocol',
            title: '',
            text: '',
        },
        medical: {
            header: 'Medical Consent',
            title: '',
            text: '',
        },
        testingReminders: {
            header: 'Testing Reminders',
            title: 'COVID test validity expires in [x] days',
            text: 'To submit another test result please follow the below link.',
        }
    },
    modules: {
        screen: 'Pandemic',
        test: 'Test',
        vaccination: 'Vaccination',
        attendance: 'Attendance', 
        injury: 'Injury',
        optOut: 'Exemption (Unvaccinated)',
        incident: 'Incident',
    },
    moduleActions: {
        screen: 'Screening',
        test: 'Testing',
        vaccination: 'Vaccination',
        attendance: 'Attendance', 
        injury: 'Injury'
    },
    moduleStatus: {
        OK: 'Ok',  
        HOLD: 'On Hold',  
        REQUIRED: 'Action Required',
    },
    statusTitle: {
        noPending: 'No Pending Actions',  
        hold: 'Your Account is on Hold',  
        pending: '1 Pending Action',  
        pendingMany: '{count} Pending Actions',  
        overdue: '1 Overdue Action',  
        overdueMany: '{count} Overdue Actions',  
    },
    statusSubtitle: {
        notRequired: 'No Action Required', 
        pending: 'Pending', 
        due: 'Due in {dueDays} days', 
        overdue: 'Overdue', 
    },
    vaccinationStatus: {
        UNVACC: 'Unvaccinated',
        VACC: 'Vaccinated',
        OPTOUT: 'Exemptioned',
        VACCREQUIRED: 'Vaccination Required', 
        PARTIALLYVACC: 'Partially Vaccinated'
    },
    testStatus: {
        OK: 'No Action Required',
        NEGATIVE: 'Next test due by {date}',
        POSITIVE: 'Positive Test',
        REQUIRED: 'Test Required',
    },
    attendanceStatus: {
        OK: 'No Pending Actions',  
        HOLD: 'Your Account is on Hold',  
        REQUIRED: '1 Overdue Action',  
    }
};
