import { APIT } from 'utils';
import { transformFilters } from 'services';
import remindersTransformer from '../../reminder/transformers';

const transformPatientOutBulk = (data) => {
    const reminders = data.reminders 
        ? remindersTransformer.out({ data: data.reminders }) 
        : undefined;
    
    const $toUsers = transformFilters(data.$toUsers);
    return {
        ...data,
        $toUsers,
        isActive: APIT.bool.out(data.isActive),
        reminders,
        screeningStatus: data.screeningStatus === 'null' ? null : data.screeningStatus
    };
};

export default transformPatientOutBulk;
