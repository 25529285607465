import questions from './questions';
import results from './exits';

import { QUESTION_OUTCOMES } from '../../screening.model';

export default ({
        emergencyNumber,
        $hasBenefits,
    }) => {
    const qn = questions({
        params: {
            emergencyNumber,
        }
    });

    const rs = results({
        params: {
            emergencyNumber,
            $hasBenefits,
        }
    });

    return [
        {
            ...qn.Q_WORK,
            outcome: QUESTION_OUTCOMES.WORK,
        },
        {
            ...rs.R_CLEARED,
            show: ({ WORK }) => WORK
        },
        {
            ...qn.Q_IS_ER,
            outcome: QUESTION_OUTCOMES.ER,
        },
        {
            ...rs.R_ER,
            show: ({ ER }) => ER
        },
        {
            ...qn.Q_NO_WORK,
            outcome: QUESTION_OUTCOMES.FAIL,
        },
        {
            ...rs.R_FAILED
        }
    ];
};
