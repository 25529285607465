import './sidemenu.install.scss';
import React from 'react';
import PropTypes from 'prop-types';
import locales from 'locales';
import { Button } from 'components';
import { useServiceWorker } from 'providers/serviceWorker';
import { isInStandaloneMode, getSubDomain } from 'utils';

const PWAInstall = ({ hide }) => { 
    const { common: { menu: strings } } = locales;
    const { install } = useServiceWorker();

    const installApp = async () => {
        const installed = await install();
        if (installed) {
            hide();
        }
    };

    const subDomain = getSubDomain();

    if (!window.deferredPWAPrompt || isInStandaloneMode() || !subDomain) {
        return null;
    }
    
    return (
        <div className="pwa-install">
            <p>{strings.installText}</p>
            <Button 
                type="default" 
                onClick={() => hide()} 
                className="not-now"
            >
                {strings.skipInstallBtn}
            </Button>
            <Button 
                type="primary" 
                onClick={installApp}
            >{strings.installBtn}
            </Button>
        </div>
    );
};

PWAInstall.propTypes = {
    hide: PropTypes.func.isRequired
};
export default PWAInstall;
