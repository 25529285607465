import './Divider.scss';
import PropTypes from 'prop-types';
import React from 'react';

export const Divider = ({ className }) => (
    <div 
        className={`c-divider ${className}`}
    />
);

Divider.propTypes = {
    className: PropTypes.string
};

Divider.defaultProps = {
    className: ''
};
