import React from 'react';
import PropTypes from 'prop-types';

const Brands = {
    lintelio: React.lazy(() => import('./lintelio')),
    mylabbox: React.lazy(() => import('./mylabbox'))
};

const BRAND = process.env.REACT_APP_BRAND || 'lintelio'; 
const BrandContext = Brands[BRAND];

const BrandProvider = ({ children }) => (
    <React.Suspense fallback={null}>
        <BrandContext>
            {children}
        </BrandContext>
    </React.Suspense>
);

BrandProvider.propTypes = {
    children: PropTypes.any.isRequired
};

export { BrandProvider };
