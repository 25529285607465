import React, { useState } from 'react';
import { PropTypes } from 'prop-types';

import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import InpuText from './Input.Text';

const InputPassword = (props) => {
    const [showPassword, setShowPassword] = useState(false);

    return (
        <InpuText 
            {...props} 
            className="c-password"
            type={showPassword ? 'text' : 'password'}
            suffix={(
                <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              )}
            autoComplete="current-password"
        />
    );
};

InputPassword.propTypes = {
    prefix: PropTypes.any
};

InputPassword.defaultProps = {
    prefix: null
};
export default InputPassword;
