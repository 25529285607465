import { toQueryParams } from 'utils';

export const TELEHEALTH_SESSION_STATUS_CONST = {
    INIT: 0,
    QUEUED: 1,
    IN_PROGRESS: 2,
    PENDING_TEST_RESULT: 3,
    PENDING_VERIFICATION: 4,
    COMPLETED: 5, // file attached
    ABANDONED: 6,
    // CANCELED: 7,
};

export const TELEHEALTH_SESSION_STATUS = {
    INIT: {
        key: 'INIT',
        value: TELEHEALTH_SESSION_STATUS_CONST.INIT,
        label: 'New'
    },
    QUEUED: {
        key: 'QUEUED',
        value: TELEHEALTH_SESSION_STATUS_CONST.QUEUED,
        label: 'Queued'
    },
    IN_PROGRESS: {
        key: 'IN_PROGRESS',
        value: TELEHEALTH_SESSION_STATUS_CONST.IN_PROGRESS,
        label: 'In Progress'
    },
    PENDING_TEST_RESULT: {
        key: 'PENDING_TEST_RESULT',
        value: TELEHEALTH_SESSION_STATUS_CONST.PENDING_TEST_RESULT,
        label: 'Pending Test Result'
    },
    PENDING_VERIFICATION: {
        key: 'PENDING_VERIFICATION',
        value: TELEHEALTH_SESSION_STATUS_CONST.PENDING_VERIFICATION,
        label: 'Pending Verification'
    },
    COMPLETED: {
        key: 'COMPLETED',
        value: TELEHEALTH_SESSION_STATUS_CONST.COMPLETED,
        label: 'Completed'
    },
    ABANDONED: {
        key: 'ABANDONED',
        value: TELEHEALTH_SESSION_STATUS_CONST.ABANDONED,
        label: 'Abandoned'
    }
    
};

const callback = process.env.REACT_APP_API;
const TcdUrl = process.env.REACT_APP_TCD_URL; 

export const getIframeSrc = (guid, params) => {
    if (!guid) {
        return undefined;
    }

    const queryParams = toQueryParams(params, true);
    
    return `${TcdUrl}?confid=${guid}&callback=${callback}&${queryParams}`;
};
