import { api } from 'services';

class SettingsService {
    static get(key) {
        return api.get(`/publicSystemSettings?page=1&perPage=50&filters={ "key": "${key}"}`)
            .then(({ data }) => data);
    }

    static create(values) {
        return api.post('/publicSystemSettings', values);
    }

    static update(values) {
        let { value } = values;
        // This is needed to keep inline links while editing text
        if (values.key === 'PRIVACY_CONTENT') {
            value = value
                .replace(' target="_blank">Data We', '>Data We')
                .replace('“Data We Collect and How We Use It”', '“<a href="#data-collect">Data We Collect and How We Use It</a>”')
                .replace('4. DATA WE COLLECT AND HOW WE USE IT', '4. <span class="anchor" id="data-collect"></span>DATA WE COLLECT AND HOW WE USE IT');
        }
        return api.put(`/publicSystemSettings/${values.id}`, { ...values, value });
    }

    static delete(id) {
        return api.delete(`/publicSystemSettings/${id}`);
    }
}

export { SettingsService }; 
