import { useCallback, useState } from 'react';
import { showError } from 'utils';
import { ShowMessage } from 'services';

const usePatientBulkUpdate = (service) => {
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);

    const fetchMethod = (values, total) => {
        setLoading(true);
        return service(values)
            .then((data) => {
                ShowMessage.saved({ msg: `${total} User(s) updated successfully` });
                return data;
            })
            .catch((err) => {
                setError(err);
                showError(err);
                return Promise.reject(err);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const stableFetch = useCallback(fetchMethod, [service]);
    return [stableFetch, { loading, error }];
};

export { usePatientBulkUpdate };
