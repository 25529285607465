import React, { useContext } from 'react';
import locales from 'locales';
import { BrandContext } from '../../context';

const TermsLink = () => {
    const { common } = locales;
    const { termsLink } = useContext(BrandContext);
    
    return (
        <a
            className="terms"
            href={termsLink}
            target="_blank"
            rel="noopener noreferrer"
        >
            {common.terms}
        </a>
    );
};

const PolicyLink = () => {
    const { common } = locales;
    const { privacyLink } = useContext(BrandContext);
    return (
        <a
            className="privacy"
            href={privacyLink}
            target="_blank"
            rel="noopener noreferrer"
        >
            {common.policy}
        </a>
    );
};

export { PolicyLink, TermsLink };
