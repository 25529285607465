import './address.scss';
import React from 'react';
import { Form, Input } from 'components';
import { LocationIcon } from 'components/Icons';

import locales from 'locales';
import { ValidationRules } from 'utils';

const ProfileAddressFields = () => {
    const { Item } = Form;
    const { routes: { profile: strings } } = locales;
    const rules = ValidationRules.translate();

    return (
        <div className="address">
            <Item
                name="address"
                label={strings.labels.address}
                rules={[rules.required]}
            >
                <Input
                    maxLength={50}
                    prefix={<LocationIcon />}
                    placeholder={strings.placeholders.address}
                />
            </Item>
            <Item
                name="address2"
                label={strings.labels.address2}
            >
                <Input
                    maxLength={50}
                    prefix={<LocationIcon />}
                    placeholder={strings.placeholders.address2}
                />
            </Item>
            <Item
                name="city"
                label={strings.labels.city}
                rules={[rules.required]}
            >
                <Input
                    maxLength={50}
                    placeholder={strings.placeholders.city}
                />
            </Item>
            <Item
                name="state"
                label={strings.labels.state}
                rules={[rules.required]}
            >
                <Input
                    maxLength={50}
                    placeholder={strings.placeholders.state}
                />
            </Item>
            <Item
                name="zip"
                label={strings.labels.zip}
                rules={[rules.required]}
            >
                <Input
                    maxLength={50}
                    placeholder={strings.placeholders.zip}
                />
            </Item>
        </div>
    );
};

export default ProfileAddressFields;
