import './CheckboxGroup.scss';
import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import PropTypes from 'prop-types';

const CheckboxGroup = ({ value, onChange, options, className, disabled }) => {
    const handleChange = (val, checked) => {
        if (checked) {
            onChange([...(value || []), val]);
        } else {
            const res = Array.remove(value, val);
            onChange(res);
        }
    };

    return (
        <div className={`c-checkbox-group ${className}`}>
            {options.map((option) => {
                const isChecked = value?.includes(option.value) || false;
                return (
                    <FormControlLabel
                        key={option.value}
                        label={option.text}
                        className={`c-checkbox ${isChecked ? 'c-checkbox-checked' : ''}`}
                        control={(
                            <Checkbox
                                disabled={disabled}
                                checked={isChecked}
                                onChange={() => handleChange(option.value, !isChecked)}
                                inputProps={{ 'aria-label': option.text }}
                            />
                        )}
                    />
                );
            })}
        </div>
    );
};

CheckboxGroup.propTypes = {
    className: PropTypes.string,
    options: PropTypes.array,
    value: PropTypes.array,
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired
};

CheckboxGroup.defaultProps = {
    className: '',
    options: [],
    value: [],
    disabled: false,
};

export { CheckboxGroup };
