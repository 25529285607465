import { setLocale, LangDefault } from 'locales';
import { PatientConstants as constants } from './patient.constants';

const initialState = Object.freeze({ 
    $loading: false,
    reminders: {
        screen: [],
        test: []
    },
    location: { $modules: {} },
    $moduleStatus: {},
    language: 'en'
});

const patient = (state = initialState, action = {}) => {
    switch (action.type) {
        case constants.LOGOUT: { 
            setLocale(LangDefault);
            return {
                ...initialState,
            };
        }
        case constants.LOADED: {
            if (action.data && action.data.language) { 
                setLocale(action.data.language); 
            }
            return {
                ...state,
                ...action.data,
                error: undefined,
                $loading: false,
            };
        }
        
        case constants.CLEAR: {
           return {
                ...initialState,
            };
        }
        case constants.REMINDERS_LOADED: {
            return {
                ...state,
                reminders: action.data,
                $loading: false,
            };
        }
        case constants.LOADING: {
            return {
                ...state,
                 ...action.data,
                $loading: true,
            };
        }
        case constants.ERROR: {
            return {
                ...state,
                ...action.data,
                error: action.error,
                $loading: false, 
            };
        }
        default:
            return state;
    }
};

export default patient;
