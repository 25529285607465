const getterSetter = (key, decoded) => (...args) => {
    const name = `Lintelio-DS-${key}`;

    if (!args.length) {
        let value = localStorage.getItem(name);
        try {
            if (value && decoded) {
                value = decodeURIComponent(window.atob(value));
            }
            return JSON.parse(value);
        } catch {
            localStorage.removeItem(name);
            return {};
        }
    }
    const value = args[0];
    if (value === false) {
        return localStorage.removeItem(name);
    }
    let str = JSON.stringify(value);
    if (str && decoded) {
        str = window.btoa(encodeURIComponent(str));
    }
    return localStorage.setItem(name, str);
};

const local = {
    refresh: getterSetter('refresh_token'),
    token: getterSetter('token'),
    dashboard: getterSetter('dashboardData'),
    dashboardFilters: getterSetter('dashboard_data', false),
    custom: getterSetter,
    hash: getterSetter('locationHash'),
    subdomain: getterSetter('subdomain'),
    apiVersion: getterSetter('apiVersion'),
};

export { local };
