import { useCallback, useState } from 'react';
import { showError } from 'utils';
import { PatientServiceAdmin } from '../patient.service.admin';

export const usePatientReminders = () => {
    const [error, setError] = useState();
    const [data, setData] = useState({ screen: [], test: [] });
    const [loading, setLoading] = useState(false);

    const fetchMethod = (id) => {
        setLoading(true);
        return PatientServiceAdmin.reminders(id)
            .then(setData)
            .catch((err) => {
                setError(err);
                showError(err);
                return Promise.reject(err);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const stableFetch = useCallback(fetchMethod, []);
    return [stableFetch, { data, loading, error }];
};
