import './quickScreen.scss';
import React from 'react';
import dayjs from 'dayjs';
import { Accordion } from 'components';
import renderField from '../questions/fields';

const renderQuickQuestions = (quickScreening, questions) => {
    const { questionsAndAnswers } = quickScreening;
    const questionsDisable = questions.map(question => ({
        ...question,
        props: { ...question?.props, disabled: true }
    }));
    const answeredArr = Object.keys(questionsAndAnswers)
        .map(key => ({ ...questionsAndAnswers[key], key }));

    return (
        <div className="quick-screening-container">
            {answeredArr.map((answered) => {
                const current = questionsDisable.find(q => answered.key === q.key);
                if (current) {
                    current.options = current?.options?.map((option) => {
                        if (option.value === 'Y' && option.text === 'Yes') {
                            return ({ ...option, value: option.text });
                        }
                        if (option.value === 'N' && option.text === 'No') {
                            return ({ ...option, value: option.text });
                        }
                        return option;
                    });

                    const value = String.isDate(answered.value)
                        ? dayjs(answered.value)
                        : answered.value;

                    return (
                        <Accordion
                            header={(
                                <div
                                    dangerouslySetInnerHTML={{ __html: current.label }}
                                />
                            )}
                            key={answered.key}
                        >
                            {renderField(current, value)}
                        </Accordion>
                    );
                }
                return null;
            })}
        </div>
    );
};

export default renderQuickQuestions;
