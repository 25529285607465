import './Checkbox.scss';
import React from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@mui/material/FormControlLabel';
import CheckboxMUI from '@mui/material/Checkbox';

const Checkbox = ({
    className, onChange, disabled,
    checked, children, loading
}) => (
    <FormControlLabel
        label={children}
        className={`c-checkbox ${className} ${loading ? 'c-checkbox-loading' : ''}`}
        control={(
            <CheckboxMUI
                disabled={disabled}
                checked={!!checked}
                onChange={onChange}
            />
        )}
    />
);

Checkbox.propTypes = {
    className: PropTypes.string,
    onChange: PropTypes.func,
    children: PropTypes.any,
    checked: PropTypes.any,
    loading: PropTypes.bool,
    disabled: PropTypes.bool
};

Checkbox.defaultProps = {
    className: '',
    onChange: undefined,
    children: null,
    checked: false,
    loading: false,
    disabled: false
};

export { Checkbox };
