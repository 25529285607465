import { APIT } from 'utils';
import { LOCATION_DEFAULT } from '../../location.model';
import transformAuth from '../auth';
import transformModules from '../modules';
import transformLocales from '../locales';
import transformHiddenQuestions from '../hiddenQuestions';

const transformLocationIn = (data) => {
    const tempThreshold = parseFloat(data.temperatureThreshold);
    
    const transProps = transformLocales.in(data);

    return {
        ...data,
        color: data.color || LOCATION_DEFAULT.color,
        locationHash: data.locationHash,
        logo: data.logo || LOCATION_DEFAULT.logo,
        remindersPaused: !data.remindersActive,
        emergencyNumber: data.emergencyNumber || LOCATION_DEFAULT.emergencyNumber,
        flowType: data.flowType || LOCATION_DEFAULT.flowType,
        // Toggles
        isTriage: APIT.bool.in(data.isTriage),
        whiteLabelPermission: APIT.bool.in(data.whiteLabelPermission),
        whiteLabelReminders: APIT.bool.in(data.whiteLabelReminders),
        whiteLabelAlerts: APIT.bool.in(data.whiteLabelAlerts),
        isSpecificSymptomsEnabled: APIT.bool.in(data.isSpecificSymptomsEnabled),
        isScreenFullIntakeEnabled: APIT.bool.in(data.isScreenFullIntakeEnabled),
        emailSendingEnabled: APIT.bool.in(data.emailSendingEnabled),
        smsSendingEnabled: APIT.bool.in(data.smsSendingEnabled),
        isScreeningDetailsEnabled: APIT.bool.in(data.isScreeningDetailsEnabled),
        hideCongestionOption: APIT.bool.in(data.hideCongestionOption),
        passIfVaccinatedEnabled: APIT.bool.in(data.passIfVaccinatedEnabled),
        allowCustomQuestions: APIT.bool.in(data.allowCustomQuestions),
        showBenefitsTile: APIT.bool.in(data.showBenefitsTile),
        enableSmsAlertForAdmins: APIT.bool.in(data.enableSmsAlertForAdmins), 
        areAttachmentsMandatory: APIT.bool.in(data.areAttachmentsMandatory),
        isMedicalConsentEnabled: APIT.bool.in(data.isMedicalConsentEnabled),
        isEmployeeIdMandatory: APIT.bool.in(data.isEmployeeIdMandatory),
        isDateOfBirthFieldEnabled: APIT.bool.in(data.isDateOfBirthFieldEnabled),
        isAddressFieldEnabled: APIT.bool.in(data.isAddressFieldEnabled), 
        isGenderFieldEnabled: APIT.bool.in(data.isGenderFieldEnabled),
        isVaccinationMandatory: APIT.bool.in(data.isVaccinationMandatory),
        isQuickScreeningEnabled: APIT.bool.in(data.isQuickScreeningEnabled),
        
        children: data.children || [],
        temperatureThreshold: tempThreshold,
        sendWhat: data.sendWhat || [],
        sendWhen: data.sendWhen || [],
        temperatureUnit: data.temperatureUnit,
        exposureDays: data.exposureDays ?? 14,
        hiddenQuestions: transformHiddenQuestions.in(data, true),
        testValidityDays: data.testValidityDays,
        integrityLineUrl: data.integrityLineUrl,
        useIntegrityLineUrl: data.useIntegrityLineUrl,
        
        ...transformAuth.in(data.authenticationTypes), 
        ...transProps,
        // Readonly props
        isSelfRegistrationEnabled: APIT.bool.in(data.isSelfRegistrationEnabled, true),
        $modules: transformModules.in(data.modules),
        $hideTemperature: APIT.bool.in(data.tempCheck, true),
        reportable: APIT.bool.in(data.reportable),
        code: data.code,
        clientCode: data.clientCode
        
    };
};

export default transformLocationIn;
