export default 
{
    screening: {
        title: 'Kiểm tra',
        outcome: 'Outcome', // @NEW
        date: 'Date', // @NEW
    },
    testing: {
        title: 'Đang kiểm tra',
        verified: 'Verified', // @NEW
        unverified: 'Unverified', // @NEW
        desease: 'Disease/Agent', // @NEW
        verification: 'Verification Issued By', // @NEW
        status: 'Test Status', // @NEW
        testResult: 'Kết quả xét nghiệm',
        date: 'Test Date', // @NEW
        reportDate: 'Report Date', // @NEW
        location: 'Location', // @NEW
        facility: 'Facility', // @NEW
        admin: 'Admin', // @NEW,
        POSITIVE: 'Positive', // @NEW
        NEGATIVE: 'Negative', // @NEW
        INCONCLUSIVE: 'Inconclusive', // @NEW
        type: 'Type', // @NEW
        takenAt: 'Taken At', // @NEW
        antigen: 'Antigen', // @NEW
        pcr: 'PCR', // @NEW
        lab: 'Laboratory', // @NEW
        home: 'Home', // @NEW
        'Home-Proctored': 'Home-Proctored', // @NEW
    },
    vaccination: {
        title: 'Chích ngừa',
        manufacturer: 'Manufacturer', // @NEW
        shotDate: 'Date of Shot', // @NEW
        firstShotDate: 'Date of First Shot', // @NEW
        secondShotDate: 'Date of Second Shot', // @NEW
        status: 'Vaccination Status', // @NEW
        verified: 'Verified', // @NEW
        unverified: 'Unverified', // @NEW
        desease: 'Disease/Agent', // @NEW
        vaccine: 'Vaccine', // @NEW
        lot: 'Lot Number', // @NEW
        date: 'Date of shot', // @NEW
        lot1: 'Lot First Shot', // @NEW
        lot2: 'Lot Second Shot', // @NEW
        date1: 'Date of First Shot', // @NEW
        date2: 'Date of Second Shot', // @NEW
        facility: 'Facility', // @NEW
        facility1: 'Facility First Shot', // @NEW
        facility2: 'Facility Second Shot', // @NEW
        verification: 'Verification Issued By', // @NEW
        admin: 'Admin', // @NEW
    },
    vaccinationOptOut: {
        reason: 'Reason', // @NEW
        date: 'Date', // @NEW
        verified: 'Verified', // @NEW
        unverified: 'Unverified', // @NEW
        status: 'Exemption (Unvaccinated) Status', // @NEW
        reasonDetails: 'Reason Details', // @NEW
    }
};
