import './langTabs.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { TabsBase } from 'apps/administration/components';
import { langs } from 'locales';

const LangTabs = ({ children, className }) => (
    <TabsBase className={`${className} lang-tabs`}>
        {langs.map(({ key, title, icon }) => {
            const tab = (
                <>
                    <img alt={key} src={icon} />
                    {title}
                </>
            );
            return (
                <TabsBase.TabPane
                    tab={tab}
                    key={key}
                >
                    {children(key)}
                </TabsBase.TabPane>
            );
        })}
    </TabsBase>
);
  
LangTabs.propTypes = {
    children: PropTypes.any.isRequired,
    className: PropTypes.string
};

LangTabs.defaultProps = {
    className: ''
};

export { LangTabs };
