export const ADMIN_ROLES = {
    SYS_ADMIN: {
        key: 'SYS_ADMIN',
        title: 'Sys Admin',
    },
    ADMIN: {
        key: 'ADMIN',
        title: 'Admin',
    },
};

export const COGNITO_STATUS = {
    DONE: 'DONE',
    INVITE_SENT: 'INVITE_SENT',
    NOT_INVITED: 'NOT_INVITED',
    INACTIVE: 'INACTIVE'
};

export const CognitoStatusOptions = [
    { value: 'DONE', text: 'Done' },
    { value: 'INVITE_SENT', text: 'Invite Sent' },
    { value: 'NOT_INVITED', text: 'Invite Not Sent' },
    { value: 'INACTIVE', text: 'Inactive' }
];

export const ROLES = { 
    PATIENT: {
        key: 'USER',
        title: 'User',
    },
    ...ADMIN_ROLES
};

export const ROLE_ADMIN = ROLES.ADMIN.key;

export const ROLE_SYS_ADMIN = ROLES.SYS_ADMIN.key;

export const ROLE_PATIENT = ROLES.PATIENT.key;
