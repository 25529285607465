import { api } from 'services';
import Transformers from './transformers';

class TelehealthService {
    static get(id) {
        return api.get(`/telehealthSessions/${id}`)
            .then(Transformers.in);
    }
 
    static create(patientId, data) {
        const postData = Transformers.out(data);
        return api.post(`/patients/${patientId}/telehealthSessions`, postData);
    }

    static update(id, data) {
        const postData = Transformers.out(data);
        return api.put(`/telehealthSessions/${id}`, postData)
            .then(Transformers.in);
    }
    
    static check(guid, { userid, adminid }) {
        return api.get(`/checkconf?confid=${guid}&userid=${userid || ''}&adminid=${adminid || ''}`);
    }

    static end(guid) {
        return api.get(`/endconf?confid=${guid}`);
    }

    static list(...args) {
        return api.list('/telehealthSessions', ...args)
            .then(Transformers.list);
    }

    static listByPatient({ patientId, page, take, filters, sort }) {
        return api.list(`/patients/${patientId}/telehealthSessions`, page, take, filters, sort)
            .then(Transformers.list);
    }

    static count() {
        return api.get('/telehealthSessions/count');
    }
    
    static attach(id, formData) {
        return api.upload(`/media/attachment/telehealthSession/${id}`, formData);
    }

    static getAttachments(id) {
        return api.get(`/media/all/telehealthSession/${id}`);
    }

    static downloadFile(id, ...rest) {
        return api.download(`/media/${id}`, ...rest);
    }
}

export { TelehealthService };
