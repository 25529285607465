import { createRef, useEffect, useState } from 'react';
import { throttle } from 'utils';

function useViewport(
    offset = 100,
    throttleMilliseconds = 100
) {
    const [isVisible, setIsVisible] = useState(false);
    const currentElement = createRef();

    const onScroll = throttle(() => {
        if (!currentElement.current) {
            setIsVisible(false);
            return;
        }
        const { top } = currentElement.current.getBoundingClientRect();
        setIsVisible(top + offset >= 0 && top - offset <= window.innerHeight);
    }, throttleMilliseconds);

    useEffect(() => {
        onScroll();
    }, [onScroll]);

    useEffect(() => {
        window.addEventListener('scroll', onScroll);
        return () => window.removeEventListener('scroll', onScroll);
    });

    return [isVisible, currentElement];
}

export { useViewport };
