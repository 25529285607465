export default {
    title: 'Recordatorios', // @OBSOLETE
    screenReminders: 'Recordatorios para evaluaciones',
    testReminders: 'Recordatorios para examen',
    scheduled: 'Programar Recordatorios',
    selectTypeSend: 'Porfavor seleccione para programar recordatorios',
    selectTypeReceive: 'Porfavor seleccione para programar recordatorios',
    types: {
        email: 'Email',
        sms: 'SMS',
        alert: 'Alerta',
    },
    days: {
        Monday: 'Lunes',
        Tuesday: 'Martes',
        Wednesday: 'Miércoles',
        Thursday: 'Jueves',
        Friday: 'Viernes',
        Saturday: 'Sabado',
        Sunday: 'Domingo',
    },
    done: 'Listo',
    set: 'Configurar',
    selectTime: 'Selecionar Hora',
    back: 'Regresar',
    addSchedule: '+ Agregar',
    scheduled1: 'Programado',
    remove: 'Eliminar',
    banner: 'Para recibir alertas y recordatorios por SMS, Porfavor agregue un numero de telefono desde las configuraciones de perfil o haciendo click aqui.', 
};
