import './importButton.scss';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Upload, Button } from 'apps/administration/components';
import { DownloadOutlined } from '@ant-design/icons';
import { NOTIFICATION_INLINE } from 'modules/notification';
import { ShowMessage } from 'services';
import { useAlerts } from 'hooks';
import ImportHelp from './help';

const ImportButton = ({ service, type, ...rest }) => {
    const [uploadProps, setUploadProps] = useState();

    const { start } = useAlerts(true);

    useEffect(() => {
        service.import().then(setUploadProps);
    }, [service]);

    const onUploadChange = (info) => {
        const { status, response } = info.file;
        if (status === 'done') {
            const notification = { ...NOTIFICATION_INLINE.importStart };
            notification.message = response;
            start(notification);
        } else if (status === 'error') {
            let msgResponse = `${info.file.name} file import failed.`;
            if (response && response.error) {
                msgResponse = response.error;
            }
            ShowMessage.custom({ msg: msgResponse, duration: 12, type: 'error' });
        }
    };

    return (
        <div className="import-button">
            <Upload
                {...uploadProps}
                onChange={onUploadChange}
            >
                <Button
                    type="link"
                    icon={<DownloadOutlined />}
                    {...rest}
                >
                    Bulk Import
                </Button>
            </Upload>
            <ImportHelp type={type} />
        </div>
    );
};

ImportButton.propTypes = {
    service: PropTypes.func.isRequired,
    type: PropTypes.oneOf(['users', 'admins', 'tests']).isRequired
};

export { ImportButton };
