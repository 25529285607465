import React from 'react';
import PropTypes from 'prop-types';
import { MoreOutlined } from '@ant-design/icons';
import { PopoverMenu, Button } from 'apps/administration/components';

const TableOptions = ({ options, disabled, loading }) => {
    const menu = options.filter(opt => opt.hide !== true)
        .map(option => (
            <Button
                type={option.type}
                key={option.label}
                onClick={option.onClick}
                icon={option.icon}
                disabled={option.disabled}
                module={option.module}
            >
                {option.label}
            </Button>
        ));

    return (
        <PopoverMenu
            options={menu}
        >
            <Button
                type="link"
                icon={<MoreOutlined />}
                disabled={disabled}
                loading={loading}
            />
        </PopoverMenu>
    );
};

TableOptions.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            onClick: PropTypes.func.isRequired,
            icon: PropTypes.element,
            label: PropTypes.string.isRequired,
            disabled: PropTypes.bool,
            hide: PropTypes.bool,
            type: PropTypes.string
        })
    ).isRequired,
    disabled: PropTypes.bool,
    loading: PropTypes.bool
};

TableOptions.defaultProps = {
    disabled: false,
    loading: false
};

export { TableOptions };
