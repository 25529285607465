import { MODULE_PERMISSION } from 'modules/module';
import { ModulesConfig } from 'apps/administration/constants';

import { AdminConstants as constants } from './admin.constants';

const defaultPermissions = {};
Object.values(ModulesConfig).forEach((item) => {
    defaultPermissions[item.id] = MODULE_PERMISSION.NONE;
});

const initialState = Object.freeze({
    $loading: false,
    role: 'ADMIN',
    $role: 'ADMIN',
    locations: [],
    permissions: defaultPermissions
});

const admin = (state = initialState, action = {}) => {
    switch (action.type) {
        case constants.LOADED: {
            return {
                ...state,
                ...action.data,
                error: undefined,
                $loading: false,
            };
        }

        case constants.CLEAR: {
            return {
                ...initialState,
            };
        }
        case constants.LOADING: {
            return {
                ...state,
                ...action.data,
                $loading: true,
            };
        }
        case constants.ERROR: {
            return {
                ...state,
                ...action.data,
                error: action.error,
                $loading: false,
            };
        }
        default:
            return state;
    }
};

export default admin;
