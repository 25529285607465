import React from 'react';
import PropTypes from 'prop-types';
import MUIRadio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';

const Radio = ({ children, value, disabled, className, groupValue }) => (
    <FormControlLabel
        className={`c-radio ${className} ${groupValue === value ? 'c-radio-checked' : ''} `}
        value={value}
        control={<MUIRadio disabled={disabled} />}
        label={children}
    />
    );

Radio.propTypes = {
    className: PropTypes.string,
    value: PropTypes.any,
    children: PropTypes.any,
    disabled: PropTypes.bool,
    groupValue: PropTypes.string
};

Radio.defaultProps = {
    className: '',
    value: '',
    children: null,
    disabled: false,
    groupValue: ''
};

export default Radio;
