import { api } from 'services';

class ModuleService {
    static create(locationId, module, data) {
        return api.post(`/locations/${locationId}/modules/${module}`, data);
    }

    static update(locationId, module, data) {
        return api.put(`/locations/${locationId}/modules/${module}`, data);
    }

    static remove(locationId, module, data) {
        return api.delete(`/locations/${locationId}/modules/${module}`, { data });
    }

    static list(locationId) {
        return api.get(`/locations/${locationId}/modules`)
            .then(({ data }) => data);
    }
}

export { ModuleService }; 
