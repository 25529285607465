import { APIT } from 'utils';
import AuthTransformer from './auth';
import transformModules from './modules';

const transformLocationListIn = (list) => {
    if (list && !list.data) {
        return list;
    }
    
    return {
        ...list, 
        data: list.data.map(data => 
            ({
                ...data, 
                ...AuthTransformer.in(data.authenticationTypes, data.title),
                isActive: APIT.bool.in(data.isActive),
                $modules: transformModules.in(data.modules),
            }))
        
    };
};

export default transformLocationListIn;
