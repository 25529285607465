import { changeBrandColor } from 'utils';
import { LOCATION_DEFAULT } from './location.model';
import { LocationConstants } from './location.constants';

const initialState = Object.freeze({ 
    ...LOCATION_DEFAULT,
});

const location = (state = initialState, action = {}) => {
    switch (action.type) {
        case LocationConstants.LOADING: {
            return {
                ...state,
                $loading: true,
            };
        }
        case LocationConstants.CLEAR: {
            return {
                ...initialState,
                $loading: false,
            };
        }
        case LocationConstants.LOADED_IN_ADMIN: {
            const { data } = action;
            return {
                ...data,
                $loading: false,
            };
        }
        case LocationConstants.LOADED: {
            const { data } = action;
            if (data.useColor) {
                changeBrandColor(data.useColor);
            }
                
            return {
                ...state,
                ...data,
                $loading: false,
            };
        }
        case LocationConstants.ERROR: {
            return {
                ...state,
                 ...action.data,
                $error: action.error,
                $loading: false,
            };
        }
        default:
            return state;
    }
};

export default location;
