import './Popover.scss';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Popover } from 'antd';
import { stopEvent } from 'utils';
import { Button } from '../Button';

const PopoverMenuButton = ({ onClick, setOpen, ...rest }) => {
    const onBeforeClick = (e) => {
        stopEvent(e);
        setOpen(false);
        onClick(e);
    };

    return (
        <Button
            {...rest}
            onClick={onBeforeClick}
        />
    );
};

PopoverMenuButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    setOpen: PropTypes.func.isRequired,
};

const PopoverMenu = ({ children, options, ...rest }) => {
    const [open, setOpen] = useState(false);

    const menuItems = options.filter(item => !!item)
        .map(({ props }, idx) => (
            <PopoverMenuButton
                // eslint-disable-next-line react/no-array-index-key
                key={idx}
                setOpen={setOpen}
                {...props}
            />
        ));

    return (
        <Popover
            trigger="click"
            overlayClassName="popover-menu"
            content={menuItems}
            open={open}
            onOpenChange={setOpen}
            {...rest}
        >
            {React.cloneElement(children, { onClick: stopEvent })}
        </Popover>
    );
};

PopoverMenu.propTypes = {
    children: PropTypes.any.isRequired,
    options: PropTypes.array.isRequired
};

export { Popover, PopoverMenu };
