import './medicalConsent.scss';
import React, { useState, useEffect } from 'react';
import locales from 'locales';
import { useSelector } from 'react-redux';
import { Form, Checkbox } from 'components';
import { ValidationRules } from 'utils';
import { LOCATION_CONTENT_MANAGEMENT_SLUGS } from 'modules/location';
import MedicalConceptPopup from '../../../dialogs/medicalConsent';

const SLUG_CONSTANT = LOCATION_CONTENT_MANAGEMENT_SLUGS.MEDICAL.key;

const MedicalConsent = () => {
    const { routes: { profile: strings } } = locales;
    const rules = ValidationRules.translate();
    const { common } = locales;
    const { contentManagement, title: locationTitle } = useSelector(state => state.location);
    const [visible, setVisible] = useState(false);
    
    const defaultTitle = common.customContent[SLUG_CONSTANT]?.title;
    const defaultDescription = common.customContent[SLUG_CONSTANT]?.text;

    const [title, setTitle] = useState(defaultTitle);
    const [description, setDescription] = useState(defaultDescription);
    const hasContent = title || description;

    useEffect(() => {
        if (contentManagement) {
            const customContent = contentManagement.find(item => item.type === SLUG_CONSTANT);
            if (customContent?.title) {
                setTitle(locales[customContent.title]);
            }
            if (customContent?.description) {
                setDescription(locales[customContent.description]);
            }
        }
    }, [contentManagement]);

    const onClick = () => {
        setVisible(true);
    };

    const { medicalConsentAfter, medicalConsentMiddle } = strings.labels;
    const medicalConsentBefore = locales
        .formatString(strings.labels.medicalConsentBefore, { locationTitle });

    return (
        <>
            <MedicalConceptPopup 
                visible={visible} 
                onClose={() => setVisible(false)} 
            /> 
            <Form.Item
                name="isMedicalConsentAccepted"
                valuePropName="checked"
                rules={[rules.terms]}
            >
                <Checkbox>
                    {medicalConsentBefore}
                    {' '}
                    {hasContent ? (
                        <span
                            role="button"
                            tabIndex={-1}
                            onKeyDown={null}
                            onClick={onClick}
                            className="consent-btn"
                        >
                            {medicalConsentMiddle}
                        </span>
                    ) : medicalConsentMiddle}
                    {' '}
                    {medicalConsentAfter}
                </Checkbox>
            </Form.Item>
        </>
    );
};

export default MedicalConsent;
