/* eslint-disable class-methods-use-this */
import { showError } from 'utils';
import constants from './notification.constants';
import { NotificationService } from './notification.service';

class NotificationActions {
    static getAlertsUnread() {
        return dispatch => NotificationService.getAlertsUnread()
            .then(({ data }) => {
                dispatch(({ type: constants.ADD, data }));
                return data;
            })
            .catch(showError);
    }

    static getAlersHistory() {
        return dispatch => NotificationService.getAlersHistory()
            .then(({ data }) => {
                dispatch(({ type: constants.LIST, data }));
                return data;
            })
            .catch(showError);
    }

    static markShow(id) {
        return dispatch =>
            dispatch(({ type: constants.SHOW, id }));
    }

    static markRead(id) {
        return dispatch => NotificationService.markRead(id)
            .then((data) => {
                dispatch(({ type: constants.READ, id }));
                return data;
            })
            .catch(showError);
    }

    static delete(id) {
        return (dispatch) => {
            dispatch(({ type: constants.DELETING, id }));
            return NotificationService.delete(id)
                .then((data) => {
                    dispatch(({ type: constants.DELETE, id }));
                    return data;
                })
                .catch(showError);
        };
    }

    static download(id, link) {
        return dispatch => NotificationService.download(link, true)
            .then((data) => {
                dispatch(({ type: constants.READ, id }));
                return data;
            })
            .catch(showError);
    }
}

export { NotificationActions }; 
