import React from 'react';
import PropTypes from 'prop-types';

import TooltipMUI from '@mui/material/Tooltip';

const Tooltip = ({ title, children }) => (
    <TooltipMUI disableFocusListener disableTouchListener arrow title={title}>
        <div>{children}</div>
    </TooltipMUI>
);

Tooltip.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.elementType.isRequired
};

export { Tooltip };
