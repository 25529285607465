import { showError, GA } from 'utils';
import { PatientActionsUser } from 'modules/patient';
import { message } from 'components';
import constants from './screening.constants';
import service from './screening.service';

const { START, PREV, ANSWER, END, SENT } = constants; 

class ScreeningActions {
    static start(config) {
        return dispatch => dispatch({ type: START, config });
    }

    static answer(answer) {
        return dispatch => dispatch({ type: ANSWER, answer });
    }

    static prev() {
        return dispatch => dispatch({ type: PREV });
    }

    static cancel() {
        return dispatch => dispatch({ type: END });
    }

    static end(data) {
        return async (dispatch) => { 
            await dispatch(PatientActionsUser.get());
            dispatch({ type: END, data });
        };
    }
        
    static get(id) {
        return service.get(id);
    }  
    
    static list(...args) {
        return service.list(...args);
    }

    static listByPatient(id, ...args) {
        return service.listByPatient(id, ...args);
    }
    
    static quickScreen(data) {
        return async (dispatch) => { 
            await service.quickScreen(data)
                .then(res => message.success(res.message || 'Success'))
                .catch(showError);
            dispatch({ type: END });
        };
    }

    static send(...args) {
        GA.version();
        /* @TEST Uncomment this to test the flows 
        return dispatch => new Promise((resolve) => {
            console.info(...args);
            dispatch({ type: SENT, data: {} });
            resolve({ outcome: 1 });
        }); */
       
        return dispatch => service.send(...args)
            .then((data) => {
                dispatch({ type: SENT, data });
            });
    }
}

export default ScreeningActions; 
