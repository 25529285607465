/* eslint-disable max-len */
import './questions.scss';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'components';
import { ScreeningActions } from 'modules/screening';
import locales from 'locales';
import PropTypes from 'prop-types';
import { scrollTop } from 'utils';
import { LeaveDialog } from 'apps/lintelio/containers';
import { BackButton, CloseButton, Wrapper } from 'apps/lintelio/layout';

import Banner from './banner';
import renderField from './fields';
import QuickScreenMessage from '../quick/message';

const AutoscreeningQuestions = ({ setIsQuick }) => {
    const { routes: { screening: strings } } = locales;
    const { current, answers, title } = useSelector(state => state.screening); 
    const { quickScreening } = useSelector(state => state.patient);
    const dispatch = useDispatch();
    const [value, setValue] = useState();
    const [confirmVisible, setConfirmVisible] = useState(false);
    const disablePrevButton = !current.index;
    const [showQuickScreening, setShowQuickScreening] = useState(true);

    if (!current) return null;

    const cancel = () => dispatch(ScreeningActions.cancel());

    const onCancel = () => {
        if (answers.length) {
            setConfirmVisible(true);
        } else {
            cancel();
        }
    };
    
    const gotoPrev = () => {
        setValue();
        dispatch(ScreeningActions.prev());
        scrollTop();
    };
    
    const gotoNext = () => {
        setShowQuickScreening(false);
        setValue();
        dispatch(ScreeningActions.answer({ ...current, value }));
        scrollTop();
    };
    
    const { banner, label } = current;
    const disabledNext = !value || (Array.isArray(value) && !value.length);
    const wrapperProps = {
        title: title || strings.questions.title,
        left: (
            <BackButton
                onClick={gotoPrev}
                disabled={disablePrevButton}
            />
        ),
        right: (
            <CloseButton
                type="link right"
                onClick={onCancel}
            />
        ),
        footer: (
            <Button
                type="primary brand"
                block
                onClick={gotoNext}
                disabled={disabledNext}
            >
                {strings.questions.continue}
            </Button>
        ),
        className: 'autoscreening-questions'
    };

    return (
        <Wrapper {...wrapperProps}>
            { quickScreening && showQuickScreening && (
                <QuickScreenMessage setIsQuick={setIsQuick} />
            )}

            { banner && (
                <Banner text={banner} />
            )}

            <LeaveDialog 
                visible={confirmVisible} 
                setVisible={setConfirmVisible} 
                onOk={cancel}
            />

            <div className="questions">
                <h1
                    dangerouslySetInnerHTML={{ __html: label }}
                />

                { renderField(current, value, setValue) }
            </div>
        </Wrapper>
    );
};

AutoscreeningQuestions.propTypes = {
    setIsQuick: PropTypes.func.isRequired
};

export default AutoscreeningQuestions;
