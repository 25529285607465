import './banner.scss';
import React from 'react';
import dayjs from 'dayjs';
import locales from 'locales';
import { formats } from 'utils';
import { Banner } from 'components';

const QuickScreenBanner = (quickScreening) => {
    const { routes: { screening: strings } } = locales;
    const { createdAt } = quickScreening;

    const date = dayjs(createdAt).format(formats.date.in);
    const time = dayjs(createdAt).format('hh:mma');

    return (
        <Banner className="white quick-screening-banner">
            <h1>{strings.quickScreening.header.title}</h1>
            <span>
                {locales.formatString(
                    strings.quickScreening.header.body,
                    { date, time }
                )}
            </span>
        </Banner>
    );
};

export default QuickScreenBanner;
