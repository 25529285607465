import './sidemenu.items.settings.lang.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { List, ListItem } from 'components';
import { withLang } from 'apps/lintelio/containers';
import { BackButton } from 'apps/lintelio/layout';

const SideMenuItemsSettingsLang = ({ onBack, setLang, langOptions, currentLang, onClose }) => {
    const onClick = (val) => {
        setLang(val);
        onClose();
    };

    return (
        <List className="menu-list-lang">
            <ListItem onClick={onBack}>
                <BackButton 
                    size="small" 
                    onClick={() => {}} 
                    text
                />
            </ListItem>

            { langOptions.map(item => (
                <ListItem
                    key={item.value}
                    prefix={<img src={item.icon} alt={item.label} />}
                    onClick={() => onClick(item.value)}
                    disabled={item.value === currentLang.key}
                >
                    {item.label}
                </ListItem>
            ))}
        </List>
    );
};

SideMenuItemsSettingsLang.propTypes = {
    setLang: PropTypes.func.isRequired,
    onBack: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    langOptions: PropTypes.array.isRequired,
    currentLang: PropTypes.object.isRequired,
};

SideMenuItemsSettingsLang.defaultProps = {
    onBack: () => {},
};

export default withLang(SideMenuItemsSettingsLang);
