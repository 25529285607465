/* eslint-disable no-console */

import React from 'react';
import PropTypes from 'prop-types';
import { Auth } from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import { history } from 'routing';
import { local } from 'services';
import { signOut } from './utils';
import { AuthState, AuthConstants, HubEvents } from './constants';
import { AuthContext } from './context';

const EmptyContainer = ({ children }) => children;

EmptyContainer.propTypes = {
    children: PropTypes.any.isRequired
};

class Authenticator extends React.Component {
    _initialAuthState;

    _mounted;

    constructor(props) {
        super(props);
        const { initialAuthState } = this.props;

        this._initialAuthState = initialAuthState;

        this.state = {
            authState: this._initialAuthState,
            authLoading: false
        };
        Hub.listen('auth', this.onHubCapsule);
    }

    componentDidMount() {
        this._mounted = true;

        // The workaround for Cognito Hosted UI:
        // Don't check the user immediately if redirected back from Hosted UI as
        // it might take some time for credentials to be available, instead
        // wait for the hub event sent from Auth module. This item in the
        // localStorage is a mark to indicate whether the app is just redirected
        // back from Hosted UI or not and is set in Auth:handleAuthResponse.
        const {
            REDIRECTED_FROM_HOSTED_UI,
            SIGNIN_FROM_HOSTED_UI
        } = AuthConstants;
        const redirectedFromHostedUI = localStorage.getItem(REDIRECTED_FROM_HOSTED_UI);
        const signInFromHostedUI = localStorage.getItem(SIGNIN_FROM_HOSTED_UI);
        localStorage.removeItem(REDIRECTED_FROM_HOSTED_UI);
        const fromHostedUI = redirectedFromHostedUI === 'true' || signInFromHostedUI === 'true';
        // SSO & Social Logins doesn't need MFA
        this.checkUser({ checkMFA: !fromHostedUI });
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    onHubCapsule = async (capsule) => {
        const { channel, payload } = capsule;
        if (channel === 'auth') {
            const user = payload.data;

            switch (payload.event) {
                case HubEvents.customOAuthState: {
                    this.redirectToHome();
                    break;
                }
                case HubEvents.customState_failure: {
                    console.error('customState_failure', payload);
                    break;
                }
                case HubEvents.customSignIn: {
                    user.$customSignedIn = true;
                    this.handleStateChange(AuthState.SignedIn, user);
                    break;
                }
                case HubEvents.cognitoHostedUI:
                    this.redirectToHome();
                    break;
                case HubEvents.signIn: {
                    this.checkUser({ checkMFA: true, bypassCache: true });
                    break;
                }
                case HubEvents.cognitoHostedUI_failure:
                    this.handleStateChange(this._initialAuthState, null);
                    break;
                case HubEvents.parsingUrl_failure:
                    this.handleStateChange(this._initialAuthState, null);
                    break;
                case HubEvents.signOut: {
                    this.handleStateChange(AuthState.SignedOut, null);
                    this.redirectToHome();
                    break;
                }
                default:
                    break;
            }
        }
    };

    handleStateChange = async (authState, data) => {
        const { onStateChange } = this.props;
        const state = authState;

        try {
            localStorage.setItem(AuthConstants.AUTHENTICATOR_AUTHSTATE, state);
        } catch (e) {
            console.error('Failed to set the auth state into local storage', e);
        }

        let next = true;
        if (onStateChange) {
            const res = await onStateChange(state, data);
            next = !!res;
        }
        if (next && this._mounted) {
            this.setState({
                authState: state,
                authData: data,
                authLoading: false,
            });
        }
    };

    // eslint-disable-next-line class-methods-use-this
    handleAuthEvent = (state, event) => {
        const { onError } = this.props;
        onError(state, event);
    };
    
    // eslint-disable-next-line class-methods-use-this
    redirectToHome = () => {
        const isAWSAuthCallback = history.location.pathname 
            === `/${AuthConstants.AWS_AUTH_CALLBACK}`;

        if (isAWSAuthCallback) {
            let path = '/';
            const locationHash = local.hash();
            if (locationHash) {
                path = locationHash;
            } 
            history.push(path);
            // Force refresh
            history.go();
        }
    };

    checkUser = async ({ bypassCache = false } = {}) => { // , checkMFA = true
        const { authData } = this.state;
        if (authData?.$customSignedIn) {
            return;
        }
        this.setState({ authLoading: true });
        try {
            const user = await Auth.currentAuthenticatedUser({ bypassCache });
            if (!this._mounted) {
                return;
            }
            
             /**  if (isAdminPortal && isAdminFlowTypeCustom && checkMFA) {
                const { attributes } = user;
                const mfaType = attributes[USER_ATTRS.mfa];
                if (!mfaType) {
                    this.handleStateChange(AuthState.MFASetup, user);
                    return;
                }

                const mfaIsPhone = mfaType === USER_ATTRS.phone;
                const mfaPhoneIsNotVerified = mfaIsPhone && !attributes.phone_number_verified;
                if (mfaPhoneIsNotVerified) {
                    this.handleStateChange(AuthState.VerifyPhone, user);
                    return;
                }

                const mfaIsEmail = mfaType === USER_ATTRS.email;
                const mfaEmailIsNotVerified = mfaIsEmail && !attributes.email_verified;
                if (mfaEmailIsNotVerified) {
                    this.handleStateChange(AuthState.VerifyEamil, user);
                    return;
                }
            }
            */
            this.handleStateChange(AuthState.SignedIn, user);
        } catch (exceptionCar) {
            if (!this._mounted) {
                return;
            }

            let cachedAuthState = null;
            try {
                cachedAuthState = localStorage.getItem(AuthConstants.AUTHENTICATOR_AUTHSTATE);
            } catch (e) {
                console.error('Failed to get the auth state from local storage', e);
            }

            const isCachedSignedIn = cachedAuthState === AuthState.SignedIn;
            const promise = isCachedSignedIn ? signOut() : Promise.resolve();
            promise
                .then(() => this.handleStateChange(this._initialAuthState))
                .catch((e) => {
                    console.error('Failed to sign out', e);
                });
        } finally {
            this.setState({ authLoading: false });
        }
    };

    render() {
        const { authState, authData, authLoading } = this.state;
        const {
            container,
            children,
            loading,
        } = this.props;

        const Wrapper = [AuthState.SignedIn, AuthState.PreSignIn].includes(authState)
            ? EmptyContainer : (container || EmptyContainer);

        let propsChildren = [];

        if (Array.isArray(children)) {
            propsChildren = children;
        } else if (Object.isObject(children)) {
            propsChildren.push(children);
        }

        const loadingMerged = loading || authLoading;

        const childrenProps = (child, index) => React.cloneElement(child, {
            key: `aws-amplify-authenticator-default-children-${index}`,
        });

        const renderedChildren = React.Children
            .map(propsChildren, childrenProps);

        // eslint-disable-next-line react/jsx-no-constructed-context-values
        const contextValue = {
            authState,
            authData,
            loading: loadingMerged,
            signOut,
            changeState: this.handleStateChange,
            onAuthEvent: this.handleAuthEvent,
            checkUser: this.checkUser
        };
        // disable react/jsx-no-constructed-context-values
        return (
            <Wrapper
                loading={loadingMerged}
                className={authState}
                authState={authState}
            >
                <AuthContext.Provider value={contextValue}>
                    {renderedChildren}
                </AuthContext.Provider>
            </Wrapper>
        );
    }
}

Authenticator.propTypes = {
    initialAuthState: PropTypes.string,
    children: PropTypes.any.isRequired,
    container: PropTypes.func,
    loading: PropTypes.bool.isRequired,
    onStateChange: PropTypes.func,
    onError: PropTypes.func.isRequired
};

Authenticator.defaultProps = {
    initialAuthState: AuthState.SignIn,
    onStateChange: undefined,
    container: undefined,
};

export { Authenticator, Hub };
