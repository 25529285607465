export default {
    // title: 'Báo cáo Kết quả Kiểm tra',
    testingTitle: 'Report Test', // @NEW
    vaccinationTitle: 'Report Vaccination', // @NEW
    boosterTitle: 'Report Booster', // @NEW
    vaccinations: 'Vaccinations', // @NEW
    tests: 'Tests', // @NEW
    exemptTitle: 'Report Exemption (Unvaccinated)', // @NEW
    message: 'Báo cáo Kiểm tra đã được gửi thành công!',
    messageVaccination: 'Báo cáo chích ngừa đã được gửi thành công!',
    testSuccessReportTitle: 'Test reported successfully!', // @NEW
    testSuccessReportText: 'Thank you for reporting your test.', // @NEW
    vaccineSuccessReportTitle: 'Vaccination reported successfully!', // @NEW
    vaccineSuccessReportText: 'Thank you for reporting your vaccination.', // @NEW
    boosterSuccessReportTitle: 'Booster reported successfully!', // @NEW
    boosterSuccessReportText: 'Thank you for reporting your booster.', // @NEW
    optOutSuccessReportTitle: 'Exemption (Unvaccinated) reported successfully!', // @NEW
    optOutSuccessReportText: 'Thank you for reporting.', // @NEW
    backToHomeBtnText: 'Back to Homepage', // @NEW
    noTests: 'No reported tests yet', // @NEW
    noVaccinations: 'No reported vaccinations yet', // @NEW
    viewMore: 'View More', // @NEW
    viewLess: 'View Less', // @NEW
    tooltip: {
        testLocation: 'Please provide the location where you were tested or the ID of your testing proctor.', // @NEW
    },
    form: {
        manufacturer: 'Nhà sản xuất thuốc chích ngừa',
        firstShot: '(thay đổi nếu không phải hôm nay)',
        shot: 'Ngày tháng',
        vaccineLocation: 'Địa điểm chích ngừa',
        vaccineManufacturer: 'Please select manufacturer first', // @NEW
        testResult: 'Kết quả xét nghiệm',
        dateOfTest: 'Ngày xét nghiệm',
        testReportDate: 'Test Report Date', // @NEW
        testLocation: 'Location/Proctor ID', // @NEW
        attachments: 'Tập tin gửi kèm',
        attachmentsTooltip: 'Supported file types: .pdf, .xls, .xlsx, .doc, .docx, .jpg, .jpeg, .png, .heif', // @NEW
        attachmentsTab: 'Nhấn vào để tải lên',
        lotNumber: 'Số lô',
        secondShotSection: 'Liều thứ hai',
        firstShotSection: 'Liều thứ nhất',
        boosterDose: 'Booster Dose', // @NEW
        doseSection: 'Liều',
        testNegative: 'COVID-19 Negative', // @NEW
        testPositive: 'COVID-19 Positive', // @NEW
        testInconclusive: 'Inconclusive', // @NEW
        antigen: 'Antigen', // @NEW
        pcr: 'PCR', // @NEW
        home: 'Home', // @NEW
        lab: 'Laboratory', // @NEW
        homeProctored: 'Home-Proctored', // @NEW
        testType: 'Test Type', // @NEW
        testTakenAt: 'Test Taken At', // @NEW
    },
    formExempt: {
        reason: 'Reason', // @NEW
        religion: 'Religious', // @NEW
        disability: 'Medical', // @NEW
        other: 'Other', // @NEW
    }
};
