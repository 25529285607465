import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Link, homePath } from 'routing';
import { Avatar, List, ListItem, Divider } from 'components';
import { TermsLink, PolicyLink } from 'brands';
import { signOut } from 'security';

import { LayoutConstants } from 'modules/layout';
import locales from 'locales';
import settings from 'apps/lintelio/assets/icons/ico-settings.svg';
import privacy from 'apps/lintelio/assets/icons/ico-privacy.svg';
import termsOfUse from 'apps/lintelio/assets/icons/ico-terms.svg';
import support from 'apps/lintelio/assets/icons/ico-support.svg';
import logout from 'apps/lintelio/assets/icons/ico-logout.svg';
import InstallAppContainer from './sidemenu.install';

const icons = {
    privacy: <div className="icon"><img src={privacy} alt="Privacy" /></div>,
    settings: <div className="icon"><img src={settings} alt="Settings" /></div>,
    terms: <div className="icon"><img src={termsOfUse} alt="terms" /></div>,
    support: <div className="icon"><img src={support} alt="Support" /></div>,
    signOut: <div className="icon"><img src={logout} alt="Logout" /></div>
};

const SideMenuItems = ({ onNext, onClose }) => {
    const dispatch = useDispatch();
    const { common, common: { menu: strings } } = locales;
    const { installBannerHidden } = useSelector(state => state.layout);
    const { firstName, lastName, employeeNumber, avatar } = useSelector(state => state.patient);

    const onSignOut = () => {
        onClose();
        signOut();
    };

    const hideInstallBanner = () => {
        dispatch({ type: LayoutConstants.INSTALL_BANNER_HIDE });
    };
    
    return (
        <div>
            <List>
                <ListItem 
                    className="person-info" 
                    arrow
                    prefix={<Avatar>{avatar}</Avatar>}
                    onClick={onClose}
                >
                
                    <Link to={`${homePath}profile`}>
                        <span>{firstName} {lastName}</span>
                        {employeeNumber && (
                        <small className="blurred">
                            ID: {employeeNumber}
                        </small>
                    )}
                    </Link>
                </ListItem>
                <Divider />
                <ListItem
                    prefix={icons.settings}
                    arrow
                    onClick={onNext}
                >
                    {strings.settings}
                </ListItem>
                <ListItem
                    prefix={icons.privacy}
                    arrow
                >
                    <PolicyLink />
                </ListItem>
                <ListItem
                    prefix={icons.terms}
                    arrow
                >
                    <TermsLink />
                </ListItem>
                
                <ListItem
                    prefix={icons.support}
                    arrow
                    onClick={onClose}
                >
                    <Link to={`${homePath}support`}>
                        {strings.support}
                    </Link>
                </ListItem>
                <ListItem 
                    prefix={icons.signOut} 
                    onClick={onSignOut}
                >
                    {common.signOut}
                </ListItem>
            </List>
        
            {!installBannerHidden 
                && <InstallAppContainer hide={hideInstallBanner} />}
        </div>
    ); 
};

SideMenuItems.propTypes = {
    onNext: PropTypes.func,
    onClose: PropTypes.func.isRequired,
};

SideMenuItems.defaultProps = {
    onNext: () => {}
};

export default SideMenuItems;
