import { LOCAL_KEY } from 'modules/notification';
import { PatientConstants } from 'modules/patient/patient.constants';
import { now } from 'utils';
import constants from './notification.constants';

const initialState = Object.freeze([]);

const notifications = (state = initialState, action = {}) => {
    switch (action.type) {
        case constants.ADD: {
            const newIds = action.data.map(i => i.id);
            const oldItems = state.filter(({ id }) => !newIds.includes(id));
            return [
                ...oldItems,
                ...action.data,
            ];
        }
        case constants.LIST: {
            return [
                ...action.data,
            ];
        }
        case constants.SHOW: {
            const lastIds = JSON.parse(localStorage.getItem(LOCAL_KEY)) || [];
            return state
                .map((item) => {
                    if (!lastIds.find(i => i === item.id)) {
                        localStorage.setItem(LOCAL_KEY, JSON.stringify([...lastIds, item.id]));
                    }
                    return ({
                        ...item,
                        show: (item.show || item.id === action.id)
                    });
                });
        }
        case constants.READ: {
            return state
                .map(item => ({
                    ...item,
                    readAt: (
                        item.readAt
                            || item.id === action.id ? now() : null
                    )
                }));
        }
        case constants.DELETING: {
            return state
                .map(item => ({
                    ...item,
                    deleting: item.id === action.id
                }));
        }
        case constants.DELETE: {
            return state
                .filter(item => item.id !== action.id);
        }
        case constants.DISMISS: {
            return state
                .map(item => ({
                    ...item,
                    readAt: (
                        item.readAt
                        || (item.type === action.dismissedType ? now() : null)
                    )
                }));
        }
        case PatientConstants.LOGOUT: {
            return initialState;
        }
        default:
            return state;
    }
};

export default notifications;
