import './Empty.scss';
import React from 'react';
import PropTypes from 'prop-types';
import EmptyImage from '../../assets/images/ico.empty.svg';

const Empty = ({ description, className, image }) => (
    <div className={`empty ${className}`}>
        <img src={image || EmptyImage} alt="Empty" />
        <span>{description} </span>
    </div>
);

Empty.propTypes = {
    description: PropTypes.any,
    className: PropTypes.string,
    image: PropTypes.any
};

Empty.defaultProps = {
    className: '',
    description: 'No data found',
    image: null
};

export { Empty };
